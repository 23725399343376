import axios from 'axios';

const translateWord = async (word, target) => {

  let apiUrl;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    apiUrl = "http://localhost:8080/api/translate";
  } else {
    // apiUrl = "https://nvl-api-microservice.herokuapp.com/api/translate";
    apiUrl = "https://microservice.flowspeak.io/api/translate";
  }

  apiUrl = apiUrl + `?text=${word}&target=${target}&auth=l0091`;

  const url = encodeURI(apiUrl);

  var config = {
    method: 'get',
    url: url,
    headers: { "Content-Type": "application/json" },
  };

  // Send the data to the server
  return axios(config)
  .then(function (response) {

    let responseData;

    // statusText = 'OK'
    if( response.status === 200 && response.data && !response.data.errors ) {
      responseData = {translated: true, translation: response.data};
    } else {
      responseData = {translated: false, error: 'Could not tranlsate', translation: 'Sorry, we could not translate into the native langauge that is set in your profile'};
    }

    return responseData;
  })
  .catch(function (error) {
    return {"transcribed": false, "error": error.message};
  });

}

export default translateWord;