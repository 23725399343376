import { Link } from "react-router-dom";


function LessonDetailUpgrade() {

  return (
    <div class='lesson-no-access-message flex-column'>
      <h4>Sorry &#128546;</h4>
      <p class='has-text-centered special mt-2'>Detailed response data is a premium feature</p>
      <p class='has-text-centered mt-3'>Upgrade to see the detailed breakdown and get unlimited access to Premium Lessons</p>
      <Link to='/packages' class='' title='View Plans'>View Plans</Link>
    </div>
  );
}

export default LessonDetailUpgrade;