import React from "react";
import { Link } from "react-router-dom"


function ProfileSubNav() {

  let infoClass, preferencesClass, subscriptionClass;
  const pathname = window.location.pathname;

  switch (pathname) {
    case '/profile/preferences':
      preferencesClass = 'active';
      break;
    case '/profile/subscription':
      subscriptionClass = 'active';
      break;
    default:
      infoClass = 'active';
      break;
  }


  return (

    <div class='profile-subnav'>
      <Link to='/profile' class={infoClass} alt='View Profile'>My Info</Link>
      <Link to='/profile/preferences' class={preferencesClass} alt='View Profile'>Preferences</Link>
      <Link to='/profile/subscription'class={subscriptionClass} alt='View Profile'>Subscription</Link>
    </div>
  )
}

export default ProfileSubNav;