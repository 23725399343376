import React from "react";
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import '../../styles/bites.css';

import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import alertMessage from "../utilities/alertMessage";
import apiCall from '../utilities/auth/apiCall';
import { getBite, logExerciseStart } from "../utilities/auth/authApi";
import sendExerciseData from "../../functions/sendExerciseData";
import fadeInMainBody from "../../functions/fadeinmainbody";

import CurrentQuestion from "./currentquestion";
import CurrentQuestionSpeak from "./currentquestionspeak";
import BiteLeftColumn from "./biteleftcolumn";
import LessonCompleteModal from "../utilities/lessonCompleteModal";

let responsesArray = [];
let retryLimit = 20;


function Bite() {

  // Dispatch functions
  const dispatch = useDispatch();

  function setActivePlaying(status) {
    dispatch({ type: 'setActivePlaying', payload: status});
  }

  function clearDashboardData() {
    dispatch({ type: 'clearDashboardData' });
  }

  function clearGrammarErrors() {
    dispatch({ type: 'clearGrammarErrors', payload: {}});
  }


  // Define variables
  const { id } = useParams();

  const initialCurrentQuestion = {
    title: 'Loading...',
    answers: []
  }

  const initialQuestionData = {
    questions: [],
    nextLesson: {},
    relatedLessons: []
  }

  const [questionData, setQuestionData] = useState(initialQuestionData);
  const [exerciseId, setExerciseId] = useState(null);
  const [completeElement, setCompleteElement] = useState(null);
  const [currentType, setCurrentType] = useState('question');
  const [ieltsCourse, setIeltsCourse] = useState('question');

  const [currentQuestion, setCurrentQuestion] = useState(initialCurrentQuestion);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);

  const [currentSpeak, setCurrentSpeak] = useState(initialCurrentQuestion);
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [currentSpeakNumber, setCurrentSpeakNumber] = useState(0);
  const [currentAnswerNumber] = useState(0);
  const [responseText, setResponseText] = useState(null);
  // const [responseBlobUrl, setResponseBloresponseBlobUrl] = useState(null);
  const [responseObject, setResponseObject] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  const [translateToggle, setTranslateToggle] = useState(false);
  const [translateContent, setTranslateContent] = useState(null);


  // Component load actions
  useEffect(() => {

    setActivePlaying(false);

    // Ensure everything is reset
    reset();

    fadeInMainBody();

    getBite(id).then((response) => {

      if( response && response.data && response.data.questions ) {

        const nextLesson = response.data.nextLesson;
        const relatedLessons = response.data.relatedLessons;
        const questions = response.data.questions;
        const currentQuestion = response.data.questions[0];
        const currentAnswer = currentQuestion.answers.filter(answer => answer.correctAnswer)[0];
        const ieltsCourse = response.data.ieltsCourse;

        const questionData = {
          questions: questions,
          nextLesson: nextLesson,
          relatedLessons: relatedLessons
        }

        setQuestionData(questionData);

        setCurrentQuestion(currentQuestion);
        setCurrentSpeak(currentQuestion);
        setCurrentAnswer(currentAnswer);
        setIeltsCourse(ieltsCourse);

        retryLimit = response.data.retryLimit;

      } else {
        // Handle
      }

    });

  }, [id]);


  // Define functions
  function nextAction() {

    const backgroundImage = document.getElementsByClassName('bite-background-image')[0];
    backgroundImage.classList.remove('fade-in-on-load');

    if( currentType === 'speak' ) {

      // Add the rest of the necessary data to the resonseObject then store it
      responseObject.text = responseText;

      //responseObject.audioFileUri

      responseObject.retryCount = retryCount;
      responsesArray.push(responseObject);


      if( questionData.questions.length === (currentSpeakNumber + 1) ) {

        // End of Bite
        completeBite();

      } else {

        const currentAnswersLength = currentQuestion.answers.filter(answer => answer.correctAnswer).length;

        if( currentAnswersLength === (currentAnswerNumber + 1)) {

          // Next question
          const newCurrentSpeakNumber = currentSpeakNumber + 1;
          const currentAnswer = questionData.questions[newCurrentSpeakNumber].answers.filter(answer => answer.correctAnswer)[0];

          setRetryCount(0);
          setResponseText(null);
          setResponseObject(null);
          // setResponseBloresponseBlobUrl(null);
          setCurrentAnswer(currentAnswer);
          clearGrammarErrors();

          // Give it just a moment so the next image will fade in
          setTimeout(() => {
            backgroundImage.classList.add('fade-in-on-load');

            setCurrentSpeakNumber(newCurrentSpeakNumber);
            setCurrentSpeak(questionData.questions[newCurrentSpeakNumber]);

          }, 100);

        } else {
          // Next answer
        }
      }


    } else {

      if( questionData.questions.length === (currentQuestionNumber + 1) ) {

        // End of questions
        setCurrentType('speak');

      } else {

        const newCurrentQuestionNumber = currentQuestionNumber + 1;

        // Give it just a moment so the next image will fade in
        setTimeout(() => {
          backgroundImage.classList.add('fade-in-on-load');

          setCurrentQuestionNumber(newCurrentQuestionNumber);
          setCurrentQuestion(questionData.questions[newCurrentQuestionNumber]);

        }, 100);

      }

    }
  }

  function handleTranscript(transcript, audioBlobUrl, blob, responseObject) {

    // Need to manually set this so we can save the audio later
    responseObject.blob = blob;

    // setResponseText(transcript);
    setResponseText(currentAnswer.text);
    setResponseObject(responseObject);
    // setResponseBloresponseBlobUrl(audioBlobUrl);
  }

  function tryAgain() {
    setResponseText(null);
    setResponseObject(null);
    // setResponseBloresponseBlobUrl(null);

    // Log the retries
    const newRetryCount = retryCount + 1;
    setRetryCount(newRetryCount);

    // Clear the grammar check data
    clearGrammarErrors();

  }

  function handleNoTalkClick() {
    // End the Bite
    completeBite();
  }

  function completeBite() {

    // Send the responses to the server
    const payload = {
      lessonType: 'Bite',
      exerciseId: exerciseId,
      lessonId: id,
      responsesToSave: responsesArray
    }

    sendExerciseData(payload).then((response) => {

      if( response.success ) {

        setCompleteElement(<LessonCompleteModal data={response.data} />);

        // Make the dashboard recall lesson data
        // invalidateInitialized();
        clearDashboardData();

        // Clear the grammar check data
        clearGrammarErrors();

        // Log the event
        Mixpanel.track('Event: Lesson Complete', {'Lesson ID': id, 'Lesson Type': 'Bite'});

      } else {
        alertMessage(response.error, 'error');
      }

    });

  }

  function logStart() {
    if( !exerciseId ) {
      const payload = {
        lesson_id: id,
        started: true,
        lesson_type: 'Bite'
      }

      apiCall(logExerciseStart, payload).then((response) => {
        if( response.success ) {
          setExerciseId(response.data.exerciseId);
        }
      });
    }
  }

  function reset() {
    setQuestionData(initialQuestionData);
    setExerciseId(null);
    setCompleteElement(null);
    setCurrentType('question');
    setCurrentQuestion(initialCurrentQuestion);
    setCurrentQuestionNumber(0);
    setCurrentSpeak(initialCurrentQuestion);
    setCurrentAnswer('');
    setCurrentSpeakNumber(0);
    setResponseText(null);
    // setResponseBloresponseBlobUrl(null);
    setResponseObject(null);
    setRetryCount(0);
    setTranslateToggle(false);
    setTranslateContent(false);
    responsesArray = [];
  }


  // Screen components
  let current = (
    <CurrentQuestion
      currentQuestion={currentQuestion}
      nextAction={nextAction}
      translateToggle={translateToggle}
      setTranslateToggle={setTranslateToggle}
      translateContent={translateContent}
      setTranslateContent={setTranslateContent}
      id={id}
      logStart={logStart}
    />
  );

  if( currentType === 'speak' ) {
    current = (
      <CurrentQuestionSpeak
        currentQuestion={currentSpeak}
        currentAnswer={currentAnswer}
        nextAction={nextAction}
        handleNoTalkClick={handleNoTalkClick}
        handleTranscript={handleTranscript}
        // responseText={responseText}
        responseObject={responseObject}
        // responseBlobUrl={responseBlobUrl}
        tryAgain={tryAgain}
        ieltsCourse={ieltsCourse}
        retryCount={retryCount}
        retryLimit={retryLimit}
      />
    );
  }


  return (
    <div class='wrapper invisible'>
      {completeElement}
      <div class='lesson-body bite'>

        <BiteLeftColumn relatedLessons={questionData.relatedLessons} />

        <div class='lesson-right-column bites'>
          <section class='body-content'>
            {current}
          </section>
        </div>

      </div>
    </div>
  );
};

export default Bite;