import { useState } from 'react';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { Link } from 'react-router-dom';

import PathwayLevel from "../pathways/pathwayLevel";
import Badge from '../badge';
import BadgeInfoModal from '../badgeInfoModal';
import IssueDateElement from './issueDateElement';
import StatsRetakeLevels from './statsRetakeLevels';


function StatsPathways(props) {

  // Define variables
  const hasPremium = useSelector((state) => state.profile.hasPremiumSubscription);
  const [badgeInfoModal, setBadgeInfoModal] = useState(null);
  const isThisLearner = props.isThisLearner;
  const pathwayData = props.pathwayData;
  const hasAnyBlocks = pathwayData?.hasAnyBlocks|| false;
  const activePathway = pathwayData?.activePathway || null;
  const activeLevel = pathwayData?.activeLevel || null;
  const completedLevels = pathwayData?.completedLevels || [];
  const certificates = pathwayData?.certificates || [];
  const badgesList = pathwayData?.badges || [];
  const earnedBadges = badgesList.filter(badge => badge.issueDate);
  const badges = isThisLearner ? badgesList : earnedBadges;
  const retakeLevels = pathwayData?.retakeLevels || [];
  const hasActiveCourse = pathwayData?.hasActiveCourse || false;

  const completedLevelsBeginner = completedLevels[0] || [];
  const completedLevelsIntermediate = completedLevels[1] || [];
  const completedLevelsAdvanced = completedLevels[2] || [];



  // Define functions
  function showBadgeInfoModal(badge) {
    if( isThisLearner ) {
      setBadgeInfoModal(
        <BadgeInfoModal
          badge={badge}
          closeModal={closeBadgeInfoModal}
        />
      );
    }
  }

  function closeBadgeInfoModal() {
    setBadgeInfoModal(null);
  }


  // Screen components
  let pathwayElement;
  if( isThisLearner ) {

    let bottomElement;
    if( !hasAnyBlocks && !hasActiveCourse ) {
      bottomElement = (
        <div style={{marginTop: '15px'}}>
          <Link to='/welcome-choose-pathway' title='Start a Pathway'>
            Get started by selecting a learning pathway
          </Link>
        </div>
      );
    }

    pathwayElement = (
      <div>
        <PathwayLevel pathway={1} selectedPathway={activePathway} selectedLevel={activeLevel} completeLevels={completedLevelsBeginner} certificateComplete={certificates.includes(1)} />
        <PathwayLevel pathway={2} selectedPathway={activePathway} selectedLevel={activeLevel} completeLevels={completedLevelsIntermediate} certificateComplete={certificates.includes(2)} />
        <PathwayLevel pathway={3} selectedPathway={activePathway} selectedLevel={activeLevel} completeLevels={completedLevelsAdvanced} certificateComplete={certificates.includes(3)} />
        {bottomElement}
      </div>
    );
  }


  let badgesElement;
  if( badges.length > 0 ) {

    let upgradeMessage;
    if( isThisLearner && !hasPremium ) {
      upgradeMessage = (
        <Link
          to='/packages'
          title='View Premium packages'
          style={{display: 'block', marginTop: '-15px', marginBottom: '15px'}}
        >
          Upgrade to Premium to earn your badges
        </Link>
      );
    }

    badgesElement = (
      <div style={{width: '100%', marginTop: '45px'}}>
        <h4 class='h4-pathway-stat'>Pathway Badges</h4>
        {upgradeMessage}
        <div class='flex-row stats-badges-container'>
          {badges.map((badge, index) =>
            <button
              key={`badge-wrapper-${index}`}
              type='button'
              onClick={() => showBadgeInfoModal(badge)}
              class={`clean-button pointer badge-wrapper ${badge.issueDate ? null : 'disabled'}`}
            >
              <div class='flex-column' style={{height: '100%'}}>
                <div class='stat-badge-image-container'>
                  <Badge badge={badge} achieved={badge.issueDate} imageSize={150} />
                </div>
                <IssueDateElement issueDate={badge.issueDate} />
              </div>
            </button>
          )}
        </div>
      </div>
    );
  }

  let retakeElement;
  if( retakeLevels.length > 0 ) {
    retakeElement = <StatsRetakeLevels retakeLevels={retakeLevels} />;
  }

  let bodyElement;
  if( pathwayElement || badgesElement ) {
    bodyElement = (
      <div class='flex-column stat-container stats-pathways-wrapper'>
        {badgeInfoModal}
        <h3>Learning Pathways</h3>
        {retakeElement}
        {pathwayElement}
        {badgesElement}
      </div>
    )
  }


  return (
    <>{bodyElement}</>
  );
}

export default StatsPathways;