import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import alertMessage from '../../utilities/alertMessage';
import apiCall from "../../utilities/auth/apiCall";
import { createBlock } from "../../utilities/auth/authApi";
import PathwayLevel from "../../utilities/pathways/pathwayLevel";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";


function StepChoosePathway() {

  // Dispatch
  const dispatch = useDispatch();

  function clearDashboardData() {
    dispatch({ type: 'clearDashboardData' });
  }

  // Define variables
  const [pathway, setPathway] = useState(null);
  const [level, setLevel] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);


  // Define functions
  function handleSelect(selectedPathway) {
    setPathway(selectedPathway);
    // setLevel(selectedLevel);
    setLevel(1);
    setDisabled(false);
  }

  function handleSubmit() {

    if( !pathway || !level ) {
      alertMessage('Please select a Pathway and Level first', 'error')
      return;
    }

    setLoading(true);

    const payload = {
      pathway: pathway,
      level: level
    }

    apiCall(createBlock, payload).then((response) => {
      if( response.success ) {
        setTimeout(() => {
          clearDashboardData();
        }, 800);
      }
    }).finally(() => {
      setTimeout(() => {
        setLoading(false);
        setDisabled(true);
      }, 800);
    });

  }


  return (
    <div class='week-list-container'>
      <div>

        <h3>FLOW Speak learner pathways</h3>
        <p>Choose your learning pathway</p>

        <PathwayLevel pathway={1} handleSelect={handleSelect} selectedPathway={pathway} selectedLevel={level} />
        <PathwayLevel pathway={2} handleSelect={handleSelect} selectedPathway={pathway} selectedLevel={level} />
        <PathwayLevel pathway={3} handleSelect={handleSelect} selectedPathway={pathway} selectedLevel={level} />

        <div class='flex-column' style={{marginTop: '30px'}}>
          <ButtonSubmitRound
            value={"Let's Go!"}
            handleButtonClick={handleSubmit}
            disabled={disabled}
            loading={loading}
          />

          <p>
            <br />
            <a href='https://www.flowspeak.io/articles/pathways-level-up-your-speaking' target='_blank' rel="noreferrer" class='pathway-learn-link'>Learn more about the FLOW Speak Learning Pathways</a>
          </p>
        </div>

      </div>
    </div>
  );
}

export default StepChoosePathway;