import React from "react";

import PostText from "./posttext";
import PostImage from "./postimage";


function PostRouter(props) {

  const post = props.post;
  const postType = post.post_type;
  const noLinks = props.noLinks || null;

  let postComponent;
  let wrapperClass = 'post-wrapper';
  if( props.index > props.showPostCountInitial ) {
    wrapperClass = 'post-wrapper is-hidden';
  }

  const uniqueId = 'post-wrapper-' + post.id;

  switch (postType) {
    case ('image' ):
      postComponent = (
        <PostImage
          handleLikeChange={props.handleLikeChange}
          noLinks={noLinks}
          post={post}
          trackScrollPosition={props.trackScrollPosition}
        />
      );
      break;
    case 'image_with_audio':
      postComponent = (
        <PostImage
          handleLikeChange={props.handleLikeChange}
          noLinks={noLinks}
          post={post}
          trackScrollPosition={props.trackScrollPosition}
        />
      );
      break;
    case 'new_lesson':
      postComponent = (
        <PostImage
          handleLikeChange={props.handleLikeChange}
          noLinks={noLinks}
          post={post}
          subHeading={'New Lesson'}
          trackScrollPosition={props.trackScrollPosition}
        />
      );
      break;
    default:
      // Text & Response
      postComponent = (
        <PostText
          handleLikeChange={props.handleLikeChange}
          noLinks={noLinks}
          post={post}
          trackScrollPosition={props.trackScrollPosition}
        />
      );
  }

  return (
    <div key={uniqueId} class={wrapperClass} id={uniqueId}>
      {postComponent}
    </div>
  );
};

export default PostRouter;