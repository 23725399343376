import React from "react";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';


function PostIndexMainImage(props) {

  const id = props.id;
  const headerText = props.headerText;
  const headerAudioUrl = props.headerAudioUrl;
  const subHeading = props.subHeading;

  let textAudioElement;
  let textElement;
  let audioElement;

  if( headerText ) {

    let subHeadingElement;
    if( subHeading ) {
      subHeadingElement = <div class='sub-heading'>{subHeading}</div>;
    }

    let headerTextClass = 'header-text';
    if( headerText.length > 25 ) { headerTextClass = 'header-text small' }
    if( headerText.length > 40 ) { headerTextClass = 'header-text smaller' }

    textElement = (
      <div class={headerTextClass}>
        {subHeadingElement}
        {headerText}
      </div>
    );
  }

  if( headerAudioUrl ) {
    const audioId = `audio-${id}`;
    audioElement = (
      <div>
        <button class='clean-button header-audio' tabIndex='0' title='Hear the text spoken out loud' onClick={() => playAudio(audioId)}></button>
        <audio id={audioId}>
          <source src={headerAudioUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  }

  if( textElement || audioElement ) {
    textAudioElement = (
      <div class='header-text-audio-wrapper'>
        <div class='header-text-audio'>
          {textElement}
          {audioElement}
        </div>
      </div>
    );
  }

  function playAudio(id) {
    const audioPlayer = document.getElementById(id);
    if( audioPlayer ) {
      audioPlayer.play().catch((e) => {
        // Catch error because this method won't work on mobile safari
      });
      Mixpanel.track('Event: Community listen to post header audio');
    }
  }


  return (
    <div class='post-main-image'>
      <div
        class='main-image'
        style={{
          backgroundImage: "url(" + props.headerImageUrl + ")",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}>
      </div>
      {textAudioElement}
    </div>
  );
}

export default PostIndexMainImage;