import React from "react";

function InputSelect (props) {

  let label = '';
  if( props.label ) { label = <label class="label">{props.label}</label> }

  const name = props.name || '';
  const title = props.title || '';
  const required = props.required ? true : false;
  const preselectValue = props.preselectValue || undefined;
  const options = props.options;
  const handleInputChange = props.handleInputChange || undefined;


  return (
    <div class="field input-select">
      {label}
      <div class="control">
        <div class='select'>
          <select
            name={name}
            type="select"
            title={title}
            required={required}
            class='select'
            value={preselectValue}
            onChange={handleInputChange}
          >
            {options.map(option =>
              <option key={option.value} value={option.value}>{option.name}</option>
            )};
          </select>
        </div>
      </div>
    </div>
  );
}

export default InputSelect;