function ProgressDots(props) {

  const index = props.index;

  return (
    <div class='flex-row progress-dots-container'>
      <span class={index >= 0 ? 'selected' : null}>&nbsp;</span>
      <span class={index >= 1 ? 'selected' : null}>&nbsp;</span>
      <span class={index === 2 ? 'selected' : null}>&nbsp;</span>
    </div>
  );
}

export default ProgressDots;