import React from "react";
import { createComment } from "../../../utilities/auth/authApi";
import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';
// import { withRouter } from "react-router";


class CommentForm extends React.Component {
  state = {
    text: "",
    response_id: this.props.match.params.responseId,
    submitError: [],
    submitted: false
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      submitError: []
    });
  };

  handleFormSubmit = async event => {
    event.preventDefault();

    // Disable the submit button
    this.setState({submitted: true});

    const { text, response_id } = this.state;

    if (text) {
      const payload = { "text": text, "response_id": response_id };
      try {
        const { data } = await createComment(payload);

        if( data.errors ){
          toast.error(data.errors);
        }else{

          // Add the returned comment to the list
          const newComment = data.comment;
          if(newComment) { this.props.addCommentToState(newComment) };

          // Clear the text from the textarea
          document.getElementsByName("text")[0].value = '';

          // Tell the learner their comment was added
          toast.success('Comment added successfully');
        }

      } catch (error) {
        if (error.response) {
          console.log(error);
          const { data } = error.response;
          toast.error( data );
        }
      } finally {
        // Re-enable the submit button
        this.setState({submitted: false});
      }
    }
  };

  render() {

    let submitButton;
    if( this.state.submitted ){
      submitButton = <input type="submit" class='button' disabled value="Post Comment" />;
    }else{
      submitButton = <input type="submit" class='button' value="Post Comment" />;
    }

    return (
      <section class='community-comment-form'>
        <Toaster />
        <form onSubmit={this.handleFormSubmit}>

          <div class="field">
            <label class="label">Provide constructive feedback</label>
            <div class="control">
              <textarea
                name="text"
                onChange={this.handleInputChange}
                required
                minLength="4"
                placeholder='Type your feedback'
              ></textarea>
            </div>
          </div>

          <div class="field submit-button">
            {submitButton}
          </div>

        </form>

      </section>
    );
  }
}

// export default withRouter(CommentForm);
export default CommentForm;