import React from "react";
import { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Toaster } from 'react-hot-toast';

import Nav from "../utilities/nav";
import PostRouter from "./postrouter";
import PostComments from "./postcomments";
import NewComment from "../utilities/newcomment";
import formSumbitComment from "../utilities/forms/formsubmitcomment";

import { getPost, getResponse } from "../utilities/auth/authApi";
import fadeInMainBody from "../../functions/fadeinmainbody";
import LoadingBasic from "../utilities/loadingbasic";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';


function ViewPost() {

  // get the id from the params
  const { id } = useParams();

  const path = window.location.pathname;
  let postType = 'Post';
  let endpoint = getPost;

  if( path.includes('responses') ) {
    postType = 'Response';
    endpoint = getResponse;
  }

  const backLink = '/community';

  const [post, setPost] = useState(<LoadingBasic />);
  const [commentsComponent, setCommentsComponent] = useState(<LoadingBasic />);
  const [comments, setComments] = useState([]);
  const [audioBlob, setAudioBlob] = useState(false);
  const [author, setAuthor] = useState(null);

  // Get this value for later, if a comment is posted
  const postInStore = useSelector((state) => state.posts.posts.find(x => x.id === parseInt(id)));

  // Initalize redux
  const dispatch = useDispatch();

  function incrementCommentCount(id) {
    dispatch({ type: 'incrementCommentCount', payload: {id: id} });
  }

  function updateLikeCount(updateObject) {
    dispatch({ type: 'updateLikeCount', payload: {updateObject: updateObject} });
  }


  useEffect(() => {

    fadeInMainBody();

    // Lookup post
    endpoint(id).then((response) => {

      if( response.data ) {

        if( response.data.post ) {
          setPost(
            <PostRouter
              post={response.data.post}
              noLinks={true}
              handleLikeChange={handleLikeChange}
            />
          );

          setAuthor(response.data.post.author);
        }

        if( response.data.comments && response.data.comments.length > 0 ) {
          setComments(response.data.comments);
          setCommentsComponent(<PostComments comments={response.data.comments} />);
        } else {
          setCommentsComponent(<div class='blank-state-general'>No comments yet, be the first to add one.</div>);
        }

        Mixpanel.track('Event: Community view ' + postType);
      }

    });

  }, [endpoint, id]);


  const handleFormSubmit = async event => {
    event.preventDefault();

    const form = event.target;

    // Disable the button
    const button = document.querySelectorAll('button[type=submit]')[0];
    if( button ) {
      button.disabled = true;
      button.classList.add('is-loading');
    }

    formSumbitComment(form, audioBlob).then((response) => {

      if( response ) {
        if( response.success && response.comment ){
          newCommentSuccess(response.comment);
          Mixpanel.track('Event: Community create new comment');
        } else {
          // Handle Errors
          Mixpanel.track('Event: Community create new error');
        }

      } else {
        // Handle no response
        Mixpanel.track('Event: Community create new error');
      }
    }).then(() => {
      // Re-enable the button
      if( button ) {
        setTimeout(() => {
          button.disabled = false;
          button.classList.remove('is-loading');
        }, 1000);
      }
    });

  }


  function handleAudioRecording(audioUrl, blob) {
    if( blob ) { setAudioBlob(blob) }
  }


  function removeAudio() {
    setAudioBlob('');
  }


  function newCommentSuccess(comment) {
    const updatedComments = [...comments, comment];
    setComments(updatedComments);
    setCommentsComponent(<PostComments comments={updatedComments} />);

    // Clear the comment form
    const editor = document.getElementById('text-editor-basic');
    const textarea = document.getElementsByTagName('textarea')[0];

    if( editor ) {
      // Clear the content and trigger a change event so the
      // state picks it up
      editor.innerHTML = '';
      editor.focus();
      editor.blur();
    }

    if( textarea ) { textarea.value = '' }

    // Clear the audio
    const removeAudio = document.getElementsByClassName('remove-audio')[0];
    if( removeAudio ) { removeAudio.click() }

    // Push the new comment count into redux
    // Ensure the store has this post first
    if( postInStore ) {
      incrementCommentCount(id);
    }

    // Scroll the comments down so we can see the new comment
    const commentsWrapper = document.querySelector('.comments-wrapper');
    if( commentsWrapper ) {
      const scrollToHeight = commentsWrapper.scrollHeight + 500;

      window.scrollTo({
        top: scrollToHeight,
        behavior: 'smooth'
      });

      commentsWrapper.scrollTo({
        top: scrollToHeight,
        behavior: 'smooth'
      });
    }

    // Kinda a hack
    // Manually update the comment count on screen
    const postCommentsElement = document.querySelector('.post-comments');
    if( postCommentsElement ) {
      const count = parseInt( postCommentsElement.innerHTML.replace(' comments', '').replace(' comment', '') );
      const newCount = count + 1;
      const commentWord = ( newCount === 1 ? ' comment' : ' comments' );
      postCommentsElement.innerHTML = newCount + commentWord;
    }

  }


  function handleLikeChange(updateObject) {
    updateLikeCount(updateObject);
  }


  return (

    <div class='wrapper gray-background'>
      <Toaster />
      <Nav />
      <div class='main-body invisible community'>

        <div class='header comments'>
          <div class='title-wrapper'>
            <Link to={backLink} title='Back'>
              <div class='icon icon-chevron-left'></div>
            </Link>
            <h2>Comments</h2>
            <div class='icon'></div> {/* Need a blank icon div to keep things centered */}
          </div>
        </div>

        <div class='main-body-content-flex-wrapper'>
          <div class='main-body-content community comments'>

            <div class='left-column'>
              {post}
            </div>

            <div class='right-column'>

              {commentsComponent}

              <NewComment
                handleFormSubmit={handleFormSubmit}
                handleAudioRecording={handleAudioRecording}
                removeAudio={removeAudio}
                commentableType={postType}
                id={id}
                author={author}
              />

            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewPost;