import VectorIcon from "../vectorIcon";


function BonusLessonLocked() {
  return (
    <div class='flex-column bonus-lesson-locked'>
      <VectorIcon icon='lock' color='#8F8F8F' size='1.5rem' />
    </div>
  );
}

export default BonusLessonLocked;