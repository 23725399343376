
const initialSate = {
  initialized: false,
  courses: []
}

export function coursesReducer(state = initialSate, action) {

  if( action.type === 'initializeCourses') {
    return {
      ...state,
      initialized: action.payload.initialized,
      courses: action.payload.courses
    };
  }

  return state;
}