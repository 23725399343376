import React from "react";
import { Link } from "react-router-dom"


function PurchaseSteps (props) {

  const currentStep = props.currentStep;

  let purchaseStep = (
    <div class='step-wrapper'>
      <div class='dot'></div>
      <div>Purchase</div>
    </div>
  );

  let reviewStep = (
    <div class='step-wrapper'>
      <div class='dot'></div>
      <div>Review</div>
    </div>
  );

  const packageStep = (
    <div class='step-wrapper'>
      <div class='dot full'></div>
      <Link to='/packages' title='Go back to choose a package' class=''>Choose Package</Link>
    </div>
  );

  let barOne, barTwo = <div class='bar dashed'></div>;



  switch (currentStep) {
    case 'review':

      reviewStep = (
        <div class='step-wrapper'>
          <div class='dot full'></div>
          <div>Review</div>
        </div>
      );

      barOne = <div class='bar solid'></div>;

      break;
    case 'purchase':

      purchaseStep = (
        <div class='step-wrapper'>
          <div class='dot full'></div>
          <div>Purchase</div>
        </div>
      );

      reviewStep = (
        <div class='step-wrapper'>
          <div class='dot full'></div>
          <Link to='/packages/review' title='Go back to Review ' class=''>Review</Link>
        </div>
      );

      barOne = <div class='bar solid'></div>;
      barTwo = <div class='bar solid'></div>;

      break;
    case 'package':

      break;
    default:

      break;
  }


  return (
    <div class='purchase-steps-wrapper'>

      <div class='purchase-steps-bar-wrapper'>
        <div class='bar-spacer'></div>
        {barOne}
        {barTwo}
        <div class='bar-spacer'></div>
      </div>

      <div class='purchase-steps-dots-wrapper'>
        {packageStep}
        {reviewStep}
        {purchaseStep}
      </div>

    </div>
  );
};

export default PurchaseSteps;