import HeaderMain from "../utilities/headermain";
import Nav from "../utilities/nav";
import BottomNav from "../utilities/bottomnav";


function OrganizationAdmin() {


  return (
    <div class='wrapper gray-background'>
      <Nav />
      <div class='main-body'>
        <div class='main-body-content organization'>

          <HeaderMain title="Admin" />

          <div class='main-body-content-flex-wrapper'>
            <div class='main-body-content' style={{width: '100%'}}>

              <a
                href='http://admin.flowspeak.io/'
                target='_blank'
                class='button'
                rel='noreferrer'
              >Go to the FLOW Admin</a>

              <br />
              <br />
              <small><i>Login with the same email and password you use for this platform</i></small>
            </div>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default OrganizationAdmin;