
const initialSate = {
  initialized: false,
  currentScrollPosition: null,
  posts: []
}

export function postsReducer(state = initialSate, action) {

  if( action.type === 'initializePosts') {
    return {
      ...state,
      initialized: action.payload.initialized,
      posts: action.payload.posts
    };
  }

  if( action.type === 'addNewPost') {
    // We want the new post at the top of the list
    const existingPosts = state.posts.reverse();
    const newPosts = existingPosts.concat(action.payload).reverse();

    return {
      ...state,
      posts: newPosts
    };
  }

  if( action.type === 'updateLikeCount') {
    const updatedPosts = state.posts;
    const updateObject = action.payload.updateObject;
    const thisPost = updatedPosts.find(x => x.id === updateObject.id);

    if( thisPost ) {
      thisPost.like_count = updateObject.newLikeCount;
      thisPost.learner_like_count = updateObject.learnerLikeCount;
    }

    return {
      ...state,
      posts: updatedPosts
    };
  }

  if( action.type === 'incrementCommentCount') {
    const updatedPosts = state.posts;
    const id = action.payload.id;
    const thisPost = updatedPosts.find(x => x.id === parseInt(id));

    if( thisPost ) {
      if( thisPost.comment_count ) {
        thisPost.comment_count += 1;
      } else {
        thisPost.comment_count = 1;
      }
    }

    return {
      ...state,
      posts: updatedPosts
    };
  }

  if( action.type === 'setCurrentScrollPosition') {
    return {
      ...state,
      currentScrollPosition: action.payload.currentScrollPosition
    };
  }




  return state;
}