import { useSelector } from "react-redux/es/hooks/useSelector";
import WeekList from "./weekList";


function WeekLists(props) {

  // Define variables
  const courseCanSkipAhead = useSelector((state) => state.profile.courseCanSkipAhead);
  const lessonGroups = props.lessonGroups;
  let thisWeekIndex = lessonGroups.findIndex(g => g.current);

  if( thisWeekIndex === -1 || courseCanSkipAhead ) {
    thisWeekIndex = 100;
  }


  return (
    <div>
      {lessonGroups.map((week, index) => (
        <WeekList
          key={`lesson-group-${index}`}
          week={week}
          weekIndex={index}
          thisWeekIndex={thisWeekIndex}
          enabled={(index <= thisWeekIndex) || (index === (thisWeekIndex + 1))}
        />
      ))}
    </div>
  );
}

export default WeekLists;