import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import StartLeftColumn from './startLeftColumn';
import UnauthedStartHeader from './unauthedStartHeader';
import WelcomeCompleteSuccess from "./welcomecompletesuccess";
import WelcomeCompleteFailed from "./welcomecompletefailed";
import LoadingBasic from "../utilities/loadingbasic";

import fadeInMainBody from "../../functions/fadeinmainbody";
import { finalizeSubscriptionSuccess, lookupPaymentDetails } from "../utilities/auth/authApi";


function WelcomePurchaseConfirmation() {

  // Dispatch functions
  const dispatch = useDispatch();


  // Define variables
  const navigate = useNavigate();
  const paymentIntentId = new URLSearchParams(window.location.search).get('payment_intent');
  const [loading, setLoading] = useState(<LoadingBasic />);
  const [message, setMessage] = useState('');


  // Page load actions
  useEffect(() => {

    fadeInMainBody();

    function setHasPremiumSubscription(value) {
      dispatch({ type: 'setHasPremiumSubscription', payload: {hasPremiumSubscription: value}});
    }

    if( paymentIntentId ) {

      const payload = {
        payment_intent_id: paymentIntentId
      };

      lookupPaymentDetails(payload).then((response) => {

        if( response && response.data ) {
          handlePaymentDetails(response.data);
        } else {
          // Handle no response
        }

      });

    } else {
      // Redirect
      navigate('/dashboard');
    }


    function handlePaymentDetails(paymentDetails) {

      const paymentStatus = paymentDetails.paymentStatus;
      const stripePriceId = paymentDetails.stripePriceId;

      switch (paymentStatus) {
        case 'succeeded':
          setMessage(<WelcomeCompleteSuccess paymentDetails={paymentDetails} />);
          setLoading(null);
          finalizeSubscription(stripePriceId);
          break;

        case 'processing':
          setMessage("Payment processing. We'll update you when payment is received.");
          break;

        case 'requires_payment_method':
          setMessage(<WelcomeCompleteFailed paymentDetails={paymentDetails} />);
          setLoading(null);
          break;

        default:
          setMessage('Something went wrong.');
          break;
      }

    }

    // Let the server know the payment went through succesfully
    // and associate the learner to the subscription
    function finalizeSubscription(stripePriceId) {
      const payload = {
        stripe_price_id: stripePriceId
      };

      finalizeSubscriptionSuccess(payload).then((response) => {
        if( response && response.data && response.data === 'success' ) {
          // Update redux
          setHasPremiumSubscription(true);
        } else {
          // Handle no response
        }
      });

    }

  }, [paymentIntentId, dispatch]);


  return (
    <div class='wrapper'>
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <div class='welcome-mic-test-container'>

                <UnauthedStartHeader
                  hideTitle={true}
                  subTitle={" "}
                />

                {/* <h1>Purchase Complete</h1> */}
                {loading}
                {message}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div class='wrapper'>
    //   <div class='main-body start invisible'>
    //     <div class='main-body-content start welcome'>

    //       <StartHeader hideTagline={true} />

    //       <h1>Purchase Complete</h1>
    //       {loading}
    //       {message}

    //     </div>
    //   </div>
    // </div>
  );
};

export default WelcomePurchaseConfirmation;
