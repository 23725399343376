import { useState } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom"
import { useNavigate } from "react-router";

import '../../styles/start.css';

import RenderHtmlText from "../utilities/renderhtmltext";
import StartLeftColumn from './startLeftColumn';
import UnauthedStartHeader from './unauthedStartHeader';
import Input from "../utilities/forms/input";
import ButtonSubmitRound from '../utilities/forms/buttonSubmitRound';

import { setPassword } from "../utilities/auth/authApi";
import { saveToken } from '../utilities/auth/tokenHelpers';


function SetPasswordAMINEF() {

  // Define variables
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  let { accountCode } = useParams();


  // Define functions
  function handleFormSubmit(event) {
    event.preventDefault();
    setButtonLoading(true);

    setErrors([]);

    const accountCode = document.getElementsByName('account_code')[0].value;
    const password = document.getElementsByName('password')[0].value;


    const payload = {
      account_code: accountCode,
      password: password,
      agree_to_terms: true
    }

    setPassword(payload).then((response) => {
      if( response.data ) {
        if( response.data.auth ) {

          // Save token
          saveToken(response.data);

          setTimeout(() => {
            // Send to onboarding steps
            navigate({pathname:  "/welcome"});
          }, 800);

        } else {
          setErrors(response.data.errors);
        }
      } else {
        setErrors(['Sorry, there was an error. Please contact help@flowspeak.io.']);
      }
    }).finally(() => {
      setTimeout(() => {
        // Send to onboarding steps
        setButtonLoading(false);
      }, 2000);
    });

  }


  return (
    <div class='wrapper'>
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <UnauthedStartHeader
                orgLogo={'https://files.flowspeak.io/files/organization_logos/fulbright-indonesia.png'}
              />

              <section class='section-auth-form'>

                <div class='flex-row content-left auth-links-container'>
                  <p class='auth-link-button'>Set Your Password</p>
                </div>

                <form
                  class='signup-login-form'
                  onSubmit={handleFormSubmit}
                >

                  <Input
                    name={'account_code'}
                    label={'Account Code'}
                    placeholder={'enter account code'}
                    autoComplete={'off'}
                    preselectValue={accountCode}
                  />

                  <div class="field">
                    <label class="label">Password</label>
                    <div class="control">
                      <input
                        name="password"
                        type="password"
                        minLength="8"
                        title="Password must be at least 8 characters"
                        required
                        placeholder='password'
                        class='input'
                        autocomplete='off'
                      />
                    </div>
                  </div>

                  <div class='form-error-header form-error'>
                    {/* {errors} &nbsp; */}
                    <RenderHtmlText string={errors} />
                  </div>

                  <div class='flex-column' style={{marginTop: '15px'}}>
                    <ButtonSubmitRound
                      value={'Set Password'}
                      loading={buttonLoading}
                    />
                  </div>

                  <div>
                    <p class='terms-text'>
                      By clicking Set Password, you agree to the <br /><a href='https://www.flowspeak.io/terms-and-conditions' target='_blank' rel="noreferrer">Terms & Conditions</a> and <a href='https://www.flowspeak.io/privacy-policy' target='_blank' rel="noreferrer">Privacy Policy</a>
                    </p>
                  </div>

                </form>

                <br />

                <div class='login-secondary-action'>
                  Already a member? &nbsp;
                  {/* <Link to="/start#signin" title='Go to login'> */}
                  <Link to="/login" title='Go to login'>
                    Login
                  </Link>
                </div>

              </section>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetPasswordAMINEF;