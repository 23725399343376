import { Link } from "react-router-dom";
import VectorIcon from "./vectorIcon";


function NavLink(props) {

  // Define variables
  const path = props.path;
  const title = props.title;
  const displayTitle = props.displayTitle;
  const className = props.active ? 'nav-link active' : 'nav-link';
  const challengeIndicator = props.challengeIndicator;


  // Screen components
  let circleIndicatorElement;
  if( challengeIndicator && challengeIndicator === 'active' ) {
    circleIndicatorElement = (
      <div class='nav-link-icon-container'>
        <VectorIcon icon='circleFill' color='active' size={'100%'} />
      </div>
    );
  }

  if( challengeIndicator && challengeIndicator === 'complete' ) {
    circleIndicatorElement = (
      <div class='nav-link-icon-container'>
        <VectorIcon icon='checkMarkCircle' color='active' size={'100%'} />
      </div>
    );
  }


  return (
    <Link to={path} class={className} title={title}>
      <div class='nav-link-text'>{displayTitle}</div>
      {circleIndicatorElement}
    </Link>
  );
};

export default NavLink;