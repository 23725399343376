import { Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';


function IntroHints() {

  function logClick() {
    Mixpanel.track('Event: Intro Hint Clicked');
  }

  const hints = [
    {
      element: '.response-score',
      hint: 'Tap your score to see even more details.'
    },
    {
      element: '.media-left',
      hint: 'Tap the audio icon to hear your recording. Compare how you sound to the native speaker example.'
    },
  ];

  return (
    <div class='welcome-tour-wrapper'>
      <Hints
        enabled={true}
        hints={hints}
        onClick={logClick}
      />
    </div>
  );
}

export default IntroHints;