import React from "react";
import { useState } from 'react';
import { useDispatch } from "react-redux";

import '../../styles/record.css';

import alertMessage from "./alertMessage";
import { reportError } from "../../functions/sentry";
import transcribeScoreSimple from "../../functions/transcribeScoreSimple";
import transcribe from "../../functions/transcribe";
import scorePronunciation from "../../functions/scorePronunciation";

import RecordUiDefault from "./recordUiDefault";
import RecordUiExpression from "./recordUIExpression";
import RecordUiBig from "./recordUiBig";


function RecordResponse(props) {

  // Dispatch
  const dispatch = useDispatch();

  function setActiveRecording(status) {
    dispatch({ type: 'setActiveRecording', payload: status});
  }

  // Define variables
  const [recordStatus, setRecordStatus] = useState('waiting'); // waiting, listening, processing, scoring, complete
  const handleTranscript = props.handleTranscript;
  const useScorePronunciation = props.scorePronunciation;
  const useSimpleTranscribe = props.useSimpleTranscribe;
  const processingWords = props.processingWords;
  const colorScheme = props.colorScheme;
  const messages = props.messages;
  const extras = props.extras;


  // Define functions
  function handleClick() {

    switch (recordStatus) {
      case 'listening':
        stopRecording();
        break;
      case 'processing':

        break;
      case 'scoring':

        break;
      case 'complete':

        break;
      default:
        // waiting
        initalizeStream();
        break;
    }

  } // ----------------------------


  function initalizeStream() {

    // Iniatiate the stream
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    .then(initalizeRecorder)
    .then(startRecording)
    .catch((error) => {
      console.log('Unable to initalize the media recorder object');
      console.log(error);
      reportError(error);
      alertMessage('Sorry, we cannot access your microphone. Please allow microphone access so you can record your response.', error);
    });

  } // ----------------------------


  const initalizeRecorder = function(stream) {

    // const options = {mimeType: 'audio/webm'};
    const options = {
      type: 'audio',
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      audioBitsPerSecond: 128000,
      bufferSize: 128
    }

    // mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"';
    // const wav = MediaRecorder.isTypeSupported('audio/wav') // false


    let recordedChunks = [];
    mediaRecorder = new MediaRecorder(stream, options);

    // When the recording starts
    mediaRecorder.addEventListener('start', function() {
      setRecordStatus('listening');
    });

    // Push data into the recordedChunks Array
    mediaRecorder.addEventListener('dataavailable', function(e) {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    });

    mediaRecorder.addEventListener('stop', function() {

      setRecordStatus('processing');
      // processingWords();

      // Close the recording
      if (mediaRecorder) {
        stream.getAudioTracks().forEach(track => {
          track.stop();
        });
      }

      const audioData = recordedChunks;

      // Convert saved chunks to blob
      const blob = new Blob(audioData, {type: 'audio/wav'});

      // Generate audio url from blob
      const audioUrl = window.URL.createObjectURL(blob);

      let providedText;
      let transcribeFunction = transcribe;

      if( useScorePronunciation ) {
        transcribeFunction = scorePronunciation;
        providedText = props.providedText;
      }

      if( useSimpleTranscribe ) {
        transcribeFunction = transcribeScoreSimple;
      }

      // transcribe(blob, audioUrl).then((response) => {
      transcribeFunction(blob, audioUrl, providedText, messages, extras).then((response) => {

        setRecordStatus('complete');
        clearInterval(interval);

        if( response.transcribed ) {
          if( providedText ) {
            response.providedText = providedText;
          }
          handleTranscript(response.transcript, audioUrl, blob, response);
        } else {
          if (response.error === 'No speech recognized') {
            setRecordStatus('waiting');
            alertMessage('Sorry, we had a problem hearing you. Can you please try again?', 'error');
          }else{
            setRecordStatus('waiting');
            reportError(response.error);
            alertMessage('Sorry, there was an error, please try again.', 'error');
          }
        };

      }).catch((error) => {
        console.log('Other Transcription Error: ' + error);
        reportError(error);
      });

    });

    // Clear the recording
    // It's ok to go ahead and clear because we will want to clear no matter
    // the outcome of the translation funciton
    recordedChunks = [];

  } // ----------------------------


  function startRecording() {
    // recording = true;
    setActiveRecording(true);
    mediaRecorder.start();
    clearTimeout(timeout);
    timeout = setTimeout(() => mediaRecorder.stop(), 40000);
  } // ----------------------------


  function stopRecording() {
    // recording = false;
    mediaRecorder.stop();
    setActiveRecording(false);
  } // ----------------------------


  let recordElement;
  recordElement = (
    <RecordUiDefault
      handleClick={handleClick}
      recordStatus={recordStatus}
      processingWords={processingWords}
    />
  );

  if( colorScheme === 'expression' ) {
    recordElement = (
      <RecordUiExpression
        handleClick={handleClick}
        recordStatus={recordStatus}
      />
    );
  }

  if( colorScheme === 'big' ) {
    recordElement = (
      <RecordUiBig
        handleClick={handleClick}
        recordStatus={recordStatus}
      />
    );
  }


  return (
    <>
      {recordElement}
    </>
  );
}

let timeout;
let mediaRecorder;
let interval;

export default RecordResponse;