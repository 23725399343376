import React from "react";
import { useState, useEffect } from 'react';
import { likePost } from "../utilities/auth/authApi";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';


function LikeButton(props) {

  const id = props.id;

  const postType = props.postType || 'post';
  const learnerLikeCount = props.learnerLikeCount || 0;

  // Set initial state
  const [buttonClass, setButtonClass] = useState('like-button');
  const [likeCount, setLikeCount] = useState(props.likeCount || 0);


  useEffect(() => {

    if( learnerLikeCount && learnerLikeCount > 0 ) {
      setButtonClass('like-button learner-like');
    }

  }, [learnerLikeCount]);


  function handleLikePostClick(event) {

    const payload = { id: id, post_type: postType }

    likePost(payload).then((response) => {
      if( response ) {
        if( response.data ) {
          if( response.data.success ) {

            let newLikeCount;
            let learnerLikeCount;


            if( response.data.enabled ) {
              setButtonClass('like-button learner-like');
              newLikeCount = likeCount + 1;
              learnerLikeCount = 1;
            } else {
              setButtonClass('like-button');
              newLikeCount = ( likeCount > 0 ) ? likeCount - 1 : 0;
              learnerLikeCount = 0;
            }

            const updateObject = {
              id: id,
              newLikeCount: newLikeCount,
              learnerLikeCount: learnerLikeCount
            }

            setLikeCount(newLikeCount);

            // Pass the data back to the parent component
            if( props.handleLikeChange ) {
              props.handleLikeChange(updateObject);
            }

            Mixpanel.track('Event: Community clicked like button');

          }
        } else {
          Mixpanel.track('Event: Community clicked like button error');
        }
      }
    });
  }

  return (
    <button class={buttonClass} type='button' onClick={handleLikePostClick} title='Tap to like'>
      <div class='icon icon-like'></div>
      <div class='like-count-wrapper'>
        <div class='like-count'>{likeCount}</div>
        <div class='like-title'>{likeCount === 1 ? 'like' : 'likes'}</div>
      </div>
    </button>
  );
}

export default LikeButton;