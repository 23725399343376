import React from "react";
import { useState } from "react";

import apiCall from "../../utilities/auth/apiCall";
import { updateCourse } from "../../utilities/auth/authApi";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";
import RenderHtmlText from "../../utilities/renderhtmltext";
import ScoreDonutWithText from "../../utilities/learning_module/scoreDonutWithText";


function StepCourseCompleteShowScore(props) {

  // Define variables
  const [loading, setLoading] = useState(false);
  const clearDashboardData = props.clearDashboardData;
  const stepData = props.stepData;
  const title = stepData.title;
  const subline = stepData.subline;
  const score = stepData.score;
  const isIelts = stepData.isIelts;
  const ieltsScore = stepData.ieltsScore;
  const scoreText = stepData.scoreText;
  const actionWording = stepData.actionWording;

  const scoreToUse = score;


  // Define functions
  function handleSubmit() {
    setLoading(true);

    apiCall(updateCourse, {}).then((response) => {
      if( response.success ) {
        setTimeout(() => {
          clearDashboardData();
        }, 500);
      }
    });
  }


  return (
    <div class='flex-column' style={{marginTop: '45px'}}>
      <div class='flex-column pathway-achievement-container'>
        <h2>{title}</h2>
        <p>{subline}</p>

        <div class='flex-column' style={{marginTop: '30px', marginBottom: '30px'}}>
          <ScoreDonutWithText percent={scoreToUse} text={scoreText} isIelts={isIelts} />
          <h3>Course Score</h3>
        </div>

        <RenderHtmlText string={actionWording} />

        <ButtonSubmitRound
          value={"Continue"}
          handleButtonClick={handleSubmit}
          loading={loading}
        />

      </div>
    </div>
  );
}

export default StepCourseCompleteShowScore;