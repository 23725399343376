import PlayAudioIcon from "../utilities/playaudioicon";


function BoosterPhrase(props) {

  // Define variables
  const phraseData = props.phraseData;
  const phrase = phraseData.phrase;
  const definition = phraseData.definition;
  const audioUrl = phraseData.audioUrl;
  const translateContent = props.translateContent;


  // Screen components
  let audioElement;
  if( audioUrl ) {
    audioElement = <PlayAudioIcon audioUrl={audioUrl} iconDark={true} />;
  }

  let phraseTranslateElement;
  let definitionTranslateElement;
  if( translateContent ) {
    phraseTranslateElement = <span class='booster-translate-phrase'>({translateContent.text})</span>
    definitionTranslateElement = <p class='booster-translate-definition'>{translateContent.definition}</p>
  }

  return (
    <div class='booster-definition-container'>

      <div class='flex-row'>
        <p><strong>{phrase}</strong>{phraseTranslateElement}</p>
        {audioElement}
      </div>

      <p class='booster-definition'>{definition}</p>
      {definitionTranslateElement}

    </div>
  );
}

export default BoosterPhrase;