import StartHeader from "./startHeader";
import { Link } from "react-router-dom";


function NotFound() {

  return (
    <div class='wrapper'>
      <div class='main-body start'>
        <div class='main-body-content start'>
          <StartHeader />
          <div class='flex-column' style={{marginTop: '45px'}}>
            <h1>Oops! We can't find that page.</h1>
            <p>
              <Link to='/'>Head back home</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;