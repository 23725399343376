function ProgressSteps(props) {

  // Define variables
  const steps = props.steps;
  const currentStep = props.currentStep;
  const maxWidth = steps * 100;


  // Screen components
  let stepsElement = [];
  let i = 1;

  while (i <= steps) {
    let divClass = 'progress-step';
    if( currentStep >= (i) ) { divClass = 'progress-step full' }
    stepsElement.push(<div class={divClass} key={`progress-step-${i}`}></div>);
    i++;
  }


  return (
    <div class='flex-column'>
      <div
        class='progress-steps-container'
        style={{maxWidth: `${maxWidth}px`}}
      >
        {stepsElement}
      </div>
    </div>
  )
}

export default ProgressSteps;