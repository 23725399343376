
const initialSate = {
  initialized: false,
  lessons: []
}

export function SavedLessonsReducer(state = initialSate, action) {

  if( action.type === 'initializeSavedLessons') {
    return {
      ...state,
      initialized: action.payload.initialized,
      lessons: action.payload.lessons
    }
  }

  if( action.type === 'invalidateSavedLessons') {
    return {
      ...state,
      initialized: false
    }
  }

  return state;
}