import React from "react";

function QuestionImage (props) {

  const questionId = Date.now();
  const content = props.content;
  const className = "question-wrapper image";
  const imageUrl = content.imageUrl;
  const image = (imageUrl) ? <img src={imageUrl} alt=' ' /> : null;
  
  return (
    <div class={className} key={questionId}>
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            {image}
          </div>
        </div> 
      </div>
    </div>
  );
};

export default QuestionImage;