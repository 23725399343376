import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";


function PlayAudioIcon(props) {

  // Dispatch
  const dispatch = useDispatch();

  function setActivePlaying(status) {
    dispatch({ type: 'setActivePlaying', payload: status});
  }

  // Define variables
  const audioPlayerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const activeRecording = useSelector((state) => state.lesson.activeRecording);
  const activePlaying = useSelector((state) => state.lesson.activePlaying);
  const audioUrl = props.audioUrl;
  const fullActive = props.fullActive || false;
  const fullDark = props.fullDark || false;
  const width = props.width || '24px';
  const position = props.position || 'right';
  const audioSpeed = props.audioSpeed || 'normal';
  const playbackRate = audioSpeed === 'normal' ? 1 : .8;
  const colorScheme = props.colorScheme || 'dark';


  let className = "icon audio-icon icon-audio-outline-dark";

  if( colorScheme === 'light' ) {
    className = "icon audio-icon icon-audio-outline-light";
  }

  if ( props.isHidden ) { className = className + " is-hidden"; }

  if( props.responseAudio ) {
    className = "icon audio-icon icon-audio-outline-light left";
  }

  if( fullActive ) {
    className = "icon audio-icon icon-audio-full-active";
  }

  if( fullDark ) {
    className = "icon audio-icon icon-audio-full-dark";
  }

  if( position === 'left' ) {
    className = className + ' left';
  }

  if( isPlaying ) {
    className = className + ' playing';
  }


  // Component load actions
  useEffect(() => {
    if( activeRecording ) { pauseAudio() }
  }, [activeRecording]);


  // Define functions
  function playAudio() {
    if( activeRecording || activePlaying ) { return }

    setActivePlaying(true);
    setIsPlaying(true);

    const audioPlayer = audioPlayerRef.current;
    if( audioPlayer ) {
      audioPlayer.playbackRate = playbackRate;
      audioPlayer.volume = 1;
      audioPlayer.play().catch((e) => {
        // Catch error because this method won't work on mobile safari
      });
    }
  }

  function pauseAudio() {
    const audioPlayer = audioPlayerRef.current;
    if( audioPlayer ) {
      audioPlayer.pause();
      afterPlayEnds();
    }
  }

  function afterPlayEnds() {
    setActivePlaying(false);
    setIsPlaying(false);
  }


  return (
    <div class='flex-column' style={{width: width, height: width, justifyContent: 'center'}}>
      <audio
        ref={audioPlayerRef}
        onEnded={() => afterPlayEnds()}
      >
        <source src={audioUrl} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>

      <button
        class={className}
        style={{width: width, height: width}}
        tabIndex='0'
        title='Hear the text spoken out loud'
        onClick={() => playAudio()}></button>
    </div>
  );
};

export default PlayAudioIcon;