
const initialSate = {
  initialized: false,
  newNotifications: false,
  notifications: []
}

export function notificationsReducer(state = initialSate, action) {

  if( action.type === 'initializeNotifications') {
    return {
      ...state,
      initialized: action.payload.initialized,
      newNotifications: action.payload.newNotifications,
      notifications: action.payload.notifications
    };
  }

  if( action.type === 'setNewNotifications') {
    return {
      ...state,
      newNotifications: action.payload.newNotifications
    }
  }

  return state;
}