import React from 'react';
import { useNavigate } from "react-router-dom";

import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import MicrophoneAccessContent from './microphoneaccesscontent';

import '../../styles/microphoneaccess.css';


function MicrophoneAccess() {

  // Define variables
  const navigate = useNavigate();


  return (
    <div class='wrapper gray-background'>

      <Nav />

      <div class='main-body'>
        <HeaderMain title="Microphone Access" />
        <div class='main-body-content microphone-access'>

          <h3>How to allow access to your microphone</h3>
          <MicrophoneAccessContent />
          <button class='button' onClick={() => navigate(-1)}>Back</button>

        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default MicrophoneAccess;