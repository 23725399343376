import React from "react";
// import '../../../../styles/conversation.css';
// import '../../../../styles/community.css';

import QuestionText from "../questiontext";
import ContentResponse from "./contentresponse";

import { getResponses } from "../../../utilities/auth/authApi";
// import { withRouter } from "react-router";


class ModuleContentResponses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // conversationId: this.props.match.params.id,
      currentLessonId: this.props.match.params.id,
      moduleContentId: this.props.match.params.moduleContentId,
      moduleType: props.match.url.split('/')[1],
      responses: [],
      questionContent: {},
      introContent: {}
    };

  }

  componentDidMount() {

    getResponses(this.state.moduleContentId).then((response) => {

      if( response.data ) {

        const introObject = response.data.introContent;
        let introContent = {};

        if( introObject ) {
          introContent = {
            id: response.data.introContent.id,
            type: 'introduction',
            headline: response.data.introContent.headline,
            content: response.data.introContent.text,
            audioUrl: response.data.introContent.audioUrl
          }
        }

        this.setState({
          responses: response.data.responses,
          questionContent: {
            id: response.data.id,
            questionId: 1,
            type: 'question',
            content: response.data.text,
            audioUrl: response.data.audioUrl
          },
          introContent: introContent
        });
      }

    });

  }

  render() {

    let introComponent;
    if( this.state.introContent && this.state.introContent.id ){
      introComponent = <QuestionText content={this.state.introContent} isCommunityResponse={true} />;
    }

    // Show the question so we have context
    let questionComponent;
    const backUrl = `/lessons/${this.state.currentLessonId}/community`;

    if( this.state.questionContent && this.state.questionContent.id ){
      questionComponent = <QuestionText content={this.state.questionContent} backUrl={backUrl} isCommunityResponse={true} />;
    }

    const responseWord = (this.state.responses.length === 1) ? 'Response' : 'Responses';

    return (
      <div class='conversation-wrapper'>
        <div class='questions'>
          {introComponent}
          {questionComponent}

          <div class='response-header'> &#8213; {this.state.responses.length} {responseWord} &#8213; </div>

          {
            this.state.responses.map((response, index) => (
              <ContentResponse response={response} index={index + 'cr'} />
            ))
          }
        </div>
      </div>
    );
  }
};

// export default withRouter(ModuleContentResponses);
export default ModuleContentResponses;