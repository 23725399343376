import React from "react";
import * as api from "../utilities/auth/authApi";

import UnauthedStartHeader from "./unauthedStartHeader";
import StartLeftColumn from "./startLeftColumn";
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";


class ForgotPasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      resetError: "",
      submitted: false,
      disabled: false,
      successMessage: null
    };

  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      resetError: ''
    });
  };

  handleFormSubmit = async event => {
    event.preventDefault();

    // Disable the submit button
    this.setState({submitted: true});

    const { email  } = this.state;
    if (email ) {
      try {
        const payload = { email };
        const { data } = await api.forgotpassword(payload);

        if( data.error ){
          this.setState({ resetError: data.error });
        }else{
          this.setState({ successMessage: 'Reset password email sent. Check your inbox for instructions.' });
          this.setState({disabled: true});
        }

        // Re-enable the submit button
        setTimeout(() => {
          this.setState({submitted: false});
        }, 1500);

      } catch (error) {
        if (error.response) {
          this.setState({ resetError: error.response });

          // Re-enable the submit button
          setTimeout(() => {
            this.setState({submitted: false});
          }, 1500);
        }
      }
    }
  };

  render() {

    const { email, resetError } = this.state;


    return (
      <div class='wrapper'>
        <div class='main-body start with-start-left-column'>
          <StartLeftColumn />
          <div class='main-body-content start'>
            <div class='start-vertical-wrapper'>
              <div class='start-vertical-container'>
                <UnauthedStartHeader hideTitle={true} />

                <section class='section-auth-form'>

                  <div class='flex-row content-left auth-links-container'>
                    <p class='auth-link-button'>Forgot Password</p>
                  </div>

                  <form
                    class='signup-login-form'
                    onSubmit={this.handleFormSubmit}
                  >

                    <p>Fill out the form below and we'll send you an email with reset password instructions.</p>
                    <br />

                    <div class="field">
                      <label class="label">Email Address</label>
                      <div class="control">
                        <input
                          name="email"
                          type="email"
                          value={email}
                          onChange={this.handleInputChange}
                          required
                          placeholder='email address'
                          class='input'
                        />
                      </div>
                    </div>

                    <div class='form-error-header form-error'>
                      {resetError}
                      {this.state.successMessage}
                      &nbsp;
                    </div>

                    <div class='flex-column' style={{marginTop: '15px'}}>
                      <ButtonSubmitRound
                        value={'Send Reset Password Instructions'}
                        loading={this.state.submitted}
                        disabled={this.state.disabled}
                      />
                    </div>

                  </form>

                  <div class='login-secondary-action'>
                    {/* <a href='/start#signin' class='' title="Sign In">Login</a> */}
                    <a href='/login' class='' title="Sign In">Login</a>
                  </div>

                </section>

              </div>
            </div>
        </div>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordReset;