import VectorIcon from "../utilities/vectorIcon";
import { useNavigate } from "react-router-dom";
import AvatarImage from "../utilities/avatarImage";
import Flag from "../profile/flag";
import StackedNav from "../utilities/stackednav";


function ProgressHeader(props) {

  // Define variables
  const navigate = useNavigate();
  const isThisLearner = props.isThisLearner;
  const profileData = props.profileData;
  const avatarUrl = profileData.avatarUrl;
  const firstName = profileData.firstName;
  const confidenceLevel = profileData.flowScore;
  const countryCode = profileData.countryCode;


  // Screen Components
  let avatarElement;
  if( avatarUrl && avatarUrl !== 'https://files.flowspeak.io/files/default-avatar.png' ) {
    avatarElement = <AvatarImage avatarUrl={avatarUrl} medium={true} />;
  }

  let flagElement;
  if( countryCode ) {
    flagElement = (
      <div style={{marginLeft: 10, marginTop: 10}}>
        <Flag countryCode={countryCode} />
      </div>
    );
  }

  let closeElement = <StackedNav />;
  if( !isThisLearner ) {
    closeElement = (
      <button
        class='clean-button pointer'
        onClick={() => navigate(-1)}
      >
        <VectorIcon icon={'close'} color={'#ffffff'} />
      </button>
    );
  }


  return (
    <div class='flex-column progress-header-wrapper'>
      <div class='flex-row progress-header-container'>
        <div style={{width: '44px', marginLeft: '30px'}}></div>
        <div class='flex-column'>
          {avatarElement}
          <div class='flex-row'>
            <h3>{firstName}</h3>
            {flagElement}
          </div>
          <h5>{confidenceLevel}</h5>
        </div>
        <div style={{minWidth: '30px', marginRight: '30px'}} >
          {closeElement}
        </div>
      </div>
    </div>
  );
}

export default ProgressHeader;