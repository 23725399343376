
function currencyFormat(number) {

  if( !!(number % 1) ) {
    // Has a decimal
    number = number.toFixed(2);
  }

  return number;
}

export default currencyFormat;