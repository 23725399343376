import React from "react";
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import '../../styles/progress.css';

import { getPublicProfile } from "../utilities/auth/authApi";

import BottomNav from "../utilities/bottomnav";
import ProgressHeader from "./progressHeader";
import ProgressSubNav from "./progresssubnav";
import StatsChallengeBar from "../utilities/stats/statsChallengeBar";
import StatsActivity from "../utilities/stats/statsActivity";
import StatsBadges from "../utilities/stats/statsBadges";
import StatsCourses from "../utilities/stats/statsCourses";
import StatsCertificates from "../utilities/stats/statsCertificates";
import StatsPathways from "../utilities/stats/statsPathways";


function Progress(props) {

  // Define variables
  const [profileData, setProfileData] = useState({});

  let propsPublicProfileId;
  if( props?.location?.state?.publicProfileId ) {
    propsPublicProfileId = props.location.state.publicProfileId;
  }

  const { params_public_profile_id } = useParams();
  if( params_public_profile_id ) {
    propsPublicProfileId = params_public_profile_id;
  }

  const reduxPublicProfileId = useSelector((state) => state.profile.publicProfileId);
  const publicProfileId = propsPublicProfileId ? propsPublicProfileId : reduxPublicProfileId;
  const isThisLearner = publicProfileId === reduxPublicProfileId;



  useEffect(() => {

    if( publicProfileId ) {
      getPublicProfile(publicProfileId).then((response) => {
        if( response?.data?.success ) {
          response.data.learner.flowScore = response.data.learner.flowScore + ' Confidence Level';
          setProfileData(response.data.learner);
        } else {
          // Handle
        }
      });
    }

  }, [publicProfileId]);


  // Screen components
  let subNavElement;
  let bottomNavElement;

  if( isThisLearner ) {
    subNavElement = <ProgressSubNav />;
    bottomNavElement = <BottomNav />;
  }


  return (
    <div class='flex-column wrapper progress-screen'>
      <div class='main-container'>

        <ProgressHeader
          profileData={profileData}
          isThisLearner={isThisLearner}
        />

        {subNavElement}

        <div class={`main-body ${isThisLearner ? 'with-sub-nav' : null}`}>
          <div class='main-body-content'>

            <StatsChallengeBar
              weeklyChallengesCompleted={profileData.weeklyChallengesCompleted}
              isThisLearner={isThisLearner}
            />
            <StatsActivity publicProfileId={publicProfileId} isThisLearner={isThisLearner} />
            <StatsBadges badges={profileData.badges} isThisLearner={isThisLearner} />
            <StatsPathways pathwayData={profileData.pathwayData} isThisLearner={isThisLearner} />
            <StatsCourses courses={profileData.courses} isThisLearner={isThisLearner} />
            <StatsCertificates certificates={profileData.certificates} isThisLearner={isThisLearner} />

          </div>
        </div>
        {bottomNavElement}
      </div>
    </div>
  )
}

export default Progress;