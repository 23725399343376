import React from "react";
import { Link } from "react-router-dom";

import RenderHtmlText from "../utilities/renderhtmltext";
import PlayAudioIcon from "../utilities/playaudioicon";


function PostIndexBody(props) {

  // Define variables
  const bodyText = props.bodyText;
  const postLink = props.postLink;
  const trackScrollPosition = props.trackScrollPosition;
  const category = props.category;
  const audioUrl = props.audioUrl;
  const lessonId = props.lessonId;
  const noLinks = props.noLinks;

  const lessonCtas = [
    'Check out the lesson',
    'Try out the lesson',
    'Try the lesson'
  ]


  // Screen components
  let categoryElement;
  if( category ) { categoryElement = <div class='post-category'>{category}</div> }

  let audioPlayer;
  if( audioUrl ) { audioPlayer = <PlayAudioIcon audioUrl={audioUrl} iconDark={true} /> }

  let lessonLink;
  if( lessonId ) {
    const linkUrl = `/lessons/${lessonId}`;
    lessonLink = <Link to={linkUrl} class='question-lesson-link'>{lessonCtas[Math.floor(Math.random() * lessonCtas.length)]}</Link>;
  }

  let bodyContent;
  if( noLinks ) {
    bodyContent = (
      <div title='View Post' class='post-body-text'>
        {categoryElement}
        <RenderHtmlText string={bodyText} />
        {lessonLink}
      </div>
    );
  } else {
    bodyContent = (
      <Link to={postLink} title='View Post' class='post-body-text' onClick={trackScrollPosition}>
        {categoryElement}
        <RenderHtmlText string={bodyText} />
      </Link>
    );
  }

  let content;
  if( bodyText ) {
    content = (
      <div class='post-body'>
        {bodyContent}
        <div class='post-body-audio'>
          {audioPlayer}
        </div>
      </div>
    );
  }

  return (
    // <div class='post-body'>
    //   {bodyContent}
    //   <div class='post-body-audio'>
    //     {audioPlayer}
    //   </div>
    // </div>
    <>{content}</>
  );
}

export default PostIndexBody;