import React from 'react';
import { useState } from 'react';


import { Mixpanel } from '../analytics/mixpanelHelpers';
import alertMessage from "../alertMessage";
import { reportError } from "../../../functions/sentry";
import apiCall from '../auth/apiCall';
// import { translateLearningModule, validateNativeLanguage } from '../auth/authApi';
import { validateNativeLanguage } from '../auth/authApi';

import SetNativeLangauge from './setNativeLangauge';
import ProfileToggle from "../../profile/profiletoggle";


function TranslateToggle(props) {

  // Define variables
  const [selectNativeLanguageModal, setSelectNativeLanguageModal] = useState(null);
  const [translateLoading, setTranslateLoading] = useState(false);
  const id = props.id;
  const apiMethod = props.apiMethod;
  const translateToggle = props.translateToggle;
  const setTranslateToggle = props.setTranslateToggle;
  const handleTranslationToggle = props.handleTranslationToggle;
  const translateContent = props.translateContent;
  const setTranslateContent = props.setTranslateContent;
  const lessonType = props.lessonType;


  // Define functions
  function tryToggleTranslate() {

    if( translateLoading ) {
      return;
    }

    setTranslateLoading(true);

    if( translateToggle ) {
      setTranslateToggle(false);
      clearLoading();
    } else {
      if( !translateContent ) {

        apiCall(validateNativeLanguage, {}).then((response) => {
          if( response?.success ) {

            if( lessonType.toLowerCase() === 'ai talk' ) {
              handleTranslationToggle(true);
              setTimeout(() => clearLoading(), 1000);
            } else {
              toggleTranslate();
            }

          } else {
            if( response.error === 'Learner does not have a native langauge set' ) {
              handleNativeLanguageSelect();
            } else {
              alertMessage(response.error, 'error');
            }
          }
        });

      } else {
        setTranslateToggle(true);
        clearLoading();
      }

    }

    // Log the event
    Mixpanel.track(`Event: Toggle Translate - ${lessonType}`, {'Lesson ID': id, 'Value': !translateToggle});
  }

  function toggleTranslate() {
    apiCall(apiMethod, {id: id}).then((response) => {
      if( response?.success ) {
        setTranslateContent(response.data);
        setTranslateToggle(true);
      } else {
        if( response.error === 'Learner does not have a native langauge set' ) {
          handleNativeLanguageSelect();
        } else {
          alertMessage(response.error, 'error');
        }
      }
    }).catch((error) => {
      reportError(error);
      alertMessage('Sorry, there was an error. Please contact support@flowspeak.io.', 'error')
    }).finally(() => {
      clearLoading();
    });
  }

  function clearLoading() {
    setTimeout(() => setTranslateLoading(false), 1000);
  }

  function handleNativeLanguageSelect() {
    setSelectNativeLanguageModal(
      <SetNativeLangauge
        closeModal={() => setSelectNativeLanguageModal(null)}
        tryToggleTranslate={tryToggleTranslate}
      />
    );
  }


  // Screen components
  let loadingElement = <div class='button clean-button'></div>;
  if( translateLoading ) {
    loadingElement = <div class='button clean-button is-loading'></div>;
  }

  return (
    <div class='flex-column translate-toggle-container'>

      {selectNativeLanguageModal}

      <div class='flex-row'>
        <ProfileToggle
          label={'Show translation'}
          value={translateToggle}
          handlePreferenceChange={tryToggleTranslate}
        />
        {loadingElement}
      </div>
    </div>
  );
}

export default TranslateToggle;