import React from "react";
import QuestionText from "../questiontext";
import QuestionImage from "../questionimage";
import QuestionVideo from "../questionvideo";


function CommunityContent (props) {

  const content = props.content;
  const type = content.type;

  let questionComponent;

  switch(type) {
    case 'video':
      questionComponent = <QuestionVideo content={content} isCommunityIndex={true} />;
      break;
    case 'image':
      questionComponent = <QuestionImage content={content} isCommunityIndex={true} />;
      break;
    case 'introduction':
      questionComponent = <QuestionText content={content} isCommunityIndex={true} />;
      break;
    default:
      // Default to question
      questionComponent = <QuestionText content={content} isCommunityIndex={true} />;
  }

  return (
    <div>
      {questionComponent}
    </div>
  );
};

export default CommunityContent;