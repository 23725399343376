import React from "react";
import AddToCalendar from "@culturehq/add-to-calendar";
import { DateTime } from "luxon";


import "@culturehq/add-to-calendar/dist/styles.css";


function CalendarDate (props) {

  const data = props.date;
  const month = data.month;
  const day = data.day;
  const numeral = data.numeral;
  const time = data.time;
  const startsAt = data.startsAt;
  const endsAt = data.endsAt;
  const link = data.link;

  let event = {
    name: 'FLOW Group Chat Session',
    details: 'Join this event from your web browser: <br /> ' + link,
    startsAt: startsAt,
    endsAt: endsAt
  }

  let action = (
    <div class='add-to-calendar-button-wrapper'>
      <AddToCalendar event={event} children={'Add to Calendar'} />
    </div>
  );


  let startsSoonWording;

  const startsAtIso = DateTime.fromISO(startsAt);
  const endsAtIso = DateTime.fromISO(endsAt);

  // Get the time in PST
  const currentTime = DateTime.now().setZone("America/Los_Angeles");

  const fiveMinutesBeforeStart = startsAtIso.minus({ minutes: 5 });
  const twoHoursBeforeStart = startsAtIso.minus({ hours: 2 });

  if( fiveMinutesBeforeStart <= currentTime && endsAtIso >= currentTime ) {

    startsSoonWording = 'Live';
    action = <a href={link} rel="noreferrer" title='Join the chat' target='_blank'>Join Live</a>;

  } else if( twoHoursBeforeStart <= currentTime && endsAtIso >= currentTime ) {
    startsSoonWording = 'Starts Soon';
  } else if( endsAtIso < currentTime ) {
    action = <i class='chat-ended'>Event has ended</i>;
  }



  return (
    <div class='calendar-date-wrapper'>
      <div class='starts-soon'>{startsSoonWording}</div>
      <div class='calendar-date'>
        <div class='calendar-day'>{day}</div>
        <div class='calendar-month'>{month}</div>
        <div class='calendar-numeral'>{numeral}</div>
      </div>
      <div class='calendar-time'>{time}</div>
      {action}
    </div>
  );
}

export default CalendarDate;