import VectorIcon from "../vectorIcon";


function PathwayLevelBlock(props) {

  // Define variables
  const pathway = props.pathway;
  const level = props.level;
  const duration = props.duration;
  const selectedPathway = props.selectedPathway;
  const selectedLevel = props.selectedLevel;
  const certificateComplete = props.certificateComplete;
  let complete = props.complete;
  let checkCompleteElement;

  if( certificateComplete ) {
    complete = certificateComplete;
  }

  let pathwayLevelBlockContainerClass = 'flex-column pathway-level-block-container';
  if( pathway === selectedPathway && level === selectedLevel ) {
    pathwayLevelBlockContainerClass += ' selected';
  }

  if( complete ) {
    pathwayLevelBlockContainerClass += ' complete';
    checkCompleteElement = (
      <div class='level-complete-checkmark'>
        <VectorIcon icon='checkMarkCircle' size={'100%'} />
      </div>
    );
  }

  const color = '#8C0E85';
  let icon = <VectorIcon icon='badge' size={'1.5em'} color={color} />;
  if( level === 'Certificate' ) {
    icon = <VectorIcon icon='certificate' size={'1.5em'} color={color} />;
  }


  return (
    <div class={pathwayLevelBlockContainerClass}>
      {checkCompleteElement}
      {/* <img
        src={"https://files.flowspeak.io/files/badges/pathways/badge-pathway-placeholder.png"}
        alt={'Pathway level badge'}
      /> */}
      {icon}
      <p>Level {level}</p>
      <p class='small'>{duration}</p>
    </div>
  );
}

export default PathwayLevelBlock;