import axios from 'axios';
import { apiBaseUrl } from "../auth/authApi";
import { getToken } from "../auth/tokenHelpers";

const formSumbitComment = async (form, audioBlob) => {

  axios.defaults.baseURL = apiBaseUrl;

  let apiPath = 'comments';

  const formData = new FormData();

  const text = document.querySelector('textarea[name=text]').value;
  const commentable_type = document.querySelector('input[name=commentable_type]').value;
  const post_id = document.querySelector('input[name=post_id]').value;
  const fileName = 'comment-' + document.querySelector('textarea[name=text]').getAttribute('id');

  formData.append('text', text);
  formData.append('commentable_type', commentable_type);
  formData.append('post_id', post_id);
  formData.append('comment[text]', text);
  formData.append('comment[commentable_type]', commentable_type);
  formData.append('comment[post_id]', post_id);

  if( audioBlob ) {
    formData.append('audio', audioBlob, fileName);
  }

  var config = {
    method: 'post',
    url: apiPath,
    headers: { authorization: `Bearer ${getToken()}`, "Content-Type": "multipart/form-data" },
    data : formData
  };

  // Send the data to the server
  return axios(config)
  .then(function (response) {

    if( response && response.data ) {
      return response.data;
    } else {
      // handle with errors
    }


  })
  .catch(function (error) {
    // console.log('-- Response blob save error');
    // console.log(error);
    return error;
  });

}

export default formSumbitComment;