import React from "react";
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { validateSmsLoginCode, validateSmsAccountVerifyCode } from "../utilities/auth/authApi";
import { Mixpanel } from "../utilities/analytics/mixpanelHelpers";
import VerifyForm from "./verifyForm";


function Verify(props) {

  // Define variables
  const [code, setCode] = useState('');
  const [errorText, setErrorText] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(null);
  const runLoginProcess = props.runLoginProcess;
  const changeCurrentScreen = props.changeCurrentScreen;
  const smsPayload = useSelector((state) => state.profile.smsPayload);
  const type = smsPayload.type || 'register';
  const countryCode = smsPayload.phone_country_code;
  const phoneNumber = smsPayload.phone_number;


  // Define functions
  function validateLoginCode() {

    setButtonLoading(true);

    const payload = {
      code: code,
      phone_number: phoneNumber
    }

    validateSmsLoginCode(payload).then((response) => {

      if( response && response.data ) {
        if( response.data.success ) {
          const path = '/dashboard';
          runLoginProcess(response.data, path);
        } else {
          setErrorText(response.data.error);
        }
      } else {
        Mixpanel.track('Error: SMS Login Error', {'phone': phoneNumber, error: 'Unknown error'});
      }

    }).catch((error) => {
      // reportError(error);
      Mixpanel.track('Error: SMS Login Error', {'phone': phoneNumber, error: error});
    }).finally(() => {
      setTimeout(() => setButtonLoading(false), 400);
    });

  }

  function validateAccountCode() {

    const payload = JSON.parse(JSON.stringify(smsPayload));
    payload.code = code;

    validateSmsAccountVerifyCode(payload).then((response) => {
      if( response && response.data ) {
        if( response.data.success ) {
          // const path = '/welcome';
          const path = '/welcome-choose-pathway';
          runLoginProcess(response.data, path);
        } else {
          setErrorText(response.data.error);
        }
      } else {
        // Handle
      }

    }).catch((error) => {
      Mixpanel.track('Error: SMS Account Verify Error', {'phone': phoneNumber, error: error});
    }).finally(() => {
      setTimeout(() => setButtonLoading(false), 400);
    });
  }


  return (
    <VerifyForm
      validateLoginCode={validateLoginCode}
      validateAccountCode={validateAccountCode}
      changeCurrentScreen={changeCurrentScreen}
      buttonLoading={buttonLoading}
      setButtonLoading={setButtonLoading}
      errorText={errorText}
      setErrorText={setErrorText}
      code={code}
      setCode={setCode}
      type={type}
      countryCode={countryCode}
      phoneNumber={phoneNumber}
    />
  );
}

export default Verify;
