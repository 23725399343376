import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function buildTooltip(context) {
  const label = 'Confidence Level: ' + context.parsed.y + ', Consistency: ' + context.parsed.x + ' days';
  return label;
}


function ScatterPlot(props) {

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: '15',
          usePointStyle: true
        }
      },
      title: {
        display: false,
        text: 'Confidence Level',
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = buildTooltip(context);
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        max: 1000,
        min: 100,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7
        }
      },
      x: {
        min: 0,
        max: 30,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10
        },
        title: {
          display: true,
          text: 'Consistency in days'
        }
      }
    }
  };


  const data = {
    datasets: [
      {
        label: 'My level',
        data: props.myData,
        backgroundColor: 'rgb(120, 8, 168)',
        pointRadius: 8,
        pointBorderWidth: 1,
        pointHoverRadius: 20
      },
      {
        label: 'Other learners score',
        data: props.allData,
        backgroundColor: 'rgb(220, 220, 220)',
        pointRadius: 4,
        pointBorderWidth: 1,
        pointHoverRadius: 10
      }
    ]
  };

  return (
    <div class='graph-holder'>
      <Scatter options={options} data={data} />
    </div>
  )
}

export default ScatterPlot;