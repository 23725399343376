import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import '../../styles/start.css';

import { validateAuthCode } from "../utilities/auth/authApi";
import { isAuthed, saveToken } from "../utilities/auth/tokenHelpers";

import StartLeftColumn from "./startLeftColumn";
import UnauthedStartHeader from "./unauthedStartHeader";
import Signin from "./signin"
import Signup from "./signup"
import ForgotPassword from "./forgotpassword"
import Verify from "./verify";


function Start(props) {

  // Dispatch functions
  const dispatch = useDispatch();

  function updateField(field, value) {
    dispatch({ type: 'updateField', payload: {field, value} });
  }


  // Define variables
  const defaultLocation = props.defaultLocation;

  const loginScreen = (
    <Signin
      runLoginProcess={runLoginProcess}
      changeCurrentScreen={changeCurrentScreen}
      updateField={updateField}
    />
  );

  const registerScreen = (
    <Signup
      runLoginProcess={runLoginProcess}
      changeCurrentScreen={changeCurrentScreen}
      updateField={updateField}
    />
  );

  const validateScreen = (
    <Verify
      runLoginProcess={runLoginProcess}
      changeCurrentScreen={changeCurrentScreen}
    />
  );

  const forgotPasswordScreen = (
    <ForgotPassword
      changeCurrentScreen={changeCurrentScreen}
    />
  );

  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState(null);


  // Page load actions
  useEffect(() => {

    // Check for hashtags
    const hash = window.location.hash;
    let screen = 'register';

    if( hash ) { screen = hash.replace('#', '') }
    if( defaultLocation ) { screen = defaultLocation }
    changeCurrentScreen(screen);

    // Look for auth_code
    // format -> /start?auth_code=xxxxx#signin
    let authCode;
    let promoCode;
    let verifyAccount;
    let packages;
    let publicProfileId;
    let certificateName;

    // if( URLSearchParams ) {
    if( typeof window.URLSearchParams === 'function' ) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      authCode = urlParams.get('auth_code');
      promoCode = urlParams.get('promo_code');
      verifyAccount = urlParams.get('verify_account');
      packages = urlParams.get('packages');
      publicProfileId = urlParams.get('download_certificate');
      certificateName = urlParams.get('certificate_name');
    }

    // Redirect if already logged in
    const isLoggedIn = isAuthed();
    if( isLoggedIn ) {
      let path = '/dashboard';
      if( promoCode ) { path = `/packages?code=${promoCode}` }
      // if( verifyAccount ) { path = `/welcome` }
      if( verifyAccount ) { path = `/welcome-choose-package` }
      if( packages ) { path = '/packages' }
      if( certificateName ) { path = '/profile/certificate-name' }
      navigate(path);
    }

    if( authCode ) {
      attemptAutoLogin(authCode, promoCode, packages, verifyAccount, publicProfileId, certificateName);
    }

  }, []);


  // Define functions
  function changeCurrentScreen(screen) {
    if( screen === 'login' || screen === 'signin' ) {
      setCurrentScreen(loginScreen);
    } else if( screen === 'verify' ) {
      setCurrentScreen(validateScreen);
    } else if( screen === 'forgotpassword' ) {
      setCurrentScreen(forgotPasswordScreen);
    } else {
      setCurrentScreen(registerScreen);
    }
  }

  function runLoginProcess(response, path) {
    saveToken(response);
    navigate(path);
  }

  function attemptAutoLogin(authCode, promoCode = null, packages = null, verifyAccount = null, publicProfileId = null, certificateName = null) {
    validateAuthCode({auth_code: authCode}).then((response) => {
      if( response.data && response.data.auth && response.data.jwt ) {

        let path = '/dashboard';
        if( packages ) { path = `/packages` }
        if( promoCode ) { path = `/packages?code=${promoCode}` }
        // if( verifyAccount ) { path = `/welcome` }
        if( verifyAccount ) { path = `/welcome-choose-pathway` }
        // if( publicProfileId ) { path = `/profile/${publicProfileId}` }
        if( publicProfileId ) { path = `/progress` }
        if( certificateName ) { path = `/profile/certificate-name` }

        // Log the learner in
        runLoginProcess(response.data, path);

      } else {
        return false;
      }
    }).catch((error) => {
      // reportError(error);
    });
  }


  return (
    <div class='wrapper'>
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <UnauthedStartHeader />
              {currentScreen}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Start;