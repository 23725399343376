import React from "react";
import { useState, useEffect } from "react";

import Modal from "../utilities/modal";
import LoadingBasic from "../utilities/loadingbasic";
import RenderHtmlText from "../utilities/renderhtmltext";


function AiTalkExample(props) {

  // Define variables
  const [exampleElement, setExampleElement] = useState(null);
  const generateExample = props.generateExample;
  const exampleResponse = props.exampleResponse;
  const setExampleResponse = props.setExampleResponse;
  const closeModal = props.closeModal;

  const body = (
    <div>
      <br />
      {exampleElement ? <RenderHtmlText string={exampleElement} /> : <LoadingBasic />}
    </div>
  );


  // Page load actions
  useEffect(() => {
    if( exampleResponse ) {
      setExampleElement(exampleResponse);
    } else {
      getExample();
    }
  }, []);


  // Define functions
  function getExample() {
    generateExample().then((response) => {
      setExampleElement(response);
      setExampleResponse(response);
    });
  }


  return (
    <Modal
      windowClass={'aitalk-message-modal'}
      closeModal={closeModal}
      title={'Example Response'}
      centerTitle={true}
      body={body}
      closeIcon={true}
    />
  );
}

export default AiTalkExample;