import React from "react";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import apiCall from "../utilities/auth/apiCall";
import { getTopicsList, updateLearnerProfile, getProfile } from "../utilities/auth/authApi";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';

import StartLeftColumn from "./startLeftColumn";
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";
import ProgressDots from "../utilities/progressDots";
import SelectPill from "./selectPill";
import Modal from "../utilities/modal";


function WelcomeChooseTopics() {

  // Define variables
  const { lookupProfile } = useParams();
  const navigate = useNavigate();
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [modal, setModal] = useState(null);


  // Component load actions
  useEffect(() => {

    apiCall(getTopicsList, {}).then((response) => {
      if( response.success ) {
        setTopics(response.data);
      }
    });

    if( lookupProfile ) {
      getProfile().then((response) => {
        if( response.data ) {

          // See if the learner was auto upgraded
          // show the auto upgrade modal if so
          const subscriptionData = response.data.subscription;
          if( subscriptionData?.autoUpgrade ) {
            setModal(
              <Modal
                closeModal={closeModal}
                title={subscriptionData.autoUpgradeMessageTitle}
                body={subscriptionData.autoUpgradeMessageBody}
                bodyAsHtml={true}
                closeButton={true}
              />
            );
          }
        }
      });
    }

  }, []);


  // Define functions
  function closeModal() {
    setModal(null);
  }

  function handlePillClick(value) {
    const array = selectedTopics;
    let newArray;

    if( array.includes(value) ) {
      newArray = array.filter(item => item !== value);
    } else {
      newArray = [...selectedTopics, value];
    }

    setSelectedTopics(newArray);
  }

  function handleSubmit() {

    setLoading(true);

    if( selectedTopics.length > 0 ) {

      const payload = { topics: selectedTopics };

      apiCall(updateLearnerProfile, payload).then((response) => {
        // Do nothing here
        Mixpanel.track('Event: Select Topics', {'Topics': JSON.stringify(selectedTopics)});
      }).finally(() => {
        nextScreen();
      });

    } else {
      nextScreen();
    }
  }

  function nextScreen() {
    setTimeout(() => {
      setLoading(false);
      navigate('/welcome-set-reminders');
    }, 600);
  }


  return (
    <div class='wrapper'>
      {modal}
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <div class='pathway-welcome-container'>

                <div>
                  <h2>Choose your topics</h2>
                  <p>Customize your lessons with topics you like</p>
                </div>

                <div class='pathways-container'>

                  <div class='flex-row select-pill-container' style={{marginTop: '30px'}}>
                    {topics.map((topic, index) =>
                      <SelectPill
                        key={`topic-select-${index}`}
                        text={topic}
                        value={topic}
                        handleClick={handlePillClick}
                        selected={selectedTopics.includes(topic)}
                      />
                    )}
                  </div>
                </div>

                <div class='flex-column pathway-bottom-container'>
                  <ButtonSubmitRound
                    value={"Continue"}
                    handleButtonClick={handleSubmit}
                    loading={loading}
                  />

                  <div style={{marginTop: '15px'}}>
                    <ProgressDots index={1} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeChooseTopics;