import React from "react";
import LoadingBasic from "../utilities/loadingbasic";
import currencyFormat from "../../functions/currencyformat";

function PurchaseSummary (props) {

  const packageData = props.packageData;
  const noDisclaimer = props.noDisclaimer || false;
  let renewDisclaimer;

  let packageContents = (
    <div class='package-summary'>

      <div class='summary-header'>
        <div class='summary-header-plan-name'>
          <p><strong>Premium Membership</strong></p>
        </div>
      </div>

      <LoadingBasic />

    </div>
  );

  if( packageData ) {

    const intervalCount = packageData.intervalCount;
    const interval = (intervalCount > 1 ) ? packageData.interval + 's' : packageData.interval;

    // Stripe prices don't have decimals
    const packagePrice = (packageData.packagePrice / 100);
    const totalPriceWithDiscount = (packageData.totalPriceWithDiscount / 100);

    const promoCode = packageData.coupon;
    let promoCodeLine;

    if( promoCode ) {
      const code = promoCode.code;
      const amountOff = promoCode.amount_off;
      const percentOff = promoCode.percent_off;
      let discount;

      if( amountOff ) {
        discount = ( amountOff / 100 );
      } else if ( percentOff ) {
        discount = packagePrice * ( percentOff / 100 );
      } else {
        // handle
      }

      if( discount ) {
        promoCodeLine = (
          <div class='package-line'>
            <div class='package-line-title'>Promo code <i>{code}</i></div>
            <div class='package-line-price'>-${currencyFormat(discount)}</div>
          </div>
        );
      }
    }

    if( !noDisclaimer ) {
      renewDisclaimer = (
        <div class='sub-line'>
          Your subscription will automatically renew every {intervalCount === 1 ? '' : intervalCount} {interval}.<br />
          You can cancel at any time.
        </div>
      );
    }


    packageContents = (
      <div>
        <div class='package-summary'>

          <div class='summary-header'>
            <div class='summary-header-plan-name'>
              <p><strong>Premium Membership</strong></p>
              <p>&nbsp;- {intervalCount} {interval}</p>
            </div>
            {/* <div class='summary-price'>${currencyFormat(packagePrice)}</div> */}
            <p><strong>${currencyFormat(packagePrice)}</strong></p>
          </div>

          {promoCodeLine}

          <div class='package-line total'>
            {/* <div class='package-total-title'>Total</div>
            <div class='package-total-price'>${currencyFormat(totalPriceWithDiscount)}</div> */}
            <p><strong>Total</strong></p>
            <p><strong>${currencyFormat(totalPriceWithDiscount)}</strong></p>
          </div>

        </div>

        {renewDisclaimer}
      </div>
    );
  }


  return (
    <div class='purchase-summary-wrapper'>
      {packageContents}
    </div>
  );
};

export default PurchaseSummary;