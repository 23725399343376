import React from "react";
import MicrophoneAccessContent from '../help/microphoneaccesscontent';


function MicAccessError() {

  return (

    <div class='microphone-access-message mt-6'>
      <p><strong>Sorry! Your microphone access is blocked.</strong></p>

      <p>Follow these instructions to allow microphone access.</p>
      <br />
      <MicrophoneAccessContent reloadAction={true} />
    </div>
  );
}

export default MicAccessError;
