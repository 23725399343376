import React from 'react';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';


function IntroTour () {

  const stepOne = (
    <div>
      <h3>Welcome to FLOW!</h3>
      <p>We want to help you speak English confidently. You can do it! 🤩</p>
      <p>Take a quick tour to learn how to get started. 💫</p>
    </div>
  )

  const stepTwo = (
    <div>
      <h3>This Week</h3>
      <p>When you login, you will automatically get a mix of new lessons to practice each week.</p>
    </div>
  )

  const stepThree = (
    <div>
      <h3>Confidence Level</h3>
      {/* <p>The Confidence Level helps you quickly track learning progress and participation in our community.</p> */}
      <p>The Confidence Level helps quickly track your learning progress and supercharge your improvement!</p>
      {/* <p>Keep a high Confidence Level to supercharge your improvement!</p> */}
    </div>
  )

  const stepFour = (
    <div>
      <h3>Get Started</h3>
      <p>Take the first lesson to see how FLOW works.</p>
    </div>
  )

  const steps = [
    {
      intro: stepOne
    },
    {
      element: '.title-wrapper',
      intro: stepTwo
    },
    {
      element: '.header-learner-info-container',
      intro: stepThree
    },
    {
      element: '.lesson-card-wrapper',
      intro: stepFour
    }
  ];

  function onExit() {
    console.log('onExit');
  }

  const options = {
    overlayOpacity: .25,
    doneLabel: "Let's Go!"
  }

  return (
    <div class='welcome-tour-wrapper'>
      <Steps
        enabled={true}
        steps={steps}
        initialStep={0}
        onExit={onExit}
        options={options}
      />
    </div>
  );
}

export default IntroTour;