import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import '../../styles/start.css';
import StartHeader from '../start/startHeader';
import SignupJsaf from './signupjsaf';
import { isAuthed } from '../utilities/auth/tokenHelpers';


function OrgStart(props) {

  // Define variables
  const navigate = useNavigate();
  const path = window.location.pathname;
  let orgLogo;
  let description;
  let signupElement;

  if( path === '/jsaf' ) {
    orgLogo = 'https://files.flowspeak.io/files/jsaf-logo.png';
    description = (
      <div class='mb-5'>
        <p class='has-text-centered mb-4'>FLOWとJSAFはパートナーシップに基づき、あなたの英語力向上をサポートします。</p>
        <p class='has-text-centered'>以下の情報をローマ字でご入力ください。</p>
      </div>
    );
    signupElement = <SignupJsaf />
  }


  // Page load actions
  // Page load actions
  useEffect(() => {

    const isLoggedIn = isAuthed();
    if( isLoggedIn ) {
      navigate('/dashboard');
    }

  },[]);


  return (
    <div class='wrapper'>
        <div class='main-body org-start'>
          <div class='main-body-content start'>

            <StartHeader orgLogo={orgLogo} />

            {description}
            {signupElement}

            <br /><br /><br /><br />

          </div>
        </div>
      </div>
  );
}

export default OrgStart;