function UnauthedStartHeader(props){

  // Define variables
  const orgLogo = props.orgLogo;
  const subTitle = props.subTitle;
  const hideTitle = props.hideTitle || false;


  // Screen components
  let titleElement;
  if( !hideTitle ) {
    titleElement = <h1>Welcome</h1>;
  }

  let subTitleText = 'Speak English Like a Pro';
  if( subTitle ) {
    subTitleText = subTitle;
  }

  let orgLogoElement;
  if( orgLogo ) {
    let logoClass = 'org-logo';

    // Hack
    if( orgLogo.includes('simpul')) {
      logoClass = 'org-logo simpul-logo';
    }

    orgLogoElement = (
      <div
        class={logoClass}
        style={{
          backgroundImage: "url(" + orgLogo + ")",
        }}>
      </div>
    );
  }


  return (
    <div class='unauthed-start-header-container'>
      <div class='flex-row content-left'>
        <div class='unauthed-start-header-logo'></div>
        {orgLogoElement}
      </div>
      {titleElement}
      <p>{subTitleText}</p>
    </div>
  );
}

export default UnauthedStartHeader;