import { useState } from 'react';
import { Link } from "react-router-dom";

import Badge from '../badge';
import BadgeInfoModal from '../badgeInfoModal';
import IssueDateElement from './issueDateElement';


function StatsBadges(props) {

  // Define variables
  const [badgeInfoModal, setBadgeInfoModal] = useState(null);
  const badges = props.badges;
  const isThisLearner = props.isThisLearner;
  const scoreBadges = badges?.scoreBadges ? badges.scoreBadges : [];
  const earnedBadges = scoreBadges.filter(badge => badge.issueDate);


  // Define functions
  function showBadgeInfoModal(badge) {
    if( isThisLearner ) {
      setBadgeInfoModal(
        <BadgeInfoModal
          badge={badge}
          closeModal={closeBadgeInfoModal}
        />
      );
    }
  }

  function closeBadgeInfoModal() {
    setBadgeInfoModal(null);
  }


  // Screen components
  let bodyElement;
  if( isThisLearner || (!isThisLearner && earnedBadges.length > 0) ) {

    let bottomTextElement;
    if( isThisLearner ) {
      bottomTextElement = (
        <div class='flex-column'>
          <p style={{marginTop: '15px'}}>Earn badges and share them on your social media!</p>
          <Link to='/badges' style={{marginTop: '10px'}}>
            Learn More
          </Link>
        </div>
      );
    }

    let badgesList;
    if( isThisLearner ) {

      badgesList = (
        <div class='flex-row stats-badges-container'>
          {scoreBadges.map((badge, index) =>
            <button
              key={`badge-wrapper-${index}`}
              type='button'
              onClick={() => showBadgeInfoModal(badge)}
              class={`clean-button pointer badge-wrapper ${badge.issueDate ? null : 'disabled'}`}
            >
              <div class='flex-column'>
                <div class='stat-badge-image-container'>
                  <Badge badge={badge} achieved={badge.issueDate} imageSize={150} />
                </div>
                <IssueDateElement issueDate={badge.issueDate} />
              </div>
            </button>
          )}
        </div>
      );

    } else {

      badgesList = (
        <div class='flex-row stats-badges-container'>
          {earnedBadges.map((badge, index) =>
            <div
              key={`badge-wrapper-${index}`}
              class='badge-wrapper'
            >
              <div class='flex-column' style={{height: '100%'}}>
                <div class='stat-badge-image-container'>
                  <Badge badge={badge} achieved={badge.issueDate} imageSize={150} />
                  </div>
                <IssueDateElement issueDate={badge.issueDate} />
              </div>
            </div>
          )}
        </div>
      );

    }

    bodyElement = (
      <div class='flex-column stat-container stats-badges-wrapper'>
        {badgeInfoModal}
        <h3>Badges</h3>
        {badgesList}
        {bottomTextElement}
      </div>
    );
  }


  return (
    <>{bodyElement}</>
  );
}


export default StatsBadges;