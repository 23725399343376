import React from "react";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import BiteAction from "./biteaction";
import PlayAudioIcon from "../utilities/playaudioicon";
import RecordResponse from "../utilities/recordresponse";
// import ScoreDetails from "../utilities/learning_module/scoredetails"
// import ScoreDetailsBite from "../utilities/learning_module/scoreDetailsBite";
import ScoreDetailsBiteLite from "../utilities/learning_module/scoreDetailsBiteLite";
import ExpressionTranscript from '../expression/expressionTranscript';


function CurrentQuestionSpeak(props) {

  // Define variables
  const [action, setAction] = useState('record-waiting');
  // const [skipSisabled, setSkipDisabled] = useState(false);
  // const handleNoTalkClick = props.handleNoTalkClick;
  const nextAction = props.nextAction;
  const tryAgain = props.tryAgain;
  const responseObject = props.responseObject;
  const currentQuestion = props.currentQuestion;
  const currentAnswer = props.currentAnswer;
  // const responseText = props.responseText;
  const responseBlobUrl = (props.responseBlobUrl) ? props.responseBlobUrl : null;
  const imageUrl = currentQuestion.imageUrl;
  const answerText = currentAnswer.text ? currentAnswer.text : '';
  const ieltsCourse = props.ieltsCourse;
  const retryCount = props.retryCount;
  const retryLimit = props.retryLimit;
  // const responseTextDisplay = (responseText) ? responseText : <RecordResponse handleTranscript={props.handleTranscript} scorePronunciation={true} providedText={answerText} colorScheme={'bite'} />;


  // Component load actions
  useEffect(() => {
    if( responseObject ) {
      setAction('record-complete');
    } else {
      setAction('record-waiting');
    }

  // }, [currentQuestion, responseText]);
}, [currentQuestion, responseObject]);


  // Define functions
  // function handleSkip() {
  //   setSkipDisabled(true);
  //   handleNoTalkClick();

  //   setTimeout(() => {
  //     setSkipDisabled(false);
  //   }, 1000);
  // }


  // Screen components
  let tryAgainElement = <div class='try-again-wrapper'></div>;
  // if( responseText && ( retryCount < retryLimit) ) {
  if( responseObject && ( retryCount < retryLimit) ) {

    tryAgainElement = (
      <div class='try-again-wrapper'>
        <button
          class='clean-button try-again'
          data-key='try-again-div'
          onClick={tryAgain}
          title='Tap to retry your response'
        >
          Tap to retry
        </button>
      </div>
    );
  }


  let audioIconResponse;
  if( responseBlobUrl ) {
    audioIconResponse = <div class="media-left"><PlayAudioIcon audioUrl={responseBlobUrl} iconDark={true} responseAudio={true} /></div>;
  }


  let audioIconQuestion;
  if( currentAnswer.audioUrl ) {
    audioIconQuestion = <div class="media-left"><PlayAudioIcon audioUrl={currentAnswer.audioUrl} colorScheme={'light'} /></div>;
  }


  let secondaryLineClass = 'title secondary-line speak';
  if( answerText.length > 18 && answerText.length <= 30 ) {
    secondaryLineClass = 'title secondary-line speak small';
  } else if ( answerText.length > 31 ) {
    secondaryLineClass = 'title secondary-line speak smaller';
  }



  let actionElement;
  if( responseObject ) {
    actionElement = (
      <div class='response-content'>

        <div class="card-content bite-card-content">
          <ExpressionTranscript
            responseObject={responseObject}
            audioUrl={responseObject.audioFileUri}
          />
        </div>

        <div class='response-extras'>
          {tryAgainElement}
          <ScoreDetailsBiteLite
            responseObject={responseObject}
            ieltsCourse={ieltsCourse}
          />
        </div>

      </div>
    );
  } else {
    actionElement = (
      <div class='response-content'>
        <div class="card-content">
          <div class="media">
          {audioIconResponse}
            <div class="media-content">
              <div class="has-text-right response-input">
                <RecordResponse
                  handleTranscript={props.handleTranscript}
                  scorePronunciation={true}
                  providedText={answerText}
                  colorScheme={'bite'}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div class='response-extras'>
          {tryAgainElement}
          <ScoreDetailsBite
            responseObject={responseObject}
            ieltsCourse={ieltsCourse}
          />
        </div> */}
      </div>
    );
  }


  return (
    <div class='bite-wrapper'>

      <div
        class='bite-background-image fade-in-on-load fade-slow'
        style={{
          backgroundImage: "url(" + imageUrl + ")",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}>
      </div>

      <div class='bite-top-fade'></div>
      <div class='bite-content'>

        <div class='bite-header'>
          <span class='icon'></span>
          <div class='title speak'>Speaking Practice</div>
          <Link to='/' class='icon icon-close-light' title='Return to Dashboard'></Link>
        </div>
        <div class={secondaryLineClass}>
          <p>
            <span class='answer-say'>Say</span>
            <span class='answer-text'>
              {answerText}
              {audioIconQuestion}
            </span>
          </p>
        </div>

        <div class='bite-record-wrapper'>
          {/* <div class='response-content'>
            <div class="card-content">
              <div class="media">
              {audioIconResponse}
                <div class="media-content">
                  <div class="has-text-right response-input">
                    {responseTextDisplay}
                  </div>
                </div>
              </div>
            </div>
            <div class='response-extras'>
              {tryAgainElement}
              <ScoreDetailsBite
                responseObject={responseObject}
                ieltsCourse={ieltsCourse}
              />
            </div>
          </div> */}
          {actionElement}
        </div>

        {/* <div class='cant-talk-wrapper'>
          <button
            class='clean-button'
            onClick={() => handleSkip()}
            disabled={skipSisabled}
          >Can't talk right now</button>
        </div> */}

        <BiteAction
          action={action}
          nextAction={nextAction}
        />

      </div>
    </div>
  );
};

export default CurrentQuestionSpeak;