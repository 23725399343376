function ScoreDetailsTooltipModal(props) {

  // Define variables
  const closeScoreDetailsTooltipModal = props.closeScoreDetailsTooltipModal;
  const content = props.content;
  const event = props.event;

  const offsetParent = event.target.offsetParent;
  const offsetTop = event.target.offsetTop;
  const offsetHeight = event.target.offsetHeight;
  const offsetLeft = event.target.offsetLeft;
  const offsetWidth = event.target.offsetWidth;

  const bottom = offsetParent.offsetHeight - offsetTop - offsetHeight + 30;
  const left = offsetLeft - 20 + (offsetWidth / 2);


  return (
    <div class='word-score-tooltip-wrapper'>
      <div class='word-score-tooltip' style={{bottom: bottom}}>
        <div class='word-score-tooltip-left'>
          {content}
        </div>
        <div class='word-score-tooltip-right'>
          <button
            type='button'
            class='icon icon-close'
            onClick={() => closeScoreDetailsTooltipModal()}
          ></button>
        </div>
        <div class="arrow-down" style={{left: left}}></div>
      </div>
    </div>
  );
}

export default ScoreDetailsTooltipModal;