import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


function DoughnutChart(props) {

  const title = props.title;
  const labels = props.data.labels;
  const data = props.data.chartData;
  const backgroundColors = props.data.backgroundColors;
  const borderColors = props.data.borderColors;


  const chartData = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: data,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      }
    ],
  }


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          boxWidth: '8',
          usePointStyle: true
        }
      },
      title: {
        display: false
      }
    }
  };

  return (
    <div class='graph-holder'>
      <Doughnut data={chartData} options={options} />
    </div>
  )
}

export default DoughnutChart;