import React from "react";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import alertMessage from "../utilities/alertMessage";
import apiCall from "../utilities/auth/apiCall";
import { createBlock, getProfile } from "../utilities/auth/authApi";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';

import StartLeftColumn from "./startLeftColumn";
import PathwayLevel from "../utilities/pathways/pathwayLevel";
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";
import ProgressDots from "../utilities/progressDots";
import Modal from "../utilities/modal";


function WelcomeChoosePathway() {

  // Define variables
  const navigate = useNavigate();
  const [pathway, setPathway] = useState(null);
  const [level, setLevel] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(null);


  // Component load actions
  useEffect(() => {

    getProfile().then((response) => {
      if( response.data ) {

        // See if the learner was auto upgraded
        // show the auto upgrade modal if so
        const subscriptionData = response.data.subscription;
        if( subscriptionData?.autoUpgrade ) {
          setModal(
            <Modal
              closeModal={closeModal}
              title={subscriptionData.autoUpgradeMessageTitle}
              body={subscriptionData.autoUpgradeMessageBody}
              bodyAsHtml={true}
              closeButton={true}
            />
          );
        }
      }
    });

  }, []);


  // Define functions
  function closeModal() {
    setModal(null);
  }

  function handleSelect(pathway) {
    setPathway(pathway);
    setLevel(1);
    setDisabled(false);
  }

  function handleSubmit() {

    if( !pathway || !level ) {
      alertMessage('Please select a Pathway and Level first', 'error')
      return;
    }

    setLoading(true);

    const payload = {
      pathway: pathway,
      level: level
    }

    apiCall(createBlock, payload).then((response) => {
      if( response.success ) {
        setTimeout(() => {
          navigate('/welcome-choose-topics');

          Mixpanel.track('Event: Select Pathway', {'Pathway': pathway});
        }, 600);
      } else {
        alertMessage(response.error, 'error');
      }
    }).finally(() => {
      setLoading(false);
      setDisabled(true);
    });

  }


  return (
    <div class='wrapper'>
      {modal}
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <div class='pathway-welcome-container'>

                <div>
                  <h2>Choose your pathway</h2>
                  <p>Our learning pathways guide you to success</p>
                </div>

                <div class='pathways-container'>
                  <PathwayLevel pathway={1} handleSelect={handleSelect} selectedPathway={pathway} selectedLevel={level} allowSelect={true} />
                  <PathwayLevel pathway={2} handleSelect={handleSelect} selectedPathway={pathway} selectedLevel={level} allowSelect={true} />
                  <PathwayLevel pathway={3} handleSelect={handleSelect} selectedPathway={pathway} selectedLevel={level} allowSelect={true} />
                  <a href='https://www.flowspeak.io/articles/pathways-level-up-your-speaking' target='_blank' rel="noreferrer" class='pathway-learn-link'>Learn more about the FLOW Speak Learning Pathways</a>
                </div>

                <div class='flex-column pathway-bottom-container'>
                  <ButtonSubmitRound
                    value={"Continue"}
                    handleButtonClick={handleSubmit}
                    loading={loading}
                    disabled={disabled}
                  />

                  <div style={{marginTop: '15px'}}>
                    <ProgressDots index={0} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeChoosePathway;