function loadCustomCss(url) {

  // Ensure file is a css file
  if( !url.includes('.css') ) {
    return;
  }

  // Add an attribute to root
  const rootElement = document.getElementById('root');
  if( rootElement ) {
    rootElement.setAttribute('data-iframe-height', true);
  }

  // Get HTML head element
  let head = document.getElementsByTagName('HEAD')[0];

  // Create new link Element
  let link = document.createElement('link');

  // set the attributes for link element
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = url;

  // Append link element to HTML head
  head.appendChild(link);


  // Load the iframe resizer
  let linkTwo = document.createElement('script');
  linkTwo.type = 'text/javascript';
  linkTwo.src = 'https://portal.bridge.edu/iframe/iframe-resizer-4.3.6/js/iframeResizer.contentWindow.js';
  linkTwo.defer = true;

  head.appendChild(linkTwo);
}

export default loadCustomCss;