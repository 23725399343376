function SeriesFilters(props) {

  // Define variables
  const filterByType = props.filterByType;
  const filterByLevel =  props.filterByLevel;
  const filterByTerm =  props.filterByTerm;
  const clearFilters = props.clearFilters;
  const activeFilter = props.activeFilter;

  // Screen components
  let clearElement = <button type='button' class='clean-button'>&nbsp;</button>;
  if( activeFilter ) {
    clearElement = (
      <button
        type='button'
        class='clean-button pointer'
        onClick={() => clearFilters()}
      >
        Clear filters
      </button>
    );
  }


  return (
    <div class='flex-row library-filter-container'>

      <div class='form-field'>
        <label>Lesson Type</label>
        <select
          onChange={(event) => filterByType(event.target.value)}
          id='filter-lesson-type'
        >
          <option value='All'>All</option>
          <option value='Conversation'>Conversation</option>
          <option value='Idea'>Idea</option>
          <option value='Expression'>Expression</option>
          <option value='AiTalk'>AI Talk</option>
        </select>
      </div>

      <div class='form-field'>
        <label>Level</label>
        <select
          onChange={(event) => filterByLevel(event.target.value)}
          id='filter-level'
        >
          <option value='All'>All</option>
          <option value='Beginner'>Beginner</option>
          <option value='Intermediate'>Intermediate</option>
          <option value='Advanced'>Advanced</option>
        </select>
      </div>

      <div class='form-field search-input'>
        <label>Search</label>
        <input
          onChange={(event) => filterByTerm(event.target.value)}
          id='filter-search-term'
          placeholder="Search by lesson title"
        />
      </div>

      <div class='form-field clear-filters'>
        <label>&nbsp;</label>
        {clearElement}
      </div>

    </div>
  );
}

export default SeriesFilters;