
function headerTitle(title) {
  // let title = urlString;

  if( title.charAt(title.length-1) === 's' ) {
    title = title.substring(0, title.length - 1);
  }

  return title.charAt(0).toUpperCase() + title.slice(1);
}

export default headerTitle;