import React from "react";

import '../../styles/start.css';

import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";
import SelectPill from "./selectPill";


function AccountSetupForm(props) {

  // Define variables
  const submitAndContinue = props.submitAndContinue;
  const learningMotivations = props.learningMotivations;
  const setLearningMotivations = props.setLearningMotivations;
  const mainGoal = props.mainGoal;
  const setMainGoal = props.setMainGoal;
  const languageLevel = props.languageLevel;
  const setLanguageLevel = props.setLanguageLevel;
  const reminderFrequency = props.reminderFrequency;
  const setReminderFrequency = props.setReminderFrequency;
  const buttonLoading = props.buttonLoading;


  // Define functions
  function handleMotivationClick(value) {

    const array = learningMotivations;
    let newArray;

    if( array.includes(value) ) {
      newArray = array.filter(item => item !== value);
    } else {
      newArray = [...learningMotivations, value];
    }

    setLearningMotivations(newArray);
  }


  return (
    <div>

      <div class='welcome-question-wrapper'>
        <h4>My English speaking level is</h4>
        <div class='welcome-question-answers'>

          <SelectPill
            text={'Beginner'}
            value={'Beginner'}
            handleClick={setLanguageLevel}
            selected={languageLevel === 'Beginner'}
          />

          <SelectPill
            text={'Intermediate'}
            value={'Intermediate'}
            handleClick={setLanguageLevel}
            selected={languageLevel === 'Intermediate'}
          />

          <SelectPill
            text={'Advanced'}
            value={'Advanced'}
            handleClick={setLanguageLevel}
            selected={languageLevel === 'Advanced'}
          />

        </div>
      </div>

      <div class='welcome-question-wrapper'>
        <h4>I'm learning English to</h4>
        <div class='welcome-question-answers'>

          <SelectPill
            text={'Advance my career'}
            value={'grow-my-career'}
            handleClick={handleMotivationClick}
            selected={learningMotivations.includes('grow-my-career')}
          />

          <SelectPill
            text={'Just for fun'}
            value={'for-fun'}
            handleClick={handleMotivationClick}
            selected={learningMotivations.includes('for-fun')}
          />

          <SelectPill
            text={'Travel & make friends'}
            value={'for-travel'}
            handleClick={handleMotivationClick}
            selected={learningMotivations.includes('for-travel')}
          />

        </div>
      </div>

      <div class='welcome-question-wrapper'>
        <h4>My goal is to become a</h4>
        <div class='welcome-question-answers'>

          <SelectPill
            text={'Fluent speaker, do 7+ lessons/week'}
            value={'fluent-speaker'}
            handleClick={setMainGoal}
            selected={mainGoal === 'fluent-speaker'}
          />

          <SelectPill
            text={'Advanced speaker, do 3 - 4 lessons/week'}
            value={'advanced-speaker'}
            handleClick={setMainGoal}
            selected={mainGoal === 'advanced-speaker'}
          />

          <SelectPill
            text={'Intermediate speaker, do 1 - 3 lesson/week'}
            value={'intermediate-speaker'}
            handleClick={setMainGoal}
            selected={mainGoal === 'intermediate-speaker'}
          />

        </div>
      </div>

      <div class='welcome-question-wrapper'>
        <h4>Send me reminder emails</h4>
        <div class='welcome-question-answers'>

          <SelectPill
            text={'Every Day'}
            value={'every-day'}
            handleClick={setReminderFrequency}
            selected={reminderFrequency === 'every-day'}
          />

          <SelectPill
            text={'Twice a week'}
            value={'twice-a-week'}
            handleClick={setReminderFrequency}
            selected={reminderFrequency === 'twice-a-week'}
          />

          <SelectPill
            text={'Never'}
            value={'never'}
            handleClick={setReminderFrequency}
            selected={reminderFrequency === 'never'}
          />

        </div>
      </div>

      <div class='flex-column' style={{marginTop: '15px'}}>
        <ButtonSubmitRound
          value={'Continue'}
          handleButtonClick={submitAndContinue}
          loading={buttonLoading}
        />
      </div>

    </div>
  );
}

export default AccountSetupForm;
