import toast from 'react-hot-toast';
import VectorIcon from './vectorIcon';

const checkMarkIcon = <VectorIcon icon='checkMarkCircle'color='#61d345' size='1.75rem' />;
const errorIcon = <VectorIcon icon='close' color='#f14668' size='1.75rem' />;

// Type = alert, success, error, modal
function alertMessage(message, type = 'success') {

  let duration = 6000;

  if( type === 'success' ) {

    toast(
      (t) => (
        <div class='toast-wrapper'>
          <div class='flex-row toast-container'>
            {checkMarkIcon}
            <p>{message}</p>
          </div>
          <div class='flex-row toast-button-container'>
            <button onClick={() => toast.dismiss(t.id)} class='clean-button toast-button'>Ok</button>
          </div>
        </div>
      ),
      {
        duration: duration
      }
    );

  } else if( type === 'error' ) {

    duration = 10000;

    toast(
      (t) => (
        <div class='toast-wrapper'>
          <div class='flex-row toast-container'>
            {errorIcon}
            <p>{message}</p>
          </div>
          <div class='flex-row toast-button-container'>
            <button onClick={() => toast.dismiss(t.id)} class='clean-button toast-button'>Ok</button>
          </div>
        </div>
      ),
      {
        duration: duration
      }
    );

    // toast.error(message, {duration: duration});

  } else if( type === 'alert' ) {

  } else if( type === 'modal' ) {

  }
}

export default alertMessage;