import React from "react";
import { useState } from "react";

import apiCall from "../../utilities/auth/apiCall";
import { updateBlock } from "../../utilities/auth/authApi";

import PathwayLevel from "../../utilities/pathways/pathwayLevel";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";


function StepShowLevel(props) {


  // Define variables
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const clearDashboardData = props.clearDashboardData;
  const stepData = props.stepData;
  // const step = stepData.step;
  const pathway = stepData.pathway;
  const pathwayName = stepData.pathwayName;
  const level = stepData.level;
  const completedLevels = stepData.completedLevels;
  const certificates = stepData.certificates || [];

  const completedLevelsBeginner = completedLevels[0];
  const completedLevelsIntermediate = completedLevels[1];
  const completedLevelsAdvanced = completedLevels[2];


  // Define functions
  function handleSubmit() {
    setLoading(true);

    apiCall(updateBlock, {}).then((response) => {
      if( response.success ) {
        clearDashboardData();
      }
    });
  }


  return(
    <div>
      <div class='week-list-container'>
        <div>

          <div style={{marginBottom: '15px'}}>
            <h3>FLOW Speak learner pathways</h3>
            <p>Continue your learning with {pathwayName} Level {level}</p>
          </div>


          <PathwayLevel pathway={1} selectedPathway={pathway} selectedLevel={level} completeLevels={completedLevelsBeginner} certificateComplete={certificates.includes(1)} />
          <PathwayLevel pathway={2} selectedPathway={pathway} selectedLevel={level} completeLevels={completedLevelsIntermediate} certificateComplete={certificates.includes(2)} />
          <PathwayLevel pathway={3} selectedPathway={pathway} selectedLevel={level} completeLevels={completedLevelsAdvanced} certificateComplete={certificates.includes(3)} />

          <div class='flex-column' style={{marginTop: '30px', marginBottom: '15px'}}>
            <ButtonSubmitRound
              value={"Let's Go!"}
              handleButtonClick={handleSubmit}
              disabled={disabled}
              loading={loading}
            />
          </div>

        </div>

      </div>

      <div class='flex-column' style={{marginTop: '15px'}}>
        <p>
          <a href='https://www.flowspeak.io/articles/pathways-level-up-your-speaking' target='_blank' rel="noreferrer" class='pathway-learn-link'>Learn more about the FLOW Speak Learning Pathways</a>
        </p>
      </div>
    </div>
  );
}

export default StepShowLevel;