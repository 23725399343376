import PlayAudioIcon from "../utilities/playaudioicon";
import PhrasePhoneDetail from "../utilities/phrasePhoneDetail";


function ExpressionTranscript(props) {

  // Define variables
  const responseObject = props.responseObject;
  const audioUrl = props.audioUrl;
  const position = props.position || 'left';
  const margin = (position === 'left' ) ? {marginRight: '10px'} : {marginLeft: '5px'};


  // Screen components
  let audioElement;
  if( audioUrl ) {
    audioElement = (
      <span style={margin}>
        <PlayAudioIcon
          audioUrl={audioUrl}
          iconDark={true}
          position={position}
        />
      </span>
    );
  }


  return (
    <div class='flex-row' style={{justifyContent: 'center'}}>
      <PhrasePhoneDetail responseObject={responseObject} />
      {audioElement}
    </div>
  );
}

export default ExpressionTranscript;