import ScoresBlockSpeaking from "./scoresBlockSpeaking";
import ScoresBlockSpeakingIelts from "./scoresBlockSpeakingIelts";
import ScoresBlockExpression from "./scoresBlockExpression";
import ScoresBlockAiTalk from "./scoresBlockAiTalk";
import ScoresBlockBooster from "./scoresBlockBooster";


function ScoresBlock(props) {

  // Define variables
  const isIelts = props.isIelts
  const scores = props.scores;
  const lessonType = props.lessonType;
  const retryCount = props.retryCount;


  // Screen components
  let scoresElement = <ScoresBlockSpeaking scores={scores} retryCount={retryCount} />;
  if( isIelts ) {
    scoresElement = <ScoresBlockSpeakingIelts scores={scores} retryCount={retryCount} />;
  }

  if( lessonType === 'Expression' || lessonType === 'Bite' ) {
    scoresElement = <ScoresBlockExpression scores={scores} isIelts={isIelts} retryCount={retryCount} />;
  }

  if( lessonType === 'AiTalk' ) {
    scoresElement = <ScoresBlockAiTalk scores={scores} isIelts={isIelts} />;
  }

  if( lessonType === 'Booster' ) {
    scoresElement = <ScoresBlockBooster scores={scores} isIelts={isIelts} retryCount={retryCount} />;
  }


  return (
    <div>
      {scoresElement}
    </div>
  );
}

export default ScoresBlock;