import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom"

import { Mixpanel } from "../analytics/mixpanelHelpers";
import RenderHtmlText from "../renderhtmltext";
import HelpBubble from "./helpbubble";
import PlayAudioIcon from "../playaudioicon";
import LongPressLookupElement from "../longpresslookupelement";


function QuestionText(props) {

  // Define variables
  const [showHelpBubble, setShowHelpBubble] = useState(false);
  const translateToggle = props.translateToggle;
  const translateContent = props.translateContent;
  const isCommunityIndex = props.isCommunityIndex;
  const isCommunityResponse = props.isCommunityResponse;
  const backUrl = props.backUrl;
  const audioSpeed = props.audioSpeed;

  const content = props.content;
  const type = content.type; // Only need this for "introduction" vs "question"
  const subType = content.subType; // Only need this for complete lines
  const audioUrl = content.audioUrl;
  const gem = content.gem;
  const questionId = Date.now();

  let className = `question-wrapper ${type}`;

  if( subType ) { className += ' ' + subType}

  const headline = (content.headline) ? <LongPressLookupElement classList={'headline'} content={content.headline} /> : null;
  const audioIcon = (audioUrl) ? <div class="media-right"><PlayAudioIcon audioUrl={audioUrl} audioSpeed={audioSpeed} /></div> : null;

  let gemIcon = <div class="image is-24x24"></div>;;
  let helpBubble = null;

  if( gem ) {
    gemIcon = (
      <button
        type='button'
        class='clean-button icon-gem image is-24x24'
        onClick={() => loadHelpBubble()}
        title='Click to learn more about this phrase'
      ></button>
    );
    // helpBubble = (showHelpBubble) ? <HelpBubble title={'Cultural Gem'} helpContents={gem} type={'gem'} closeBubble={closeHelpBubble} /> : null;
    if( showHelpBubble ) {
      helpBubble = (
        <HelpBubble
          title={'Cultural Gem'}
          helpContents={gem}
          type={'gem'}
          closeBubble={closeHelpBubble}
          translation={translateContent ? translateContent.gem : null}
        />
      );
    }
  }

  let question = [];

  const questionArray = content.content.split('/n');

  for (let i = 0; i < questionArray.length; i++) {
    question.push(<LongPressLookupElement key={i} content={questionArray[i]} />);
  }

  let links = [];

  if( content.links ) {
    for (let i = 0; i < content.links.length; i++) {
      const text = content.links[i].text;
      const url = content.links[i].url;

      const link = <Link to={url} key={i} title='Go to Vocabulary' class='link-to-module'>{text}</Link>;
      links.push(link);
    }
  }


  // Define functions
  function loadHelpBubble() {
    setShowHelpBubble(true);

    // Log the event
    Mixpanel.track('Event: Show Gem');
  }

  function closeHelpBubble() {
    setShowHelpBubble(false);
  }


  // Screen components
  let responses;
  let exploreResponses;

  if( isCommunityIndex ) {

    gemIcon = null;
    className = className + " community-index";

    if( type !== 'introduction' ) {

      const moduleContentId = content.id;
      const baseUrl = window.location.pathname;
      const exploreUrl = `${baseUrl}/${moduleContentId}/responses`;

      responses = <div class='community-responses'>{content.responses} {content.responses === 1 ? 'response' : 'responses'}</div>;
      exploreResponses = <Link to={exploreUrl} title='Explore responses' class='link-to-comments icon icon-chevron-right'></Link>;
    }

  }


  let backArrow;
  if( backUrl ) {
    backArrow = <Link to={backUrl} title='Back' class='link-to-responses icon icon-chevron-left'></Link>;
  }


  if( isCommunityResponse ) {
    gemIcon = null;
    className = className + " community-response";
  }

  let translateElement;
  if( translateToggle && translateContent ) {

    let translateIntroElement;
    if( translateContent.headline ) {
      translateIntroElement = <p><strong>{translateContent.headline}</strong></p>;
    }

    translateElement = (
      <div class='translate-container fade-in-on-load'>
        {translateIntroElement}
        <RenderHtmlText string={translateContent.text} />
      </div>
    );
  }


  return (
    <div class={className} key={questionId}>
      <div class='flex'>
        {backArrow}
        <div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                {headline}
                {question}
                {links}
              </div>
              {audioIcon}
            </div>
          </div>
          {translateElement}
        </div>
        {gemIcon}
        {helpBubble}
        {exploreResponses}
      </div>
      {responses}
    </div>
  );
}


export default QuestionText;