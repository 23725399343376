import React from "react";

import PostHeader from "./postheader";
import PostBody from "./postbody";


function PostIndexIntroCard() {

  const bodyText = "<h5>Listen, Speak, Ask</h5><p>This is your Community. Learning together is better. Give and receive feedback so we all improve.</p>";


  return (

    <div key='index-intro-card' class='post-wrapper index-intro-card'>
      <div>

        <PostHeader
          author={'FLOW'}
          avatarUrl={'https://files.flowspeak.io/files/FLOW-Logo.png'}
          postDate={''}
        />

        <PostBody
          bodyText={bodyText}
          noLinks={true}
        />

      </div>
    </div>
  );
}

export default PostIndexIntroCard;