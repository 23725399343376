import React from "react";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import excludeCountries from "../utilities/excludeCountries";


function PhoneNumberInput(props) {

  // Define variables
  const setCountryCode = props.setCountryCode;
  const setPhoneNumber = props.setPhoneNumber;


  // Define functions
  function setValue(value, country) {
    const countryCode = country.dialCode;
    const phone = value.replace(countryCode, '');

    setCountryCode(countryCode);
    setPhoneNumber(phone);
  }

  return (

    <PhoneInput
      country={'in'}
      onChange={(value, country) => setValue(value, country)}
      enableSearch={true}
      inputClass={'input'}
      excludeCountries={excludeCountries}
    />
  );
}

export default PhoneNumberInput;