import React from 'react';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"
import VectorIcon from "../utilities/vectorIcon";


function AitalkHeader(props) {

  // Define variables
  const [disabled, setDisabled] = useState(false);
  const isSaved = useSelector((state) => state.aiTalk.isSaved);
  const iconUrl = props.iconUrl;
  const imageUrl = props.imageUrl;
  const title = props.title;
  const description = props.description;
  const saveLesson = props.saveLesson;
  const actionWord = isSaved ? 'Remove' : 'Add';
  const icon = isSaved ? 'bookmarkFill' : 'bookmarkOutline';

  const backgroundImageUrl = imageUrl ? imageUrl : iconUrl;


  // Define functions
  function handleClick() {
    setDisabled(true);
    saveLesson();

    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  }


  return (
    <div class='aitalk-header-container'>

      <div
        class='aitalk-header-image'
        style={{backgroundImage: `url(${backgroundImageUrl})`}}
      >
        <div class='aitalk-header-blur'>

          <div class='flex-row'>
            <Link to='/' title='Close'>
              <VectorIcon icon='close' color='#ffffff' />
            </Link>
            <div class='flex-column'>
              <p>AI Talk</p>
              <h3>{title}</h3>
            </div>
            <button
              type='button'
              disabled={disabled}
              class='clean-button pointer'
              title={`${actionWord} this lesson from your saved list`}
              onClick={() => handleClick()}
            >
              <VectorIcon icon={icon} color='#ffffff' />
            </button>
          </div>

          <div class='flex-row aitalk-header-description-container'>
            <div class='aitalk-header-image-background'></div>
            <img src={iconUrl} width='45' alt='Manager icon' />
            <div class='aitalk-header-description'>
              {description}
            </div>
          </div>

          <div class='aitalk-header-divider'></div>

        </div>
      </div>
    </div>
  )
}

export default AitalkHeader;