function ExpressionMeaning(props) {

  // Define variables
  const logStart = props.logStart;
  const translateToggle = props.translateToggle;
  const translation = props.translation;
  const expression = props.expression;
  const title = expression.title;
  const meaning = expression.meaning;
  const meaningExtra = expression.meaningExtra;


  // Screen components
  let meaningExtraElement;
  if( meaningExtra ) {
    meaningExtraElement = (
      <div>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>{meaningExtra}</p>
      </div>
    );
  }


  let translationElement;
  if( translateToggle && translation ) {
    translationElement = (
      <p
        class='translate-text'
        style={{marginTop: '15px'}}
      >{translation}</p>
    );
  }


  return (
    <div class='expression-body-wrapper'>
      <div class='expression-body-container'>
        <div class='expression-inner-content-container flex-column' style={{justifyContent: 'center'}}>
          <div class='expression-inner-content meaning'
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p class='expression-simple-heading'><i>"{title}" means</i></p>
            <p style={{fontSize: '1.125rem'}}>{meaning}</p>
            {translationElement}
            {meaningExtraElement}
          </div>
        </div>
      </div>

      <div class='expression-button-container'>
        <button
          type='button'
          class='button action-button'
          onClick={() => logStart()}
        >Next</button>
      </div>
    </div>
  )

}

export default ExpressionMeaning;