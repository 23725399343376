import React from "react";
import { useState, useEffect } from 'react';

import ListeningSpinner from "./listeningspinner";
import AudioProcessingCircle from "./audioprocessingcircle";

const processingWordsDefault = ['Scoring Pronunciation', 'Checking Speed', 'Scoring Fluency', 'Rating Vocabulary', 'Analyzing Grammar'];
let interval;


function RecordUiDefault(props) {

  // Define functions
  const [instructions, setInstructions] = useState('');
  const handleClick = props.handleClick;
  const recordStatus = props.recordStatus;
  const processingWords = props.processingWords || processingWordsDefault;

  const recordIcon = <div id='record-icon'></div>;
  const listenIcon = <ListeningSpinner />;
  const processingIcon = <AudioProcessingCircle />;


  // Page load actions
  useEffect(() => {

    clearInterval(interval);

    if( recordStatus === 'waiting' ) {
      setInstructions('Tap to record your response');
    } else if( recordStatus === 'listening' ) {
      setInstructions('Tap to stop recording');
    } else if( recordStatus === 'processing' ) {
      setInstructions(processingWords[0]);
      cycleWords();
    }

  }, [recordStatus]);


  // Define functions
  function cycleWords() {
    let i = 0;

    interval = setInterval(function(){
      setInstructions(processingWords[i]);
      i++;
      if( processingWords.length === i ) {
        clearInterval(interval);
      }
    }, 1200);

  }


  // Screen components
  let iconElement = recordIcon;

  if( recordStatus === 'listening') {
    iconElement = listenIcon;
  }

  if( recordStatus === 'processing') {
    iconElement = processingIcon;
  }


  return (
    <button
      class='record-response-button'
      onClick={handleClick}
    >
      <p>{instructions}</p>
      {iconElement}
    </button>
  );
}

export default RecordUiDefault;