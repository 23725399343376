function ScoreBlockRetryCount(props) {

  // Define variables
  const retryCount = props.retryCount;
  let retryElement;
  if( retryCount || retryCount === 0 ) {
    retryElement = (
      <div class='flex-row score-block-row'>
        <p>Retries</p>
        <p class='flex-row'>
          {retryCount}
        </p>
      </div>
    );
  }


  return (
    <>
      {retryElement}
    </>
  );
}

export default ScoreBlockRetryCount;