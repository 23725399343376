import React from 'react';
import { useState } from 'react';

import apiCall from './auth/apiCall';
import { updateShareSetting } from './auth/authApi';
import { Mixpanel } from './analytics/mixpanelHelpers';


function ShareResponsesButton(props) {

  // Define variables
  const exerciseId = props.exerciseId;
  const [shareSetting, setShareSetting] = useState(false);


  // Define functions
  function updateExercise() {

    const newShareSetting = !shareSetting;

    const payload = {
      id: exerciseId,
      share_setting: newShareSetting
    }

    apiCall(updateShareSetting, payload).then((response) => {
      if( response.success ) {
        setShareSetting(newShareSetting);
        Mixpanel.track('Event: Lesson Share Responses');
      }
    });

  }


  return (
    <div class='share-responses-container flex-row'>
      <button
        class={`action-toggle-wrapper ${shareSetting ? 'selected' : null}`}
        data-attribute='share_responses'
        onClick={updateExercise}
      >
        <div class='toggle-ball'></div>
      </button>
      <p>Ask for community feedback</p>
    </div>
  );
}

export default ShareResponsesButton;