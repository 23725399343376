function Flag(props) {

  const countryCode = props.countryCode.toLowerCase();
  // const url = `https://flagcdn.com/w40/${countryCode}.png`;

  return (
    // <img src={url} class='country-flag-image' alt='Country flag' />
    <img
      src={`https://flagcdn.com/24x18/${countryCode}.png`}
      srcSet={`https://flagcdn.com/48x36/${countryCode}.png 2x,
        https://flagcdn.com/72x54/${countryCode}.png 3x`}
      width="24"
      height="18"
      alt="Country flag"
    />
  );
}

export default Flag;