import React from "react";


function SelectPill(props) {

  // Define variables
  const text = props.text;
  const selected = props.selected;
  const value = props.value;
  const handleClick = props.handleClick;


  return (
    <button
      type='button'
      class={selected ? 'clean-button pointer select-pill selected' : 'clean-button pointer select-pill'}
      onClick={() => handleClick(value)}
    >
      {text}
    </button>
  );
}

export default SelectPill;