function ProfileToggle(props) {
  let toggleWrapperClass = 'action-toggle-wrapper';
  if( props.value ) { toggleWrapperClass += ' selected' }


  return (
    <div class='profile-input-wrapper'>
      <label>{props.label}</label>
      <div class='profile-input'>
        <div class={toggleWrapperClass} onClick={() => props.handlePreferenceChange(props.name, !props.value)}>
          <div class='toggle-ball'></div>
        </div>
      </div>
    </div>
  );
}

export default ProfileToggle;