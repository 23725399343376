import React from 'react';
import { useState } from 'react';
import { useSelector } from "react-redux";

import ScoreDetailsModal from './scoreDetailsModal';
import ScoreDonut from './scoredonut.jsx';


function ScoreDetails(props) {

  // Define variables
  const questionId = props.questionId;
  const responseData = props.responseObject || {};
  const ieltsCourseDispatch = useSelector((state) => state.lesson.ieltsCourse);
  const ieltsCourseProps = props.ieltsCourse;
  const ieltsCourse = ieltsCourseDispatch || ieltsCourseProps;
  const [scoreDetailsModal, setShowScoreDetailsModal] = useState(null);

  const hasPremiumSubscription = useSelector((state) => state.profile.hasPremiumSubscription);
  const isFirstWeek = useSelector((state) => state.profile.isFirstWeek);
  let hideSeeDetails = !hasPremiumSubscription;

  if( window.location.pathname === '/lessons/167' || isFirstWeek ) {
    // We want to show the real score details
    hideSeeDetails = false;
  }


  // Handle overall score variables
  let overallScorePercent;
  let overallScore;

  let ieltsOverallScore;
  // let ieltsOverallPercent;

  if( ieltsCourse ) {
    ieltsOverallScore = responseData?.ieltsScores?.overall;
    // ieltsOverallPercent = ieltsOverallScore;

    overallScore = ieltsOverallScore;
    overallScorePercent = ieltsOverallScore;
  } else {
    overallScore = responseData?.overall?.word;
    overallScorePercent = responseData?.overall?.percent;
  }


  // Define functions
  function showScoreDetailsModal() {

    // Set the local storage count so we can use this elsewhere
    const scoreDetailsHelperCount = parseInt(localStorage.getItem('scoreDetailsHelperCount')) || 0;
    localStorage.setItem('scoreDetailsHelperCount', scoreDetailsHelperCount + 1);

    setShowScoreDetailsModal(
      <ScoreDetailsModal
        hideScoreDetailsModal={hideScoreDetailsModal}
        ieltsCourse={ieltsCourse}
        responseData={responseData}
        hideSeeDetails={hideSeeDetails}
        questionId={questionId}
      />
    );

  };

  function hideScoreDetailsModal() {
    setShowScoreDetailsModal(null);
  };


  return (
    <div class='response-score-wrapper'>
      <button
        class='clean-button response-score'
        title='Tap to see score details'
        onClick={showScoreDetailsModal}
      >
        <span>{overallScore}</span>
        <ScoreDonut
          scorePercent={overallScorePercent}
          isIelts={ieltsCourse}
        />
      </button>
      {scoreDetailsModal}
    </div>
  );
}

export default ScoreDetails;