import PlayAudioIcon from "../utilities/playaudioicon";


function BiteAnswer(props) {

  // Define variables
  const translateContentQuestionAnswers = props.translateContentQuestionAnswers;
  const showExplanation = props.showExplanation;
  const handleAnswerClick = props.handleAnswerClick;
  const action = props.action;
  const selected = props.selected;
  const answer = props.answer;
  const text = answer.text;
  const audioUrl = answer.audioUrl;
  const audioPlayerId = 'post-audio-player-' + answer.id;


  // Define functions
  function playAudio() {
    const audioPlayerElement = document.getElementById(audioPlayerId);

    if( audioPlayerElement ) {
      audioPlayerElement.load();
      audioPlayerElement.play().catch((e) => {
        // Catch error because this method won't work on mobile safari
      });
    }
  }


  // Screen components
  let displayText = text;
  let thisAnswerTranslation;
  if( translateContentQuestionAnswers ) {
    thisAnswerTranslation = translateContentQuestionAnswers.find(a => a.recordId === answer.id);
    if( thisAnswerTranslation ) {
      displayText = thisAnswerTranslation.text;
    }
  }

  let audioIcon;
  if( audioUrl ) {
    // audioIcon = (
    //   <div class='icon-audio-wrapper'>
    //     <button class={`icon icon-audio ${selected ? null : 'dark'}`} tabIndex='0' onClick={playAudio}></button>
    //     <audio id={audioPlayerId}>
    //       <source src={answer.audioUrl} type="audio/mpeg" />
    //     </audio>
    //   </div>
    // );
    audioIcon = <PlayAudioIcon audioUrl={audioUrl} colorScheme={selected ? 'light' : 'dark'} />;
  }

  let explanation = <div class='icon'></div>;
  if( answer.explanation && action === 'incorrect' ) {
    const translation = thisAnswerTranslation ? thisAnswerTranslation.explanation : null;
    explanation = (
      <button
        class='icon icon-info-light pointer'
        style={{marginLeft: '10px'}}
        onClick={() => showExplanation(answer.explanation, translation)}
      ></button>
    );
  }


  return (
    <div class='bite-answer-wrapper'>
      <div class={`bite-answer-content ${selected ? 'selected' : null}`}>
        <button class='clean-button bite-answer' onClick={() => handleAnswerClick(answer.id)}>
          {displayText}
        </button>
        {audioIcon}
      </div>
      {explanation}
    </div>
  );
};

export default BiteAnswer;