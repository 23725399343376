function AppLinksCard () {


  return (
    <div class='dashboad-app-links-card'>
      <p>Download the app!</p>
      <div class='dashboad-app-links-container'>
        <a href='https://apps.apple.com/us/app/flow-speak/id1639740192' target='_blank' title='Download the app on the App Store' rel='noreferrer'>
          <img src='https://bucket.mailersendapp.com/0r83ql3eyx4zw1jm/x2p03471o94zdrn7/images/978a81d4-c068-441a-aa4c-b61144eb1199.png' alt='App Store Logo' />
        </a>
        <a href='https://play.google.com/store/apps/details?id=com.flow_app&hl=en_US&gl=US&utm_source=webapp' target='_blank' title='Download the app on the Google Play Store' rel='noreferrer'>
          <img src='https://bucket.mailersendapp.com/0r83ql3eyx4zw1jm/x2p03471o94zdrn7/images/978a81d5-e2c0-409e-93c8-6fd044211bf4.png' alt='App Store Logo' />
        </a>
      </div>
    </div>
  );
}

export default AppLinksCard;