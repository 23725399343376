import React from "react";

import PostHeader from "./postheader";
import PostFooter from "./postfooter";
import PostBody from "./postbody";
// import dateFormat from "../../functions/dateformat";


function PostText(props) {

  const post = props.post;
  const postId = post.id;
  const author = post.author;
  const authorPublicProfileId = post.author_public_profile_id;
  const countryCode = post.country_code;
  const postType = post.post_type;
  // const postDate = dateFormat(post.created_at);
  const postDate = post.posted_date;
  const audioUrl = post.audio_url || false;
  const avatarUrl = post.avatar_url;

  const commentCount = post.comment_count || 0;
  const likeCount = post.like_count;
  const learnerLikeCount = post.learner_like_count;

  const handleLikeChange = props.handleLikeChange || null;
  const noLinks = props.noLinks || null;

  let category = post.category;
  let bodyText = post.body;
  let postLink = `/community/posts/${post.id}/comments`;
  let responseQuestionBody;

  if( postType === 'response' ) {
    category = 'Feedback request';
    postLink = `/community/responses/${post.id}/comments`;
    bodyText = post.text;

    // Show the response question if we are viewing the response
    if( noLinks ) {
      category = author + "'s response";

      const questionAudioUrl = post.question_audio_url;
      const questionText = post.question_text;
      const lessonId = post.lesson_id;

      responseQuestionBody = (
        <PostBody
          audioUrl={questionAudioUrl}
          bodyText={questionText}
          category={'Question'}
          noLinks={true}
          lessonId={lessonId}
        />
      );
    }

  }


  return (

      <div>

        <PostHeader
          author={author}
          authorPublicProfileId={authorPublicProfileId}
          avatarUrl={avatarUrl}
          countryCode={countryCode}
          category={category}
          postDate={postDate}
          postType={postType}
        />

        {responseQuestionBody}

        <PostBody
          audioUrl={audioUrl}
          bodyText={bodyText}
          category={category}
          noLinks={noLinks}
          postLink={postLink}
          trackScrollPosition={props.trackScrollPosition}
        />

        <PostFooter
          id={postId}
          commentCount={commentCount}
          handleLikeChange={handleLikeChange}
          likeCount={likeCount}
          learnerLikeCount={learnerLikeCount}
          noLinks={noLinks}
          postLink={postLink}
          postType={postType}
          trackScrollPosition={props.trackScrollPosition}
        />

      </div>
  );
}

export default PostText;