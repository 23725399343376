import React from "react";
import { useState, useEffect } from 'react';

import ListeningSpinner from "./listeningspinner";
import AudioProcessingCircle from "./audioprocessingcircle";


function RecordUiExpression(props) {

  // Define functions
  const [instructions, setInstructions] = useState('');
  const handleClick = props.handleClick;
  const recordStatus = props.recordStatus;

  const recordIcon = <div id='record-icon'></div>;
  const listenIcon = <ListeningSpinner />;
  const processingIcon = <AudioProcessingCircle />;


  // Page load actions
  useEffect(() => {

    if( recordStatus === 'waiting' ) {
      setInstructions('Tap to record');
    } else if( recordStatus === 'listening' ) {
      setInstructions('Tap to stop recording');
    } else if( recordStatus === 'processing' ) {
      setInstructions('Scoring Pronunciation');
    } else if( recordStatus === 'complete' ) {
      setInstructions('Tap to try again');
    }

  }, [recordStatus]);


  // Screen components
  let iconElement = recordIcon;

  if( recordStatus === 'listening') {
    iconElement = listenIcon;
  } else if( recordStatus === 'processing') {
    iconElement = processingIcon;
  }


  return (
    <button
      class='record-response-button'
      onClick={handleClick}
    >
      <div class='expression-record-icon-container-base'>
        <div
          class='expression-record-icon-container'
          style={( recordStatus === 'complete' ) ? {opacity: .5} : null}
        >
          {iconElement}
        </div>
      </div>
      <p style={( recordStatus === 'complete' ) ? {textDecoration: 'underline'} : null}>{instructions}</p>
    </button>
  );
}

export default RecordUiExpression;