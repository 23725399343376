import React from "react";


function ChatFaqs() {


  return (
    <div class='chat-faqs-wrapper'>

      <h3>Chat FAQ's</h3>

      <p>
        <strong>Are the weekly group chats free?</strong>
        <br />
        Yes, these are free for all members. Come and join us!
      </p>

      <p>
        <strong>How do I confirm the date for the chat?</strong>
        <br />
        There is no need to confirm. Simply join on the date and time of the chat.
        <br /><br />
        Click the “Add to Calendar” button to save the date, which will include the meeting link for you.
        Or, if you come back to this screen during the event, there will be a link you can click to join.
      </p>

    </div>
  );
}

export default ChatFaqs;