import React from "react";
import { useEffect } from 'react';
import { Link } from "react-router-dom"
import { DateTime } from "luxon";

import { chatDatesArray } from "./chatdates";

import '../../styles/chat.css';

// import * as api from "../utilities/auth/authApi";
import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import CalendarDate from "./calendardate";
import ChatFaqs from "./chatfaqs";

import fadeInMainBody from "../../functions/fadeinmainbody";


function Chat () {

  useEffect(() => {
    fadeInMainBody();
  });



  const upcomingDates = [];
  const currentDay = DateTime.now().setZone("America/Los_Angeles").startOf('day');

  for (let i = 0; i < chatDatesArray.length; i++) {

    const startsAtDay = DateTime.fromISO(chatDatesArray[i].startsAt).setZone("America/Los_Angeles").startOf('day');

    if( startsAtDay >= currentDay ) {

      if( upcomingDates.length <= 1 ) {
        upcomingDates.push( chatDatesArray[i] );
      }
    }
  }


  return (
    <div class='wrapper gray-background'>
      <Nav />

      <div class='main-body invisible'>
        <HeaderMain title="Live Chat" />
        <div class='main-body-content chat'>

          <h3>Join our weekly group chat sessions</h3>

          <div class='chat-calendar-wrapper'>
            {upcomingDates.map((date) =>
              <CalendarDate date={date} />
            )}
          </div>

          <div class='chat-cta-wrapper'>

            <p>Want a 1-on-1 coaching session?</p>
            <h4>Upgrade to Premium</h4>

            <div class='action-button-wrapper'>
              <Link to='/packages' class='action-button' title='View plans'>View Plans</Link>
            </div>

          </div>

          <ChatFaqs />

        </div>
      </div>

      <BottomNav />

    </div>
  );
}

export default Chat;