import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import alertMessage from '../utilities/alertMessage';
import fadeInMainBody from '../../functions/fadeinmainbody';
import { getPublicProfile } from '../utilities/auth/authApi';

import HeaderMain from '../utilities/headermain';
import Nav from '../utilities/nav';
import BottomNav from '../utilities/bottomnav';
import Badge from '../utilities/badge';


function BadgeScreen() {

  // Define variables
  const publicProfileId = useSelector((state) => state.profile.publicProfileId);
  const [scoreBadges, setScoreBadges] = useState([]);
  const [pathwayBadges, setPathwayBadges] = useState([]);


  // Page load actions
  useEffect(() => {

    fadeInMainBody();

    if( publicProfileId ) {
      getPublicProfile(publicProfileId).then((response) => {
        if( response?.data?.learner?.badges?.badgesScoresAll ) {
          setScoreBadges(response.data.learner.badges.badgesScoresAll);
          setPathwayBadges(response.data.learner.pathwayData.badges);
        } else {
          alertMessage('Sorry, we could not find a learner with that ID', 'error');
        }
      }).catch((error) => {

      });
    }

  }, [publicProfileId]);



  return (
    <div class='wrapper gray-background'>
      <Nav />

      <div class='main-body invisible'>
        <HeaderMain title="Badges" />
        <div class='main-body-content profile'>

          <div>
            <h3 style={{marginBottom: '15px'}}>Confidence Level Badges</h3>
            <p style={{marginBottom: '5px'}}>Each time you reach a new Confidence Level, you earn a badge and build your way to English conversational fluency.</p>
            <p>Share your badges on LinkedIn and other social media to prove your ability.</p>
          </div>

          <div style={{marginBottom: '15px'}}>
            {scoreBadges.map((badge, index) =>
              <div class='flex-row' style={{justifyContent: 'flex-start', marginTop: '15px'}} key={`badge-container-${index}`}>
                <Badge badge={badge} />
                <div style={{marginLeft: '5px'}}>
                  <p>{badge.name}</p>
                  {badge.description ? <p>{badge.description}</p> : null}
                </div>
              </div>
            )}
          </div>


          <div style={{marginTop: '60px'}}>
            <h3 style={{marginBottom: '15px'}}>Pathway Badges</h3>
            <p style={{marginBottom: '5px'}}>Each time you complete a Pathway Level, you earn a badge to show your progress.</p>
            <p>Share your badges on LinkedIn and other social media to prove your ability.</p>
          </div>

          <div style={{marginBottom: '60px'}}>
            {pathwayBadges.map((badge, index) =>
              <div class='flex-row' style={{justifyContent: 'flex-start', marginTop: '15px'}} key={`badge-container-${index}`}>
                <Badge badge={badge} imageSize={40} />
                <div style={{marginLeft: '5px'}}>
                  <p>{badge.name}</p>
                  {badge.description ? <p>{badge.description}</p> : null}
                </div>
              </div>
            )}
          </div>


        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default BadgeScreen;
