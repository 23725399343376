import React from "react";
import { Link } from "react-router-dom"

import '../../styles/packages.css';

import ChoosePackage from "../packages/choosepackage";
import PurchaseSteps from "../packages/purchasesteps";


function WelcomeChoosePackage() {

  return (
    <div class='wrapper'>
      <div class='main-body'>
        <div class='main-body-content start welcome choose-package'>

          <h3 class='start-header'>
            <div class='logo'></div>
          </h3>

          <h1>Welcome to the Community!</h1>
          <p>Choose your plan.</p>
          <p><br /></p>

          <PurchaseSteps currentStep={'package'} />
          <ChoosePackage packageFeaturesPlacement={'bottom'} />

          <div class='login-secondary-action'>
            <Link to={{pathname: '/', state: { referrer: 'welcome' }}} title='Go to login'>
              Skip
            </Link>
          </div>

        </div>
      </div>
    </div>
  );
}

export default WelcomeChoosePackage;
