import NoAccess from "../../utilities/noAccess";

function ExpressionNoAccess() {

  return (
    <div class='expression-body-wrapper' style={{marginTop: '30px'}}>
      <div class='expression-body-container'>
        <div class='expression-inner-content-container flex-column' style={{justifyContent: 'center'}}>
          <div class='expression-inner-content meaning'
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <NoAccess />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpressionNoAccess;