function dateFormat(dateString) {

  if( !dateString ) { return '' }

  const rawDate = new Date(dateString);
  const month = rawDate.toLocaleString('default', { month: 'long' });
  const day = rawDate.getDate();
  const date = `${month} ${day}${nth(day)}`;

  return date;

}

const nth = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

export default dateFormat;