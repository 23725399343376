import React from "react";
import { useState, useEffect } from 'react';

import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import RecordResponse from "../utilities/recordresponse";
import AiTalkExample from "./aiTalkExample";
import RecordUiBig from "../utilities/recordUiBig";


function AiTalkResponse(props) {

  // Define variables
  const [modal, setModal] = useState(null);
  const [exampleResponse, setExampleResponse] = useState(null);
  const handleTranscript = props.handleTranscript;
  const isWaiting = props.isWaiting;
  const messages = props.messages;
  const generateExample = props.generateExample;
  const extras = props.extras;


  // Component load actions
  useEffect(() => {
    setExampleResponse(null);
  }, [messages]);


  // Define functions
  function openModal() {
    setModal(
      <AiTalkExample
        closeModal={closeModal}
        exampleResponse={exampleResponse}
        setExampleResponse={setExampleResponse}
        generateExample={generateExample}
      />
    );
    Mixpanel.track('Event: AI Talk see example');
  }

  function closeModal(){
    setModal(null);
  }

  function handleTranscriptIntermediary(transcript, audioBlobUrl, blob, responseObject) {
    handleTranscript(transcript, audioBlobUrl, blob, responseObject);
    closeModal();
  }


  // Screen components
  let recordElement = (
    <div class='big-record-outer-wrapper'>
      <RecordUiBig
        disabled={true}
        recordStatus={'waiting'}
      />
    </div>
  );

  if( !isWaiting ) {
    recordElement = (
      // <div class='response-wrapper active'>
      //   <div class='response-content'>
      //     <div class='card-content'>
      //       <RecordResponse
      //         handleTranscript={handleTranscriptIntermediary}
      //         processingWords={['Scoring Fluency', 'Checking Speed', 'Analyzing Grammar']}
      //         useSimpleTranscribe={true}
      //         messages={messages}
      //         colorScheme={'big'}
      //       />
      //     </div>

      //     <div class='flex-column' style={{width: '100%'}}>
      //       <button
      //         type='button'
      //         title='See an example response'
      //         class='clean-button aitalk-get-example-button'
      //         onClick={() => openModal()}
      //       >
      //         Need an example?
      //       </button>
      //     </div>

      //   </div>
      // </div>

      <div class='big-record-outer-wrapper'>
        <RecordResponse
          handleTranscript={handleTranscriptIntermediary}
          processingWords={['Scoring Fluency', 'Checking Speed', 'Analyzing Grammar']}
          useSimpleTranscribe={true}
          messages={messages}
          colorScheme={'big'}
          extras={extras}
        />

        <div class='flex-column' style={{width: '100%'}}>
          <button
            type='button'
            title='See an example response'
            class='clean-button aitalk-get-example-button'
            onClick={() => openModal()}
          >
            Need an example?
          </button>
        </div>

      </div>
    );
  }


  return (
    <div>
      {modal}
      {recordElement}
    </div>
  );
}

export default AiTalkResponse;