import scoreWord from "../../../functions/scoreWord";
import ScoresBlock from "./scoresBlock";
import LessonDetailResponse from "./lessonDetailResponse";
import LessonDetailUpgrade from "./lessonDetailUpgrade";


function LessonDetailBody(props) {

  // Define variables
  const exerciseData = props.exerciseData;
  const lessonData = props.lessonData;
  const responseData = props.responseData;
  const lessonScoreWord = scoreWord(exerciseData.scores.score);
  const hasPremiumSubscription = props.hasPremiumSubscription;


  // Screen components
  let responsesElement;
  if( !hasPremiumSubscription ) {
    responsesElement = <LessonDetailUpgrade />;
  } else {
    if( !responseData || responseData.length === 0 ) {
      responsesElement = <p>Sorry, no responses were recorded for this lesson.</p>;
    } else {
      responsesElement = (
        <>
          {responseData.map((response, index) =>
            <LessonDetailResponse
              key={`lesson-detail-response-${index}`}
              response={response}
              isIelts={exerciseData.isIelts}
              lessonType={lessonData.lessonType}
            />
          )}
        </>
      );
    }
  }


  return (
    <div class='flex-column'>

      <div class='flex-column lesson-detail-body'>

        <div class='lesson-detail-container'>
          <h4>Lesson Score: <strong>{lessonScoreWord}</strong></h4>

          <div class='lesson-detail-content'>
            <ScoresBlock
              isIelts={exerciseData.isIelts}
              scores={exerciseData.scores}
              lessonType={lessonData.lessonType}
            />
          </div>
        </div>

        <div class='lesson-detail-container' style={{marginBottom: '90px'}}>
          <h4>Responses</h4>
          {responsesElement}
        </div>

      </div>
    </div>
  );
}

export default LessonDetailBody;