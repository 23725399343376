// Tutorial
// https://egghead.io/lessons/react-add-redux-to-an-existing-react-application

import { createStore, combineReducers } from "redux"
import { profileReducer } from "./profile";
import { LessonReducer } from "./lesson";
import { AllSeriesReducer } from "./allseries";
import { SavedLessonsReducer } from "./savedlessons";
import { notificationsReducer } from "./notifications";
import { postsReducer } from "./posts";
import { coursesReducer } from "./courses";
import { packageReducer } from "./package";
import { ExpressionReducer } from "./expression";
import { AiTalkReducer } from "./alTalk";
import { BoosterReducer } from "./booster";
import { dashboardDataReducer } from "./dashboard";
import { progressLessonsReducer } from "./progressLessons";

export const store = createStore(
  combineReducers({
    dashboardData: dashboardDataReducer,
    profile: profileReducer,
    lesson: LessonReducer,
    progressLessons: progressLessonsReducer,
    allSeries: AllSeriesReducer,
    savedLessons: SavedLessonsReducer,
    notifications: notificationsReducer,
    posts: postsReducer,
    courses: coursesReducer,
    package: packageReducer,
    expression: ExpressionReducer,
    aiTalk: AiTalkReducer,
    booster: BoosterReducer
  })
);