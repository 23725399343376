import { translateBooster } from "../utilities/auth/authApi";
import BoosterPhrase from "./boosterPhrase";
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";
import LoadingBasic from "../utilities/loadingbasic";
import TranslateToggle from "../utilities/translate/translateToggle";


function BoosterDefinitions(props) {

  // Define variables
  const isInitialized = props.isInitialized;
  const id = props.id;
  const nextStep = props.nextStep;
  const booster = props.booster;
  const phrases = booster.phrases;
  const iconUrl = booster.iconUrl;
  const introductionText = booster.introductionText;

  const translateToggle = props.translateToggle;
  const setTranslateToggle = props.setTranslateToggle;
  const translateContent = props.translateContent;
  const setTranslateContent = props.setTranslateContent;


  // Screen components
  let loadingElement;
  if( !isInitialized ) {
    loadingElement = <LoadingBasic />;
  }


  return (
    <section class='lesson-content'>

      <TranslateToggle
        id={id}
        apiMethod={translateBooster}
        translateToggle={translateToggle}
        setTranslateToggle={setTranslateToggle}
        translateContent={translateContent}
        setTranslateContent={setTranslateContent}
        lessonType={'Booster'}
      />

      <div class='booster-definitions-wrapper'>

        <div class='flex-row'>
          <img src={iconUrl} class='booster-avatar-big' alt='Image of the speaking companion' />
          <p style={{width: '100%'}}>{introductionText}</p>
        </div>

        <div class='booster-definitions-container'>
          {loadingElement}
          {phrases.map((phrase, index) =>
            <BoosterPhrase
              key={`booster-definition-${index}`}
              phraseData={phrase}
              translateContent={translateToggle && translateContent?.phrases[index]}
            />
          )}
        </div>

        <div class='flex-column' style={{marginTop: '30px', marginBottom: '15px'}}>
          <ButtonSubmitRound
            value={"Continue"}
            handleButtonClick={nextStep}
            disabled={!isInitialized}
            // loading={loading}
          />
        </div>

      </div>
    </section>
  );
}

export default BoosterDefinitions;