import React from "react";

function LoadingGrammar() {
  return (
    <div class='loading-general grammar'>
      <div class='button is-loading'></div>
      <p>Checking Grammar ...</p>
    </div>
  );
};

export default LoadingGrammar;