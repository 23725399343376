import React from 'react';
import '../../styles/lesson.css';
import '../../styles/conversation.css';

import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';

import alertMessage from '../utilities/alertMessage.js';
import { reportError } from "../../functions/sentry";
import apiCall from '../utilities/auth/apiCall';
import { logExerciseStart, translateLearningModule } from '../utilities/auth/authApi';
import sendExerciseData from '../../functions/sendExerciseData';

import HeaderLesson from "./headerlesson";
import LessonScreenSwitcher from './lessonScreenSwitcher';
import LeftColumn from "../utilities/learning_module/leftcolumn";

import ProgressSteps from '../utilities/progressSteps';
import LearningModule from "../utilities/learning_module/learningmodule";
import LessonCompleteModal from '../utilities/lessonCompleteModal';

import loadModule from '../../functions/loadmodule.js';
import scrollQuestionsDiv from '../../functions/scrollquestionsdiv';
import TranslateToggle from '../utilities/translate/translateToggle';

let retryLimit = 20;


function Lesson() {

  // Define dispatch functions
  const dispatch = useDispatch();

  function setActivePlaying(status) {
    dispatch({ type: 'setActivePlaying', payload: status});
  }

  function markLessonAsComplete() {
    dispatch({ type: 'markAsComplete', payload: {complete: true}});
  }

  function setExerciseId(id) {
    dispatch({ type: 'setCurrentExerciseId', payload: {currentExerciseId: id}});
  }

  async function updateActiveQustionArray(questionsArray) {
    dispatch({ type: 'updateActiveQuestions', payload: {activeQuestions: questionsArray}});
  }

  function updateCurrentQuestionNumber(number) {
    dispatch({ type: 'updateCurrentQuestionNumber', payload: {currentQuestionNumber: number}});
  }

  function setHasPremiumSubscription(value) {
    dispatch({ type: 'setHasPremiumSubscription', payload: {hasPremiumSubscription: value}});
  }

  async function updateCurrentLessonAudio(array) {
    dispatch({ type: 'updateCurrentLessonAudio', payload: {currentLessonAudio: array}});
  }

  function setLesson(payload) {
    dispatch({ type: 'setLesson', payload: payload});
  }

  // function invalidateInitialized() {
  //   dispatch({ type: 'invalidateInitialized', payload: {}});
  // }

  function clearDashboardData() {
    dispatch({ type: 'clearDashboardData' });
  }

  function updateGrammarErrors(key, value) {
    dispatch({ type: 'updateGrammarErrors', payload: {key: key, value: value}});
  }


  // Define variables
  const { id } = useParams();

  const [completeElement, setCompleteElement] = useState(null);
  const [showCompleteLoading, setShowCompleteLoading] = useState(false);

  const exerciseId = useSelector((state) => state.lesson.currentExerciseId);
  const currentLessonId = useSelector((state) => state.lesson.currentLessonId);
  const questionList = useSelector((state) => state.lesson.currentLessonQuestions) || [];
  const activeQuestions = useSelector((state) => state.lesson.activeQuestions) || [];
  const currentQuestionNumber = useSelector((state) => state.lesson.currentQuestionNumber) || 0;

  const totalQuestionCount = questionList.length;

  const isLastQuestion = currentQuestionNumber === (questionList.length - 1);
  const currentLessonAudio = useSelector((state) => state.lesson.currentLessonAudio);
  const microphonePermission = useSelector((state) => state.lesson.microphonePermission);
  const isLessonComplete = useSelector((state) => state.lesson.complete);

  const [showNextButton, setShowNextButton] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [translateToggle, setTranslateToggle] = useState(false);
  const [translateContent, setTranslateContent] = useState(null);


  // TO DO - update to redux
  const autoplayAudio = localStorage.getItem("autoplayAudio");
  let autoplayDivClass = 'autoplay-notice';

  // Hide the autoplace notice on mobile safari and chrome
  const userAgent = navigator.userAgent.toLowerCase();

  if( userAgent.includes('iphone') && userAgent.includes('safari') ) { autoplayDivClass += ' is-hidden' }


  // Componet load actions
  useEffect(() => {

    setActivePlaying(false);

    // See if we are loading the same lesson were we're already on
    if( currentLessonId === id ) {

      // If we are coming back to the lesson
      // and we have not loaded the next question yet
      // we need to show the next button
      // but, only show if the lesson is not complete
      if( activeQuestions.length > 0 && activeQuestions[activeQuestions.length - 1].responseObject && !isLessonComplete ) {
        setTimeout(() => setShowNextButton(true), 2500);
      }

    } else {

      // When coming directly from another lesson that is finished
      // the next-question-wrapper is still hidden
      // so we need to remove that hidden class
      const nextQuestionWrapperDiv = document.getElementsByClassName('next-question-wrapper')[0];
      nextQuestionWrapperDiv.classList.remove('is-hidden');

    }

    // See if we are loading a new lesson
    // and therefore need to fetch the lesson data
    if( !currentLessonId || currentLessonId !== id ) {

      loadModule(id).then((response) => {
        if( response.data ){

          // Ensure the lesson complete modal is not active
          setCompleteElement(null);

          // In the case that the learner went directly to this lesson
          // Pull the premium subscription info
          setHasPremiumSubscription(response.data.learner_has_premium_subscription);

          const lesson = response.data;
          const lessonContents = lesson.moduleContent;

          // Update the redux store
          // dispatch({ type: 'setLesson', payload: {
          //   id: id,
          //   learnerHasAccessToLesson: response.data.learner_has_access_to_lesson,
          //   lesson: lesson,
          //   questionList: lessonContents,
          //   activeQuestions: [lessonContents[0]],
          //   currentQuestionNumber: 0,
          //   ieltsCourse: response.data.ieltsCourse
          // }});

          const activeQuestionsArray = [lessonContents[0]];
          const lessonAudioArray = [];

          // Collect the audio so we can auto play
          lessonContents.map((group) => {
            group.contents.map((item) => {
                if( item.audioUrl ) {
                  lessonAudioArray.push({
                    id: item.id,
                    audioUrl: item.audioUrl,
                    status: 'notPlayed'
                  })
                }
            });
          });

          const payload = {
            id: id,
            learnerHasAccessToLesson: response.data.learner_has_access_to_lesson,
            lesson: lesson,
            questionList: lessonContents,
            activeQuestions: activeQuestionsArray,
            currentQuestionNumber: 0,
            ieltsCourse: response.data.ieltsCourse,
            currentLessonAudio: lessonAudioArray
          };

          setLesson(payload);

          retryLimit = response.data.retryLimit;

          // // Collect the audio files & autoplay
          // setTimeout(() => {
          //   collectAudio();
          // }, 1000);

        }
      }).catch((error) => {
        console.log("Error loading module data");
        reportError(error);
      });
    }


    // Ensure we have access to the mic
    checkMicrophonePermission();

    function checkMicrophonePermission() {

      if( navigator && navigator.mediaDevices ) {
        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        .then((stream) => {
          setMicrophonePermission('granted');
        })
        .catch((err) => {
          setMicrophonePermission('denied');
        });
      } else {
        setMicrophonePermission('denied');
      }

    }


    function setMicrophonePermission(permission) {
      dispatch({ type: 'setMicrophonePermission', payload: {microphonePermission: permission}});
    }

    // When the component renders, call the scroll function
    // If there is already data loaded, the questions div will have height
    // And will scroll to the bottom
    scrollQuestionsDiv();

  }, [id, dispatch]);


  // Define functions
  function handleTranscript(transcript, audioBlobUrl, blob, responseObject) {

    // After the first quesiton has been answered, log the start action
    if( activeQuestions.length === 1) {
      logStart();
    }

    // Get the current question from activeQuestions
    const currentQuestion = activeQuestions[currentQuestionNumber];

    // Add the transcribed response to the current question
    currentQuestion.response = transcript;
    currentQuestion.responseObject = responseObject;

    // Add the blob url and blob to the current question
    currentQuestion.responseBlobUrl = audioBlobUrl;
    currentQuestion.responseBlob = blob;

    // Show the next button
    // Also, we need to change the state to force
    //  a re-render so the response component updates
    setShowNextButton(true);
    scrollQuestionsDiv();

  }


  function nextQuestion() {

    // Hide the next button
    setShowNextButton(false);

    // Define the next number in the question list
    const nextQuestionListNumber = currentQuestionNumber + 1;

    // Clear the retry count
    setRetryCount(0);

    // Update the redux store
    updateActiveQustionArray(activeQuestions);

    // Only incremient if there is a next question
    if( questionList.length <= nextQuestionListNumber ){

      // Conversation is complete
      completeModule();

    }else{

      // Find the next question
      const nextQuestion = questionList[nextQuestionListNumber];

      // Add the next question to an array
      let newQuestions = activeQuestions;
      newQuestions.push(nextQuestion);

      // Update the redux store
      updateActiveQustionArray(activeQuestions);
      updateCurrentQuestionNumber(nextQuestionListNumber);

      scrollQuestionsDiv();

      // // Play the next audio
      // collectAudio();

    }

  }


  function tryAgain() {

    // Get the current question from activeQuestions
    const currentQuestion = activeQuestions[currentQuestionNumber];

    // Remove the transcribed response to the current question
    currentQuestion.response = null;
    currentQuestion.responseObject = null;

    // Remove the blob url and blob to the current question
    currentQuestion.responseBlobUrl = null;
    currentQuestion.responseBlob = null;

    // Log the retries
    const newRetryCount = retryCount + 1;
    setRetryCount(newRetryCount);
    currentQuestion.retryCount = newRetryCount;

    setShowNextButton(false);

    // Reset the grammar errors
    updateGrammarErrors(currentQuestionNumber, null)

  }

  function logStart() {

    const payload = {
      lesson_id: id,
      started: true,
      lesson_type: 'LearningModule'
    }

    apiCall(logExerciseStart, payload).then((response) => {
      if( response.success ) {
        setExerciseId(response.data.exerciseId);
      }
    });
  }

  function completeModule() {

    // Show the loading spinner
    setShowCompleteLoading(true);

    // Build responses object
    const responsesArray = [];

    // Loop the responses and push data into the responses array
    activeQuestions.forEach((question) => {
      const responseData = question.responseObject;

      responsesArray.push({
        moduleContentId: question.contents[(question.contents.length - 1)].id,
        text: question.response,
        blob: question.responseBlob,
        retryCount: question.retryCount,
        overall: responseData.overall,
        pronunciation: responseData.pronunciation,
        cadence: responseData.cadence,
        vocabulary: responseData.vocabulary,
        fluency: responseData.fluency,
        grammar: responseData.grammar,
        ieltsScores: responseData.ieltsScores
      });
    });

    // Send the responses to the server
    const payload = {
      lessonType: 'LearningModule',
      exerciseId: exerciseId,
      lessonId: id,
      responsesToSave: responsesArray
    }

    sendExerciseData(payload).then((response) => {

      if( response.success ) {

        setCompleteElement(<LessonCompleteModal data={response.data} />);

        // Make the dashboard recall lesson data
        // invalidateInitialized();
        clearDashboardData();

        // Update redux - TO DO -> refactor to remove
        markLessonAsComplete();

        // Remove the loading spinner
        setTimeout(() => setShowCompleteLoading(false), 1000);

        // Log the event
        Mixpanel.track('Event: Lesson Complete', {'Lesson ID': id, 'Lesson Type': 'Speaking'});

      } else {
        alertMessage(response.error, 'error');
      }

    });

  }

  function collectAudio(audioUrlArray) {

    if( !autoplayAudio || autoplayAudio === 'false' ) { return }

    let audioArray = currentLessonAudio;

    audioUrlArray.forEach((audioUrl) => {

      // Find the element and update to queued
      const index = currentLessonAudio.findIndex(item => (item.audioUrl === audioUrl && item.status === 'notPlayed'));
      if( audioArray[index] ) {
        audioArray[index].status = 'queued';
      }
    });

    updateCurrentLessonAudio(audioArray).then(() => {
      findNextAudio();
    });

  }

  function findNextAudio() {
    for ( let element of currentLessonAudio ) {
      if ( element.status === 'queued' ) {

        setTimeout(() => {
          playAudio(element.audioUrl, function() {

            // Update the audio to "played"
            let audioArray = currentLessonAudio;
            const index = currentLessonAudio.findIndex(item => item.audioUrl === element.audioUrl);
            audioArray[index].status = 'played';

            updateCurrentLessonAudio(audioArray).then(() => {
              // See if there another audio to play
              findNextAudio();
            });

          });
        }, 600);

        // Break so we only play one audio at a time
        break;
      }
    }
  }

  function playAudio(audioUrl, callback) {

    if( autoplayAudio && autoplayAudio !== 'false' ) {
      const audio = new Audio(audioUrl);

      audio.addEventListener("canplay", (event) => {
        audio.play().catch((e) => {
          // Catch error because this method won't work on mobile safari
        });
      });

      if (callback) {
        //When the audio object completes it's playback, call the callback
        audio.addEventListener('ended', callback);
      }
    }
  }


  // Screen components
  let microphoneAccess;
  switch(microphonePermission) {
    case 'granted':
      // Do nothing
      break;
    case 'denied':
      microphoneAccess =
        <div class='microphone-access-message-wrapper'>
          <div class='microphone-access-message help-bubble'>
            <p><b>We cannot access your microphone.</b></p>
            <p>Please allow access to your microphone so you can take this lesson.</p>
            <br />
            <p>
              <Link to='/help/microphone' title='View instructions to allow microphone access'>Learn how to allow microphone access.</Link>
            </p>
          </div>;
        </div>
      break;
    case 'prompt':
      microphoneAccess = <div class='microphone-access-message help-bubble'>Please allow access to your microphone so you can take this lesson.</div>;
      break;
    default:
      // Do nothing
  }

  let completeLoadingElement;
  if( showCompleteLoading ) {
    completeLoadingElement = (
      <div class='flex-column' style={{marginTop: '-120px', marginBottom: '90px'}}>
        <button class='button clean-button is-loading'>&nbsp;</button>
      </div>
    );
  }


  return (
    <div class='wrapper'>

      {completeElement}

      <div class='lesson-body'>

        <LeftColumn />

        <div class='lesson-right-column'>

          <div class='lesson-header-content-container'>
            <HeaderLesson />
            <LessonScreenSwitcher
              id={id}
              currentScreen={'practice'}
            />

            <div class='lesson-progress-steps-wrapper'>
              <ProgressSteps
                steps={totalQuestionCount}
                currentStep={currentQuestionNumber + 1}
              />
            </div>
          </div>

          <section class='body-content'>
            <div class='conversation-wrapper'>

              <TranslateToggle
                id={id}
                apiMethod={translateLearningModule}
                translateToggle={translateToggle}
                setTranslateToggle={setTranslateToggle}
                translateContent={translateContent}
                setTranslateContent={setTranslateContent}
                lessonType={'Speaking'}
              />

              <div class={autoplayDivClass}>
                <p><b>Audio autoplay is <u>{autoplayAudio === 'true' ? 'enabled' : 'disabled'}</u></b></p>
                {/* Note, we want to force the page reload by using an `a` tag so the
                lesson reloads if they go to their profile and come back  */}
                <p>You can update the autoplay setting in your <a href='/profile/preferences' title='Go to your profile'>profile</a></p>
              </div>

              <LearningModule
                questions={activeQuestions}
                tryAgain={tryAgain}
                handleTranscript={handleTranscript}
                showNextButton={showNextButton}
                isLastQuestion={isLastQuestion}
                nextQuestion={nextQuestion}
                translateToggle={translateToggle}
                translateContent={translateContent}
                collectAudio={collectAudio}
                retryLimit={retryLimit}
                retryCount={retryCount}
              />

              {completeLoadingElement}
              {microphoneAccess}

            </div>
          </section>
        </div>

      </div>
    </div>
  );
}

export default Lesson;