
const initialSate = {
  initialized: false,
  publicProfileId: null,
  firstName: "Loading ...",
  lastName: " ",
  email: "Loading ...",
  avatarUrl: 'https://files.flowspeak.io/files/default-avatar.png',
  country: null,
  languageLevelName: "Loading ...",
  languageLevelId: 1,
  interests: [],
  nativeLanguage: 'en',
  autoplayAudio: false,
  reminderFrequency: null,
  shareResponses: false,
  requestFeedback: false,
  includeAudioInShare: false,

  isFirstWeek: false,

  courseCanSkipAhead: false,

  notificationDailyReminders: false,
  notificationCommunityFeedback: false,
  notificationCommunityWeekDigest: false,
  notificationEncouragementEmails: false,
  notificationFlowPromotions: false,

  notifications: {},

  organization: null,
  organizationAdmin: false,
  specialDiscount: null,
  promoCode: null,

  orgLogo: null,

  hasPremiumSubscription: false,
  subscriptionName: null,
  subscription: {
    hasPremium: false,
    name: 'Community Member',
    autoUpgrade: false,
    autoUpgradeMessage: null,
    premiumTrial: false
  },

  badges: [],
  certificates: [],

  challenge: {},

  pricingTier: 1,

  infoCard: null,
  phonePrompt: null,

  score: {
    score: 'Loading ...',
    // score: '',
    streak_day_count: 0,
    lessons_completed_count: 0,
    community_interaction_count: 0
  },

  smsPayload: null,
  registerType: null
}

export function profileReducer(state = initialSate, action) {

  if( action.type === 'initializeProfile') {
    return {
      ...state,
      initialized: action.payload.initialized,
      publicProfileId: action.payload.publicProfileId,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      email: action.payload.email,
      avatarUrl: action.payload.avatarUrl,
      country: action.payload.country,
      languageLevelName: action.payload.languageLevelName,
      languageLevelId: action.payload.languageLevelId,
      interests: action.payload.interests,
      nativeLanguage: action.payload.nativeLanguage,
      autoplayAudio: action.payload.autoplayAudio,

      hasPremiumSubscription: action.payload.hasPremiumSubscription,
      subscriptionName: action.payload.subscriptionName,
      subscription: action.payload.subscription,

      badges: action.payload.badges,
      certificates: action.payload.certificates,
      challenge: action.payload.challenge,

      pricingTier: action.payload.pricingTier,

      infoCard: action.payload.infoCard,
      phonePrompt: action.payload.phonePrompt,

      reminderFrequency: action.payload.reminderFrequency,
      shareResponses: action.payload.shareResponses,
      requestFeedback: action.payload.requestFeedback,
      includeAudioInShare: action.payload.includeAudioInShare,

      isFirstWeek: action.payload.isFirstWeek,

      courseCanSkipAhead: action.payload.courseCanSkipAhead,

      notificationDailyReminders: action.payload.notificationDailyReminders,
      notificationCommunityFeedback: action.payload.notificationCommunityFeedback,
      notificationCommunityWeekDigest: action.payload.notificationCommunityWeekDigest,
      notificationEncouragementEmails: action.payload.notificationEncouragementEmails,
      notificationFlowPromotions: action.payload.notificationFlowPromotions,

      notifications: action.payload.notifications,

      organization: action.payload.organization,
      organizationAdmin: action.payload.organizationAdmin,
      specialDiscount: action.payload.specialDiscount,
      promoCode: action.payload.promoCode,
      orgLogo: action.payload.orgLogo
    };
  }

  if( action.type === 'updateField') {
    return {
      ...state,
      [action.payload.field]: action.payload.value
    }
  }

  if( action.type === 'setHasPremiumSubscription') {
    return {
      ...state,
      hasPremiumSubscription: action.payload.hasPremiumSubscription
    }
  }

  if( action.type === 'setScore') {
    return {
      ...state,
      score: action.payload
    }
  }


  return state;
}