import React from "react";
import { Link } from "react-router-dom"
import RenderHtmlText from "../utilities/renderhtmltext";


function Notification(props) {

  const notification = props.notification;
  const notificationType = notification.notification_type;
  let notificationHtml;

  if( notificationType === 'feedback_request' ) {

    notificationHtml = (
      // <Link to={`/lessons/${notification.learning_module_id}/community/${notification.module_content_id}/responses/${notification.response_id}/comments`} title='View comment'>
      <Link to={`/community/responses/${notification.response_id}/comments`} title='View comment'>
        <div class='card'>
          <div class='notification_header'>
          <p>{notification.author} requested feedback</p>
            <p class='notification_date nowrap'>{notification.notification_date_short}</p>
          </div>
          <div class='notification-details feedback_request'>
            <p class='notification_text'>&#128129;</p>
            <button class='button is-small'>Leave feedback</button>
          </div>
        </div>
      </Link>
    );

  } else if( notificationType === 'system_notification' ) {

    // const markdown = new Remarkable();

    // let htmlMessage = markdown.render(notification.message);
    // htmlMessage = htmlMessage.replace('a href="https://', 'a target="_blank" href="https://');
    // const notificationDetails = ReactHtmlParser(htmlMessage);

    const htmlMessage = notification.message.replace('a href="https://', 'a target="_blank" href="https://');

    notificationHtml = (
      <div class='card'>
        <div class='notification_header'>
          <p>A message from <b class='nowrap'>FLOW Speaking</b></p>
          <p class='notification_date nowrap'>{notification.notification_date_short}</p>
        </div>
        <RenderHtmlText string={htmlMessage} />
      </div>
    );

  } else if( notificationType === 'post_comment' ) {

    const linkUrl = `/community/posts/${notification.post_id}/comments`;

    // Default to comment as the type
    notificationHtml = (
      <Link to={linkUrl} title='View comment'>
        <div class='card'>
          <div class='notification_header'>
            <p>{notification.author} left you a comment</p>
            <p class='notification_date nowrap'>{notification.notification_date_short}</p>
          </div>
          <div class='notification-details'>
            <p class='notification_text'>
              &#128073; &nbsp;
              <RenderHtmlText string={notification.text} />
            </p>
            <button class='button is-small'>View</button>
          </div>
        </div>
      </Link>
    );

  } else {

    // `/lessons/${notification.learning_module_id}/community/${notification.module_content_id}/responses/${notification.response_id}/comments`
    const linkUrl = `/community/responses/${notification.response_id}/comments`;

    // Default to comment as the type
    notificationHtml = (
      <Link to={linkUrl} title='View comment'>
        <div class='card'>
          <div class='notification_header'>
            <p>{notification.author} left you a comment</p>
            <p class='notification_date nowrap'>{notification.notification_date_short}</p>
          </div>
          <div class='notification-details'>
            <p class='notification_text'>
              &#128073; &nbsp;
              <RenderHtmlText string={notification.text} />
            </p>
            <button class='button is-small'>View</button>
          </div>
        </div>
      </Link>
    );

  }

  return (
    <div>
      {notificationHtml}
    </div>
  );
};

export default Notification;