import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import AvatarImageClean from "./avatarImageClean";
import Modal from "./modal";
import ScoreDetails from "../progress/scoreDetails";
import Badge from "./badge";


function HeaderLearnerInfo() {

  // Define variables
  const [scoreDetailsModal, setScoreDetailsModal] = useState(null);
  const profile = useSelector((state) => state.profile);
  const dashboardData = useSelector((state) => state.dashboardData);
  const headerData = dashboardData.headerData;
  const courseData = headerData?.courseData;
  const pathwayData = headerData?.pathwayData;

  const firstName = profile.firstName;
  const avatarUrl = profile.avatarUrl;
  const score = profile?.score?.score;
  const badgeData = profile?.badges || [];
  const badgeList = badgeData.slice(Math.max(badgeData.length - 3, 0));

  const nameLength = firstName.length;

  let nameSize = '2rem';
  let nameLineHeight = '2.25rem';
  if( nameLength > 12 && nameLength <= 20 ) {
    nameSize = '1.5rem';
    nameLineHeight = '1.75rem';
  } else if( nameLength > 20 ) {
    nameSize = '1.25rem';
    nameLineHeight = '1.5rem';
  }


  // Define functions
  function showScoreDetails() {
    setScoreDetailsModal(
      <Modal
        title={'Confidence Level'}
        body={<ScoreDetails profile={profile} />}
        closeModal={closeModal}
        closeIcon={true}
        windowClass={'score-details-modal'}
        centerTitle={true}
      />
    );
  }

  function closeModal() {
    setScoreDetailsModal(null);
  }


  // Screen components
  let pathwayElement;
  let courseElement;
  let courseScoreElement;
  let scoreElement;
  let badgesElement;


  if( courseData ) {

    let courseScore = courseData?.score ? `${courseData.score}%` : '--';
    if( courseData?.isIelts ) {
      courseScore = courseData.ieltsScore;
    }

    courseElement = (
      <div>
        <p class='header-label'>Course</p>
        <p class='header-detail'>{courseData.title}</p>
      </div>
    );

    courseScoreElement = (
      <div>
        <p class='header-label'>Course Score</p>
        <p class='header-detail'>&nbsp;{courseScore}&nbsp;</p>
      </div>
    );
  }

  if( pathwayData ) {
    pathwayElement = (
      <Link
        to='/progress'
        title='View your Pathway progress'
      >
        <p class='header-label'>Pathway</p>
        <p class='header-detail'>{pathwayData.pathwayName} {pathwayData.level}</p>
      </Link>
    );
  }

  scoreElement = (
    <button
      onClick={() => showScoreDetails()}
      type='button'
      class='clean-button pointer'
      title={'See your confidence level details'}
    >
      <p class='header-label'>Confidence Level</p>
      <p class='header-detail'>{score}</p>
    </button>
  );

  if( badgeList ) {
    badgesElement = (
      <Link
        to='/progress'
        title='View your badges'
        class='flex-column header-badge-container'
      >
        {badgeList.map((badge, index) =>
          <div
            key={`dashboard-badge-${index}`}
            style={{
              position: 'absolute',
              top: 0 - (index * 2),
              left: (index * 3)
            }}
          >
            <Badge
              badge={badge}
              achieved={true}
              isThumbnail={true}
              thumbnailSize={25}
            />
          </div>
        )}
      </Link>
    );
  }


  return (
    <div class='header-learner-info-container'>

      {scoreDetailsModal}

      <p class='header-welcome-text'>Welcome back</p>

      {/* Name and Avatar */}
      <div class='flex-row header-learner-info-name-avatar'>
        <h2 style={{
          fontSize: nameSize,
          lineHeight: nameLineHeight
        }}>{firstName}</h2>
        <AvatarImageClean avatarUrl={avatarUrl} />
      </div>


      {/* Details */}
      <div class='flex-row header-learner-info-details-container'>
        <div class='flex-row header-learner-info-details-left-container'>
          {pathwayElement}
          {courseElement}
          {courseScoreElement}
          {scoreElement}
        </div>
        {badgesElement}
      </div>

    </div>
  );
};

export default HeaderLearnerInfo;