import ScoreDonut from "../../utilities/learning_module/scoredonut";


function ScoresBlockAiTalk(props) {

  // Define variables
  // const isIelts = props.isIelts
  const scores = props.scores;
  const overallScore = scores.score;
  const fluencyScore = scores.fluencyScore;
  const relevanceScore = scores.relevanceScore;
  // const ieltsFluency = scores.ieltsFluencyAndCoherence;
  // const score = isIelts ? ieltsFluency : fluencyScore;


  // Screen components
  let relevanceElement;
  if( relevanceScore ) {
    relevanceElement = (
      <div class='flex-row score-block-row'>
        <p>Relevance</p>
        <p class='flex-row'>
          {relevanceScore}%
          <ScoreDonut scorePercent={relevanceScore} />
        </p>
      </div>
    );
  }


  return (
    <div class='score-block-container'>

      <div class='flex-row score-block-row'>
        <p><strong>Overall</strong></p>
        <p class='flex-row'>
          {overallScore}%
          <ScoreDonut scorePercent={overallScore} />
        </p>
      </div>

      <div class='flex-row score-block-row'>
        <p>Fluency</p>
        <p class='flex-row'>
          {fluencyScore}%
          <ScoreDonut scorePercent={fluencyScore} />
        </p>
      </div>

      {relevanceElement}

    </div>
  );
}

export default ScoresBlockAiTalk;