import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


function LineGraph(props) {

  const datasets = props.datasets;

  const xConfig = props.xConfig || {};
  const xMin = xConfig.min;
  const xMax = xConfig.max;
  const xSuggestedMax = xConfig.suggestedMax;
  const xGridDisplay = xConfig.gridDisplay;
  const xMaxTicksLimit = xConfig.maxTicksLimit;

  const yConfig = props.yConfig || {};
  const yMin = yConfig.min;
  const yMax = yConfig.max;
  const ySuggestedMax = yConfig.suggestedMax;
  const yGridDisplay = yConfig.gridDisplay;
  const yMaxTicksLimit = yConfig.maxTicksLimit;



  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      y: {
        min: yMin,
        max: yMax,
        suggestedMax: ySuggestedMax,
        grid: {
          display: yGridDisplay,
        },
        ticks: {
          autoSkip: true,
          includeBounds: false,
          maxTicksLimit: yMaxTicksLimit
        }
      },
      x: {
        min: xMin,
        max: xMax,
        suggestedMax: xSuggestedMax,
        grid: {
          display: xGridDisplay,
        },
        ticks: {
          autoSkip: true,
          includeBounds: false,
          maxTicksLimit: xMaxTicksLimit
        }
      }
    }
  };

  const labels = props.labels;

  const data = {
    labels,
    datasets: datasets
  };

  return (
    <div class='graph-holder'>
      <Line options={options} data={data} />
    </div>
  )
}

export default LineGraph;