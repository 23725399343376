import React from "react";

function InputHidden (props) {

  const name = props.name || '';
  const value = props.value || '';

  return (
    <input
      type="hidden"
      name={name}
      value={value}
    />
  );
}

export default InputHidden;