import ExpressionTranscript from "../expression/expressionTranscript";
import ScoreDetailsBiteLite from "../utilities/learning_module/scoreDetailsBiteLite";
import PlayAudioIcon from "../utilities/playaudioicon";
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";


function BoosterPracticeResponse(props) {

  // Define variables
  const retry = props.retry;
  const nextStep = props.nextStep;
  const avatarUrl = props.avatarUrl;
  const response = props.response;
  const audioUrl = response.audioFileUri;
  const retryLimit = props.retryLimit;
  const retryCount = props.retryCount;


  // Screen components
  let audioElement;
  if( audioUrl ) {
    audioElement = (
      <div class='booster-avatar-audio-container'>
        <PlayAudioIcon audioUrl={audioUrl} fullDark={true} />
      </div>
    );
  }

  let retryElement = <span></span>;
  if( retryLimit > retryCount ) {
    retryElement = (
      <button
        onClick={retry}
        class='clean-button pointer booster-try-again-button'
      >Try Again</button>
    );
  }


  return (
    <div class='flex-column booster-practice-learner-container'>

      <div class='flex-row' style={{width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start'}}>

        <div style={{position: 'relative'}}>
          <img src={avatarUrl} class='booster-avatar-medium' alt='Image of the speaking companion' />
          {audioElement}
        </div>

        <div>

          <div class='booster-example-text-container'>
            <ExpressionTranscript responseObject={response} />
          </div>

          <div class='flex-row' style={{marginTop: '5px'}}>
            {retryElement}

            <ScoreDetailsBiteLite
              responseObject={response}
              ieltsCourse={false}
            />
          </div>

        </div>
      </div>

      <div class='flex-column' style={{marginTop: '30px', marginBottom: '30px'}}>
        <ButtonSubmitRound
          value={"Continue"}
          handleButtonClick={nextStep}
          // disabled={disabled}
          // loading={loading}
        />
      </div>

    </div>
  );
}

export default BoosterPracticeResponse;