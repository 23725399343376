import React from "react";

function ButtonSubmit (props) {

  const buttonId = props.id || `button-${Date.now()}`;
  const value = props.value || 'Submit';
  let buttonClass = props.buttonClass || 'button';
  const handleButtonClick = props.handleButtonClick || undefined;
  const disabled = props.loading || false;
  const title = props.title || `Tap to ${value}`;
  // TO DO - add color, size

  if( disabled ) {
    buttonClass = buttonClass + ' is-loading';
  }

  return (
    <div class="field submit-button">
      <button
        id={buttonId}
        type="submit"
        class={buttonClass}
        value={value}
        onClick={handleButtonClick}
        title={title}
        disabled={disabled}
      >{value}</button>
    </div>
  );
}

export default ButtonSubmit;