import React from "react";
import { useEffect, useState } from 'react';


function ProfileInput(props) {

  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function handleInputChange(event) {
    const value = event.target.value;
    setValue(value);
  }

  function cancelEdit(id){
    setValue(props.value);
    props.cancelEdit(id);
  }


  return (
    <div class='profile-input-wrapper'>
      <label>{props.label}</label>
      <div class='profile-input'>
        <input
          id={props.id}
          name={props.name}
          value={value}
          onChange={handleInputChange}
          disabled={props.disabled}
        />
        <div class='profile-input-buttons'>
          <button type='button' class='button clean-button edit-button' onClick={() => props.enableField(props.id)}>
            <span class='icon icon-edit'></span>
            <span>edit</span>
          </button>
          <button type='button' class='button clean-button save-button is-hidden' onClick={() => props.saveData(props.id)}>save</button>
          <button type='button' class='button clean-button cancel-button is-hidden' onClick={() => cancelEdit(props.id)}>cancel</button>
        </div>
      </div>
    </div>
  );
}

export default ProfileInput;