import React from "react";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import Listener from "./listener";
import BiteAction from "../bite/biteaction";
import BiteAnswer from "../bite/biteanswer";

import Modal from "../utilities/modal";


function CurrentQuestion(props) {

  // Define variables
  const [action, setAction] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [explanationModal, setExplanationModal] = useState(null);

  const id = props.id;
  const translateToggle = props.translateToggle;
  const setTranslateToggle = props.setTranslateToggle;
  const translateContent = props.translateContent;
  const setTranslateContent = props.setTranslateContent;
  const logStart = props.logStart;
  const nextAction = props.nextAction;
  const currentQuestion = props.currentQuestion;
  const multipleCorrectAnswers = false;
  const correctAnswerIds = currentQuestion.answers.filter(answer => answer.correctAnswer).map(answer => answer.id);

  const title = currentQuestion.title;
  const secondaryLine = currentQuestion.secondaryLine;
  const imageUrl = currentQuestion.imageUrlFull;

  const listeningText = currentQuestion.listeningText;
  const audioUrlConversationSpeed = currentQuestion.audioUrlConversationSpeed;
  const audioUrlHalfSpeed = currentQuestion.audioUrlHalfSpeed;
  const hideListeningTextByDefault = currentQuestion.hideListeningTextByDefault;
  const showListeningText = currentQuestion.showListeningText;
  const allowTextToggle = currentQuestion.allowTextToggle;
  const answers = currentQuestion.answers;


  // Component load actions
  useEffect(() => {
    setAction('waiting');
  }, [currentQuestion]);


  // Define functions
  function handleAnswerClick(id) {

    // Log the action
    logStart();

    if( multipleCorrectAnswers ) {
      // TO DO
    } else {
      setSelectedAnswers([id]);
      setAction('check');
    }
  }

  function checkAnswer() {
    const correct = correctAnswerIds.join() === selectedAnswers.join();
    const value = correct ? 'correct' : 'incorrect';
    setAction(value);
  }

  function showExplanation(bodyText, translation = null) {

    const bodyElement = (
      <div>
        <p>{bodyText}</p>
        <p class='translate-text'>{translation}</p>
      </div>
    );

    const modal = (
      <Modal
        windowClass={'bite-explanation'}
        body={bodyElement}
        closeButton={true}
        closeModal={hideExplanation}
      />
    );

    setExplanationModal(modal);
  }

  function hideExplanation() {
    setExplanationModal(null);
  }


  // Screen components
  let titleClass = 'title';
  if( title.length > 18 && title.length <= 24 ) {
    titleClass = 'title small';
  } else if ( title.length > 24 ) {
    titleClass = 'title smaller';
  }

  let translateContentQuestionAnswers;
  let displayTitle = title;
  let displaySecondaryLine = secondaryLine;
  let displayListeningText = listeningText;

  if( translateToggle && translateContent?.questions?.length > 0 ) {

    let translateContentQuestion = translateContent.questions.find(q => q.recordId === currentQuestion.id);
    if( translateContentQuestion ) {
      translateContentQuestionAnswers = translateContentQuestion.answers;
      displayTitle = translateContentQuestion.title;
      displaySecondaryLine = translateContentQuestion.secondaryLine;
      displayListeningText = translateContentQuestion.listeningText;
    }

  }


  return (
    <div class='bite-wrapper'>
      {explanationModal}

      <div
        class='bite-background-image fade-in-on-load fade-slow'
        style={{
          backgroundImage: "url(" + imageUrl + ")",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}>
      </div>

      <div class='bite-top-fade'></div>
      <div class='bite-content'>

        <div class='bite-header'>
          <div class={titleClass}>{displayTitle}</div>
          <Link to='/' class='icon icon-close-light' title='Return to Dashboard'></Link>
        </div>

        <Listener
          secondaryLine={displaySecondaryLine}
          listeningText={displayListeningText}
          audioUrlConversationSpeed={audioUrlConversationSpeed}
          audioUrlHalfSpeed={audioUrlHalfSpeed}
          hideListeningTextByDefault={hideListeningTextByDefault}
          showListeningText={showListeningText}
          allowTextToggle={allowTextToggle}
        />

        <div class='bite-bottom'>
          <div class='bite-answers-wrapper'>
            {answers.map((answer, index) =>
              <BiteAnswer
                answer={answer}
                selected={selectedAnswers.includes(answer.id)}
                handleAnswerClick={handleAnswerClick}
                showExplanation={showExplanation}
                action={action}
                key={index}
                translateContentQuestionAnswers={translateContentQuestionAnswers}
              />
            )}
          </div>

          <BiteAction
            action={action}
            nextAction={nextAction}
            checkAnswer={checkAnswer}
            translateToggle={translateToggle}
            setTranslateToggle={setTranslateToggle}
            translateContent={translateContent}
            setTranslateContent={setTranslateContent}
            id={id}
            lessonType={'listening'}
          />
        </div>

      </div>
    </div>
  );
};

export default CurrentQuestion;