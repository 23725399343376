function IssueDateElement(props) {

  // Define variables
  const word = props.word || 'Issued';
  const score = props.score;
  const scoreIelts = props.scoreIelts;
  const isThisLearner = props.isThisLearner;


  // Screen components
  let scoreElement;
  if( score && isThisLearner ) {
    scoreElement = <span> | Score: {score}%</span>;
  }

  if( scoreIelts && isThisLearner ) {
    scoreElement = <span> | Score: {scoreIelts}</span>;
  }

  let body;
  if( props.issueDate ) {
    body = (
      <div class='flex-column issue-date-container'>
        <p><small>{word}</small></p>
        <p>
          <span>{props.issueDate}</span>
          {scoreElement}
        </p>
      </div>
    );
  }

  return (
    <>{body}</>
  )
}

export default IssueDateElement;