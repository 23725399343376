function ExpressionScreenSwitcher(props) {

  // Define variables
  const currentScreen = props.currentScreen;
  const changeScreen = props.changeScreen;


  return (
    <div class='screen-toggler-wrapper'>
      <div class='screen-toggler-container'>

        <button
          type='button'
          class={`clean-button pointer ${currentScreen === 'practice' ? 'active' : ''}`}
          onClick={() => changeScreen('practice')}
        >Practice</button>

        <button
          type='button'
          class={`clean-button pointer ${currentScreen === 'related' ? 'active' : ''}`}
          onClick={() => changeScreen('related')}
        >Releated</button>

      </div>
    </div>
  );

}
export default ExpressionScreenSwitcher;