import React from "react";
import PlayAudioIcon from "../playaudioicon";
import LongPressLookupElement from "../longpresslookupelement";


function helpBubbleText (props) {

  const text = props.text;
  const typeClass = props.typeClass;
  const audioUrl = props.audioUrl;

  const audioIcon = (audioUrl) ? <div class="media-right"><PlayAudioIcon audioUrl={audioUrl} iconDark={true} /></div> : null;

  return (
    <div class={typeClass}>
      <div class='media'>
        <div class='media-content'>
          <LongPressLookupElement content={text} />
        </div>
        {/* <div class='media-right'>{audioIcon}</div> */}
        {audioIcon}
      </div>
    </div>
  );
};

export default helpBubbleText;