import { useState } from "react";

import Modal from "../utilities/modal";
import BoosterPhrase from "./boosterPhrase";


function BoosterTalkHeader(props) {

  // Define variables
  const [modalElement, setModalElement] = useState(null);
  const iconUrl = props.iconUrl;
  const phrases = props.phrases;


  // Define functions
  function showPhrases() {

    const body = (
      <div>
        <br />
        {phrases.map((phrase, index) =>
          <BoosterPhrase
            key={`booster-definition-${index}`}
            phraseData={phrase}
          />
        )}
      </div>
    );

    setModalElement(
      <Modal
        windowClass={'booster-phrase-modal'}
        closeModal={closeModal}
        title={'Phrases'}
        centerTitle={true}
        body={body}
        closeIcon={true}
      />
    );
  }

  function closeModal() {
    setModalElement(null);
  }


  return (
    <div class='booster-talk-header'>
      <div class='flex-row' style={{justifyContent: 'flex-start'}}>
        <img src={iconUrl} class='booster-avatar-big' alt='Image of the speaking companion' />
        <div>
          <p>Way to go! Now it's time to have a real conversation.</p>
          <p class='booster-talk-reminder'>
            Remember to use your new phrases in your responses.
            <br />
            <a href='#' onClick={() => showPhrases()} style={{textDecoration: 'underline'}}>Review phrases</a>
          </p>
        </div>
      </div>
      {modalElement}
    </div>
  );
}

export default BoosterTalkHeader;