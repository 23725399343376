import React from "react";
import { useState, useEffect } from "react";

import alertMessage from '../utilities/alertMessage';
import titleCase from "../../functions/titleCase";
import PhoneNumberInput from "./phonenumberinput";
import OrgContactPhoneNumber from "./orgContactPhoneNumber";
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";
import isIpExcluded from "../../functions/ipLookup";
import PasswordWithEye from "../utilities/passwordWithEye";


function SignupForm(props) {

  // Define functions
  const disableLogin = props.disableLogin;
  const includeLastName = props.includeLastName;
  const phoneNumberMessage = props.phoneNumberMessage;
  const errorText = props.errorText;
  const promoCode = props.promoCode || '';
  const utmCode = props.utmCode || '';
  const referrer = props.referrer || '';
  const setButtonLoading = props.setButtonLoading;
  const buttonLoading = props.buttonLoading;
  const changeCurrentScreen = props.changeCurrentScreen;
  const register = props.register;
  const smsRegister = props.smsRegister;
  const selectedPackage = props.selectedPackage;
  const indonesiaUniversity = props.indonesiaUniversity;
  const registerHidePhoneAsPassword = props.registerHidePhoneAsPassword;
  const usePasswordFieldByDefault = props.usePasswordFieldByDefault;
  // let defaultRegisterType = usePasswordFieldByDefault || registerHidePhoneAsPassword ? 'email' : 'phone';
  let defaultRegisterType = 'email';

  let defaultCountryCode = '91';
  if( indonesiaUniversity ) { defaultCountryCode = '62' }

  const timezoneOffset = new Date().getTimezoneOffset(); // Get the local timezone offset


  const [registerType, setRegisterType] = useState(defaultRegisterType);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [countryCode, setCountryCode] = useState(defaultCountryCode);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [joinNewsletter] = useState('');
  const [allowOrganizationContactByPhoneNumber, setAllowOrganizationContactByPhoneNumber] = useState(true);
  const [countryCodeOrgContact, setCountryCodeOrgContact] = useState(defaultCountryCode);
  const [phoneNumberOrgContact, setPhoneNumberOrgContact] = useState('');


  // Component load actions
  useEffect(() => {

    // IP lookup
    isIpExcluded().then((response) => {
      if( response ) {
        setRegisterType('email');
      }
    }).catch((error) => {
      // Do nothing
    });

  }, []);


  // Define functions
  function registerRouter(event) {

    event.preventDefault();

    // Verfy fields
    let error = false;

    if( !firstName || !email ) { error = 'Please enter your first name and email' }
    if( registerType === 'email' ) {
      if( !password || password.length < 8 ) { error = 'Please enter your password' }
    } else {
      if( !phoneNumber || phoneNumber.length < 5 ) { error = 'Please enter your phone number' }
    }

    if( error ) {
      alertMessage(error, 'error');
      return;
    }


    // Disable the submit button
    setButtonLoading(true);

    // Build the payload
    const payload = {
      type: 'register',
      user: {
        first_name: firstName,
        email: email,
        agree_to_terms: 'true',
        timezone_offset: timezoneOffset,
        signup_platform: 'web',
        full_name: fullName,
        utm_code: utmCode,
        referrer: referrer,
        promo_code: promoCode,
        join_newsletter: joinNewsletter,
        selected_package: selectedPackage,
      }
    };

    if( includeLastName ) {
      payload.user['last_name'] = lastName;
    }

    // Put these numbers first so the normal phone number gets priority
    if( allowOrganizationContactByPhoneNumber && countryCodeOrgContact && phoneNumberOrgContact ) {
      payload.user.phone_country_code = countryCodeOrgContact;
      payload.user.phone_number = phoneNumberOrgContact;
      payload.user.allow_organization_contact_by_phone_number = allowOrganizationContactByPhoneNumber;
    }

    if( countryCode && phoneNumber ) {
      payload.phone_country_code = countryCode;
      payload.phone_number = phoneNumber;
      payload.user.phone_country_code = countryCode;
      payload.user.phone_number = phoneNumber;
    }

    if( password ) { payload.user.password = password }

    if( registerType === 'email') {
      register(payload);
    } else {
      smsRegister(payload);
    }
  }


  // Screen components
  let passwordInputElement;
  if( registerType === 'email' ) {

    let passwordChangeButtonElement;
    if( !registerHidePhoneAsPassword ) {
      passwordChangeButtonElement = (
        <div class='secondary-helper-button-container'>
          <button
            type='button'
            class='secondary-helper-button'
            onClick={() => setRegisterType('phone')}
          >
            <u>Use phone number instead</u>
          </button>
        </div>
      );
    }

    passwordInputElement = (
      <div>
        {/* <input
          name="code_to_enter_site"
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          minLength="8"
          title="Password must be at least 8 characters"
          required
          placeholder='Password'
          // class='with-padding'
          class='input'
          autoComplete='off'
        /> */}
        <PasswordWithEye
          password={password}
          setPassword={setPassword}
        />
        {passwordChangeButtonElement}
      </div>
    );

  } else {
    passwordInputElement = (
      <div>
        <PhoneNumberInput
          setCountryCode={setCountryCode}
          setPhoneNumber={setPhoneNumber}
        />

        <div class='secondary-helper-button-container'>
          <button
            type='button'
            class='secondary-helper-button'
            onClick={() => setRegisterType('email')}
          >
            <u>Set a password instead</u>
          </button>
        </div>

      </div>
    );
  }


  let lastNameElement;
  if( includeLastName ) {
    lastNameElement = (
      <input
        name="last_name"
        type="text"
        value={lastName}
        onChange={event => setLastName(titleCase(event.target.value))}
        required
        placeholder='Your last name'
        // class='with-padding'
        class='input'
      />
    );
  }

  let orgContactPhoneNumberElement;
  if( phoneNumberMessage ) {
    orgContactPhoneNumberElement = (
      <div>
        <OrgContactPhoneNumber
          registerType={registerType}
          phoneNumberMessage={phoneNumberMessage}
          countryCode={countryCode}
          phoneNumber={phoneNumber}
          setCountryCodeOrgContact={setCountryCodeOrgContact}
          setPhoneNumberOrgContact={setPhoneNumberOrgContact}
          allowOrganizationContactByPhoneNumber={allowOrganizationContactByPhoneNumber}
          setAllowOrganizationContactByPhoneNumber={setAllowOrganizationContactByPhoneNumber}
          indonesiaUniversity={indonesiaUniversity}
        />
      </div>
    );
  }

  let loginButtonElement;
  if( !disableLogin ) {
    loginButtonElement = (
      <button
        type='button'
        class='clean-button auth-link-button inactive'
        onClick={() => changeCurrentScreen('login')}
      >Login</button>
    );
  }


  return (
    <section class='section-auth-form'>

      <div class='flex-row content-left auth-links-container'>
        <p class='auth-link-button'>Sign Up</p>
        {loginButtonElement}
      </div>

      <form
        class='signup-login-form'
        onSubmit={registerRouter}
      >

        <div class='input-container'>
          <input
            name="first_name"
            type="text"
            value={firstName}
            onChange={event => setFirstName(titleCase(event.target.value))}
            required
            placeholder='Your first name'
            // class='with-padding'
            class='input'
            autoFocus
          />

          {lastNameElement}

          <input
            name="email"
            type="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            required
            placeholder='Email address'
            title="Enter your email address"
            // class='with-padding'
            class='input'
          />

          {passwordInputElement}

          <div class='form-error-header form-error'>
            {errorText}
          </div>

        </div>

        {orgContactPhoneNumberElement}

        <div class="flex-column auth-form-button-container">
          <ButtonSubmitRound
            value={'Continue'}
            loading={buttonLoading}
          />
        </div>

        <div>
          <p class='terms-text'>
            By clicking Continue, you agree to the <br /><a href='https://www.flowspeak.io/terms-and-conditions' target='_blank' rel="noreferrer">Terms & Conditions</a> and <a href='https://www.flowspeak.io/privacy-policy' target='_blank' rel="noreferrer">Privacy Policy</a>
          </p>
        </div>

      </form>

    </section>
  );
}

export default SignupForm;