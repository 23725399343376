import React from "react";
// import { useLocation } from "react-router-dom"


function HeaderMain (props) {

  const title = props.title;
  const subTitle = props.subTitle;
  const subLine = props.subLine;

  let h1Class;
  if( title.length > 11 ) { h1Class = 'h1-smaller'}

  // const location = useLocation();
  // const path = location.pathname;

  // let notificationsClassList = 'header-icon';
  // let profileClassList = 'header-icon';

  // switch (path) {
  //   case '/notifications':
  //     // notificationsClassList = notificationsClassList + ' active';
  //     break;
  //   case '/profile':
  //     // profileClassList = profileClassList + ' active';
  //     break;
  //   default:

  //     break;
  // }

  let subtitleElement;
  if( subTitle ) {
    subtitleElement = <p>{subTitle}</p>;
  }


  return (
    <div class='header'>
      <div class='sub-line'>{subLine}</div>
      <div class='title-wrapper'>
        <h1 class={h1Class}>{title}</h1>
        {subtitleElement}
      </div>
    </div>
  );
};

export default HeaderMain;