import { useEffect, useState } from 'react';
import { reportError } from "../../functions/sentry";
import { getScoreOverTime } from "../utilities/auth/authApi";
import LoadingBasic from "../utilities/loadingbasic";
import LineGraph from "../utilities/graphs/linegraph";


function ScoreLineGraph(props) {

  // Define variables
  const [graph, setGraph] = useState(<LoadingBasic />);
  const publicProfileId = props.publicProfileId;


  // Page load actions
  useEffect(() => {

    if( publicProfileId) {
      getScoreOverTime(publicProfileId).then((response) => {
        if( response?.data?.success ) {

          const datasets = [
            {
              label: 'Confidence Level',
              data: response.data.scoreOverTime.data,
              borderColor: 'rgb(120, 8, 168)',
              backgroundColor: 'rgba(120, 8, 168, 0.2)',
              borderWidth: 2,
              pointRadius: 3,
              pointBorderWidth: 1,
              pointHoverRadius: 10,
              tension: .35
            }
          ];

          const graph = (
            <LineGraph
              datasets={datasets}
              labels={response.data.scoreOverTime.labels}
              yConfig={{min: 0, max: 1000, gridDisplay: false, maxTicksLimit: 6}}
              xConfig={{maxTicksLimit: 10}}
            />
          );

          setGraph(graph);

        }
      }).catch((error) => {
        reportError(error);
      });
    }

  }, [publicProfileId]);


  return (

    <div class='stat-container'>
      <h3>Confidence Level</h3>
      <p style={{marginTop: '-15px', marginBottom: '15px'}}>
        <small><i>Over the past 30 days</i></small>
      </p>
      {graph}
    </div>
  )
}

export default ScoreLineGraph;