import AvatarImageUpload from "./avatarImageUpload";


function AvatarImageClean(props) {

  // Define variables
  const avatarUrl = props.avatarUrl;


  // Screen components
  let imageElement;
  if( avatarUrl && !avatarUrl.includes('default-avatar') ) {
    imageElement = (
      <img
        src={avatarUrl}
        style={{width: 32, height: 32, maxWidth: 32, maxHeight: 32}}
        class='avatar-image-clean'
        alt='Profile'
      />
    );
  } else {
    imageElement = <AvatarImageUpload />
  }


  return (
    <div class='header-avatar-upload-wrapper'>
      {imageElement}
    </div>
  );
}

export default AvatarImageClean;