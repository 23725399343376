import React from "react";
import LikeButton from "../utilities/likebutton";
import { Link } from "react-router-dom";


function PostFooter(props) {

  let commentCountElement;
  const commentCount = props.commentCount;
  const commentWord = commentCount === 1 ? 'comment' : 'comments';


  if( props.noLinks ) {
    // commentCountElement = (
    //   <div class='post-comments'>
    //     {/* {commentCount} {commentWord} */}
    //   </div>
    // );
  } else {
    commentCountElement = (
      <Link to={props.postLink} title='View Post' class='post-comments' onClick={props.trackScrollPosition}>
        {/* {commentCount} {commentWord} */}
        leave a comment
      </Link>
    );
  }



  return (
    <div class='post-footer'>
      <LikeButton
        id={props.id}
        postType={props.postType}
        likeCount={props.likeCount}
        learnerLikeCount={props.learnerLikeCount}
        handleLikeChange={props.handleLikeChange}
      />

      {commentCountElement}
    </div>
  );
}

export default PostFooter;