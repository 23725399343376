import React from "react";
import { useState } from 'react';

// import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';

import apiCall from "../utilities/auth/apiCall";
import { updatePreferenceData } from "../utilities/auth/authApi";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';

import StartLeftColumn from "./startLeftColumn";
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";
import ProgressDots from "../utilities/progressDots";
import SelectPill from "./selectPill";

const days = [
  {display: 'Sun', value: 'sunday'},
  {display: 'Mon', value: 'monday'},
  {display: 'Tues', value: 'tuesday'},
  {display: 'Wed', value: 'wednesday'},
  {display: 'Thur', value: 'thursday'},
  {display: 'Fri', value: 'friday'},
  {display: 'Sat', value: 'saturday'},
];


function WelcomeSetReminders() {

  // Define variables
  const [loading, setLoading] = useState(false);
  // const [countryCode, setCountryCode] = useState(null);
  // const [phoneNumber, setPhoneNumber] = useState(null);
  const [selectedEmailDays, setSelectedEmailDays] = useState(['monday', 'thursday']);
  // const [selectedWhatsappDays, setSelectedWhatsappDays] = useState([]);


  // Define functions
  // function handlePhoneNumber( value, country) {
  //   const countryCode = country.dialCode;
  //   const phone = value.replace(countryCode, '');

  //   setCountryCode(countryCode);
  //   setPhoneNumber(phone);
  // }

  function handleEmailClick(value) {
    const array = selectedEmailDays;
    let newArray;

    if( array.includes(value) ) {
      newArray = array.filter(item => item !== value);
    } else {
      newArray = [...selectedEmailDays, value];
    }

    setSelectedEmailDays(newArray);
  }

  // function handleWhatsappClick(value) {
  //   const array = selectedWhatsappDays;
  //   let newArray;

  //   if( array.includes(value) ) {
  //     newArray = array.filter(item => item !== value);
  //   } else {
  //     newArray = [...selectedWhatsappDays, value];
  //   }

  //   setSelectedWhatsappDays(newArray);
  // }

  function handleSubmit() {

    setLoading(true);

    // if( selectedEmailDays.length > 0 || selectedWhatsappDays.length > 0 ) {
    if( selectedEmailDays.length > 0 ) {

      // if( (selectedWhatsappDays.length > 0) && (!countryCode || !phoneNumber) ) {
      //   alert('Please add your phone number and country code so we can send you Whatsapp notifications')
      //   setLoading(false);
      //   return;
      // }

      const payload = {
        email_days: selectedEmailDays,
        // whatsapp_days: selectedWhatsappDays,
        // user: {
        //   phone_country_code: countryCode,
        //   phone_number: phoneNumber
        // }
      };

      apiCall(updatePreferenceData, payload).then((response) => {
        Mixpanel.track('Event: Set Reminders', {'Email': JSON.stringify(selectedEmailDays)});
      }).finally(() => {
        nextScreen();
      });

    } else {
      nextScreen();
    }
  }

  function nextScreen() {
    setTimeout(() => {
      setLoading(false);
      // Do it the hard way so the page reloads and pulls new data
      window.location.replace('/');
    }, 600);
  }


  return (
    <div class='wrapper'>
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <div class='pathway-welcome-container'>

                <div>
                  <h2>Stay on track</h2>
                  <p>Set frequent reminders to reach your goal</p>
                </div>

                <div class='pathways-container'>

                  <div class='pathway-wrapper'>
                    <h3>Email</h3>
                    <div class='flex-row reminder-select-wrapper'>
                      {days.map((day, index) =>
                        <SelectPill
                          key={`email-day-select-${index}`}
                          text={day.display}
                          value={day.value}
                          handleClick={handleEmailClick}
                          selected={selectedEmailDays.includes(day.value)}
                        />
                      )}
                    </div>
                  </div>

                  <p style={{lineHeight: '1.1rem'}}>
                    <small>
                      <i>
                        {/* Boost your success with more reminders, especially at the week's start and middle. */}
                        More reminders, more success - especially at the week's start and middle
                      </i>
                    </small>
                  </p>

                  {/* <div class='pathway-wrapper' style={{marginTop: '30px'}}>
                    <h3>WhatsApp</h3>
                    <div class='flex-row reminder-select-wrapper'>
                      {days.map((day, index) =>
                        <SelectPill
                          key={`whatsapp-day-select-${index}`}
                          text={day.display}
                          value={day.value}
                          handleClick={handleWhatsappClick}
                          selected={selectedWhatsappDays.includes(day.value)}
                        />
                      )}
                    </div>

                    <PhoneInput
                      country={'in'}
                      enableSearch={true}
                      onChange={(value, country) => handlePhoneNumber(value, country)}
                      inputClass={'input'}
                    />

                  </div> */}

                </div>

                <div class='flex-column pathway-bottom-container'>
                  <ButtonSubmitRound
                    value={"Continue"}
                    handleButtonClick={handleSubmit}
                    loading={loading}
                  />

                  <div style={{marginTop: '15px'}}>
                    <ProgressDots index={2} />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeSetReminders;