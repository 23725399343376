import { Link } from "react-router-dom";
import VectorIcon from "../vectorIcon";
import IssueDateElement from "./issueDateElement";


function StatsCourses(props) {

  // Define variables
  const gemIcon = <VectorIcon icon={'gem'} color={'active'} />;
  const gemIconGray = <VectorIcon icon={'gem'} />;
  const courses = props.courses ? props.courses : [];
  const isThisLearner = props.isThisLearner;
  const placeholderCourses = (courses.length > 0 ) ? [] : [1,2];
  const placeholderTextElement = (courses.length > 0 ) ? null : <p>You haven't completed any Courses yet</p>;


  // Screen components
  let bodyElement;
  if( isThisLearner || (!isThisLearner && courses.length > 0) ) {

    let bottomTextElement;
    if( isThisLearner ) {
      bottomTextElement = (
        <Link to='/courses' style={{marginTop: 15}}>
          <p>View all Courses</p>
        </Link>
      );
    }

    bodyElement = (
      <div class='flex-column stat-container'>
        <h3>Courses</h3>
        <div class='stats-courses-wrapper'>
          <div class='flex-row stats-courses-container'>
            {courses.map((course, index) =>
              <div class='flex-column stats-course-container' key={index}>
                <div class='flex-column stats-course-detail'>
                  {gemIcon}
                  <h5>{course.title}</h5>
                </div>
                <IssueDateElement
                  issueDate={course.completedDate}
                  word={'Completed'}
                  score={course.score}
                  scoreIelts={course.scoreIelts}
                  isThisLearner={isThisLearner}
                />
              </div>
            )}
            </div>

          {placeholderTextElement}
          <div class='flex-row stats-courses-container'>
            {placeholderCourses.map((course, index) =>
              <div class='flex-column stats-course-container placeholder' key={index}>
                <div class='flex-column stats-course-detail'>
                  {gemIconGray}
                </div>
              </div>
            )}
          </div>

        </div>

        {bottomTextElement}

      </div>
    );
  }

  return (
    <>{bodyElement}</>
  );
}


export default StatsCourses;