function scoreWord(score) {
  let word;

  // 0 - 55 -> Ok
  // 56 - 79 -> Good
  // 80 - 100 -> Excellent

  if( score >= 80 ) {
    word = 'Excellent';
  } else if( score <= 79 && score >= 56 ) {
    word = 'Good';
  } else {
    word = 'Ok';
  }

  return word;
}

export default scoreWord;