
const initialSate = {
  id: null,
  lessonData: {},
  isSaved: false,
  isIelts: false,
  isInitialized: false
}

export function ExpressionReducer(state = initialSate, action) {

  if( action.type === 'setExpression') {
    return {
      ...state,
      id: action.payload.id,
      lessonData: action.payload.lessonData,
      isSaved: action.payload.isSaved,
      isIelts: action.payload.isIelts,
      isInitialized: true,

    };
  }

  if( action.type === 'setExpressionIsSaved') {
    return {
      ...state,
      isSaved: action.payload
    };
  }

  return state;
}