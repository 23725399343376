import React from "react";

import LessonCardBig from "../utilities/lessoncards/lessonCardBig";


function BiteLeftColumn(props) {

  const relatedLessons = props.relatedLessons;
  const relatedLessonsTitle = (relatedLessons.length === 0) ? null : <p class='related-lessons-title'>Related Lessons</p>;


  return (
    <div class='lesson-left-column'>

      {relatedLessonsTitle}

      <div class='related-lesson-wrapper'>

        {relatedLessons.map((lesson, index) =>
          <LessonCardBig
            lesson={lesson}
            key={index}
          />
        )}

      </div>
    </div>
  );
};

export default BiteLeftColumn;