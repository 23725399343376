import React from "react";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import PurchaseSteps from "./purchasesteps";
import PackageSummary from "./packagesummary";
import ReviewTotal from "./reviewtotal";
import CancelLink from "./cancellink";

import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";

import { lookupPackage } from "../utilities/auth/authApi";
import fadeInMainBody from "../../functions/fadeinmainbody";


function PackagesReview() {

  const navigate = useNavigate();
  const [packageData, setPackageData] = useState(null);
  const packageId = useSelector((state) => state.package.packageId);
  const promoCode = useSelector((state) => state.package.promoCode);


  useEffect(() => {

    fadeInMainBody();

    if( packageId ) {

      const payload = {package_id: packageId};

      // Look up the package
      lookupPackage(payload).then((response) => {
        if(response && response.data) {
          setPackageData(response.data);
        } else {
          // handle
        }
      });

    } else {
      // Redirect
      navigate('/packages');
    }

  // });
  }, [packageId]); // -------------------------------


  function handleNextClick(event) {
    event.preventDefault();

    // Redirect to /purcase
    navigate('/packages/purchase');

  }


  return (
    <div class='wrapper gray-background'>
      <Nav />
      <div class='main-body invisible'>
        <HeaderMain title="Review" />
        <div class='main-body-content packages'>

          <PurchaseSteps currentStep={'review'} />
          <PackageSummary packageData={packageData} />
          <ReviewTotal packageData={packageData} promoCode={promoCode} />

          <div class='action-button-wrapper'>
            <button class='button action-button' onClick={handleNextClick}>Next</button>
          </div>

          <CancelLink />

        </div>
      </div>
    </div>
  );
};

export default PackagesReview;