import React from "react";
import { useNavigate } from "react-router-dom";


function WelcomeCompleteFailed(props) {

  // const paymentDetails = props.paymentDetails
  const navigate = useNavigate();

  function handleButtonClick() {
    navigate('/packages/purchase');
  }

  return (
    <div class='payment-complete-failed-wrapper'>

      <h3>Payment failed. Please try another payment method.</h3>


      <div class='action-button-wrapper'>
        <button type='button' class='button' onClick={handleButtonClick}>Go Back</button>
      </div>

    </div>
  );
};

export default WelcomeCompleteFailed;