import React from "react";


function RecordUiExpressionRetryLimit() {
  return (
    <button
      class='record-response-button'
      style={{cursor: 'initial'}}
    >
      <div class='expression-record-icon-container-base'>
        <div
          class='expression-record-icon-container'
          style={{opacity: .5}}
        >
          <div id='record-icon'></div>
        </div>
      </div>
      <p>&nbsp;</p>
    </button>
  );
}

export default RecordUiExpressionRetryLimit;