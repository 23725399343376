import { useState } from 'react';
import { Link } from "react-router-dom"


function StackedNav(props) {

  // Define variables
  const [stackedNav, setStackedNav] = useState(null);
  const includeText = props.includeText || false;


  const navElement = (
    <div class='stacked-nav'>

      <div class='stacked-nav-header'>
        <button class='clean-button icon icon-close' onClick={closeNav}></button>
      </div>

      <div class='stacked-nav-primary-links'>
        {/* <Link to='/' title='Go to the Weekly Mix' onClick={() => closeNav()}>Weekly Mix</Link> */}
        <Link to='/' title='Go to the Weekly Mix' onClick={() => closeNav()}>Home</Link>
        <Link to='/courses' title='View Courses' onClick={() => closeNav()}>Courses</Link>
        <Link to='/explore' title='Explore all Lessons' onClick={() => closeNav()}>Library</Link>
        <Link to='/saved' title='Your saved Lessons' onClick={() => closeNav()}>Saved Lessons</Link>
        <Link to='/packages' title='View Premium Membership Options' onClick={() => closeNav()}>Membership</Link>
      </div>

      <div class='stacked-nav-tertiary-links'>
        <Link to="/profile" title='Your profile' onClick={() => closeNav()}>Settings</Link>
        {/* <Link to="/progress" title='View your progress, achievements, and stats' onClick={() => closeNav()}>Progress</Link> */}
        <Link to="/help" title='Get help or leave a suggestion' onClick={() => closeNav()}>Help</Link>
        <Link to="/logout" title='Logout' onClick={logout}>Logout</Link>
      </div>

    </div>
  );


  // Define functions
  function openNav() {
    setStackedNav(navElement);
  }

  function closeNav() {
    setStackedNav(null);
  }

  function logout(event) {
    event.preventDefault();

    localStorage.removeItem("jwt-token");
    // window.location = '/start#signin';
    window.location = '/login';
  }


  // Screen components
  let textElement;
  if( includeText ) {
    textElement = <div class='nav-link-text'>More</div>;
  }


  return (
    <div>
      {stackedNav}
      <button type='button' class='clean-button pointer stacked-nav-opener' onClick={openNav}>
        {/* <div class='icon icon-stacked-nav'></div> */}
        <div class='icon icon-more'></div>
        {textElement}
      </button>
    </div>
  );
};

export default StackedNav;