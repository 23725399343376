import ScoreDonut from "../../utilities/learning_module/scoredonut";
import ScoreBlockRetryCount from "./scoreBlockRetryCount";


function ScoresBlockBooster(props) {

  // Define variables
  const retryCount = props.retryCount;
  const scores = props.scores;
  const overallScore = scores.score;
  const pronunciationScore = scores.pronunciationScore;
  const relevanceScore = scores.relevanceScore;
  const fluencyScore = scores.fluencyScore;


  // Screen components
  let pronunciationElement;
  if( pronunciationScore ) {
    pronunciationElement = (
      <div class='flex-row score-block-row'>
        <p>Pronunciation</p>
        <p class='flex-row'>
          {pronunciationScore}%
          <ScoreDonut scorePercent={pronunciationScore} />
        </p>
      </div>
    );
  }

  let fluencyElement;
  if( fluencyScore ) {
    fluencyElement = (
      <div class='flex-row score-block-row'>
        <p>Fluency</p>
        <p class='flex-row'>
          {fluencyScore}%
          <ScoreDonut scorePercent={fluencyScore} />
        </p>
      </div>
    );
  }

  let relevanceElement;
  if( relevanceScore ) {
    relevanceElement = (
      <div class='flex-row score-block-row'>
        <p>Relevance</p>
        <p class='flex-row'>
          {relevanceScore}%
          <ScoreDonut scorePercent={relevanceScore} />
        </p>
      </div>
    );
  }



  return (
    <div class='score-block-container'>

      <div class='flex-row score-block-row'>
        <p><strong>Overall</strong></p>
        <p class='flex-row'>
          {overallScore}%
          <ScoreDonut scorePercent={overallScore} />
        </p>
      </div>

      {pronunciationElement}
      {fluencyElement}
      {relevanceElement}

      <ScoreBlockRetryCount retryCount={retryCount} />

    </div>
  );
}

export default ScoresBlockBooster;