import React from "react";
import { Link } from "react-router-dom"

function CancelLink () {
  return (
    <div class='purchase-cancel-link-wrapper'>
      <Link to='/dashboard' title='Back to the Dashboard' class='purchase-cancel-link'>Cancel</Link>
    </div>
  );
};

export default CancelLink;