import React from "react";
import QuestionText from "./questiontext";
import QuestionImage from "./questionimage";
import QuestionVideo from "./questionvideo";


function Question(props) {

  // Define variables
  const content = props.content;
  const translateToggle = props.translateToggle;
  const translateContent = props.translateContent;
  const audioSpeed = props.audioSpeed;
  const type = content.type;
  let questionComponent;

  switch(type) {
    case 'video':
      questionComponent = <QuestionVideo content={content} />;
      break;
    case 'image':
      questionComponent = <QuestionImage content={content} />;
      break;
    // case 'introduction':
    //   questionComponent = <QuestionText content={content} translateToggle={translateToggle} translateContent={translateContent} />;
    //   break;
    default:
      // Default to question
      questionComponent = (
        <QuestionText
          content={content}
          translateToggle={translateToggle}
          translateContent={translateContent}
          audioSpeed={audioSpeed}
        />
      );
  }

  return (
    <>{questionComponent}</>
  );
}


export default Question;