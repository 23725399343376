function formatPromoCodeDescription(promoCodeInfo) {

  // {
  //   amount_off: 1000
  //   code: "take10"
  //   id: "laTQpGpD"
  //   percent_off: null
  //   promoCodeExists: true
  // }

  // const code = promoCodeInfo.code;
  const amountOff = promoCodeInfo.amount_off;
  const percentOff = promoCodeInfo.percent_off;
  let discount;
  let amount;

  if( amountOff ) {
    discount = ( amountOff / 100 );
    amount = `$${discount}`;
  } else if ( percentOff ) {
    // discount = packagePrice * ( percentOff / 100 );
    amount = `${percentOff}%`;
  }

  return `See your ${amount} discount on the next step`;
}

export default formatPromoCodeDescription;