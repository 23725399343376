import { useEffect, useState } from 'react';

import apiCall from '../auth/apiCall';
import { getActivityMonitor } from '../auth/authApi';
import ActivityCircle from '../../progress/activitycircle';


function ActivityGraph(props) {

  // Define variables
  const publicProfileId = props.publicProfileId;
  const isThisLearner = props.isThisLearner;
  const [activity, setActivity] = useState(Array(30).fill(0));
  const [streak, setStreak] = useState(0);

  useEffect(() => {
    if( publicProfileId) {
      apiCall(getActivityMonitor, publicProfileId).then((response) => {
        if( response.success ) {
          setActivity(response.data.activity);
          setStreak(response.data.streak);
        }
      });
    }
  }, [publicProfileId]);


  // Screen components
  let streakElement;
  if( streak > 0 ) {
    streakElement = (
      <div class='stats-activity-row'>
        <p class='streak-text'>{streak} day streak</p>
      </div>
    );
  }


  return (
    <div class='stats-activity-wrapper'>
      <div class='stats-activity-row'>
        {activity.map((percent, index) =>
          (index < 15) ? <ActivityCircle percent={percent} index={index} key={`activity-${index}`} /> : ''
        )}
      </div>
      <div class='stats-activity-row'>
        {activity.map((percent, index) =>
          (index > 14) ? <ActivityCircle percent={percent} index={index} key={`activity-${index}`} isThisLearner={isThisLearner} /> : ''
        )}
      </div>
      {streakElement}
    </div>
  )
}

export default ActivityGraph;