const excludeCountries = [
  'ax',
  'aq',
  'bv',
  'io',
  'bq',
  'cx',
  'cc',
  'cw',
  'sz',
  'tf',
  'hm',
  'mh',
  'yt',
  'fm',
  'nr',
  'mp',
  'pw',
  'pn',
  'bl',
  'sh',
  'mf',
  'ws',
  'sx',
  'gs',
  'sj',
  'tk',
  'tv',
  'um',
  'vi',
  'va',
  'wf',
  'eh',
  'ru',
  'sn',
  'jo',
  'ir',
  'ca',
  'pk',
  'ae',
  'eg',
  'id',
  'ua',
  'cd',
  'bj',
  'ly',
  'af',
  'ng',
  'il',
  'et',
  'ph',
  'mz',
  'mn',
  'ml',
  'cm',
  'kz',
  'bo'
];

export default excludeCountries;