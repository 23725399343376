
let publishableKey;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  publishableKey = 'pk_test_51KJuYTIs167BP3av2cS6cGzD5JS5HcRRtYxkLHTGRi2eGEB0ge0nKrcOhFuXdrfULGrdZrtNoPwg87EGHsBO88FW00pFit15wL';
} else {

  const host = window.location.host;
  // const onStaging = host.search('--newvoiceprototype.netlify.app') > 0;
  const onStaging = host.search('--flow-web.netlify.app') > 0;

  if( onStaging ) {
    publishableKey = 'pk_test_51KJuYTIs167BP3av2cS6cGzD5JS5HcRRtYxkLHTGRi2eGEB0ge0nKrcOhFuXdrfULGrdZrtNoPwg87EGHsBO88FW00pFit15wL';
  } else {
    publishableKey = 'pk_live_51KJuYTIs167BP3avgra6G9o2W8Zcr7IU26HAu388HO5uqMoMJiFZx0A5IBMUPqrZOcXqfo6blKDbohjBHLW5Rah800K4D5pCHi';
  }

}

export const stripePublishableKey = publishableKey;



export const getStripePackageIds = (pricingTier) => {

  let stripePackageIds;

  let useTestIds = false;
  const host = window.location.host;
  // const onStaging = host.includes('--newvoiceprototype.netlify.app');
  const onStaging = host.includes('--flow-web.netlify.app')

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') { useTestIds = true }
  if( onStaging ) { useTestIds = true }

  if( useTestIds ) {
    stripePackageIds = testStripeIds(pricingTier);
  } else {
    stripePackageIds = productionStripeIds(pricingTier);
  }


  return stripePackageIds;
}


function productionStripeIds(pricingTier) {

  let stripePackageIds;

  switch (pricingTier) {
    case (4 ):

      stripePackageIds = {
        monthlyPackageId: {
          price: '$2.49',
          packageId: 'price_1MFP54Is167BP3avj4j2FeWh'
        },
        sixMonthPackageId: {
          price: '$10.99',
          packageId: 'price_1MFP54Is167BP3avP1Z5smfh',
          discount: '18% off'
        },
        yearlyPackageId: {
          price: '$18.99',
          packageId: 'price_1MFP54Is167BP3avVteEhQ0L',
          discount: '33% off'
        }
      }
      break;

    case 3:

      stripePackageIds = {
        monthlyPackageId: {
          price: '$3.99',
          packageId: 'price_1MFP4aIs167BP3avozSGk0gW'
        },
        sixMonthPackageId: {
          price: '$17.99',
          packageId: 'price_1MFP4aIs167BP3avP7YlUI83',
          discount: '18% off'
        },
        yearlyPackageId: {
          price: '$31.99',
          packageId: 'price_1MFP4aIs167BP3avERVIdy2j',
          discount: '33% off'
        }
      }
      break;

    case 2:

      stripePackageIds = {
        monthlyPackageId: {
          price: '$7.99',
          packageId: 'price_1MFP3XIs167BP3avL7vuvKlb'
        },
        sixMonthPackageId: {
          price: '$37.99',
          packageId: 'price_1MFP3XIs167BP3av75enjq4X',
          discount: '18% off'
        },
        yearlyPackageId: {
          price: '$63.99',
          packageId: 'price_1MFP3XIs167BP3avEvRyp8UJ',
          discount: '33% off'
        }
      }
      break;

    default:

      stripePackageIds = {
        monthlyPackageId: {
          price: '$12',
          packageId: 'price_1KWDOuIs167BP3avthDunqtO'
        },
        sixMonthPackageId: {
          price: '$59',
          packageId: 'price_1KWDOuIs167BP3avncgRhnce',
          discount: '18% off'
        },
        yearlyPackageId: {
          price: '$97',
          packageId: 'price_1KWDOuIs167BP3av3tAnHNJs',
          discount: '33% off'
        }
      }

  }

  return stripePackageIds;
}



function testStripeIds(pricingTier) {

  let stripePackageIds;

  switch (pricingTier) {
    case (4 ):

      stripePackageIds = {
        monthlyPackageId: {
          price: '$2.49',
          packageId: 'price_1MFOwfIs167BP3av1i71YkX0'
        },
        sixMonthPackageId: {
          price: '$10.99',
          packageId: 'price_1MFOwfIs167BP3avmTuoGROR',
          discount: '18% off'
        },
        yearlyPackageId: {
          price: '$18.99',
          packageId: 'price_1MFOwfIs167BP3avlxNGZlac',
          discount: '33% off'
        }
      }
      break;

    case 3:

      stripePackageIds = {
        monthlyPackageId: {
          price: '$3.99',
          packageId: 'price_1MFOvRIs167BP3avlyKd1fqn'
        },
        sixMonthPackageId: {
          price: '$17.99',
          packageId: 'price_1MFOvRIs167BP3av3GJALN7E',
          discount: '18% off'
        },
        yearlyPackageId: {
          price: '$31.99',
          packageId: 'price_1MFOvRIs167BP3avLUVajOgz',
          discount: '33% off'
        }
      }
      break;

    case 2:

      stripePackageIds = {
        monthlyPackageId: {
          price: '$7.99',
          packageId: 'price_1MFOtiIs167BP3avxyixGGOK'
        },
        sixMonthPackageId: {
          price: '$37.99',
          packageId: 'price_1MFOtiIs167BP3avnDSkI6GW',
          discount: '18% off'
        },
        yearlyPackageId: {
          price: '$63.99',
          packageId: 'price_1MFOtiIs167BP3aveS4oN0Qo',
          discount: '33% off'
        }
      }
      break;

    default:

      stripePackageIds = {
        monthlyPackageId: {
          price: '$12',
          packageId: 'price_1KPKygIs167BP3av6gu1lFBN'
        },
        sixMonthPackageId: {
          price: '$59',
          packageId: 'price_1MFOpGIs167BP3avLXRa99BI',
          discount: '18% off'
        },
        yearlyPackageId: {
          price: '$97',
          packageId: 'price_1MFOoVIs167BP3avtY007uBi',
          discount: '33% off'
        }
      }

  }

  return stripePackageIds;

}





// let monthlyPackageId;
// let sixMonthPackageId;
// let yearlyPackageId;

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//   monthlyPackageId = 'price_1KPKygIs167BP3av6gu1lFBN';
//   sixMonthPackageId = 'price_1MFOpGIs167BP3avLXRa99BI';
//   yearlyPackageId = 'price_1MFOoVIs167BP3avtY007uBi';
// } else {

//   const host = window.location.host;
//   const onStaging = host.includes('--newvoiceprototype.netlify.app')

//   if( onStaging ) {
//     monthlyPackageId = 'price_1KPKygIs167BP3av6gu1lFBN';
//     sixMonthPackageId = 'price_1MFOpGIs167BP3avLXRa99BI';
//     yearlyPackageId = 'price_1MFOoVIs167BP3avtY007uBi';
//   } else {
//     monthlyPackageId = 'price_1KWDOuIs167BP3avthDunqtO';
//     sixMonthPackageId = 'price_1KWDOuIs167BP3avncgRhnce';
//     yearlyPackageId = 'price_1KWDOuIs167BP3av3tAnHNJs';
//   }

// }

// export const stripeMonthlyPackageId = monthlyPackageId;
// export const stripeSixMonthPackageId = sixMonthPackageId;
// export const stripeYearlyPackageId = yearlyPackageId;




let baseUrl;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  baseUrl = 'http://localhost:3000';
} else {

  const host = window.location.host;
  // const onStaging = host.search('--newvoiceprototype.netlify.app') > 0;
  const onStaging = host.search('--flow-web.netlify.app') > 0;

  if( onStaging ) {
    // baseUrl = "https://staging--newvoiceprototype.netlify.app";
    baseUrl = "https://staging--flow-web.netlify.app";
  } else {
    baseUrl = 'https://app.flowspeak.io';
  }
}

export const appBaseUrl = baseUrl;