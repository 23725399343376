import ScoreDonut from "../utilities/learning_module/scoredonut";


function ExpressionScore(props) {

  // Define variables
  const responseObject = props.responseObject;
  const isIelts = props.isIelts;
  const pronunciationPercent = Math.round(responseObject.pronunciation.percent);
  let pronunciationScore = `${pronunciationPercent}%`;

  if( isIelts ) {
    pronunciationScore = responseObject.ieltsScores.pronunciation;
  }


  return (
    <div class='expression-score-container'>
      <div class='flex-row'>
        <ScoreDonut scorePercent={pronunciationPercent} />
        <h2>{pronunciationScore}</h2>
      </div>
      <p>Pronunciation Score</p>
    </div>
  );
}

export default ExpressionScore;