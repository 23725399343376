import PathwayLevelBlock from "./pathwayLevelBlock";


function PathwayLevel(props) {

  // Define variables
  const pathway = props.pathway;
  const handleSelect = props.handleSelect;
  const allowSelect = handleSelect;
  const selectedPathway = props.selectedPathway;
  const selectedLevel = props.selectedLevel;
  const completeLevels = props.completeLevels || [];
  const certificateComplete = props.certificateComplete;

  let pathwayWrapperClass = 'pathway-wrapper';
  if( pathway === selectedPathway ) { pathwayWrapperClass = 'pathway-wrapper selected' }


  let pathwayName;
  let pathwayCEFR;
  if( pathway === 1 ) {
    pathwayName = 'Beginner';
    pathwayCEFR = '(A2→B1)';
  } else if( pathway === 2 ) {
    pathwayName = 'Intermediate';
    pathwayCEFR = '(B1→B2)';
  } else if( pathway === 3 ) {
    pathwayName = 'Advanced';
    pathwayCEFR = '(B2→C1)';
  }


  let border = "1px solid #c0c0c0";
  if( pathway === selectedPathway ) {
    border = "1px solid #333";
  }


  // Define functions
  function handleClick() {
    if( allowSelect ) {
      handleSelect(pathway);
    }
  }


  return (
    <button
      class='clean-button pointer pathway-select-button'
      onClick={() => handleClick()}
      title={`${pathwayName} Pathway`}
    >

      <div class={pathwayWrapperClass}>
        <h3 >{pathwayName} <small>{pathwayCEFR}</small></h3>

        <div class='flex-row pathway-level-block-wrapper'>
          <PathwayLevelBlock pathway={pathway} level={1} duration={'8 weeks'} selectedPathway={selectedPathway} selectedLevel={selectedLevel} complete={completeLevels.includes(1)} />
          <PathwayLevelBlock pathway={pathway} level={2} duration={'8 weeks'} selectedPathway={selectedPathway} selectedLevel={selectedLevel} complete={completeLevels.includes(2)} />
          <PathwayLevelBlock pathway={pathway} level={3} duration={'8 weeks'} selectedPathway={selectedPathway} selectedLevel={selectedLevel} complete={completeLevels.includes(3)} />
          <PathwayLevelBlock level={'Certificate'} certificateComplete={certificateComplete} />
        </div>
      </div>

    </button>
  );
}

export default PathwayLevel;