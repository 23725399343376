import { useState } from "react";
import { Link } from "react-router-dom";

import apiCall from "../../utilities/auth/apiCall";
import { updateBlock } from "../../utilities/auth/authApi";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";
import RenderHtmlText from "../../utilities/renderhtmltext";


function StepShowBadge(props) {

  // Define variables
  const [loading, setLoading] = useState(false);
  const clearDashboardData = props.clearDashboardData;
  const stepData = props.stepData;
  const level = stepData.level;
  const item = stepData.item;
  const imageUrl = stepData.imageUrl;
  const pathwayName = stepData.pathwayName;
  const longText = stepData.longText;
  const upgradeText = stepData.upgradeText;


  // Define functions
  function handleSubmit() {
    setLoading(true);

    apiCall(updateBlock, {}).then((response) => {
      if( response.success ) {
        setTimeout(() => {
          clearDashboardData();
        }, 500);
      }
    });
  }


  // Screen components
  let upgradeElement;
  if( upgradeText ) {
    upgradeElement = (
      <Link to='/packages'>
        <RenderHtmlText string={upgradeText} />
      </Link>
    );
  }


  return(
    <div class='flex-column' style={{marginTop: '45px'}}>
      <div class='flex-column pathway-achievement-container'>
        <h4>{pathwayName} {level}</h4>
        <h2>{item}</h2>
        <img src={imageUrl} />
        <RenderHtmlText string={longText} />
        {upgradeElement}

        <ButtonSubmitRound
          value={"Continue"}
          handleButtonClick={handleSubmit}
          loading={loading}
        />

      </div>
    </div>
  );
}

export default StepShowBadge;