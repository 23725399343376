import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import { reportError } from "../../functions/sentry";
import apiCall from '../utilities/auth/apiCall';
import { validateAuthCode, getOrganizationBySlug } from "../utilities/auth/authApi";
import { saveToken } from "../utilities/auth/tokenHelpers";
import LoadingBasic from "../utilities/loadingbasic";
import loadCustomCss from '../../functions/loadCustomCss';


function LaunchScreen() {

  // Define variables
  const navigate = useNavigate();
  const { slug } = useParams();
  const [loadingElement, setLoadingElement] = useState(<LoadingBasic />);
  const [errorMessage, setErrorMessage] = useState('');
  const [organization, setOrganization] = useState({});
  let authToken;


  // Page load actions
  useEffect(() => {

    apiCall(getOrganizationBySlug, {slug: slug}).then((response) => {
      if( response.success ) {
        setOrganization(response.data);

        if( response.data.external_css_url ) {
          loadCustomCss(response.data.external_css_url);
        }
      } else {
        reportError(response.error);
      }
    });

    if( typeof window.URLSearchParams === 'function' ) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      authToken = urlParams.get('auth_token');

      if( authToken ) {
        attemptAutoLogin(authToken);
      } else {
        showErrorMessage();
      }

    }

  }, []);


  // Define functions
  function attemptAutoLogin(authToken) {
    validateAuthCode({auth_code: authToken, persist: true}).then((response) => {
      if( response?.data?.auth ) {
        let path = '/dashboard';

        // Log the learner in
        saveToken(response.data);
        navigate(path);

      } else {
        showErrorMessage();
      }
    }).catch((error) => {
      showErrorMessage();
      reportError(error);
    });
  }

  function showErrorMessage() {
    setTimeout(() => {
      setLoadingElement(null);
      setErrorMessage('Sorry, there was an error. Please contact your support team.');
    }, 700);
  }


  return (
    <div class='wrapper'>
      <div class='main-body start'>
        <div class='flex-column main-body-content start launch'>
          <div
            class='launch-logo'
              style={{backgroundImage: `url("${organization.logo_url}")`}}
          ></div>
          <p>English Speaking Practice</p>
          <h1>To build your speaking confidence</h1>
          {loadingElement}
          <p class='error-message'>{errorMessage}</p>
        </div>
      </div>
    </div>
  );
}

export default LaunchScreen;