import React from "react";
import HelpBubbleText from "./helpbubbletext";


function helpBubble (props) {

  // Define variables
  const helpContents = props.helpContents || [];
  const translation = props.translation;
  const type = props.type;
  const title = props.title;
  const closeBubble = props.closeBubble;


  // Screen components
  let translationElement;
  if( translation ) {
    translationElement = <p class='translation-help-bubble'>{translation}</p>;
  }


  return (
    <div class={`help-bubble ${type}`}>
      <div class="media">
        <div class="media-content">
          <div class='title'>{title}</div>
        </div>
        <div class="media-right">
          <button
          type='button'
          class='clean-button close-bubble icon-close'
          title='Close'
          onClick={closeBubble}
        ></button>
        </div>
      </div>

      {
        helpContents.map((content, index) => (
          <div class='help-content' key={index}>
            <HelpBubbleText text={content.label} typeClass={'label'} />
            <HelpBubbleText text={content.text} typeClass={'text'} audioUrl={content.audioUrl} />
          </div>
        ))
      }

      {translationElement}

    </div>
  );
};

export default helpBubble;