import React from "react";
import { useState } from 'react';

import alertMessage from '../utilities/alertMessage';
import { resendVerifyCode } from "../utilities/auth/authApi";
import { Mixpanel } from "../utilities/analytics/mixpanelHelpers";
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";


function VerifyForm(props) {

  // Define variables
  const validateLoginCode = props.validateLoginCode;
  const validateAccountCode = props.validateAccountCode;
  const changeCurrentScreen = props.changeCurrentScreen;
  const buttonLoading = props.buttonLoading;
  const setButtonLoading = props.setButtonLoading;
  const errorText = props.errorText;
  const setErrorText = props.setErrorText;
  const code = props.code || '';
  const setCode = props.setCode;
  const type = props.type;
  const countryCode = props.countryCode;
  const phoneNumber = props.phoneNumber;
  const [resendLoading, setResendLoading] = useState(null);
  const number = `+${countryCode} ${phoneNumber}`;
  const backScreen = type;


  // Define functions
  function validateRouter(event) {
    event.preventDefault();

    if( code.length < 4 ) {
      alertMessage('Please enter your verification code', 'error');
      return;
    }

    setButtonLoading(true);
    setErrorText(null);

    if( type === 'register' ) {
      validateAccountCode();
    } else {
      validateLoginCode();
    }
  }


  function resendCode() {

    setResendLoading(true);

    const payload = {
      phone_country_code: countryCode,
      phone_number: phoneNumber,
      type: type
    };

    resendVerifyCode(payload).then((response) => {
      // Do nothing
    }).catch((error) => {
      Mixpanel.track('Error: SMS Resend Error', {'phone': phoneNumber, error: error});
    }).finally(() => {
      setTimeout(() => setResendLoading(false), 5000);
    });

  }


  return (
    <form class='signup-login-form'>

      {/* <div class='input-container taller'> */}
      <div class='input-container'>

        <div class='top-message'>
          <p>We sent a verification code</p>
          <p>to {number}</p>
        </div>

        <h3 style={{textAlign: 'center'}}>Enter Verification Code</h3>

        <input
          name="code"
          type="text"
          value={code}
          onChange={event => setCode(event.target.value)}
          minLength="4"
          title="Enter your verification code"
          required
          class='input has-text-centered'
          autoComplete="off"
        />

        <div class='secondary-helper-button-container'>
          <button
            onClick={() => resendCode()}
            class='secondary-helper-button'
            disabled={resendLoading}
          >
            Didn't get it? Resend code
          </button>
        </div>

        {/* <div
          style={{
            padding: '15px',
            paddingTop: '0px',
            marginTop: '15px',
            borderWidth: '1px',
            borderColor: '#bf1a84',
            borderStyle: 'solid',
            borderRadius: '10px'
          }}
        >
          <h2>Notice</h2>
          <p class='has-text-centered'>We are having technical issues sending SMS verificatin codes at the moment. Please either go back and login with your email and password or contact support@flowspeak.io for assistance.</p>
        </div> */}

      </div>


      <div class='form-error-header form-error'>
        <p>{errorText}</p>
      </div>

      <div class="action-button-wrapper">
        {/* <ButtonSubmit */}
        <ButtonSubmitRound
          value={'Continue'}
          loading={buttonLoading}
          buttonClass={'button action-button'}
          handleButtonClick={validateRouter}
        />
      </div>

      <div class='secondary-helper-button-container'>
        <button
          type='button'
          onClick={() => changeCurrentScreen(backScreen)}
          class='secondary-helper-button'
        >
          Back
        </button>
      </div>

    </form>
  );
}

export default VerifyForm;
