import React from "react";
import { Link } from "react-router-dom"
import { useEffect } from 'react';
import '../../styles/packages.css';

import Nav from "../utilities/nav";
import BottomNav from "../utilities/bottomnav";
import HeaderMain from "../utilities/headermain";
import fadeInMainBody from "../../functions/fadeinmainbody";


function Membership () {

  useEffect(() => {

    fadeInMainBody();

  // });
  }, []);


  return (
    <div class='wrapper gray-background'>
      <Nav />
      <div class='main-body invisible'>
        <HeaderMain title="Membership Options" />
        <div class='main-body-content membership'>

          <div>
            <p><strong>Premium</strong></p>
            <ul>
              <li>For serious speaking improvement to boost your career</li>
              <li>Paid plans with unlimited access to all lessons, courses, feedback, and saved lessons</li>
              <li>Full support from the FLOW team</li>
              <li>14 day money back guarantee</li>
            </ul>
          </div>

          <div>
            <p><strong>Community</strong></p>
            <ul>
              <li>For casual learning</li>
              <li>Free plan with limited lessons</li>
              <li>Join the community channel with other members</li>
            </ul>
          </div>

          <div>
            <p>Questions? Contact us at <a href='mailto:achieve@flowspeak.io' title='Email us with your questions'>achieve@flowspeak.io</a></p>
          </div>

          <p>
            <Link to='/packages' class='button' title='View Premium Packages'>View Premium Packages</Link>
          </p>

          {/* <p class='mb-6'><b>FLOW</b> offers two types of memberships to learners.</p>

          <div>
            <p>
              <strong>Community Membership</strong> is a free plan with limited lessons and it allows you to join in asking questions and learning together. As a mission-driven company, we believe everyone should have access to learning and opportunity.
            </p>
          </div>

          <div>
            <p>
              <strong>Premium Membership</strong> is a paid plan for learners who are committed to reaching their English-speaking goals and boosting their careers.
            </p>
            <p>
              With this membership, you will get all the <b>FLOW</b> resources and support to reach success.
            </p>
            <p>
              This includes unlimited access to premium lessons to learn and practice real English vocabulary and conversations. You will get advanced speech scoring and professional feedback on each of your lessons from our team so you can catch your mistakes and improve quickly. You can save your lessons which is important because frequent repetition is the key to mastering new vocabulary and achieving a smooth speaking style.
            </p>
            <p>
              <Link to='/packages' class='button' title='View Premium Packages'>View Premium Packages</Link>
            </p>
          </div> */}

        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default Membership;