import React from "react";
// import '../../../../styles/community.css';

// import Header from "../../../utilities/header";
// import ModuleTabs from "../../../utilities/learning_module/moduletabs";
import CommunityContentGroup from "./communitycontentgroup";

import loadModule from '../../../../functions/loadmodule.js';
// import fixInnerHeight from '../../../../functions/fixinnerheight';
import headerTitle from '../../../../functions/headertitle';
// import { withRouter } from "react-router";
import LoadingBasic from "../../loadingbasic";


class CommunityIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleId: this.props.match.params.id,
      moduleType: this.props.match.url.split('/')[1],
      moduleTitle: headerTitle(this.props.match.url.split('/')[1]),
      questionList: [],
      loading: <LoadingBasic />
    };

  }

  componentDidMount() {

    // // Fix the inner height issue on mobile devices
    // fixInnerHeight();

    loadModule(this.state.moduleId).then((response) => {

      if( response?.data?.moduleContent ) {
        let moduleData = response.data;
        const questionList = moduleData.moduleContent;

        this.setState({loading: null});
        this.setState({questionList: questionList});
      }

    });
  }

  render() {

    return (

      <div class='conversation-wrapper'>
        <div class='questions'>
          {
            this.state.questionList.map((group, index) => (
              <CommunityContentGroup key={`index-community-group-${index}`} group={group} />
            ))
          }
          {this.state.loading}
        </div>
      </div>
    );
  }
};

// export default withRouter(CommunityIndex);
export default CommunityIndex;