import React from "react";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import BiteAction from "./biteaction";
import BiteAnswer from "./biteanswer";

import Modal from "../utilities/modal";


function CurrentQuestion(props) {

  // Define variables
  const [action, setAction] = useState(null);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [explanationModal, setExplanationModal] = useState(null);

  const id = props.id;
  const multipleCorrectAnswers = false;
  const logStart = props.logStart;
  const nextAction = props.nextAction;
  const translateToggle = props.translateToggle;
  const setTranslateToggle = props.setTranslateToggle;
  const translateContent = props.translateContent;
  const setTranslateContent = props.setTranslateContent;
  const currentQuestion = props.currentQuestion;
  const correctAnswerIds = currentQuestion.answers.filter(answer => answer.correctAnswer).map(answer => answer.id);

  const title = currentQuestion.title;
  const secondaryLine = currentQuestion.secondaryLine;
  const imageUrl = currentQuestion.imageUrl;
  const answers = currentQuestion.answers;


  // Component load actions
  useEffect(() => {
    setAction('waiting');
  }, [currentQuestion]);


  // Define functions
  function handleAnswerClick(id) {

    // Log the action
    logStart();

    if( multipleCorrectAnswers ) {
      // TO DO
    } else {
      setSelectedAnswers([id]);
      setAction('check');
    }
  }

  function checkAnswer() {
    const correct = correctAnswerIds.join() === selectedAnswers.join();
    const value = correct ? 'correct' : 'incorrect';
    setAction(value);
  }

  function showExplanation(bodyText, translation = null) {

    const bodyElement = (
      <div>
        <p>{bodyText}</p>
        <p style={{color: '#6D6D6D', fontSize: '.9rem'}}>{translation}</p>
      </div>
    );

    const modal = (
      <Modal
        windowClass={'bite-explanation'}
        body={bodyElement}
        closeButton={true}
        closeModal={hideExplanation}
      />
    );

    setExplanationModal(modal);
  }

  function hideExplanation() {
    setExplanationModal(null);
  }


  // Screen components
  let titleClass = 'title';
  let secondaryLineClass = 'title secondary-line';
  if( title.length > 18 && title.length <= 30 ) {
    titleClass = 'title small';
    secondaryLineClass = 'title secondary-line small';
  } else if ( title.length > 31 ) {
    titleClass = 'title smaller';
    secondaryLineClass = 'title secondary-line smaller';
  }

  let translateContentQuestionAnswers;
  let displayTitle = title;
  let displaySecondaryLine = secondaryLine;
  if( translateToggle && translateContent?.questions?.length > 0 ) {

    let translateContentQuestion = translateContent.questions.find(q => q.recordId === currentQuestion.id);
    if( translateContentQuestion ) {
      translateContentQuestionAnswers = translateContentQuestion.answers;
      displayTitle = translateContentQuestion.title;
      displaySecondaryLine = translateContentQuestion.secondaryLine;
    }

  }


  return (
    <div class='bite-wrapper'>
      {explanationModal}

      <div
        class='bite-background-image fade-in-on-load fade-slow'
        style={{
          backgroundImage: "url(" + imageUrl + ")",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}>
      </div>

      <div class='bite-top-fade'></div>
      <div class='bite-content'>

        <div class='bite-header'>
          <div class={titleClass}>{displayTitle}</div>
          <Link to='/' class='icon icon-close-light' title='Return to Dashboard'></Link>
        </div>
        <div class={secondaryLineClass}>{displaySecondaryLine}</div>

        <div class='bite-answers-wrapper'>
          {answers.map((answer, index) =>
            <BiteAnswer
              answer={answer}
              selected={selectedAnswers.includes(answer.id)}
              handleAnswerClick={handleAnswerClick}
              showExplanation={showExplanation}
              action={action}
              key={index}
              translateContentQuestionAnswers={translateContentQuestionAnswers}
            />
          )}
        </div>

        <BiteAction
          action={action}
          nextAction={nextAction}
          checkAnswer={checkAnswer}
          translateToggle={translateToggle}
          setTranslateToggle={setTranslateToggle}
          translateContent={translateContent}
          setTranslateContent={setTranslateContent}
          id={id}
          lessonType={'bite'}
        />

      </div>
    </div>
  );
};

export default CurrentQuestion;