import PlayAudioIcon from "../playaudioicon";
import AvatarImage from "../avatarImage";

function MessageSpeaker(props) {

  // Define variables
  const message = props.message;
  const audioUrl = props.audioUrl;
  const avatarUrl = props.avatarUrl;


  // Screen components
  let audioElement;
  if( audioUrl ) {
    audioElement = (
      <div class='message-content-audio'>
        <PlayAudioIcon
          audioUrl={audioUrl}
          iconDark={true}
        />
      </div>
    );
  }

  let avatarElement;
  if( avatarUrl ) {
    avatarElement = <AvatarImage avatarUrl={avatarUrl} />;
  }


  return (
    <div class='flex-row message-container message-speaker'>
      {avatarElement}
      <div class='flex-row message-content'>
        {message}
        {audioElement}
      </div>
    </div>
  );
}

export default MessageSpeaker;