
const initialSate = {
  microphonePermission: 'pending',
  learnerHasAccessToLesson: true,
  showTooltip: false,
  complete: false,
  // isSaved: false,
  ieltsCourse: false,
  delayQuestions: 1,
  currentExerciseId: null,
  currentLessonType: 'conversation',
  currentLessonId: null,
  currentLesson: {},
  currentLessonQuestions: [],
  activeQuestions: [],
  currentQuestionNumber: 0,
  currentLessonAudio: [],
  currentLearningModulesUsersSelection: {
    rating: null,
    share_responses: false,
    include_audio_in_share: false,
    request_feedback: false
  },
  currentLessonResponses: [],
  grammarErrors: {},

  activeRecording: false,
  activePlaying: false
}

export function LessonReducer(state = initialSate, action) {

  if( action.type === 'setLesson') {
    return {
      ...state,
      learnerHasAccessToLesson: action.payload.learnerHasAccessToLesson,
      showTooltip: action.payload.showTooltip,
      complete: false,
      ieltsCourse: action.payload.ieltsCourse,
      delayQuestions: 1,
      currentLearningModulesUsersId: null,
      currentExerciseId: null,
      currentLessonId: action.payload.id,
      currentLesson: action.payload.lesson,
      currentLessonType: action.payload.lessonType,
      currentLessonQuestions: action.payload.questionList,
      activeQuestions: action.payload.activeQuestions,
      currentQuestionNumber: action.payload.currentQuestionNumber,
      currentLessonAudio: action.payload.currentLessonAudio,
      currentLearningModulesUsersSelection: {
        rating: null,
        share_responses: false,
        include_audio_in_share: false,
        request_feedback: false
      },
      grammarErrors: {},
      activeRecording: false,
      activePlaying: false
    };
  }

  if( action.type === 'setMicrophonePermission') {
    return {
      ...state,
      microphonePermission: action.payload.microphonePermission
    };
  }

  if( action.type === 'setIsSaved') {

    return {
      ...state,
      currentLesson: {
        ...state.currentLesson,
        isSaved: action.payload.isSaved
      }
    };
  }

  if( action.type === 'markAsComplete') {
    return {
      ...state,
      complete: action.payload.complete
    };
  }

  if( action.type === 'setCurrentExerciseId') {
    return {
      ...state,
      currentExerciseId: action.payload.currentExerciseId
    };
  }

  if( action.type === 'updateActiveQuestions') {
    return {
      ...state,
      activeQuestions: action.payload.activeQuestions
    };
  }

  if( action.type === 'updateCurrentQuestionNumber') {
    return {
      ...state,
      currentQuestionNumber: action.payload.currentQuestionNumber
    };
  }

  if( action.type === 'updateShowTooltip') {
    return {
      ...state,
      showTooltip: action.payload.showTooltip
    };
  }

  if( action.type === 'updateCurrentLearningModulesUsersSelection') {
    return {
      ...state,
      currentLearningModulesUsersSelection: action.payload.currentLearningModulesUsersSelection
    };
  }

  if( action.type === 'setDelayQuestions') {
    return {
      ...state,
      delayQuestions: action.payload.delayQuestions
    };
  }

  if( action.type === 'updateCurrentLessonAudio') {
    return {
      ...state,
      currentLessonAudio: action.payload.currentLessonAudio
    };
  }

  if( action.type === 'setCurrentLessonResponses') {
    return {
      ...state,
      currentLessonResponses: action.payload.currentLessonResponses
    };
  }

  if( action.type === 'updateGrammarErrors') {
    const existingGrammarErrors = state.grammarErrors;
    existingGrammarErrors[action.payload.key] = action.payload.value;

    return {
      ...state,
      grammarErrors: existingGrammarErrors
    };
  }

  if( action.type === 'clearGrammarErrors') {
    return {
      ...state,
      grammarErrors: {}
    };
  }

  if( action.type === 'setActiveRecording') {
    return {
      ...state,
      activeRecording: action.payload
    }
  }

  if( action.type === 'setActivePlaying') {
    return {
      ...state,
      activePlaying: action.payload
    }
  }

  return state;
}