import React from "react";
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import '../../styles/bites.css';
import '../../styles/listening.css';

import alertMessage from "../utilities/alertMessage";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import apiCall from '../utilities/auth/apiCall';
import { getListeningLesson, logExerciseStart } from "../utilities/auth/authApi";
import sendExerciseData from "../../functions/sendExerciseData";
import fadeInMainBody from "../../functions/fadeinmainbody";

import CurrentQuestion from "./currentquestion";
import BiteLeftColumn from "../bite/biteleftcolumn";
import LessonCompleteModal from "../utilities/lessonCompleteModal";


function ListeningLesson() {

  // Define dispatch functions
  const dispatch = useDispatch();

  function setActivePlaying(status) {
    dispatch({ type: 'setActivePlaying', payload: status});
  }

  function clearDashboardData() {
    dispatch({ type: 'clearDashboardData' });
  }


  // Define variables
  const { id } = useParams();
  const initialCurrentQuestion = {
    title: 'Loading...',
    answers: []
  }

  const initialQuestionData = {
    questions: [],
    nextLesson: {},
    relatedLessons: []
  }

  const [questionData, setQuestionData] = useState(initialQuestionData);
  const [exerciseId, setExerciseId] = useState(null);
  const [completeElement, setCompleteElement] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(initialCurrentQuestion);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);

  const [translateToggle, setTranslateToggle] = useState(false);
  const [translateContent, setTranslateContent] = useState(null);


  // Component load actions
  useEffect(() => {

    setActivePlaying(false);

    // Ensure every thing is reset
    reset();

    fadeInMainBody();

    getListeningLesson(id).then((response) => {

      if( response && response.data && response.data.questions ) {
        const nextLesson = response.data.nextLesson;
        const relatedLessons = response.data.relatedLessons;
        const questions = response.data.questions;
        const currentQuestion = response.data.questions[0];

        const questionData = {
          questions: questions,
          nextLesson: nextLesson,
          relatedLessons: relatedLessons
        }

        setQuestionData(questionData);
        setCurrentQuestion(currentQuestion);

      } else {
        // Handle
      }

    });

  }, [id]);


  // Define functions
  function nextAction() {

    const backgroundImage = document.getElementsByClassName('bite-background-image')[0];
    backgroundImage.classList.remove('fade-in-on-load');

    if( questionData.questions.length === (currentQuestionNumber + 1) ) {

      // End of Lesson
      completeListeningLesson();

    } else {

      const newCurrentQuestionNumber = currentQuestionNumber + 1;

      // Give it just a moment so the next image will fade in
      setTimeout(() => {
        backgroundImage.classList.add('fade-in-on-load');

        setCurrentQuestionNumber(newCurrentQuestionNumber);
        setCurrentQuestion(questionData.questions[newCurrentQuestionNumber]);

      }, 100);

    }

  }

  function completeListeningLesson() {

    // Send the responses to the server
    const payload = {
      lessonType: 'ListeningLesson',
      exerciseId: exerciseId,
      lessonId: id,
      responsesToSave: []
    }

    sendExerciseData(payload).then((response) => {

      if( response.success ) {

        setCompleteElement(<LessonCompleteModal data={response.data} />);

        // // Make the dashboard recall lesson data
        // invalidateInitialized();
        clearDashboardData();

        // Log the event
        Mixpanel.track('Event: Lesson Complete', {'Lesson ID': id, 'Lesson Type': 'Listening'});

      } else {
        alertMessage(response.error, 'error');
      }

    });

  }

  function logStart() {
    if( !exerciseId ) {
      const payload = {
        lesson_id: id,
        started: true,
        lesson_type: 'ListeningLesson'
      }

      apiCall(logExerciseStart, payload).then((response) => {
        if( response.success ) {
          setExerciseId(response.data.exerciseId);
        }
      });
    }
  }

  function reset() {
    setQuestionData(initialQuestionData);
    setExerciseId(null);
    setCompleteElement(null);
    setCurrentQuestion(initialCurrentQuestion);
    setCurrentQuestionNumber(0);
    setTranslateToggle(false);
    setTranslateContent(false);
  }


  return (
    <div class='wrapper invisible'>
      {completeElement}
      <div class='lesson-body bite listening'>

        <BiteLeftColumn relatedLessons={questionData.relatedLessons} />

        <div class='lesson-right-column bites'>
          <section class='body-content'>
            <CurrentQuestion
              currentQuestion={currentQuestion}
              nextAction={nextAction}
              translateToggle={translateToggle}
              setTranslateToggle={setTranslateToggle}
              translateContent={translateContent}
              setTranslateContent={setTranslateContent}
              id={id}
              logStart={logStart}
            />
          </section>
        </div>

      </div>
    </div>
  );
};

export default ListeningLesson;