import { Link } from "react-router-dom";
import generateLessonLinkPath from '../../../functions/generateLessonLinkPath';
import VectorIcon from "../vectorIcon";


function LessonCardSmall(props) {

  // Define variables
  const lesson = props.lesson;
  const id = lesson.id;
  const title = lesson.title;
  const level = lesson.level;
  const duration = lesson.duration;
  // const thumbnailUrl = lesson.thumbnailUrl;
  const exercise = lesson.exercise;
  const isComplete = exercise.isComplete;
  const lessonType = lesson.lessonType;
  const displayLessonType = lesson.displayLessonType;
  // const premium = lesson.premium;
  // const isRepeat = lesson.isRepeat;
  const linkPath = generateLessonLinkPath(lessonType, id);


  // Screen components
  let completeIconElement;
  if( isComplete ) {
    completeIconElement = <VectorIcon icon='checkMarkCircle' size={'1rem'} color='#6D6D6D' />;
  }


  return (
    <div class='lesson-card-small-wrapper'>
      <Link to={linkPath} class='lesson-card-small-container' title='Start lesson'>
        <div class='flex-row'>
          <p>{displayLessonType}</p>
          {completeIconElement}
        </div>
        <h4>{title}</h4>
        <p><i>{level}</i></p>
        <p><i>{duration}</i></p>
      </Link>
    </div>
  );
}

export default LessonCardSmall;