import { Link } from "react-router-dom"


function LessonScreenSwitcher(props) {

  // Define variables
  const currentScreen = props.currentScreen;
  const id = props.id;


  return (
    <div class='screen-toggler-wrapper'>
      <div class='flex-row screen-toggler-container'>

        <Link
          to={`/lessons/${id}`}
          class={`faux-button ${currentScreen === 'practice' ? 'active' : ''}`}
        >Practice</Link>

        {/* <Link
          to={`/lessons/${id}/community`}
          class={`faux-button ${currentScreen === 'community' ? 'active' : ''}`}
        >Community</Link> */}

        <Link
          to={`/lessons/${id}/related`}
          class={`faux-button ${currentScreen === 'related' ? 'active' : ''}`}
        >Related</Link>

      </div>
    </div>
  );

}
export default LessonScreenSwitcher;