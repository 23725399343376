const initialState = {
  isInitialized: false,
  id: null,
  isSaved: false,
  isIelts: false,
  lessonData: {
    learnerHasAccessToLesson: true,
    iconUrl: 'https://files.flowspeak.io/files/default-avatar.png',
    title: 'Loading ...',
    initialDisplayMessage: 'Loading ...',
    description: 'Loading ...',
    everyMessageAddonResponderText: '',
    learnerSpeakingTurns: 6,
    relatedLessons: []
  },
  messages: [],
  responses: [],
  exerciseId: null
}

export function AiTalkReducer(state = initialState, action) {

  if( action.type === 'setAiTalk') {
    return {
      ...state,
      isInitialized: true,
      id: action.payload.id,
      isSaved: action.payload.isSaved,
      isIelts: action.payload.isIelts,
      lessonData: action.payload.lessonData,
      messages: action.payload.messages
    };
  }

  if( action.type === 'addMessage') {
    return {
      ...state,
      messages: [...state.messages, action.payload]
    };
  }

  if( action.type === 'addResponse') {
    return {
      ...state,
      responses: [...state.responses, action.payload]
    };
  }

  if( action.type === 'setAiTalkIsSaved') {
    return {
      ...state,
      isSaved: action.payload
    };
  }

  if( action.type === 'setExerciseId') {
    return {
      ...state,
      exerciseId: action.payload
    };
  }

  if( action.type === 'resetAiTalk') {
    return {
      ...state,
      id: initialState.id,
      lessonData: initialState.lessonData,
      isSaved: initialState.isSaved,
      isInitialized: initialState.isInitialized,
      messages: initialState.messages,
      responses: initialState.responses
    };
  }

  return state;
}