import React from "react";
import { useState, useEffect } from 'react';
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';

import RecordResponse from "../utilities/recordresponse";
import PlayAudioIcon from "../utilities/playaudioicon";


function RecordTest(props) {

  const [responseText] = useState(<RecordResponse handleTranscript={handleTranscript} />);
  const [questionsArray, setQuestionsArray] = useState([]);

  // const responseText = (props.responseText) ? props.responseText : <RecordResponse handleTranscript={props.handleTranscript} />;
  // const responseText = <RecordResponse handleTranscript={handleTranscript} />;

  function handleTranscript(transcript, audioBlobUrl, blob, responseObject) {

    if( responseObject.transcribed ) {
      // setResponseText(transcript);

      const responseInput = document.getElementById('response-input');
      if( responseInput ) {
        responseInput.style.transition = '300ms';
        responseInput.style.opacity = 0;
      }

      // Hide the hint text
      const hintTextElement = document.getElementsByClassName('mic-test-help-wrapper')[0];
      if( hintTextElement ) { hintTextElement.classList.add('is-hidden') }

      setTimeout(() => {
        if( responseInput ) {
          responseInput.innerText = transcript;
          responseInput.style.opacity = 100;
        }

        const questionOneDiv = document.getElementById('question-1');
        questionOneDiv.classList.remove('active');

        props.completeTutorial();
        Mixpanel.track('Event: Record Test Complete');
      }, 300);

    } else {
      Mixpanel.track('Event: Record Test Failed');
    }

  }


  const questionOne = (
    <div class='question-wrapper question fade-in-on-load' id='instructions-2'>
      <div class='flex'>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p>
                {/* Say "I'm speaking English". */}
                Say: "I'm speaking with confidence".
                &nbsp;&nbsp;
              </p>
            </div>
            <PlayAudioIcon audioUrl={'https://newvoicelearninglandingpage.netlify.app/files/speaking-with-confidence.mp3'} iconDark={true} />
          </div>
        </div>
      </div>
    </div>
  );

  const responseOne = (
    <div class='response-wrapper active fade-in-on-load' id='question-1'>
      <div class='response-content'>
        <div class="card-content">
          <div class="media">
          {/* {audioIcon} */}
            <div class="media-content">
              <div class="has-text-right response-input" id='response-input'>
                {responseText}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const helpOne = (
    <div class='mic-test-help-wrapper'>
      <p id='hint-text'>Hint: Tap here to record.</p>
      <span class='fun-arrow'></span>
    </div>
  );



  useEffect(() => {

    Mixpanel.track('Event: Record Test Start');

    const unloadedQuestionsArray = [
      questionOne,
      responseOne,
      helpOne
    ];

    let iterator = 0;


    const interval = setInterval(function(){

      const newQuestion = unloadedQuestionsArray[iterator];
      setQuestionsArray(array => [...array, newQuestion]);
      iterator++;

      if( iterator === unloadedQuestionsArray.length ) {
        clearInterval(interval);
      }

    }, 700);

  }, []);



  return (

    <div class='mic-test-wrapper'>

      <div class='mt-6 mb-5'>
        <h2>Success!</h2>
        <p class='has-text-centered'>Now let’s practice recording your voice.</p>
      </div>

      {questionsArray.map((question, index) =>
        <div key={index}>{question}</div>
      )}

    </div>
  );
}

export default RecordTest;
