import { useState } from "react";
import { Link } from "react-router-dom";

import apiCall from "../../utilities/auth/apiCall";
import { updateBlock } from "../../utilities/auth/authApi";
import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";
import RenderHtmlText from "../../utilities/renderhtmltext";
import StepShowCertificateValidateName from "./stepShowCertificateValidateName";


function StepShowCertificate(props) {


  // Define variables
  const [loading, setLoading] = useState(false);
  const [nameVerified, setNameVerified] = useState(false);
  const clearDashboardData = props.clearDashboardData;
  const stepData = props.stepData;
  // const step = stepData.step;
  // const pathway = stepData.pathway;
  // const level = stepData.level;
  const item = stepData.item;
  const imageUrl = stepData.imageUrl;
  const pathwayName = stepData.pathwayName;
  // const shortText = stepData.shortText;
  const longText = stepData.longText;
  const upgradeText = stepData.upgradeText;
  const validateFullName = stepData.validateFullName;
  const fullName = stepData.fullName || '';
  const blockId = stepData.blockId;

  const [tempFullName, setTempFullName] = useState(fullName);


  // Define functions
  function handleSubmit() {
    setLoading(true);

    apiCall(updateBlock, {}).then((response) => {
      if( response.success ) {
        setTimeout(() => {
          clearDashboardData();
        }, 500);
      }
    });
  }


  // Screen components
  let actionElement;
  if( validateFullName && !nameVerified ) {
    actionElement = (
      <StepShowCertificateValidateName
        fullName={fullName}
        blockId={blockId}
        setNameVerified={setNameVerified}
        setTempFullName={setTempFullName}
      />
    );
  } else {
    actionElement = (
      <ButtonSubmitRound
        value={"Continue"}
        handleButtonClick={handleSubmit}
        loading={loading}
        disabled={loading}
      />
    );
  }


  // Screen components
  let upgradeElement;
  if( upgradeText ) {
    upgradeElement = (
      <Link to='/packages'>
        <RenderHtmlText string={upgradeText} />
      </Link>
    );
  }


  return(
    <div class='flex-column'>
      <div class='flex-column pathway-achievement-container'>
        <p>Pathway Complete</p>
        <h2>{pathwayName}</h2>
        <h2>{item}</h2>
        <div class='pathway-certificate-container'>
          <p class='pathway-certificate-name' style={
            tempFullName.length > 20 ? {fontSize: '.8rem'} : null
          }>{tempFullName}</p>
          <img src={imageUrl} />
        </div>
        <RenderHtmlText string={longText} />
        {upgradeElement}

        {actionElement}
      </div>
    </div>
  );
}

export default StepShowCertificate;