import React from "react";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import '../../styles/profileSettings.css';

import toast from 'react-hot-toast';

import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import ProfileSubNav from "./profilesubnav";
import ProfileImage from "./profileimage";
import ProfileInput from "./profileinput";
import ProfileSelect from "./profileselect";

import fadeInMainBody from "../../functions/fadeinmainbody";
import camelize from "../../functions/camelize";
import { updatePersonalInformation, getLanguageLevels, forgotpassword } from "../utilities/auth/authApi";
import { languageCodes } from "../utilities/languagecodes";
import { countryList } from "../utilities/countrylist";
import { reportError } from "../../functions/sentry";


function Profile() {

  // Dispatch functions
  const dispatch = useDispatch();

  function updateField(field, value) {
    dispatch({ type: 'updateField', payload: {field, value} });
  }


  // Define variables
  const [languageLevels, setLanguageLevels] = useState([]);

  // const publicProfileId = useSelector((state) => state.profile.publicProfileId);
  const avatarUrl = useSelector((state) => state.profile.avatarUrl);
  const firstName = useSelector((state) => state.profile.firstName);
  const lastName = useSelector((state) => state.profile.lastName);
  const email = useSelector((state) => state.profile.email);
  const country = useSelector((state) => state.profile.country);
  const nativeLanguage = useSelector((state) => state.profile.nativeLanguage);
  const languageLevelId = useSelector((state) => state.profile.languageLevelId);


  // Page load actions
  useEffect(() => {
    fadeInMainBody();

    getLanguageLevels().then((response) => {
      if( response.data ) {
        setLanguageLevels(response.data);
      }
    });

  }, []);


  // Define functions
  function enableField(id) {
    const element = document.getElementById(id);
    changeElement(element, false);
  }

  function cancelEdit(id) {
    const element = document.getElementById(id);
    changeElement(element, true);

    // TO DO - replace data with previous data
  }

  function saveData(id) {

    const element = document.getElementById(id);

    const parent = element.parentElement.parentElement;
    const saveButton = parent.querySelector('.save-button');
    saveButton.classList.add('is-loading');

    const name = element.getAttribute('name');
    const value = element.value;
    const valueLength = value.length;

    if( name === 'email' || name === 'first_name') {
      if( valueLength < 2 ) {
        toast.error('This field cannot be blank');
        saveButton.classList.remove('is-loading');
        return;
      }
    }

    const payload = {
      user: {
        [name]: value
      }
    }

    updatePersonalInformation(payload).then((response) => {
      if( response && response.data ) {
        if( response.data.success ) {
          // Update redux
          updateField(camelize(name), value);
          toast.success('Profile successfully updated');
        } else {
          toast.error(response.data.error);
        }
      } else {
        toast.error('Sorry, there was a problem');
      }
    }).finally(() => {
      changeElement(element, true);
    });
  }

  function changeElement(element, disabled) {
    const parent = element.parentElement.parentElement;
    const editButton = parent.querySelector('.edit-button');
    const saveButton = parent.querySelector('.save-button');
    const cancelButton = parent.querySelector('.cancel-button');

    element.disabled = disabled;
    editButton.classList.toggle('is-hidden');
    saveButton.classList.toggle('is-hidden');
    saveButton.classList.remove('is-loading');
    cancelButton.classList.toggle('is-hidden');

    // Put the cursor in the input if we're activating it
    if( !disabled ) {
      element.focus();
    }
  }

  function logout(event) {
    event.preventDefault();

    localStorage.removeItem("jwt-token");
    // window.location = '/start#signin';
    window.location = '/login';
  }

  function confirmPasswordReset() {
    if (window.confirm('Are you sure you want to reset your password?')) {
      sendPasswordResetEmail()
    };
  }

  function sendPasswordResetEmail() {
    forgotpassword({email: email}).then((response) => {
      if( response?.data?.success ) {
        toast.success(response.data.success, {duration: 6000});
      } else {
        toast.error('Sorry, there was an error. Please contact support@flowspeak.io.', {duration: 6000});
      }
    }).catch((error) => {
      reportError(error);
    });
  }


  return (
    <div class='wrapper gray-background'>
      <Nav />

      <div class='main-body invisible'>
        <HeaderMain title="Profile" />
        <div class='main-body-content profile'>

          <ProfileSubNav />

          <ProfileImage
            avatarUrl={avatarUrl}
            updateField={updateField}
          />

          <ProfileInput
            id={'first-name-field'}
            name={'first_name'}
            label={'First Name'}
            value={firstName}
            enableField={enableField}
            saveData={saveData}
            cancelEdit={cancelEdit}
            disabled={true}
          />

          <ProfileInput
            id={'last-name-field'}
            name={'last_name'}
            label={'Last Name'}
            value={lastName}
            enableField={enableField}
            saveData={saveData}
            cancelEdit={cancelEdit}
            disabled={true}
          />

          <ProfileInput
            id={'email-field'}
            name={'email'}
            label={'Email'}
            value={email}
            enableField={enableField}
            saveData={saveData}
            cancelEdit={cancelEdit}
            disabled={true}
          />

          <ProfileSelect
            id={'country-field'}
            name={'country'}
            label={'Country'}
            value={country}
            options={countryList}
            enableField={enableField}
            saveData={saveData}
            cancelEdit={cancelEdit}
            disabled={true}
          />

          <ProfileSelect
            id={'native-language-field'}
            name={'native_language'}
            label={'Native Language'}
            value={nativeLanguage}
            options={languageCodes}
            enableField={enableField}
            saveData={saveData}
            cancelEdit={cancelEdit}
            disabled={true}
          />

          <ProfileSelect
            id={'language-level-field'}
            name={'language_level_id'}
            label={'English Speaking Level'}
            value={languageLevelId}
            options={languageLevels}
            enableField={enableField}
            saveData={saveData}
            cancelEdit={cancelEdit}
            disabled={true}
          />

          <div class='profile-extras'>
            <Link to='#' onClick={() => confirmPasswordReset()}>Reset your password</Link>
            <Link to='/help'>Send Feedback</Link>
            <Link to="/logout" title='Logout' onClick={logout}>Logout</Link>
          </div>

        </div>
      </div>
      <BottomNav />
    </div>
  )
}

export default Profile;