
const chatDates = [
  {
    date: '2022-03-03',
    month: 'March',
    day: 'Thursday',
    numeral: '03',
    time: '12:00 - 2:00 pm pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-03-02T12:00:00-08:00',
    endsAt: '2022-03-02T14:00:00-08:00'
  },
  {
    date: '2022-03-03',
    month: 'March',
    day: 'Thursday',
    numeral: '03',
    time: '2:00 - 2:30 pm pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-03-02T22:00:00-08:00',
    endsAt: '2022-03-02T22:30:00-08:00'
  },
  {
    date: '2022-03-08',
    month: 'March',
    day: 'Tuesday',
    numeral: '08',
    time: '10:00 - 10:30 am pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-03-08T10:00:00-08:00',
    endsAt: '2022-03-08T10:30:00-08:00'
  },
  {
    date: '2022-03-11',
    month: 'March',
    day: 'Friday',
    numeral: '11',
    time: '4:00 - 4:30 pm pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-03-11T16:00:00-08:00',
    endsAt: '2022-03-11T16:30:00-08:00'
  },
  {
    date: '2022-03-15',
    month: 'March',
    day: 'Tuesday',
    numeral: '15',
    time: '10:00 - 10:30 am pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-03-15T10:00:00-07:00',
    endsAt: '2022-03-15T10:30:00-07:00'
  },
  {
    date: '2022-03-18',
    month: 'March',
    day: 'Friday',
    numeral: '18',
    time: '4:00 - 4:30 pm pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-03-18T16:00:00-07:00',
    endsAt: '2022-03-18T16:30:00-07:00'
  },
  {
    date: '2022-03-22',
    month: 'March',
    day: 'Tuesday',
    numeral: '22',
    time: '10:00 - 10:30 am pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-03-22T10:00:00-07:00',
    endsAt: '2022-03-22T10:30:00-07:00'
  },
  {
    date: '2022-03-25',
    month: 'March',
    day: 'Friday',
    numeral: '25',
    time: '4:00 - 4:30 pm pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-03-25T16:00:00-07:00',
    endsAt: '2022-03-25T16:30:00-07:00'
  },
  {
    date: '2022-04-05',
    month: 'April',
    day: 'Tuesday',
    numeral: '05',
    time: '10:00 - 10:30 am pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-04-05T10:00:00-07:00',
    endsAt: '2022-04-05T10:30:00-07:00'
  },
  {
    date: '2022-04-08',
    month: 'April',
    day: 'Friday',
    numeral: '08',
    time: '4:00 - 4:30 pm pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-04-08T16:00:00-07:00',
    endsAt: '2022-04-08T16:30:00-07:00'
  },
  {
    date: '2022-04-12',
    month: 'April',
    day: 'Tuesday',
    numeral: '12',
    time: '10:00 - 10:30 am pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-04-12T10:00:00-07:00',
    endsAt: '2022-04-12T10:30:00-07:00'
  },
  {
    date: '2022-04-15',
    month: 'April',
    day: 'Friday',
    numeral: '15',
    time: '4:00 - 4:30 pm pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-04-15T16:00:00-07:00',
    endsAt: '2022-04-15T16:30:00-07:00'
  },
  {
    date: '2022-04-19',
    month: 'April',
    day: 'Tuesday',
    numeral: '19',
    time: '10:00 - 10:30 am pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-04-19T10:00:00-07:00',
    endsAt: '2022-04-19T10:30:00-07:00'
  },
  {
    date: '2022-04-22',
    month: 'April',
    day: 'Friday',
    numeral: '22',
    time: '4:00 - 4:30 pm pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-04-22T16:00:00-07:00',
    endsAt: '2022-04-22T16:30:00-07:00'
  },
  {
    date: '2022-04-26',
    month: 'April',
    day: 'Tuesday',
    numeral: '26',
    time: '10:00 - 10:30 am pst',
    link: 'https://whereby.com/flow-speak',
    startsAt: '2022-04-26T10:00:00-07:00',
    endsAt: '2022-04-26T10:30:00-07:00'
  },
  {
    date: '2022-04-29',
    month: 'April',
    day: 'Friday',
    numeral: '29',
    time: '4:00 - 4:30 pm pst',
    link: 'https://zoom.us/j/3790077845?pwd=QlZCbmM0KzA0UmhaNlRzcThONjJPZz09',
    startsAt: '2022-04-29T16:00:00-07:00',
    endsAt: '2022-04-29T16:30:00-07:00'

  },
];


export const chatDatesArray = chatDates;