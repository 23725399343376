import React from 'react';
import '../../styles/start.css';


function StartHeader(props) {

  // Define variables
  const orgLogo = props.orgLogo;
  const hideTagline = props.hideTagline;
  let startHeaderClass = 'start-header';


  // Screen components
  let orgLogoElement;
  if( orgLogo ) {

    startHeaderClass = 'start-header with-org';
    let logoClass = 'org-logo';

    // Hack
  if( orgLogo.includes('simpul')) {
    logoClass = 'org-logo simpul-logo';
  }

    orgLogoElement = (
      <div
        class={logoClass}
        style={{
          backgroundImage: "url(" + orgLogo + ")",
        }}>
      </div>
    );
  }

  let taglineElement;
  if( !hideTagline ) {
    taglineElement = <h1>Speak English Like A Pro</h1>;
  }

  return (
    <div class={startHeaderClass}>
      <div class='logo-container'>
        <div class='logo'></div>
        {orgLogoElement}
      </div>
      {taglineElement}
    </div>
  );
}

export default StartHeader;