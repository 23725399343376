import React from 'react';
import ReactHtmlParser from 'react-html-parser';

function RenderHtmlText (props) {

  return (
    <span>{ ReactHtmlParser(props.string) }</span>
  );
};

export default RenderHtmlText;