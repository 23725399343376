import React from 'react';
import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import StartLeftColumn from '../start/startLeftColumn';
import UnauthedStartHeader from '../start/unauthedStartHeader';


function OrgLanding() {

  // Define variables
  const { slug } = useParams();


  // Page load actions
  useEffect(() => {

    // Specific tracking for onboarding steps
    const mixpanelParams = { 'registerType': slug ? 'orgRegister' : 'regularRegister' };
    Mixpanel.track('Onboarding: Complete', mixpanelParams);

  }, []);


  return(
    <div class='wrapper'>
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <div class='welcome-mic-test-container'>
                <UnauthedStartHeader
                  hideTitle={true}
                  subTitle={' '}
                />

                <h3 style={{
                  marginTop: '90px',
                  marginBottom: '30px'
                }}>You're All Set!</h3>

                <p class='mb-2'>Download the app</p>
                <div class='flex-row'>
                  <a
                    href='https://apps.apple.com/us/app/flow-speak/id1639740192'
                    target='_blank'
                    title='Download the app on the App Store'
                    rel='noreferrer'
                  >
                    <img
                      src='https://bucket.mailersendapp.com/0r83ql3eyx4zw1jm/x2p03471o94zdrn7/images/978a81d4-c068-441a-aa4c-b61144eb1199.png'
                      alt='App Store Logo'
                    />
                  </a>
                  &nbsp;&nbsp;&nbsp;

                  <a
                    href='https://play.google.com/store/apps/details?id=com.flow_app&hl=en_US&gl=US&utm_source=webapp'
                    target='_blank'
                    title='Download the app on the Google Play Store'
                    rel='noreferrer'
                  >
                    <img
                      src='https://bucket.mailersendapp.com/0r83ql3eyx4zw1jm/x2p03471o94zdrn7/images/978a81d5-e2c0-409e-93c8-6fd044211bf4.png'
                      alt='App Store Logo'
                    />
                  </a>
                </div>

                <br /><br />
                <Link to='/dashboard'>or use FLOW in your browser</Link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div class='wrapper'>
    //   <div class='main-body start'>
    //     <div class='main-body-content start'>
    //       <StartHeader />
    //       <div class='flex-column'>
    //         <h3 class='mt-3 mb-6'>You're All Set!</h3>

    //         <p class='mb-2'>Download the app</p>
    //         <div style={{maxWidth: '220px', marginBottom: '100px'}}>
    //           <div class='mb-2'>
    //             <a href='https://apps.apple.com/us/app/flow-speak/id1639740192' target='_blank' title='Download the app on the App Store' rel='noreferrer'>
    //               <img src='https://bucket.mailersendapp.com/0r83ql3eyx4zw1jm/x2p03471o94zdrn7/images/978a81d4-c068-441a-aa4c-b61144eb1199.png' alt='App Store Logo' />
    //             </a>
    //           </div>
    //           <a href='https://play.google.com/store/apps/details?id=com.flow_app&hl=en_US&gl=US&utm_source=webapp' target='_blank' title='Download the app on the Google Play Store' rel='noreferrer'>
    //             <img src='https://bucket.mailersendapp.com/0r83ql3eyx4zw1jm/x2p03471o94zdrn7/images/978a81d5-e2c0-409e-93c8-6fd044211bf4.png' alt='App Store Logo' />
    //           </a>
    //         </div>


    //         <Link to='/dashboard'>or use FLOW in your browser</Link>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default OrgLanding;