import React from "react";

function ButtonSubmitRound (props) {

  const buttonId = props.id || `button-${Date.now()}`;
  const value = props.value || 'Submit';
  const handleButtonClick = props.handleButtonClick || undefined;
  let disabled = props.disabled || false;
  const loading = props.loading || false;
  const title = props.title || `Tap to ${value}`;
  // TO DO - add color, size

  let buttonClass = 'button submit-button-round';
  if( loading ) {
    buttonClass = buttonClass + ' is-loading';
    disabled = true;
  }


  return (
    <button
      id={buttonId}
      type="submit"
      class={buttonClass}
      value={value}
      onClick={handleButtonClick}
      title={title}
      disabled={disabled}
    >{value}</button>
  );
}

export default ButtonSubmitRound;