function Badge(props) {

  // Define variables
  const badge = props.badge;
  const achieved = props.achieved;
  const isGrayscale = achieved ? false : true;
  const isThumbnail = props.isThumbnail || false;
  const thumbnailSize = props.thumbnailSize || 30;
  const badgeClassName = isGrayscale ? 'badge-image grayscale' : 'badge-image';
  let imageSize = props.imageSize || 50;

  let imageUrl;
  if( isThumbnail ) {
    imageUrl = isGrayscale ? badge.thumbnailUrlGrayscale : badge.thumbnailUrl;
    imageSize = thumbnailSize;
  } else {
    imageUrl = isGrayscale ? badge.imageUrlGrayscale : badge.imageUrl;
  }


  return (
    <img
      src={imageUrl}
      width={imageSize}
      height={imageSize}
      class={badgeClassName}
      alt={`${badge.name} badge`}
    />
  );
}

export default Badge;