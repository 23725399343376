import React from 'react';
import { Link } from 'react-router-dom';

import '../../../styles/lessonCard.css';
import generateLessonLinkPath from '../../../functions/generateLessonLinkPath';

const defaultImageUrl = 'https://images.pexels.com/photos/7130552/pexels-photo-7130552.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';


function LessonCardBig(props) {

  // Define variables
  const lesson = props.lesson;
  const lessonType = lesson.lessonType;
  const level = lesson.level;
  const displayLessonType = lesson.lessonType ? lesson.lessonType.replace('ListeningLesson', 'Listening') : null;
  const thumbnailUrl = lesson.thumbnailUrl || defaultImageUrl;
  const linkPath = generateLessonLinkPath(lessonType, lesson.id);


  return (
    <div class='lesson-card-wrapper lesson-card-big'>

      <Link to={linkPath} class={'lesson-card-details-container'}>

        <div
          class='lesson-card-image'
          style={{
            backgroundImage: "url(" + thumbnailUrl + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}>
        </div>

        <div class='lesson-card-content'>
          <div class='lesson-card-type-container'>
            <p class='display-lesson-type'>{displayLessonType}</p>
          </div>
          <h6>{lesson.title}</h6>
          <p><i>{level}, {lesson.duration}</i></p>
        </div>

      </Link>

    </div>
  );
}

export default LessonCardBig;
