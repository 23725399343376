const initialState = {
  isInitialized: false,
  id: null,
  isSaved: false,
  isIelts: false,
  lessonData: {
    learnerHasAccessToLesson: true,
    iconUrl: 'https://files.flowspeak.io/files/default-avatar.png',
    title: 'Loading ...',
    introductionText: 'Loading ...',
    phrases: [],
    everyMessageAddonResponderText: '',
    learnerSpeakingTurns: 3,
    relatedLessons: []
  },
  messages: [],
  responses: [],
  exerciseId: null
}


export function BoosterReducer(state = initialState, action) {

  if( action.type === 'setBooster') {
    return {
      ...state,
      isInitialized: true,
      id: action.payload.id,
      isSaved: action.payload.isSaved,
      isIelts: action.payload.isIelts,
      lessonData: action.payload.lessonData,
      messages: action.payload.messages
    };
  }

  if( action.type === 'addMessageBooster') {
    return {
      ...state,
      messages: [...state.messages, action.payload]
    };
  }

  if( action.type === 'addResponseBooster') {
    return {
      ...state,
      responses: [...state.responses, action.payload]
    };
  }

  if( action.type === 'setIsSavedBooster') {
    return {
      ...state,
      isSaved: action.payload
    };
  }

  if( action.type === 'setExerciseIdBooster') {
    return {
      ...state,
      exerciseId: action.payload
    };
  }

  if( action.type === 'resetBooster') {
    return {
      ...state,
      id: initialState.id,
      lessonData: initialState.lessonData,
      isSaved: initialState.isSaved,
      isInitialized: initialState.isInitialized,
      messages: initialState.messages,
      responses: initialState.responses
    };
  }

  return state;
}