import React from "react";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import '../../styles/packages.css';

import ChoosePackage from "./choosepackage";
import PurchaseSteps from "./purchasesteps";
import CancelLink from "./cancellink";

import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import LoadingBasic from "../utilities/loadingbasic";
import fadeInMainBody from "../../functions/fadeinmainbody";

import { checkLearnerHasPremiumSubscription, getStripePortalUrl } from "../utilities/auth/authApi";


function Packages() {

  // Define variables
  const [loading, setLoading] = useState(<LoadingBasic />);
  const [pageBody, setPageBody] = useState(null);


  // Page load actions
  useEffect(() => {

    fadeInMainBody();

    let pageBodyContent;

    checkLearnerHasPremiumSubscription().then((response) => {

      const subscriptionID = response.data.subscriptionId;

      if( response && response.data && response.data.learnerHasPremiumSubscription && subscriptionID !== 13 && subscriptionID !== 14 ) {

        const subscriptionStripeId = response.data.subscriptionStripeId;
        let manageButton;
        if( subscriptionStripeId ) {
          manageButton = <button type='button' class='button action-button' onClick={openCustomerPortal}>Manage Subscription</button>;
        }

        pageBodyContent = (
          <div class=''>
            <p class='mb-5'>
              You have an active subscription. With your Premium membership subscription, you get:
            </p>
            <ul style={{marginLeft: '30px', listStyle: 'disc'}}>
              <li>Unlimited access to all Premium lessons</li>
              <li>Access to Premium courses</li>
              <li>Access to IELTS speaking prep courses</li>
              <li>Advanced speech scoring</li>
              <li>Professional feedback</li>
              <li>Save your favorite lessons</li>
            </ul>
            <br /><br /><br />
            {manageButton}
            {/* <br /><br /><br /><br /><br /><br /><br /> */}
            {/* <Link to='/' class='back-link' title='Back to the Dashboard'>
              Back
            </Link> */}
          </div>
        );
      } else {

        let trailElement;
        if( subscriptionID === 13 || subscriptionID === 14 ) {
          trailElement = (
            <div class='flex-column mt-5 mb-5'>
              <div class='flex-column' style={{
                padding: '15px',
                border: '1px solid #bf1a84',
                borderRadius: '10px'
              }}>
                <h3 class='has-text-centered' style={{color: '#bf1a84'}}>You currently have a free trial Membership</h3>
                <p class='has-text-centered'>Get a Premium Membership to keep improving!</p>
              </div>
            </div>
          );
        }

        pageBodyContent = (
          <div>
            <PurchaseSteps currentStep={'package'} />
            {trailElement}
            <ChoosePackage packageFeaturesPlacement={'bottom'} />
            <CancelLink />
          </div>
        );
      }
    }).finally(() => {
      setLoading(null);
      setPageBody(pageBodyContent);
    });

  // });
  }, []);


  // Define functions
  function openCustomerPortal() {

    const button = document.getElementsByClassName('action-button')[0];
    if( button ) {
      button.classList.add('is-loading');
      button.disabled = true;
    }

    getStripePortalUrl().then((response) => {
      if( response && response.data && response.data.url ) {
        window.location.href = response.data.url;
      } else {
        // Handle error
      }

    }).finally(() => {
      if( button ) {
        button.classList.remove('is-loading');
        button.disabled = false;
      }
    });
  }


  return (
    <div class='wrapper special'>
      <Nav />
      <div class='main-body invisible'>
        <HeaderMain title="Premium Membership" />
        <div class='main-body-content packages'>
          {loading}
          {pageBody}
        </div>
      </div>
    </div>
  );
};

export default Packages;