import React from 'react';
import '../../../styles/scoredonut.css';


function ScoreDonut(props) {

  // Define variables
  const isIelts = props.isIelts || false;
  const overallScorePercent = props.overallScorePercent;
  const overallScoreWord = props.overallScoreWord;
  let scorePercent = props.scorePercent;

  const colorExcellent = '#BC0181';
  const colorGood = '#7E0278';
  const colorOk = '#3F036E';

  let color = colorExcellent;
  let donut;


  if( isIelts ) {
    scorePercent = ((scorePercent / 9) * 100).toFixed();
  } else {
    if( scorePercent === 0 ) { scorePercent = 1 }
  }


  if( overallScorePercent ) {

    if( overallScoreWord === 'Good' ) {
      color = colorGood;
    } else if( overallScoreWord === 'Ok' ) {
      color = colorOk;
    }

    donut = (
      <div
        class='pie'
        style={{
          '--p': overallScorePercent,
          '--c': color
        }}>
      </div>
    );
  }


  if( scorePercent ) {

    const scoreText = scorePercent;
    const score = scorePercent;

    if( score >= 80 ) {
      // Use the default color
    } else if ( score >= 55 ) {
      color = colorGood;
    // } else if ( score >= 45 ) {
    } else {
      color = colorOk;
    }

    donut = (
      <div
        class='pie animate'
        style={{
          '--p': scoreText,
          '--c': color
        }}>
      </div>
    );
  }

  let donutHoleElement;
  if( scorePercent || overallScorePercent ) {
    donutHoleElement = <div class='donut-hole'></div>;
  }


  return (
    <span class='score-donut-wrapper'>
      <div class='score-donut'>
        {donut}
      </div>
      {donutHoleElement}
    </span>
  );
}

export default ScoreDonut;