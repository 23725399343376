import React from "react";
import { useParams } from "react-router-dom";

import PurchaseSummary from "../packages/purchasesummary";


function WelcomeCompleteSuccess(props) {

  // Define variables
  const paymentDetails = props.paymentDetails;
  const { slug } = useParams();

  let nextStepPath = '/welcome-mic-test';
  // let nextStepPath = '/welcome-choose-course';
  if( slug ) { nextStepPath = `/register/${slug}/complete` }


  return (
    <div class='payment-complete-success-wrapper'>

      <h3>Thank you for becoming a <span class='nowrap'>Premium Member!</span></h3>

      <p>Nice job on committing to your learning. &#x1F525;</p>

      <p>Remember to do one lesson every day <br />to keep improving.  &#x1F680;</p>
      <br />

      <PurchaseSummary packageData={paymentDetails} noDisclaimer={true} />

      {/* <div class='action-button-wrapper'>
        <a href={nextStepPath} class='button action-button'>Continue</a>
      </div> */}

      <div class='flex-column' style={{marginTop: '30px'}}>
        <a
          href={nextStepPath}
          class='button submit-button-round all-set-button'
        >Continue</a>
      </div>

    </div>
  );
};

export default WelcomeCompleteSuccess;