import React from "react";
import { useEffect } from "react";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';


function OrgContactPhoneNumber(props) {

  // Define variables
  const indonesiaUniversity = props.indonesiaUniversity;
  const registerType = props.registerType;
  const allowOrganizationContactByPhoneNumber = props.allowOrganizationContactByPhoneNumber;
  const setAllowOrganizationContactByPhoneNumber = props.setAllowOrganizationContactByPhoneNumber;
  const setCountryCodeOrgContact = props.setCountryCodeOrgContact;
  const setPhoneNumberOrgContact = props.setPhoneNumberOrgContact;
  const phoneNumberMessage = props.phoneNumberMessage;
  const phoneNumber = props.phoneNumber;
  const countryCode = props.countryCode;
  const fullNumber = countryCode + phoneNumber;


  // Component load actions
  useEffect(() => {
    setPhoneNumberOrgContact(phoneNumber);
    setCountryCodeOrgContact(countryCode);
  }, [allowOrganizationContactByPhoneNumber, phoneNumber, countryCode]);


  // Define functions
  function setValue(value, country) {
    const countryCode = country.dialCode;
    const phone = value.replace(countryCode, '');

    setCountryCodeOrgContact(countryCode);
    setPhoneNumberOrgContact(phone);
  }


  // Screen components
  let phoneInputElement;
  if( allowOrganizationContactByPhoneNumber && registerType === 'email' ) {

    if( indonesiaUniversity ) {

      phoneInputElement = (
        <div class='org-contact-phone-container'>
          <PhoneInput
            value={fullNumber}
            onChange={(value, country) => setValue(value, country)}
            inputClass={'input'}
            onlyCountries={['id']}
            countryCodeEditable={false}
          />
        </div>
      );

    } else {

      phoneInputElement = (
        <div class='org-contact-phone-container'>
          <PhoneInput
            value={fullNumber}
            onChange={(value, country) => setValue(value, country)}
            enableSearch={true}
            inputClass={'input'}
          />
        </div>
      );

    }
  }


  return (
    <div class='org-contact-container'>
      <p>{phoneNumberMessage}</p>

      <br />

      <input
        type='checkbox'
        id='phoneNumberMessage'
        checked={allowOrganizationContactByPhoneNumber}
        onChange={(event) => setAllowOrganizationContactByPhoneNumber(event.target.checked)}
        style={{
          width: 'initial',
          height: 'initial',
          margin: '0px'
        }}
      />

      <label for='phoneNumberMessage'>&nbsp;&nbsp;Yes, share my phone number</label>
      {phoneInputElement}
    </div>
  );
}

export default OrgContactPhoneNumber;