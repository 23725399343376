import React from "react";

function QuestionVideo (props) {

  const questionId = Date.now();
  const content = props.content;
  const className = "question-wrapper video";
  const videoId = content.videoId; // TO DO - make switch statement based on ID param and video source ( youtube, vimeo, etc )
  // const source = "https://www.youtube.com/embed/" + videoId + " +?playlist=" + videoId + "&loop=1&controls=0";
  // const source = `https://www.youtube.com/embed/${videoId}?playlist=${videoId}&loop=1&controls=0`;
  const source = `https://www.youtube-nocookie.com/embed/${videoId}?playlist=${videoId}&loop=1&controls=0`;
  // const image = (imageUrl) ? <img src={imageUrl} /> : null;

  return (
    <div class={className} key={questionId}>
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <iframe src={source} title="YouTube Video" rel="0" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionVideo;