import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

let SentryComponent;

if( process.env.NODE_ENV === 'production' ){
  SentryComponent = Sentry.init({
    dsn: "https://edb40964c09a4d56854da59d0af8bf77@o956627.ingest.sentry.io/5905983",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: .5,
  });
};


export default SentryComponent;




export const reportError = (error) => {
  if( process.env.NODE_ENV === 'production' ){
    Sentry.captureException(error);
  } else {
    console.log(' ');
    console.log('Report Error: ', error);
    console.log(' ');
  }
}


export const reportMessage = (message) => {
  Sentry.captureMessage(message);
}