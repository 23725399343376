import React from "react";
import CommunityContent from "./communitycontent";


function CommunityContentGroup (props) {

  return (
    <div>
      {
        props.group.contents.map((content, index) => (
          <CommunityContent key={`index-community-item-${index}`} content={content} />
        ))
      }
    </div>
  );
};


export default CommunityContentGroup;