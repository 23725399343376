import React from "react";
import { useSelector } from "react-redux";

import '../../styles/progress.css';

import BottomNav from "../utilities/bottomnav";
import ProgressSubNav from "./progresssubnav";
import ProgressHeader from "./progressHeader";
import ScoreDetails from "./scoreDetails";
import AllTimeStats from "./alltimestats";
import ScoreLineGraph from "./scorelinegraph";
import ScoreScatterPlot from "./scorescatterplot";
import LessonCompletedLineGraph from "./lessonscompletedlinegraph";


function ProgressStats() {

  // Define variables
  const profile = useSelector((state) => state.profile);
  const publicProfileId = profile.publicProfileId;
  const confidenceLevel = profile?.score ? `${profile.score.score} Confidence Level` : '';

  const profileData = {
    avatarUrl: profile.avatarUrl,
    firstName: profile.firstName,
    flowScore: confidenceLevel,
    countryCode: profile.country,
  };


  return (
    <div class='flex-column wrapper progress-screen'>
      <div class='main-container'>

        <ProgressHeader
          profileData={profileData}
          isThisLearner={true}
        />

        <ProgressSubNav />

        <div class={`main-body with-sub-nav`}>
          <div class='main-body-content'>

            <div class='stat-container'>
              <h3>Confidence Level</h3>
              <ScoreDetails profile={profile} />
            </div>
            <AllTimeStats publicProfileId={publicProfileId} />
            <ScoreLineGraph publicProfileId={publicProfileId} />
            <ScoreScatterPlot publicProfileId={publicProfileId} />
            <LessonCompletedLineGraph publicProfileId={publicProfileId} />
            {/* <SpeakingScoresLineGraph publicProfileId={publicProfileId} /> */}

          </div>
        </div>
        <BottomNav />
      </div>
    </div>
  );
}

export default ProgressStats;