import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';

import { appBaseUrl } from "../utilities/stripe/stripeHelpers.js";

import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";

// https://stripe.com/docs/testing#cards
// CVC error -> 4000000000000101
// card decliend by bank -> 4000000000000341


function CheckoutForm (props) {

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const actionButton = document.getElementsByClassName('action-button')[0];
    const returnPath = props.returnPath ? props.returnPath : "/packages/complete";
    const returnUrl = appBaseUrl + returnPath;

    if( actionButton ) {
      actionButton.classList.add('is-loading');
      actionButton.disabled = true;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });


    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(<div class='payment-error-message'>{error.message}</div>);
      // toast.error("We're sorry, there was an error. " + error.message);

      if( actionButton ) {
        actionButton.classList.remove('is-loading');
        actionButton.disabled = false;
      }
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };



  return (
    <div>
      <form class='package-form' onSubmit={handleSubmit}>
        <PaymentElement />

        {/* Show error message to your customers */}
        <div class='payment-error-message-wrapper'>
          {errorMessage}
        </div>

        {/* <div class='action-button-wrapper'>
          <button class='button action-button' disabled={!stripe}>Submit</button>
        </div> */}
        <div class='flex-column'>
          <ButtonSubmitRound
            value={'Submit'}
            disabled={!stripe}
          />
        </div>

      </form>

    </div>
  );
};

export default CheckoutForm;