import React from "react";
import { useState } from 'react';

import ListeningSpinner from "../utilities/listeningspinner";


function RecordBasicAudio(props) {

  // Listening status: waiting, listening, complete
  const [recordStatus, setRecordStatus] = useState('waiting');


  function handleClick() {

    switch (recordStatus) {
      case 'listening':
        stopRecording();
        break;
      case 'complete':
        playAudio();
        break;
      default:
        // waiting
        initalizeStream();
        break;
    }

  } // ----------------------------


  function initalizeStream() {

    // Iniatiate the stream
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    .then(initalizeRecorder)
    .then(startRecording)
    .catch((error) => {
      console.log('Unable to initalize the media recorder object');
      console.log(error);
    });

  } // ----------------------------


  const initalizeRecorder = function(stream) {

    const options = {mimeType: 'audio/webm'};
    let recordedChunks = [];
    // const mediaRecorder = new MediaRecorder(stream, options);
    mediaRecorder = new MediaRecorder(stream, options);

    // When the recording starts
    mediaRecorder.addEventListener('start', function() {
      setRecordStatus('listening');
    });

    // Push data into the recordedChunks Array
    mediaRecorder.addEventListener('dataavailable', function(e) {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    });

    mediaRecorder.addEventListener('stop', function() {

      // Close the recording
      if (mediaRecorder) {
        stream.getAudioTracks().forEach(track => {
          track.stop();
        });
      }

      const audioData = recordedChunks;

      // Convert saved chunks to blob
      const blob = new Blob(audioData, {type: 'audio/wav'});

      // Generate audio url from blob
      // const audioUrl = window.URL.createObjectURL(blob);
      audioUrl = window.URL.createObjectURL(blob);

      // Set the recording status
      setRecordStatus('complete');

      // Send the recording back
      props.handleAudioRecording(audioUrl, blob);

    });

    // Clear the recording
    // It's ok to go ahead and clear because we will want to clear no matter
    // the outcome of the translation funciton
    recordedChunks = [];

  } // ----------------------------


  function startRecording() {
    mediaRecorder.start();
    clearTimeout(timeout);
    timeout = setTimeout(() => mediaRecorder.stop(), 40000);
  } // ----------------------------


  function stopRecording() {
    mediaRecorder.stop();
  } // ----------------------------


  function removeAudio() {
    props.removeAudio();
    setRecordStatus('waiting');
  } // ----------------------------


  function playAudio() {
    const audioPlayerElement = document.getElementById('post-audio-player');

    if( audioPlayerElement ) {
      audioPlayerElement.play().catch((e) => {
        // Catch error because this method won't work on mobile safari
      });
    }
  } // ----------------------------


  let instructions = <p>Tap to add audio</p>;
  let audioPlayer;
  let remove;
  const recordIconClass = ( props.darkMode ) ? 'dark' : '';

  let recordIcon;
  if( recordStatus === 'waiting') { recordIcon = <div id='record-icon' class={recordIconClass}></div> }

  if( recordStatus === 'complete') {
    recordIcon =  <div class='icon icon-audio left dark'></div>;
    instructions = <p>Audio added</p>;
    remove = <span class='remove-audio' onClick={removeAudio}>- remove</span>;
    audioPlayer = (
      <audio id='post-audio-player'>
        <source src={audioUrl} type="audio/mpeg" />
      </audio>
    );
  }

  let listeningSpinner;
  if( recordStatus === 'listening') {
    listeningSpinner = <ListeningSpinner />;
    instructions = <p>Tap to <u>stop</u> recording</p>;
  }


  return (
    <div class='post-record-wrapper'>
      <div class='post-record-audio-button' onClick={handleClick}>
        {recordIcon}
        {audioPlayer}
        {listeningSpinner}
        {instructions}
      </div>
      {remove}
    </div>
  );
}

let timeout;
let mediaRecorder;
let audioUrl;

export default RecordBasicAudio;