import React from "react";
import findWord from '../../functions/findword.js';
import translateWord from '../../functions/translateword';
import { Mixpanel } from "./analytics/mixpanelHelpers.jsx";


class LongPressLookupElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDefinitionModal: true
    };
  }

  longPress(event) {

    let timer = 0;

    const target = localStorage.getItem("nativeLanguageCode");

    if( !target ) { return };

    interval = setInterval(function(){
      timer = timer + 100;

      if( timer === 300 ) {
        clearInterval(interval);

        const word = findWord();

        translateWord(word, target)
        .then((response) => {

          const translation = response.translation;

          let clientX = event.clientX || event.touches[0].clientX;
          let clientY = event.clientY || (event.touches[0].clientY - 30);

          showDefinition(translation, clientX, clientY);

          // Log the event
          Mixpanel.track('Event: Translation lookup', {'translation': response.translation});

        }).catch((error) => {
          console.log('Translation Error: ' + error);
          // Log the event
          Mixpanel.track('Event: Translation lookup Error');
        });
      }

    }, 100);

  }

  closeDefinition() {
    clearInterval(interval);

    [...document.getElementsByClassName("definition-modal")].forEach(
      (element, index, array) => {
        element.remove();
      }
    );
  }


  render() {

    const classList = this.props.classList;
    const content = this.props.content;
    const key = this.props.content;

    return (
      <p class={classList} onMouseDown={this.longPress} onMouseUp={this.closeDefinition} onMouseMove={this.closeDefinition} key={key}>{content}</p>
    );
  }
}

let interval;

function showDefinition(translation, clientX, clientY) {

  const div = document.createElement('div');
  const text = document.createTextNode(translation);
  const top = `${clientY - 75}px`;
  const left = `${clientX - 60}px`;

  div.appendChild(text);
  div.classList.add('definition-modal');
  div.style.position = 'absolute';
  div.style.top = top;
  div.style.left = left;

  const body = document.getElementsByTagName("body")[0];
  body.appendChild(div);

}

export default LongPressLookupElement;