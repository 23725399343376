function ExploreBlankState() {

  return (
    <div >
      <h3 style={{color: '#BC0181'}}>No lessons found</h3>
      <p>Please try searching again</p>
    </div>
  );
}

export default ExploreBlankState