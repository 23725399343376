import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import PlayAudioIcon from "../../utilities/playaudioicon";
import RecordResponse from "../../utilities/recordresponse";
import ExpressionTranscript from '../expressionTranscript';
import ExpressionScore from '../expressionScore';
import RecordUiExpression from '../../utilities/recordUIExpression';
import RecordUiExpressionRetryLimit from '../../utilities/recordUIExpressionRetryLimit';

const defaultRetryLimit = 20;


function ExpressionSentence(props) {

  // Define variables
  const isIelts = useSelector((state) => state.expression.isIelts);
  const [recordStatus, setRecordStatus] = useState('waiting'); // waiting, done
  const [displayTranscript, setDisplayTranscript] = useState(null);
  const [displayScore, setDisplayScore] = useState(null);
  const [responseObject, setResponseObject] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [audioElement, setAudioElement] = useState(null);
  const [doneButtonDisabled, setDoneButtonDisabled] = useState(false);

  const addResponse = props.addResponse;
  const nextStep = props.nextStep;
  const currentStep = props.currentStep;
  const expression = props.expression;
  const isLastScreen = props.isLastScreen;
  const completeLesson = props.completeLesson;
  const translateToggle = props.translateToggle;
  const translations = props.translations;
  const nextScreenNumber = currentStep + 1;
  const retryLimit = expression.retryLimit || defaultRetryLimit;

  let phrase;
  let audioUrl;
  let heading = 'Repeat this sentence';
  let innerContentClass = 'expression-inner-content';

  if( currentStep === 2 ) {
    heading = 'Listen & Repeat';
    phrase = expression.title;
    audioUrl = expression.listenRepeatAudioUrl;
    innerContentClass = 'expression-inner-content step-2';
  } else {
    const sentenceNumber = currentStep - 3;
    const sentence = expression.sentences[sentenceNumber];
    phrase = sentence.phrase;
    audioUrl = sentence.audioUrl;
  }


  // Component load actions
  useEffect(() => {

    // Clear everything
    clearEverything();

    setAudioElement(<span class='image is-24x24'></span>);

    setTimeout(() => {
      if( audioUrl ) {
        setAudioElement(
          <PlayAudioIcon
            audioUrl={audioUrl}
            iconDark={true}
          />
        );
      }
    }, 200);

  }, [currentStep, audioUrl]);


  // Define functions
  function clearEverything() {
    setRecordStatus('waiting');
    setDisplayTranscript(null);
    setDisplayScore(null);
    setResponseObject(null);
    setRetryCount(0);
  }

  function tryAgain() {
    clearEverything();

    // Log the retries
    const newRetryCount = retryCount + 1;
    setRetryCount(newRetryCount);
  }

  function handleTranscript(transcript, audioBlobUrl, blob, thisResponseObject) {

    // Add the phrase to the object
    thisResponseObject.text = phrase;
    thisResponseObject.blob = blob;
    thisResponseObject.retryCount = retryCount;

    setRecordStatus('done');
    setDisplayTranscript(
      <ExpressionTranscript
        responseObject={thisResponseObject}
        audioUrl={audioBlobUrl}
        position={'right'}
      />
    );

    setDisplayScore(<ExpressionScore responseObject={thisResponseObject} isIelts={isIelts} />);
    setResponseObject(thisResponseObject);
  }

  function handleNextStepClick() {
    setRetryCount(0);
    addResponse(responseObject).then((response) => {
      nextStep(nextScreenNumber);
    });
  }

  function handleDoneClick() {
    setDoneButtonDisabled(true);
    addResponse(responseObject).then((response) => {

      completeLesson();

      // Not the best way, but works for now
      setTimeout(() => setDoneButtonDisabled(false), 1000);
    });
  }


  // Screen components
  let buttonElement;
  let recordElement;
  if( recordStatus === 'done' ) {

    buttonElement = (
      <button
        type='button'
        class='button action-button'
        onClick={() => handleNextStepClick()}
      >Next</button>
    );

    recordElement = (
      <RecordUiExpression
        recordStatus={'complete'}
        handleClick={tryAgain}
      />
    );

    if( retryCount >= retryLimit ) {
      recordElement = <RecordUiExpressionRetryLimit />;
    }

  } else {

    buttonElement = (
      <button
        type='button'
        class='button action-button'
        style={{opacity: .5}}
      >Next</button>
    );

    recordElement = (
      <RecordResponse
        handleTranscript={handleTranscript}
        providedText={phrase}
        scorePronunciation={true}
        colorScheme={'expression'}
        retryCount={retryCount}
      />
    );

  }

  if( isLastScreen ) {
    if( recordStatus === 'done' ) {
      buttonElement = (
        <button
          type='button'
          class='button action-button'
          onClick={() => handleDoneClick()}
          disabled={doneButtonDisabled}
        >Done</button>
      );
    } else {
      buttonElement = (
        <button
          type='button'
          class='button action-button'
          style={{opacity: .5}}
        >Done</button>
      );
    }
  }

  let displayPhrase = phrase;
  if( currentStep === 2 ) {
    displayPhrase = <strong>{phrase}</strong>;
  } else {

    if( translateToggle && translations ) {
      const sentenceNumber = currentStep - 3;
      const translatedPhraseObject = translations[sentenceNumber];
      if( translatedPhraseObject ) {
        displayPhrase = translatedPhraseObject.phrase;
      }
    }

  }


  return (
    <div class='expression-body-wrapper'>
      <div class='expression-body-container'>
        <div class='expression-inner-content-container'>

          <div class='expression-inner-content-head'>
            <p class='expression-simple-heading'><i>{heading}</i></p>
            <div class='flex-row' style={{justifyContent: 'center'}}>
              <span style={{marginRight: '5px'}}>
                {displayPhrase}
              </span>
              {audioElement}
            </div>
          </div>

          <div class={innerContentClass}>
            {displayTranscript}
            {displayScore}
          </div>

        </div>

        <div class='expression-record-container'>
          {recordElement}
        </div>

      </div>

      <div class='expression-button-container'>
        {buttonElement}
      </div>
    </div>
  )

}

export default ExpressionSentence;