const initialSate = {
  initialized: false,
  allLessons: [],
  displayLessons: [],
  page: 1,
  chartData: null
}

export function progressLessonsReducer(state = initialSate, action) {


  if( action.type === 'initializeProgressLessons') {
    return {
      ...state,
      initialized: true,
      allLessons: action.payload.allLessons,
      displayLessons: action.payload.displayLessons,
      chartData: action.payload.chartData,
    }
  }

  if( action.type === 'setProgressDisplayLessons') {
    return {
      ...state,
      displayLessons: action.payload
    }
  }

  if( action.type === 'setProgressPage') {
    return {
      ...state,
      page: action.payload
    }
  }


  return state;
}