import React from "react";
import '../../styles/start.css';

import alertMessage from '../utilities/alertMessage';
import { reportError } from "../../functions/sentry";
import * as api from "../utilities/auth/authApi";
import { Link } from "react-router-dom"

import UnauthedStartHeader from "./unauthedStartHeader";
import StartLeftColumn from "./startLeftColumn";
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";



class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      resetError: "",
      submitted: false,
      successMessage: null
    };

  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      resetError: ''
    });
  };

  handleFormSubmit = async event => {
    event.preventDefault();

    // Disable the submit button
    this.setState({submitted: true});

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('reset_password_token');

    const { password  } = this.state;
    if (password && token ) {
      try {
        const payload = { password, token };;

        const { data } = await api.resetpassword(payload);

        if( data.error ){

          let errorMessage = data.error;
          if( errorMessage === 'token_error' ) {
            errorMessage = (
              <div>
                Sorry, the reset password token is invalid or expired. You can visit the&nbsp;
                <u><Link to='/start#forgotpassword'>forgot password</Link></u> page or contact support@flowspeak.io.
              </div>
            );
          }

          this.setState({ resetError: errorMessage });

          // Re-enable the submit button
          setTimeout(() => {
            this.setState({submitted: false});
          }, 1000);

          // Log the error
        reportError(data.error);

        }else{
          // this.setState({ successMessage: 'Password successfully reset. You can now login.' });
          const message = <p>Password successfully reset. You can now login. <u><a href='flowspeak://start'>Open the app</a></u>.</p>
          this.setState({ successMessage: message });
          // TO DO - make a toast notification for success
        }

      } catch (error) {
        if (error.response) {
          this.setState({ resetError: error.response });

          // Re-enable the submit button
          setTimeout(() => {
            this.setState({submitted: false});
          }, 1000);
        }
      }
    } else {
      alertMessage('Sorry, there was no reset password token provided. Please contact support@flowspeak.io for assistance.', 'error')
      setTimeout(() => {
        this.setState({submitted: false});
      }, 1000);
    }
  };

  render() {

    const { password, resetError } = this.state;


    return (
      <div class='wrapper'>
        <div class='main-body start with-start-left-column'>
          <StartLeftColumn />
          <div class='main-body-content start'>
            <div class='start-vertical-wrapper'>
              <div class='start-vertical-container'>
                <UnauthedStartHeader hideTitle={true} />

                <section class='section-auth-form'>

                  <div class='flex-row content-left auth-links-container'>
                    <p class='auth-link-button'>Reset Your Password</p>
                  </div>

                  <form
                    class='signup-login-form'
                    onSubmit={this.handleFormSubmit}
                  >

                    <div class="field">
                      <label class="label">Password</label>
                      <div class="control">
                        <input
                          name="password"
                          type="password"
                          value={password}
                          onChange={this.handleInputChange}
                          minLength="8"
                          title="Password must be at least 8 characters"
                          required
                          placeholder='password'
                          class='input'
                        />
                      </div>
                    </div>

                    <div class='form-error-header form-error'>
                      {resetError}
                      &nbsp;
                      {this.state.successMessage}
                    </div>

                    <div class='flex-column' style={{marginTop: '15px'}}>
                      <ButtonSubmitRound
                        value={'Reset Password'}
                        loading={this.state.submitted}
                      />
                    </div>

                  </form>

                  <div class='login-secondary-action'>
                    Already a member? &nbsp;
                    {/* <Link to="/start#signin" title='Go to login'> */}
                    <Link to="/login" title='Go to login'>
                      Login
                    </Link>
                  </div>

                </section>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;