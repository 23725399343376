import React from "react";

function Input (props) {

  let label = '';
  if( props.label ) { label = <label class="label">{props.label}</label> }

  const id = props.id || null;
  const type = props.type || 'text';
  const name = props.name || '';
  const title = props.title || null;
  const required = props.required ? true : false;
  const preselectValue = props.preselectValue || undefined;
  const placeholder = props.placeholder || undefined;
  const handleInputChange = props.handleInputChange || undefined;
  const disabled = props.disabled || false;
  const autoComplete = props.autoComplete || 'on';


  return (
    <div class="field">
      <div class="control">
        {label}
        <div class=''>
          <input
            id={id}
            name={name}
            title={title}
            required={required}
            class='input'
            type={type}
            defaultValue={preselectValue}
            placeholder={placeholder}
            onChange={handleInputChange}
            disabled={disabled}
            autoComplete={autoComplete}
          />
        </div>
      </div>
    </div>
  );
}

export default Input;