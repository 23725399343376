function ChallengeBadgeWithNumber(props) {

  // Define variables
  const weeklyChallengesCompleted = props.number;


  // Screen components
  let subText = <p>Completed {weeklyChallengesCompleted}</p>;
  let mainText = <p><strong>Weekly {weeklyChallengesCompleted === 1 ? 'Challenge' : 'Challenges'}</strong></p>;
  let badge = <div class='icon icon-challenge-full-burgendy'></div>;

  if( weeklyChallengesCompleted < 1 ) {
    subText = <p>You haven't completed any weekly challenges yet.</p>;
    mainText = <p><strong>Start taking lessons to compete!</strong></p>;
    badge = <div class='icon icon-challenge-full-gray' style={{opacity: .65}}></div>;
  }


  return (
    <div class='flex-row' style={{alignItems: 'center', justifyContent: 'flex-start', marginTop: '15px', textAlign: 'left'}}>
      <div class='challenge-public-profile-icon'>
        {badge}
        <span>{weeklyChallengesCompleted}</span>
      </div>
      <div>
        {subText}
        {mainText}
      </div>
    </div>
  );
}

export default ChallengeBadgeWithNumber;