import React from "react";
import { Link } from "react-router-dom"


function ProgressSubNav() {
  const pathname = window.location.pathname;

  return (
    <div class='flex-column progress-subnav-wrapper'>
      <div class='flex-row progress-subnav-container'>
        <Link to='/progress' class={pathname === '/progress' ? 'active' : ''} alt='View your achievements'>Achievements</Link>
        <Link to='/progress/stats' class={pathname === '/progress/stats' ? 'active' : ''} alt='View progress stats'>Stats</Link>
        <Link to='/progress/lessons' class={pathname.includes('/progress/lessons') ? 'active' : ''} alt='View Lesson stats'>Lessons</Link>
      </div>
    </div>
  )
}

export default ProgressSubNav;