function ActivityCircle(props) {

  // Define variables
  const percent = props.percent;
  const index = props.index;
  const isThisLearner = props.isThisLearner;

  let className = 'activity-circle';
  let style = {'--opacity': percent};

  if( percent === 0 ) {
    className = 'activity-circle empty';
    style = null;
  }


  // Screen components
  let todayElement;
  if( index === 29 && isThisLearner ) {
    todayElement = (
      <div class='flex-column activity-circle-today-indicator'>
        <div></div>
        <div><span></span></div>
        Today
      </div>
    );
  }


  return (
    <div style={{position: 'relative'}}>
      <div
        key={index}
        class={className}
        style={style}
      />
      {todayElement}
    </div>
  )
}

export default ActivityCircle;