import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Mixpanel } from "../utilities/analytics/mixpanelHelpers";
import alertMessage from '../utilities/alertMessage';
import sendExerciseData from '../../functions/sendExerciseData';
import LessonCompleteModal from "../utilities/lessonCompleteModal";
import AiTalkThread from '../aitalk/aiTalkThread';
import BoosterTalkHeader from './boosterTalkHeader';


function BoosterTalk(props) {

  // Dispatch
  const dispatch = useDispatch();

  function addMessageBooster(messageObject) {
    dispatch({ type: 'addMessageBooster', payload: messageObject});
  }

  function addResponseBooster(responseObject) {
    dispatch({ type: 'addResponseBooster', payload: responseObject});
  }

  function resetBooster() {
    dispatch({ type: 'resetBooster', payload: ''});
  }

  function clearDashboardData() {
    dispatch({ type: 'clearDashboardData' });
  }


  // Define variables
  const [completeElement, setCompleteElement] = useState(null);

  const exerciseId = useSelector((state) => state.booster.exerciseId);
  const responses = useSelector((state) => state.booster.responses);
  const messages = useSelector((state) => state.booster.messages);

  const setExerciseIdBooster = props.setExerciseIdBooster;
  const id = props.id;
  const avatarUrl = props.avatarUrl;
  const booster = props.booster;
  const phrases = booster.phrases;
  const learnerHasAccessToLesson = booster.learnerHasAccessToLesson;
  const iconUrl = booster.iconUrl;
  const learnerSpeakingTurns = booster.learnerSpeakingTurns + 1;
  const everyMessageAddonResponderText = booster.everyMessageAddonResponderText;
  const extras = booster.extras;


  // Define functions
  function completeLesson() {

    // Send the responses to the server
    const payload = {
      lessonType: 'Booster',
      exerciseId: exerciseId,
      lessonId: id,
      responsesToSave: responses
    }

    sendExerciseData(payload).then((response) => {
      if( response.success ) {

        setCompleteElement(
          <LessonCompleteModal
            data={response.data}
            reset={reset}
          />
        );

        // Make the dashboard recall lesson data
        // invalidateInitialized();
        clearDashboardData();

        // Log the event
        Mixpanel.track('Event: Lesson Complete', {'Lesson ID': id, 'Lesson Type': 'Booster'});

      } else {
        alertMessage(response.error, 'error');
      }
    }).finally(() => {
      // setTimeout(() => setDoneButtonLoading('finalized'), 1200);
    });
  }

  function logStart() {
    // No action needed, just need to pass a function
  }

  function reset() {
    // Reset the lesson
    resetBooster();
    setExerciseIdBooster(null);
    // setIsWaiting(true);
    // setDoneWithMessages(false);
    // setDoneButtonLoading(false);
    setCompleteElement(null);
  }


  return (
    <>
      {completeElement}
      <AiTalkThread
        id={id}
        learnerHasAccessToLesson={learnerHasAccessToLesson}
        isInitialized={true}
        exerciseId={exerciseId}
        learnerSpeakingTurns={learnerSpeakingTurns}
        initialDisplayMessage={null}
        everyMessageAddonResponderText={everyMessageAddonResponderText}
        avatarUrl={avatarUrl}
        iconUrl={iconUrl}
        messages={messages}
        addMessage={addMessageBooster}
        addResponse={addResponseBooster}
        completeLesson={completeLesson}
        logStart={logStart}
        extras={extras}
        headerElement={<BoosterTalkHeader iconUrl={iconUrl} phrases={phrases} />}
      />
    </>
  );
}

export default BoosterTalk;