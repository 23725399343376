import React from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";

import '../../styles/progress.css';

import apiCall from '../utilities/auth/apiCall';
import { getExercise } from "../utilities/auth/authApi";
import generateLessonLinkPath from "../../functions/generateLessonLinkPath";
import ProgressHeader from "./progressHeader";
import BottomNav from "../utilities/bottomnav";
import LoadingBasic from "../utilities/loadingbasic";
import VectorIcon from "../utilities/vectorIcon";
import LessonDetailBody from "./lessonDetail/lessonDetailBody";

const initialData = {
  exerciseData: {
    completedOn: 'loading ...',
    isIelts: false,
    scores: {}
  },
  lessonData: {
    title: 'Loading...',
    lessonType: '',
    level: 'Loading ...'
  },
  responseData: []
}


function ProgressLesson() {

  // Define variables
  const { id } = useParams();
  const navigate = useNavigate();

  const [loadingElement, setLoadingElement] = useState(<LoadingBasic />);
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(false);
  const exerciseData = data.exerciseData;
  const lessonData = data.lessonData;
  const responseData = data.responseData;
  const linkPath = generateLessonLinkPath(lessonData.lessonType, lessonData.id);

  const profile = useSelector((state) => state.profile);
  const confidenceLevel = profile?.score ? `${profile.score.score} Confidence Level` : '';
  const hasPremiumSubscription = profile?.hasPremiumSubscription ? profile.hasPremiumSubscription : false;

  const profileData = {
    avatarUrl: profile.avatarUrl,
    firstName: profile.firstName,
    flowScore: confidenceLevel,
    countryCode: profile.country,
  };


  // Component load actions
  useEffect(() => {

    reset();

    if( id ) {
      apiCall(getExercise, id).then((response) => {
        if( response?.success ) {
          setTimeout(() => {
            setData(response.data);
            setLoadingElement(null);
          }, 500);
        } else {
          setLoadingElement(null);
          setError(response.error);
        }
      });
    }

  }, [id]);


  // Define functions
  function reset() {
    setLoadingElement(<LoadingBasic />);
    setData(initialData);
    setError(false);
  }


  // Screen components
  let lessonTypeDisplay = lessonData.lessonType;
  if( lessonData.lessonType === 'LearningModule'  ) { lessonTypeDisplay = 'Speaking' }
  if( lessonData.lessonType === 'ListeningLesson'  ) { lessonTypeDisplay = 'Listening' }
  if( lessonData.lessonType === 'AiTalk'  ) { lessonTypeDisplay = 'AI Talk' }


  let bodyElement;
  if( lessonData && lessonData.id && lessonData.lessonType !== 'ListeningLesson' ) {

    if( exerciseData?.scores && exerciseData.scores.score === 0 && !exerciseData.scores.pronunciationScore ) {
      bodyElement= (
        <div class='flex-column' style={{padding: '60px', flex: 1}}>
          <p>There are no responses or scores recorded for this lesson</p>
        </div>
      );
    } else {
      bodyElement= (
        <LessonDetailBody
          exerciseData={exerciseData}
          lessonData={lessonData}
          responseData={responseData}
          hasPremiumSubscription={hasPremiumSubscription}
        />
      );
    }
  }

  let errorElement;
  if( error ) {
    errorElement = (
      <div class='flex-column' style={{marginTop: '60px'}}>
        <p class='error'>{error}</p>
        <Link to={'/progress/lessons'}>Go back</Link>
      </div>
    );
  }


  return (
    <div class='flex-column wrapper progress-screen'>
      <div class='main-container'>

        <ProgressHeader
          profileData={profileData}
          isThisLearner={true}
        />

        <div class={`main-body`}>
          <div class='main-body-content'>

            <div class='flex-column lesson-detail-header'>
              <div class='flex-row lesson-detail-header-inner'>

                <button
                  class='clean-button pointer'
                  // onClick={() => navigate('/progress/lessons/')}
                  onClick={() => navigate(-1)}
                >
                  <VectorIcon icon={'chevronBackFull'} />
                </button>

                <div class='flex-column' style={{textAlign: 'center'}}>
                  <h3 style={{marginBottom: '10px'}}>{lessonData.title}</h3>
                  <p>{lessonData.level} {lessonTypeDisplay}</p>
                  <p>
                    <small>
                      <i>
                        Lesson completed on {exerciseData.completedOn}
                        <br />
                        <Link
                          to={linkPath}
                          class='flex-row retake-lesson-link'
                        >
                          Retake Lesson
                          <VectorIcon icon={'chevronForwardOutline'} size={'1rem'} />
                        </Link>
                      </i>
                    </small>
                  </p>

                </div>

                <span>&nbsp;</span>
              </div>
            </div>

            {loadingElement}
            {errorElement}
            {bodyElement}

          </div>
        </div>
        <BottomNav />
      </div>
    </div>
  )
}

export default ProgressLesson;