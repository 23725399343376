import { Link } from "react-router-dom";

import VectorIcon from "../vectorIcon";

import ScoreDonut from '../learning_module/scoredonut';
import scoreWord from '../../../functions/scoreWord';


function LessonCardAction(props) {

  // Define variables
  const complete = props.complete;
  const score = props.score;
  const exerciseId = props.exerciseId;
  const isIelts = props.isIelts;
  const ieltsScore = props.ieltsScore;
  // const hasAccessToLesson = props.hasAccessToLesson;
  const linkPath = props.linkPath;
  const scoreToUse = isIelts ? ieltsScore : score;


  // Screen components
  let actionElement;
  if( complete ) {

    let scoreElement = <div>&nbsp;</div>;

    if( score ) {
      const word = isIelts ? scoreToUse : scoreWord(score);

      scoreElement = (
        <Link
          to={`/progress/lessons/${exerciseId}`}
          class='flex-row'
          style={{alignItems: 'center'}}
          title='View score details'
        >
          {word}
          <ScoreDonut
            scorePercent={scoreToUse}
            isIelts={isIelts}
          />
        </Link>
      );
    }

    actionElement = (
      <div class='flex-row lesson-card-action-container with-border'>

        {scoreElement}

        <Link to={linkPath} class='flex-row lesson-card-retake-lesson' title='Retake lesson'>
          Retake lesson
          <VectorIcon icon={'chevronForwardOutline'} size={'15px'} />
        </Link>
      </div>
    );

  } else {
    // if( hasAccessToLesson ) {
    //   actionElement = (
    //     <div class='flex-row lesson-card-action-container with-button'>
    //       <Link to={linkPath} class='flex-row start-button-minimal' title='Start lesson'>
    //         Start Lesson
    //         <VectorIcon icon={'chevronForwardOutline'} size={'15px'} />
    //       </Link>
    //     </div>
    //   );
    // }
  }


  return (
    <>{actionElement}</>
  );
}

export default LessonCardAction;