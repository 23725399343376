import ScoreDonut from "../../utilities/learning_module/scoredonut";
import ScoreBlockRetryCount from "./scoreBlockRetryCount";


function ScoresBlockExpression(props) {

  // Define variables
  const retryCount = props.retryCount;
  const isIelts = props.isIelts
  const scores = props.scores;
  const pronunciationScore = scores.pronunciationScore;
  const ieltsPronunciation = scores.ieltsPronunciation;
  const score = isIelts ? ieltsPronunciation : pronunciationScore;


  return (
    <div class='score-block-container'>
      <div class='flex-row score-block-row'>
        <p><strong>Pronunciation</strong></p>
        <p class='flex-row'>
          {score}%
          <ScoreDonut scorePercent={score} isIelts={isIelts} />
        </p>
      </div>
      <ScoreBlockRetryCount retryCount={retryCount} />
    </div>
  );
}

export default ScoresBlockExpression;