import { Link } from "react-router-dom";
import IssueDateElement from "./issueDateElement";


function StatsCertificates(props) {

  // Define variables
  const certificates = props.certificates ? props.certificates : [];
  const isThisLearner = props.isThisLearner;


  // Screen components
  let placeholderCertificates = [];
  let placeholderTextElement
  let placeholderTextBottomElement;

  if( isThisLearner && certificates.length === 0 ) {
    placeholderCertificates = [1,2];
    placeholderTextElement = <p style={{marginTop: '-10px', marginBottom: '10px'}}>You haven't earned any Certificates yet</p>;

    placeholderTextBottomElement = (
      // <View style={[flexColumn, {marginTop: 15}]}>
      <div class='flex-column'>
        <p>Complete a Certificate Course to show your success</p>
        <Link to='/courses' style={{marginTop: 15}}>
          <p>View all Courses</p>
        </Link>
      </div>
    );
  }

  let bodyElement;
  if( isThisLearner || (!isThisLearner && certificates.length > 0) ) {
    bodyElement = (
      <div class='flex-column stat-container'>
        <h3>Certificates</h3>
        <div class='stats-certificates-wrapper'>
          {certificates.map((certificate, index) =>
            <div class='stats-certificate-container' key={index}>
              <div class='flex-column stats-certificate-detail'>
                <span class='icon icon-certificate'></span>
                <h5>{certificate.name}</h5>
                <p style={{marginTop: '10px'}}>{certificate.description}</p>
              </div>
              {isThisLearner ? <a href={certificate.downloadUrl} class='stats-certificate-download-link' target="_blank" rel="nofollow">Download Certificate</a> : null}
              <IssueDateElement issueDate={certificate.issueDate} />
            </div>
          )}

          {placeholderTextElement}
          <div class='flex-row' style={{justifyContent: 'center'}}>
            {placeholderCertificates.map((certificate, index) =>
              <div class='flex-column stats-certificate-container placeholder' key={index}>
                <div class='flex-column stats-certificate-detail'>
                  <span class='icon icon-certificate-gray'></span>
                </div>
              </div>
            )}
          </div>

        </div>

        {placeholderTextBottomElement}

      </div>
    );
  }



  return (
    <>{bodyElement}</>
  );
}


export default StatsCertificates;