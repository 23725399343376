import React, { useState } from 'react';

import InputSelect from "../utilities/forms/inputselect";
import InputTextareaBasicRichText from "../utilities/forms/inputtextareabasicrichtext";
import ButtonSubmit from "../utilities/forms/buttonsubmit";
import formSumbitPost from "../utilities/forms/formsubmitpost";
import RecordBasicAudio from "../utilities/recordbasicaudio";


function NewPost (props) {

  const [audioBlob, setAudioBlob] = useState(false);
  let formClass = 'new-post-form inactive';

  if( props.startActive ) {
    formClass = 'new-post-form';
  }

  const handleFormSubmit = async event => {
    event.preventDefault();

    const form = event.target;

    // Disable the button
    const button = form.querySelector('button[type=submit]');

    if( button ) { button.disabled = true }

    formSumbitPost(form, audioBlob).then((response) => {

      if( response ) {
        if( response.success && response.post ){
          removeAudio();
          props.handleNewPostSuccess(response.post, form, props.startActive);
        } else {
          // Handle Errors
        }

      } else {
        // Handle no response
      }
    }).then(() => {
      // Re-enable the button
      if( button ) {
        setTimeout(() => {
          button.disabled = false;
        }, 1000);
      }
    });

  }

  function handleAudioRecording(audioUrl, blob) {
    if( blob ) { setAudioBlob(blob) }
  }

  const categoryOptions = [
    {name: 'Pronunciation help', value: 'Pronunciation help'},
    {name: 'Explain this to me', value: 'Explain this to me'},
    {name: 'Funny English', value: 'Funny English'},
    {name: 'General', value: 'General'}
  ];

  function makeFormActive() {
    const form = document.querySelectorAll('.new-post-form.inactive')[0];
    if( form ) { form.classList.remove('inactive') }
  }

  function removeAudio() {
    setAudioBlob(null);
  }

  return (

    <div class='new-post-wrapper'>

      <p><strong>Say Something</strong></p>

      <form onSubmit={handleFormSubmit} class={formClass}>

        <InputSelect
          name={'category'}
          title={'Choose a category'}
          required={true}
          preselectValue={''}
          options={categoryOptions}
        />

        <div class='new-post-main'>
          <InputTextareaBasicRichText
            name={'body'}
            placeholder={'Start typing ...'}
            required={true}
            handleOnFocus={makeFormActive}
          />

          <div class='new-post-actions'>

            <div class='new-post-record-audio'>
              <RecordBasicAudio handleAudioRecording={handleAudioRecording} removeAudio={removeAudio} />
            </div>

            <ButtonSubmit
              value={'Post'}
            />
          </div>
        </div>

      </form>
    </div>

  );
}

export default NewPost;