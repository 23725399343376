import React from "react";
import { useState } from 'react';

import TranslateToggle from "../utilities/translate/translateToggle";
import { translateBite, translateListening } from "../utilities/auth/authApi";


function BiteAction(props) {

  // Define variables
  const [disabled, setDisabled] = useState(false);
  const action = props.action;
  const translateToggle = props.translateToggle;
  const setTranslateToggle = props.setTranslateToggle;
  const translateContent = props.translateContent;
  const setTranslateContent = props.setTranslateContent;
  const checkAnswer = props.checkAnswer;
  const nextAction = props.nextAction;
  const id = props.id;
  const lessonType = props.lessonType;

  let wrapperClass = 'bite-action-wrapper';
  let content;

  let toggleElement;
  if( setTranslateToggle ) {
    toggleElement = (
      <TranslateToggle
        id={id}
        apiMethod={lessonType === 'bite' ? translateBite : translateListening}
        translateToggle={translateToggle}
        setTranslateToggle={setTranslateToggle}
        translateContent={translateContent}
        setTranslateContent={setTranslateContent}
        lessonType={'Bite'}
      />
    );
  }


  switch(action) {
    case 'check':
      wrapperClass += ' check';
      content = (
        <button
          class='clean-button frame-button'
          onClick={checkAnswer}
        >
          <p>Check Answer</p>
        </button>
      );
      break;
    case 'incorrect':
      wrapperClass += ' incorrect';
      content = (
        <div class='fade-in-on-load'>
          <p>Oops, wrong answer. Try again.</p>
        </div>
      );
      break;
    case 'correct':
      wrapperClass += ' correct';
      content = (
        <div class='fade-in-on-load fade-medium'>
          <span class='icon'></span>
          <p>Correct!</p>
          <button
            class='clean-button icon icon-arrow-right-light-hallow'
            onClick={nextAction}
          ></button>
        </div>
      );
      break;
    case 'record-waiting':
      wrapperClass += ' record-waiting';
      break;
    case 'record-complete':
      wrapperClass += ' record-complete';
      content = (
        <div class='fade-in-on-load fade-medium'>
          <span class='icon'></span>
          <p>Well Done!</p>
          <button
            class='clean-button icon icon-arrow-right-light-hallow'
            onClick={handleNextClick}
            disabled={disabled}
            style={
              disabled ? {opacity: .5} : null
            }
          ></button>
        </div>
      );
      break;
    default:
      // waiting
      wrapperClass += ' waiting';
      content = (
        <div class='fade-in-on-load'>
          <p>Check Answer</p>
        </div>
      );
  }


  // Define functions
  function handleNextClick() {
    setDisabled(true);
    nextAction();

    setTimeout(() => {
      setDisabled(false);;
    }, 1000);
  }


  return (
    <div>
      <div class={wrapperClass}>
        {content}
      </div>
      {toggleElement}
    </div>
  );
};

export default BiteAction;