import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom"

import VectorIcon from "./vectorIcon";
import StackedNav from "./stackednav";


function BottomNav() {

  // Define variables
  const location = useLocation();
  const path = location.pathname;
  const challengeData = useSelector((state) => state.profile.challenge);

  const today = new Date();
  const todayNumber = today.getDay(); // Sunday is 0

  let challengeDotElement;
  let challengeIconClass = 'nav-link-icon icon icon-challenge';

  if( challengeData.isComplete ) {
    if( path === '/challenge' ) {
      challengeIconClass = 'nav-link-icon icon icon-challenge-full-burgendy-complete';
    } else {
      challengeIconClass = 'nav-link-icon icon icon-challenge-outline-gray-complete';
    }
  }

  if( (todayNumber === 0 || todayNumber > 2 ) && challengeData?.daysLeft && !challengeData.isComplete && path !== '/challenge' ) {
    challengeDotElement = (
      <div style={{
        position: 'absolute',
        top: '14px',
        right: '34px',
        width: '15px',
        height: '15px',
      }}>
        <VectorIcon icon='circleFill' color='active' size={'100%'} />
      </div>
    );
  }


  let dashboardClassList = 'nav-link';
  let communityClassList = 'nav-link';
  let progressClassList = 'nav-link';
  let challengeClassList = 'nav-link';

  switch (path) {
    case '/progress':
      progressClassList += ' active';
      break;
    case '/progress/lessons':
      progressClassList += ' active';
      break;
    case '/progress/achievements':
      progressClassList += ' active';
      break;
    case '/community':
      communityClassList += ' active';
      break;
    case '/challenge':
      challengeClassList += ' active';
      break;
    case '/dashboard':
      dashboardClassList += ' active';
      break;
    case '/':
      dashboardClassList += ' active';
      break;
    default:
      // Do nothing
      break;
  }


  return (
    <div class='bottom-nav'>

      <Link to="/progress" class={progressClassList} title='My progress'>
        <div class='nav-link-icon icon icon-progress'></div>
        <div class='nav-link-text'>Progress</div>
      </Link>

      <Link to="/challenge" class={challengeClassList} title='Go to the Weekly Challenge'>
        <div class={challengeIconClass}></div>
        <div class='nav-link-text'>Challenge</div>
        {challengeDotElement}
      </Link>

      <Link to="/" class={dashboardClassList} title='Go to the Weekly Mix'>
        <div class='nav-link-icon icon icon-home'></div>
        <div class='nav-link-text'>Home</div>
      </Link>

      <Link to="/community" class={communityClassList} title='Go to the Community'>
        <div class='nav-link-icon icon icon-community'></div>
        <div class='nav-link-text'>Community</div>
      </Link>

      <div class='nav-link'>
        <StackedNav includeText={true} />
      </div>

    </div>
  );
};

export default BottomNav;