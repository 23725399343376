function generateLessonLinkPath(lessonType, id) {
  let linkPath = '#';

  if( lessonType && id ) {
    const lessonTypeDowncase = lessonType.toLowerCase();

    if( lessonTypeDowncase === 'bite' ) {
      linkPath = `/bites/${id}`;
    } else if( lessonTypeDowncase === 'listeninglesson' || lessonTypeDowncase === 'listening' ) {
      linkPath = `/listening/${id}`;
    } else if( lessonTypeDowncase === 'ai speaking' ) {
      linkPath = `/aispeaking/${id}`;
    } else if( lessonTypeDowncase === 'expression' ) {
      linkPath = `/expressions/${id}`;
    } else if( lessonTypeDowncase === 'aitalk' || lessonTypeDowncase === 'ai talk' ) {
      linkPath = `/aitalks/${id}`;
    } else if( lessonTypeDowncase === 'booster' ) {
      linkPath = `/booster/${id}`;
    } else {
      linkPath = `/lessons/${id}`;
    }
  }

  return linkPath;
}

export default generateLessonLinkPath;