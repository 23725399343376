import React from "react";
import { useState, useEffect } from 'react';

import '../../styles/start.css';
import fadeInMainBody from "../../functions/fadeinmainbody";
import { sendVerifyAccountEmail } from "../utilities/auth/authApi";


function VerifyAccount() {

  const [emailAddress, setEmailAddress] = useState('');

  useEffect(() => {

    fadeInMainBody();

    sendVerifyAccountEmail({}).then((response) => {
      if( response && response.data && response.data.email ) {
        setEmailAddress(response.data.email);
      }
    });

  }, []);


  return (
    <div class='wrapper'>
      <div class='main-body invisible'>
        <div class='main-body-content start welcome'>

          <h3 class='start-header'>
            <div class='logo'></div>
          </h3>

          <h1>Verify your account</h1>
          <br /><br />
          <p class='verify-welcome-text'>
            <strong>Welcome to FLOW! 👋 </strong>
            <br />
            {/* Please check your email inbox and to verify your account. */}
            We sent an account verification email to {emailAddress}.
            <br /><br />
            Open the email and click the link.
          </p>

        </div>
      </div>
    </div>
  );
}

export default VerifyAccount;
