import React from "react";
import { useState } from "react";

import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";
import { magicLink } from "../utilities/auth/authApi";
import toast from 'react-hot-toast';


function ForgotPassword(props) {

  // Define variables
  const changeCurrentScreen = props.changeCurrentScreen;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');


  // Define functions
  const handleFormSubmit = async event => {
    event.preventDefault();

    setButtonLoading(true);

    const payload = {
      email: email
    }

    magicLink(payload).then((response) => {

      if( response.data ) {
        if( response.data.success ){
          setDisabled(true);
          toast.success('Magic link sent to ' + email, {duration: 6000});
          Mixpanel.track('Event: Magic Link sent success');
        } else {
          toast.error(response.data.error, {duration: 6000});
          Mixpanel.track('Event: Magic Link sent failed');
        }

      } else {
        // Handle no response
      }
    }).finally(() => {
      setTimeout(() => {
        setButtonLoading(false);
      }, 1000);
    });

  }


  return (
    <section class='section-auth-form'>

      <form
        class='signup-login-form'
        onSubmit={handleFormSubmit}
      >

        <div class='input-container'>
          <h3 style={{textAlign: 'center'}}>Send a Magic Link</h3>

          <div class='top-message'>
            <p>We'll email you a magic link that will let you login without a password.</p>
          </div>

          <input
            name="email"
            type="email"
            value={email}
            onChange={event => setEmail(event.target.value)}
            minLength="6"
            title="Enter your email address"
            required
            placeholder='Email address'
            class='input'
          />
        </div>

        <div class='flex-column'>
          <ButtonSubmitRound
            value={'Send Magic Link'}
            loading={buttonLoading}
            disabled={disabled}
          />
        </div>

        <div class='flex-column'>
          <a
            href='/forgot_password'
            style={{
              color: '#6D6D6D',
              textDecoration: 'underline',
              marginTop: '15px'
            }}
          >or reset your password</a>
        </div>

      </form>

      <div class='login-secondary-action'>
        {/* <a href='#signin' title="Sign In" onClick={() => changeCurrentScreen('login')}>Login</a> */}
        <a href='/login' title="Sign In" onClick={() => changeCurrentScreen('login')}>Login</a>
      </div>

    </section>
  );
}

 export default ForgotPassword;
