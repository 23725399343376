import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom"

import '../../styles/nav.css';
// import LoadNotifications from "../../functions/loadnotifications";

import StackedNav from "./stackednav";
import HeaderLearnerInfo from "./headerLearnerInfo";
import NavLink from "./navLink";


function Nav() {

  // Define variables
  const profile = useSelector((state) => state.profile);
  // const newNotifications = useSelector((state) => state.notifications.newNotifications);
  const challengeData = useSelector((state) => state.profile.challenge);
  const organizationAdmin = profile.organizationAdmin;

  const location = useLocation();
  let path = location.pathname;
  if( path.includes('/community/posts')) { path = '/community' }

  const today = new Date();
  const todayNumber = today.getDay(); // Sunday is 0


  // Page load actions
  // LoadNotifications();


  // Screen components
  let organizationAdminLink;
  if( organizationAdmin ) {
    organizationAdminLink = (
      <Link to="/organization" class={path === '/organization' ? 'nav-link active' : 'nav-link'}>
        <div class='nav-link-text'>Admin</div>
      </Link>
    );
  }

  // let notificationsClass = 'icon icon-notifications-light';
  // if( path === '/notifications' ) {
  //   notificationsClass += ' active';
  // }

  // let newNotificationsElement;
  // if( newNotifications ) {
  //   newNotificationsElement = <span class='new-notifications'></span>;
  // }

  let challengeIndicator;
  if( challengeData.isComplete ) {
    challengeIndicator = 'complete';
  }

  if( (todayNumber === 0 || todayNumber > 2 ) && challengeData?.daysLeft ) {
    challengeIndicator = 'active';
    if( challengeData.isComplete ) {
      challengeIndicator = 'complete';
    }
  }


  return (
    <nav class='nav'>
      <div class='flex-row nav-inner-container'>

        <HeaderLearnerInfo />

        <div class='nav-links-container'>

          <div class='nav-links'>
            {organizationAdminLink}

            <NavLink
              path={'/'}
              title={'Go Home'}
              displayTitle={'Home'}
              active={(path === '/' || path === '/dashboard')}
            />

            <NavLink
              path={'/community'}
              title={'Go to Community'}
              displayTitle={'Community'}
              active={path === '/community'}
            />

            <NavLink
              path={'/challenge'}
              title={'Go to the Weekly Challenge'}
              displayTitle={'Challenge'}
              active={path === '/challenge'}
              challengeIndicator={challengeIndicator}
            />

            <NavLink
              path={'/progress'}
              title={'Go to Progress'}
              displayTitle={'Progress'}
              active={path.includes('progress')}
            />

          </div>

          {/* <Link to='/notifications' class={notificationsClass} title='Go to your notifications'>
            {newNotificationsElement}
          </Link> */}
          <StackedNav />

        </div>
      </div>
    </nav>
  );
};

export default Nav;