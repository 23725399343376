import React from "react";
import { Link } from "react-router-dom"
import Flag from "../profile/flag";
import AvatarImage from "../utilities/avatarImage";


function PostHeader(props) {

  // Define variables
  const category = props.category;
  const postDate = props.postDate;
  const countryCode = props.countryCode;
  const avatarUrl = props.avatarUrl;
  const authorPublicProfileId = props.authorPublicProfileId;
  const author = props.author;


  // Screen components
  let categoryFlagClass = 'category-flag';
  if( category ) {
    const classCategory = category.toLowerCase().replace(' ', '-').replace(' ', '-').replace(' ', '-');
    categoryFlagClass = `category-flag ${classCategory}`;
  }

  let postedOn;
  if( postDate ) { postedOn = `Posted on ${postDate}` };

  let flag;
  if( countryCode ) {
    flag = <Flag countryCode={countryCode} />;
  }

  let avatarElement;
  if( avatarUrl && !avatarUrl.includes('default-avatar') ) {
    avatarElement = (
      <div style={{marginRight: '10px', height: '35px', width: '35px'}}>
        <AvatarImage avatarUrl={avatarUrl} size={35} />
      </div>
    );
  }


  return (
    <div class='post-header'>

      <div class='header-left'>
        {avatarElement}
        <div class='header-name-wrapper'>
          <Link
            to={{pathname: `/progress/${authorPublicProfileId}`}}
            class='header-name-info'
            title={`View ${author}'s public profile`}
          >
            <span>{author}</span>
            {flag}
          </Link>
        </div>
      </div>

      <div class='header-right'>
        {postedOn}
      </div>

      <div class={categoryFlagClass}></div>

    </div>
  );
}

export default PostHeader;