import { Mixpanel } from "../analytics/mixpanelHelpers";


function AudioSpeedSwitcher(props) {

  // Define variables
  const audioSpeed = props.audioSpeed;
  const setAudioSpeed = props.setAudioSpeed;


  // Define functions
  function handleClick(value) {
    setAudioSpeed(value);
    Mixpanel.track('Event: Changed audio playback speed', {'Speed': value});
  }


  return (
    <div class='flex-column fade-in-on-load'>
      <div class='audio-speed-switcher-continer'>
        <p>Audio speed</p>
        <div class='audio-speed-switcher'>

          <button
            type='button'
            class={audioSpeed === 'normal' ? 'active' : 'inactive'}
            onClick={() => handleClick('normal')}
          >Normal</button>

          <button
            type='button'
            class={audioSpeed === 'slower' ? 'active' : 'inactive'}
            onClick={() => handleClick('slower')}
          >Slower</button>

        </div>
      </div>
    </div>
  );
}

export default AudioSpeedSwitcher;