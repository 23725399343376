import { Mixpanel } from '../components/utilities/analytics/mixpanelHelpers';
import excludeCountries from "../components/utilities/excludeCountries";

async function isIpExcluded() {

  let isExcluded = false;

  // Don't run on local
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log(' -- getIpInfo -- ');
    return isExcluded;
  }


  let countryCode = localStorage.getItem("countryCode");
  if( !countryCode ) {

    try {
      const ipData = await fetch("https://ipapi.co/json");
      if( ipData ) {
        const jsonData = await ipData.json();
        if( jsonData?.country_code ) {
          countryCode = jsonData.country_code.toLowerCase();
          localStorage.setItem("countryCode", countryCode);
        }
      }
    } catch( error ) {
      // Do nothing
    }
  }

  if( excludeCountries.includes(countryCode) ) {
    isExcluded = true;
  }

  Mixpanel.track('IP Data', {'country_code': countryCode, 'is_excluded': isExcluded});


  return isExcluded;
}

export default isIpExcluded;