import React from "react";

function PackagePriceButton (props) {

  const packageId = props.packageId;
  const title = props.title;
  const price = props.price;
  const discount = props.discount;
  const duration = props.duration;
  const selectedPackage = props.selectedPackage
  const setSelectedPackage= props.setSelectedPackage


  let discountDiv;
  if( discount ) {
    discountDiv = <div class='package-discount'>{discount}</div>;
  }

  let buttonClass = 'choose-package-button';
  if( selectedPackage === packageId ) {
    buttonClass = 'choose-package-button selected';
  }

  return (
    <button type='button' class={buttonClass} onClick={() => setSelectedPackage(packageId)}>
      <p class='package-title'>{title}</p>
      <p class='package-price'>{price} / {duration}</p>
      {discountDiv}
    </button>
  );
}

export default PackagePriceButton;