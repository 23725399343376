import React from "react";

import alertMessage from "../alertMessage";
import {languageCodes} from "../languagecodes";
import { updatePersonalInformation } from "../auth/authApi";

import Modal from "../modal";


function SetNativeLangauge(props) {

  // Define variables
  const closeModal = props.closeModal;
  const tryToggleTranslate = props.tryToggleTranslate;


  // Define functions
  function handleSelectChange(event) {

    const payload = {
      user: { native_language: event.target.value }
    }

    updatePersonalInformation(payload).then((response) => {
      if( response?.data?.success ) {

        setTimeout(() => {
          closeModal();
          tryToggleTranslate();
        }, 500);

      } else {
        alertMessage(response.data.error, 'error');
      }
    });
  }


  // Screen components
  const body = (
    <div>
      <br />
      <p>Choose your native language so we can show translations</p>
      <select
        type="select"
        title="Select your native language"
        class='select'
        onChange={(event) => handleSelectChange(event)}
      >
        <option value="">Select</option>
        {languageCodes.map((language, index) =>
          <option key={index} value={language.value}>{language.name}</option>
        )};
      </select>
    </div>
  );


  return (
    <Modal
      windowClass={''}
      closeModal={closeModal}
      title={'What is your native language?'}
      centerTitle={true}
      body={body}
      closeIcon={true}
    />
  );
}

export default SetNativeLangauge;