import React from "react";
import { useState } from 'react';

import toast from 'react-hot-toast';

import { updatePersonalInformation } from "../utilities/auth/authApi";
import { reportError } from "../../functions/sentry";

import Nav from "../utilities/nav";
import HeaderMain from "../utilities/headermain";
import BottomNav from "../utilities/bottomnav";
import ButtonSubmit from "../utilities/forms/buttonsubmit";


function CertificateName() {

  // Define variables
  const [buttonLoading, setButtonLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [fullName, setFullName] = useState([]);


  // Define functions
  function saveFullName() {

    if( !fullName || fullName.length < 6 ) {
      toast.error('Please enter your full name');
      return;
    }

    setButtonLoading(true);

    const payload = {
      user: {
        full_name: fullName
      }
    }

    updatePersonalInformation(payload).then((response) => {
      if( response && response.data ) {
        if( response.data.success ) {
          toast.success('Name successfully saved');
          setSaved(true);
        } else {
          toast.error(response.data.error);
        }
      } else {
        toast.error('Sorry, there was a problem');
      }
    }).finally(() => {
      setButtonLoading(false);
    }).catch((error) => {
      reportError(error);
    });
  }


  // Screen components
  let activeElement = (
    <div>
      <div class="field">
        <label class="label">Full Name</label>
        <p style={{marginTop: '-5px', marginBottom: '10px'}}><i>Enter your Full Name as you want it to appear on your Certificate</i></p>
        <div class="control">
          <input
            type="text"
            value={fullName}
            onChange={(event) => setFullName(event.target.value)}
            required
            placeholder='Your full name'
            class='input'
            autoFocus
          />
        </div>
      </div>

      <div class='action-button-wrapper'>
        <ButtonSubmit
          value={'Save'}
          buttonClass={'button action-button'}
          handleButtonClick={saveFullName}
          loading={buttonLoading}
        />
      </div>
    </div>
  );

  if( saved ) {
    activeElement = (
      <div>
        <p><strong>Thanks, your full name has been saved.</strong></p>
        <p>If you have completed your Certificate Course, we will send you our Certificate within 7 days.</p>
        <br />
        <p>Your name will appear like this on your Certificate: <strong>{fullName}</strong></p>
        <br />
        <button
          class='button'
          onClick={() => setSaved(false)}
        >Not right? Update your name.</button>
      </div>
    );
  }


  return (
    <div class='wrapper gray-background'>
      <Nav />
      <div class='main-body'>
        <HeaderMain title="Profile" />
        <div class='main-body-content profile'>
          {activeElement}
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default CertificateName;