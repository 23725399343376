import { useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"
import VectorIcon from "../utilities/vectorIcon";


function BoosterHeader(props) {

  // Define variables
  const [disabled, setDisabled] = useState(false);
  const isSaved = useSelector((state) => state.booster.isSaved);
  const title = props.title;
  const saveLesson = props.saveLesson;
  const progressBarData = props.progressBarData;
  const actionWord = isSaved ? 'Remove' : 'Add';
  const icon = isSaved ? 'bookmarkFill' : 'bookmarkOutline';


  // Define functions
  function handleClick() {
    setDisabled(true);
    saveLesson();

    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  }


  return (
    <div class='booster-header-wrapper'>

      <div class='flex-row' style={{alignItems: 'flex-start'}}>
          <div style={{marginTop: '3px'}}>
            <Link to='/' title='Close'>
              <VectorIcon icon='close' color='#4A4A4A' />
            </Link>
          </div>

          <div class='booster-progress-wrapper'>
            <h3 class='has-text-centered'>{title}</h3>

            <div class='flex-row booster-progress-container'>
              {progressBarData.map((step, index) =>
                <span key={index} class={step.type}></span>
              )}
            </div>

          </div>

          <div style={{marginTop: '3px'}}>
            <button
              type='button'
              disabled={disabled}
              class='clean-button pointer'
              title={`${actionWord} this lesson from your saved list`}
              onClick={() => handleClick()}
            >
              <VectorIcon icon={icon} color='#4A4A4A' />
            </button>
          </div>
      </div>

    </div>
  );
}

export default BoosterHeader;