import ScoreDonut from "../utilities/learning_module/scoredonut";
import '../../styles/scoreDetails.css';


function ScoreDetails(props) {

  // Define variables
  const profile = props.profile;
  const scoreObject = profile.score;

  const score = scoreObject.score;

  const streakDayCount = scoreObject.streak_day_count;
  const streakPercent = scoreObject.streakPercent;

  const lessonsCompletedCount = scoreObject.lessons_completed_count;
  const lessonsPercent = scoreObject.lessonsPercent;

  const communityInteractionCount = scoreObject.community_interaction_count;
  const communityPercent = scoreObject.communityPercent;


  // Screen components
  let scoreBlankStateElement;
  if( score === 100 ) {
    scoreBlankStateElement = (
      <div class='flex-column'>
        <div class='score-details-blank-state-container'>
          <p><strong>Jump right in!</strong></p>
          <p>Take a lesson or make a post in the Community and see your score go up.</p>
        </div>
      </div>
    );
  }


  return (
    <div class='score-details-container'>

      <div class='flex-column'>
        <h1>{score}</h1>
        <p class='subline'>out of 1,000</p>
      </div>

      <div class='flex-column score-breakdown-container'>
        <div class='flex-column'>

          <div class='flex-row'>
            <ScoreDonut scorePercent={streakPercent} />
            <div class='flex-column'>
              <h4>Consistency</h4>
              <p class='subline'>{streakDayCount}/30 days</p>
            </div>
          </div>

          <div class='flex-row'>
            <ScoreDonut scorePercent={lessonsPercent} />
            <div class='flex-column'>
              <h4>Lessons</h4>
              <p class='subline'>{lessonsCompletedCount}/50 completed</p>
            </div>
          </div>

          <div class='flex-row'>
            <ScoreDonut scorePercent={communityPercent} />
            <div class='flex-column'>
              <h4>Community</h4>
              <p class='subline'>{communityInteractionCount}/50 likes & posts</p>
            </div>
          </div>
        </div>
      </div>

      {scoreBlankStateElement}

      <div class='flex-column score-details-footer'>
        <p class='subline'>
          Track your progress for the <strong>last 30 days.</strong>
        </p>
        <p class='subline'>
          Keep your confidence up with daily practice!
        </p>
        <br />
        <a href='https://www.flowspeak.io/articles/get-a-high-flow-score'  class='has-text-centered' target='_blank' rel='noreferrer'>Learn more</a>
      </div>
    </div>
  );
}

export default ScoreDetails;