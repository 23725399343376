import React from 'react';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"
import VectorIcon from "../utilities/vectorIcon";


function ExpressionHeader(props) {

  // Define variables
  const [disabled, setDisabled] = useState(false);
  const isSaved = useSelector((state) => state.expression.isSaved);
  const title = props.title;
  const saveLesson = props.saveLesson;
  const actionWord = isSaved ? 'Remove' : 'Add';
  const icon = isSaved ? 'bookmarkFill' : 'bookmarkOutline';


  // Define functions
  function handleClick() {
    setDisabled(true);
    saveLesson();

    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  }


  return (
    <div class='expression-header-container'>
      <Link to='/' title='Close'>
        <VectorIcon icon='close' />
      </Link>
      <h3>{title}</h3>
      <button
        type='button'
        disabled={disabled}
        class='clean-button pointer'
        title={`${actionWord} this lesson from your saved list`}
        onClick={() => handleClick()}
      >
        <VectorIcon icon={icon} />
      </button>
    </div>
  )
}

export default ExpressionHeader;