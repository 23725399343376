const initialState = {
  initialized: false,
  headerData: {},
  lessonGroups: [],
  // bonusLessons: [],
  stepData: null
}

export function dashboardDataReducer(state = initialState, action) {

  if( action.type === 'initializeDashboardData') {
    return {
      ...state,
      initialized: true,
      headerData: action.payload.headerData,
      lessonGroups: action.payload.lessonGroups,
      // bonusLessons: action.payload.bonusLessons,
      stepData: action.payload.stepData
    }
  }

  if( action.type === 'clearDashboardData') {
    return {
      ...state,
      initialized: false,
      headerData: initialState.headerData,
      lessonGroups: initialState.lessonGroups,
      // bonusLessons: initialState.bonusLessons,
      stepData: initialState.stepData
    }
  }

  return state;
}