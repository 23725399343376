import React from "react";
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import '../../styles/challenge.css';

import { reportError } from "../../functions/sentry";
import { getChallengeList } from "../utilities/auth/authApi";
import BottomNav from "../utilities/bottomnav";
import ChallengeCard from "./challengeCard";
import LoadingBasic from "../utilities/loadingbasic";

import fadeInMainBody from "../../functions/fadeinmainbody";


function ChallengeScreen() {

  // Define variables
  const [listComplete, setListComplete] = useState(null);
  const [listIncomplete, setListIncomplete] = useState(null);
  const [loading, setLoading] = useState(<LoadingBasic />);
  const [endDate, setEndDate] = useState(null);
  const [daysLeft, setDaysLeft] = useState(null);
  const [challengeGroup, setChallengeGroup] = useState(null);
  const [isCommunity, setIsCommunity] = useState(null);
  const [challengeGoal, setChallengeGoal] = useState(3);
  const publicProfileId = useSelector((state) => state.profile.publicProfileId);


  // Page load actions
  useEffect(() => {

    fadeInMainBody();

    getChallengeList().then((response) => {

      if( response?.data?.success ) {

        const listData = response.data.data;
        const complete = multiSortComplete(listData.complete);
        const incomplete = multiSortIncomplete(listData.incomplete);

        setListComplete(complete);
        setListIncomplete(incomplete);
        setIsCommunity(listData.isCommunity);
        setLoading(null);
        setEndDate(listData.endDate);
        setDaysLeft(listData.daysLeft);
        setChallengeGroup(listData.challengeGroup);
        setChallengeGoal(listData.challengeGoal);
      }
    }).catch((error) => {
      reportError(error);
    });

  }, []);


  // Screen components
  let upgradeCtaMessage;
  if( isCommunity ) {
    upgradeCtaMessage = (
      <Link class='challenge-upgrade-message' to='/packages'>
        <p>Want a harder challenge?</p>
        <p><strong>Upgrade to premium to compete <span class='nobr'>for 6 lessons</span></strong></p>
      </Link>
    );
  }


  return (
    <div class='wrapper gray-background'>

      <div class='challenge-header-wrapper'>
        <div class='challenge-header-background'>
          <span class='challenge-header-link'></span>
          <div class='challenge-header-container'>
            <p class='challenge-header-brand'>FLOW Speak</p>
            <h1>Weekly Challenge</h1>
            <p class='challenge-header-detail'>Complete {challengeGoal} lessons this week</p>
          </div>
          <span class='challenge-header-link'>
            <Link to='/' title='Back to the Weekly Mix'>
              <span class='icon icon-close-light'></span>
            </Link>
          </span>
        </div>
      </div>

      <div class='main-body invisible challenge'>
        <div class='main-body-content challenge'>

          {/* {upgradeCtaMessage} */}

          <div class='challenge-complete-container'>

            <h3 class='challenge-complete-heading'>
              {daysLeft ? <span>{daysLeft}</span> : <span>Loading ...</span> }
              {endDate ? <span class='challenge-end-date'>Challenge ends {endDate}</span> : null }
            </h3>

            <div class='challenge-cards-container'>
              {loading}
              {listComplete?.map((learner, index) =>
                <ChallengeCard
                  key={`challenge-card-${index}`}
                  learner={learner}
                  learnerPublicProfileId={publicProfileId}
                  challengeGroup={challengeGroup}
                />
              )}

              {listIncomplete?.length > 0 ? <h3 class='has-text-centered mt-5 mb-4'>Keep Going!</h3> : null }
              {listIncomplete?.map((learner, index) =>
                <ChallengeCard
                  key={`challenge-card-${index}`}
                  learner={learner}
                  learnerPublicProfileId={publicProfileId}
                  challengeGroup={challengeGroup}
                />
              )}

            </div>
          </div>

        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default ChallengeScreen;



function multiSortComplete(array) {
  array.sort((a, b)=> {
    if (a.score === b.score){
      return a.completedCount < b.completedCount ? -1 : 1
    } else {
      return a.score < b.score ? -1 : 1
    }
  })

  return array.reverse();
}

function multiSortIncomplete(array) {
  array.sort((a, b)=> {
    if (a.completedCount === b.completedCount){
      return a.score < b.score ? -1 : 1
    } else {
      return a.completedCount < b.completedCount ? -1 : 1
    }
  });

  return array.reverse();
}