import React from "react";
// import { Link } from "react-router-dom"


function CoursePrepModal (props) {

  const status = props.status;
  const errors = props.coursePrepErrors;
  let content;


  switch (status) {
    case 'waiting':

      content = (
        <div class='course-prep-wrapper'>
          <div class='course-prep'>
            <div class='loading-general'>
              <div class='button is-loading'></div>
              <p>Preparing your Course</p>
            </div>
          </div>
        </div>
      );

      break;
    case 'ready':

      content = (
        <div class='course-prep-wrapper'>
          <div class='course-prep ready'>
            <h3>Your Course is ready</h3>
            <a href='/' title='Go to Dashboard'>Let's Go!</a>
          </div>
        </div>
      );

      break;
    case 'waitingCancel':

      content = (
        <div class='course-prep-wrapper'>
          <div class='course-prep'>
            <div class='loading-general'>
              <div class='button is-loading'></div>
              <p>Cancelling</p>
            </div>
          </div>
        </div>
      );

      break;
    case 'cancelled':

      content = (
        <div class='course-prep-wrapper'>
          <div class='course-prep ready'>
            <h3>Course Cancelled</h3>
            <a href='/courses' class='course-close-modal'>Close</a>
          </div>
        </div>
      );

      break;
    case 'error':

      content = (
        <div class='course-prep-wrapper'>
          <div class='course-prep ready'>
            <h3>Oops...</h3>
            {errors.map((error, index) =>
              <p class='course-error-text' key={index}>{error}</p>
            )}
            <button class='course-close-modal' onClick={props.closeModal}>Close</button>
          </div>
        </div>
      );

      break;
    default:
      // Inactive, do nothing
      break;
  }

  return (
    <div>
      {content}
    </div>
  );
}

export default CoursePrepModal;