import ButtonSubmitRound from "../utilities/forms/buttonSubmitRound";
import allowAndroidChrome from '../../images/allow-android-chrome.png';
import allowDesktopChrome from '../../images/allow-desktop-chrome.png';
import allowIosSafari from '../../images/allow-ios-safari.png';
import allowIosChrome from '../../images/allow-ios-chrome.png';
import allowMacSafari from '../../images/allow-mac-safari.png';


function GrantAccess(props) {

  let androidText;
  let allowImage;
  let instagramAndFacebookMessage;
  let message;

  if( navigator.userAgent ) {

    const userAgent = navigator.userAgent.toLowerCase();
    message = userAgent;

    // console.log(' ');
    // console.log('userAgent', userAgent);
    // console.log(' ');

    const isAndroid = userAgent.indexOf("android") > -1;
    const isIOS = userAgent.indexOf("iphone") > -1;
    const isMacOs = userAgent.indexOf("mac os") > -1;
    const isInstagram = userAgent.indexOf("instagram") > -1;
    const isFacebook = userAgent.indexOf("fb_iab") > -1;

    let isChrome;
    let isSafari;
    // let isFirefox;
    // let isOpera;
    // let isEdge;

    if(userAgent.match(/chrome|chromium|crios/i)){
      isChrome = true;
    }else if(userAgent.match(/firefox|fxios/i)){
      // isFirefox = true;
    }  else if(userAgent.match(/safari/i)){
      isSafari = true;
    }else if(userAgent.match(/opr\//i)){
      // isOpera = true;
    } else if(userAgent.match(/edg/i)){
      // isEdge = true;
    }

    // console.log(' ');
    // console.log("isAndroid", isAndroid);
    // console.log("isIOS", isIOS);
    // console.log("isMacOs", isMacOs);
    // console.log("isChrome", isChrome);
    // console.log("isSafari", isSafari);
    // console.log(' ');

    if( isAndroid ) {

      androidText = <p class='special-instructions'><i>It may ask you to allow Chrome to record audio; tap 'Allow while in app'.</i></p>;

      if( isChrome ) {
        allowImage = <img src={allowAndroidChrome} alt='Grant access instructions' class='allow-image big with-border' />;
      }

    }

    if( isMacOs ) {
      if( isIOS ) {
        if( isChrome ) {
          allowImage = <img src={allowIosChrome} alt='Grant access instructions' class='allow-image' />
        } else if ( isSafari ) {
          allowImage = <img src={allowIosSafari} alt='Grant access instructions' class='allow-image' />;
        }
      } else {
        // Mac laptop / desktop
        if( isChrome ) {
          allowImage = <img src={allowDesktopChrome} alt='Grant access instructions' class='allow-image' />;
        } else if ( isSafari ) {
          allowImage = <img src={allowMacSafari} alt='Grant access instructions' class='allow-image small' />;
        }
      }
    }

    if( (isInstagram || isFacebook) && isAndroid ) {
      instagramAndFacebookMessage = (
        <p class='instagram-message'>
          <span>Having trouble granting access?</span>
          Tap the three icons in top right,
          <br />
          and press 'Open in Chrome'.
        </p>
      );
    }

  }

  function showVerySpecialText() {
    const element = document.getElementsByClassName('very-special-text')[0];
    element.classList.add('active');
  }


  return (
    <div>
      <br />
      <p class='has-text-centered'>Please allow your microphone so you <br />can practice speaking.</p>
      <p class='special-instructions has-text-centered'>After you tap the button below, <strong>a popup will ask to <br />use your microphone. Press "<i>Allow</i>"</strong>.</p>
      {androidText}

      <p></p>

      <div class='allow-image-wrapper'>
        {allowImage}
      </div>

      <div class='flex-column' style={{marginTop: '15px'}}>
        <ButtonSubmitRound
          value={'Allow Microphone Access'}
          handleButtonClick={props.checkMicrophonePermission}
        />
      </div>

      {instagramAndFacebookMessage}

      <br /><br /><br />
      <div class='tutorial-privacy-statement'>
        <p><strong>We value your privacy.</strong></p>
        <p>When taking lessons, your voice is recorded so we can give you a speaking score and feedback.</p>
        <br />
        <p>Your recordings will only be shared with the community if you choose to share them.</p>
        <br />
        <p><strong>We will never share your recordings with any third parties.</strong></p>
      </div>
      <div class='very-special-text' onClick={showVerySpecialText}>{message}</div>
    </div>

  );
}

export default GrantAccess;
