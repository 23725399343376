import ScoreDonut from "../../utilities/learning_module/scoredonut";
import ScoreBlockRetryCount from "./scoreBlockRetryCount";


function ScoresBlockSpeakingIelts(props) {

  // Define variables
  const retryCount = props.retryCount;
  const scores = props.scores;
  const overallScore = scores.ieltsOverallScore;
  const fluencyScore = scores.ieltsFluencyAndCoherence;
  const vocabularyScore = scores.ieltsVocabulary;
  const grammarScore = scores.ieltsGrammar;
  const pronunciationScore = scores.ieltsPronunciation;


  return (
    <div class='score-block-container'>

      <div class='flex-row score-block-row'>
        <p><strong>Overall</strong></p>
        <p class='flex-row'>
          {overallScore}
          <ScoreDonut scorePercent={overallScore} isIelts={true} />
        </p>
      </div>

      <div class='flex-row score-block-row'>
      <p>Fluency and Coherence</p>
        <p class='flex-row'>
          {fluencyScore}
          <ScoreDonut scorePercent={fluencyScore} isIelts={true} />
        </p>
      </div>

      <div class='flex-row score-block-row'>
      <p>Lexical Resource</p>
        <p class='flex-row'>
          {vocabularyScore}
          <ScoreDonut scorePercent={vocabularyScore} isIelts={true} />
        </p>
      </div>

      <div class='flex-row score-block-row'>
      <p>Grammatical Range and Accuracy</p>
        <p class='flex-row'>
          {grammarScore}
          <ScoreDonut scorePercent={grammarScore} isIelts={true} />
        </p>
      </div>

      <div class='flex-row score-block-row'>
        <p>Pronunciation</p>
        <p class='flex-row'>
          {pronunciationScore}
          <ScoreDonut scorePercent={pronunciationScore} isIelts={true} />
        </p>
      </div>

      <ScoreBlockRetryCount retryCount={retryCount} />

    </div>
  );
}

export default ScoresBlockSpeakingIelts;