
const initialSate = {
  currencyCode: 'USD',
  // packageId: 'price_1KPKygIs167BP3avGppfNq7e',
  packageId: null,
  promoCode: null,
  packageData: null
}

export function packageReducer(state = initialSate, action) {

  if( action.type === 'setPackageCurrencyCode') {
    return {
      ...state,
      currencyCode: action.payload.currencyCode
    };
  }

  if( action.type === 'setPackageId') {
    return {
      ...state,
      packageId: action.payload.packageId
    };
  }

  if( action.type === 'setPromoCode') {
    return {
      ...state,
      promoCode: action.payload.promoCode
    };
  }

  if( action.type === 'setPackageData') {
    return {
      ...state,
      packageData: action.payload.packageData
    };
  }

  return state;
}