import { Link } from "react-router-dom"


function NoAccess() {
  return (
    <div class='lesson-no-access-message flex-column'>
      <h3>Sorry &#128546;</h3>
      <p class='has-text-centered mt-2'>You don't have access to this lesson</p>
      <p class='has-text-centered special mt-3'>Upgrade for unlimited access <br />  to Premium Lessons</p>
      <Link to='/packages' class='' title='View Plans'>View Plans</Link>
    </div>
  );
}

export default NoAccess;