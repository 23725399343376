import React from "react";
import currencyFormat from "../../functions/currencyformat";


function ReviewTotal (props) {

  const packageData = props.packageData;
  let totalLine;
  let promoCodeLine;
  let totalPrice;

  if( packageData && packageData.packageExists ) {

    // const intervalCount = packageData.intervalCount;
    // const interval = (intervalCount > 1 ) ? packageData.interval + 's' : packageData.interval;
    const promoCode = props.promoCode;

    // Stripe prices don't have decimals
    const packagePrice = (packageData.packagePrice / 100);
    totalPrice = packagePrice;

    if( promoCode ) {

      const code = promoCode.code;
      const amountOff = promoCode.amount_off;
      const percentOff = promoCode.percent_off;
      let discount;

      if( amountOff ) {
        discount = ( amountOff / 100 );
      } else if ( percentOff ) {
        discount = packagePrice * ( percentOff / 100 );
      } else {
        // handle
      }

      if( discount ) {
        totalPrice = (packagePrice - discount);

        promoCodeLine = (
          <div class='package-line'>
            <div class='package-line-title'>Promo code <i>{code}</i></div>
            <div class='package-line-price'>-${currencyFormat(discount)}</div>
          </div>
        );

      }

    }

    totalLine = (
      <div class='package-line total'>
        <div class='package-total-title'>Total</div>
        <div class='package-total-price'>${currencyFormat(totalPrice)}</div>
      </div>
    );

  }


  return (
    <div class='package-total-wrapper'>
      {promoCodeLine}
      {totalLine}
    </div>
  );
};

export default ReviewTotal;