import React from "react";

import { Link } from "react-router-dom"
import toast from 'react-hot-toast';

import PlayAudioIcon from "../../playaudioicon";
import { flagComment } from "../../../utilities/auth/authApi";
import RenderHtmlText from "../../../utilities/renderhtmltext";


function ContentResponse (props) {

  const response = props.response;

  let className = 'response-wrapper community-response';
  let authorText = <span class='author'>by {response.author}</span>;
  let commentCount;
  let exploreComments = <div class='image is-32x32'></div>;
  let audioIcon;

  if( response.requestFeedback ) {
    let responseDate;
    if( response.createdAt ) {
      responseDate = <span class="response-created-at">({response.createdAt})</span>;
    }

    authorText = <span class='author'>{response.author} wants feedback {responseDate}</span>;

    commentCount = <span class='comment-count'>{response.commentCount} {response.commentCount === 1 ? 'comment' : 'comments'}</span>;

    const baseUrl = window.location.pathname;
    const responseId = response.id;

    const exploreUrl = `${baseUrl}/${responseId}/comments`;
    exploreComments = <Link to={exploreUrl} title='Explore comments' class='link-to-comments icon icon-chevron-right'></Link>;
  }

  // If this is a comment, show the comment date if it exists
  if( !response.commentCount ) {
    let responseDate;
    if( response.createdAt ) {
      responseDate = <span class="response-created-at">({response.createdAt})</span>;
      authorText = <span class='author'>by {response.author} {responseDate}</span>;
    }
  }

  if( response.audioUrl ) {
    audioIcon = <div class="media-left"><PlayAudioIcon audioUrl={response.audioUrl} responseAudio={true} /></div>;
  }

  if( props.isCommentIndex ) {
    exploreComments = null;
    className = className + ' comment-index';
    commentCount = null;
  }

  let flag;
  if( props.isComment ) {
    exploreComments = null;
    className = className + ' comment';

    flag = <div class='image is-16x16 icon icon-flag' title='Flag comment as inappropriate.' data-comment-id={response.id} onClick={flagContent}></div>;

    if( props.response.audio_url ) {
      audioIcon = <div class="media-left"><PlayAudioIcon audioUrl={props.response.audio_url} iconDark={true} responseAudio={true} /></div>;
    }

  }


  return (

    <div class={className} id={"question-card-" + props.index}>
      <div class='response-content'>
        <div class='extra-response-wrapper'>
        <div class="card-content">
          <div class="media">
          {audioIcon}
            <div class="media-content">
              <div class="has-text-right response-input">
                <RenderHtmlText string={response.text} />
              </div>
            </div>
          </div>
        </div>
        {exploreComments}
        </div>
        <div class='community-responses'>
          {authorText}
          {commentCount}
          {flag}
        </div>
      </div>
    </div>
  );
};


function flagContent(event) {

  if (window.confirm('Are you sure you want to flag this comment as inappropriate?')) {
    const element = event.target;
    const commentId = element.getAttribute('data-comment-id');

    const payload = {comment_id: commentId};

    console.log(payload);

    flagComment(payload).then((response) => {
      element.classList.add('is-hidden');

      toast.success('Comment has been flagged as inappropriate.');
    });

  }

}


export default ContentResponse;