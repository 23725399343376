import { useEffect, useState } from 'react';
import { getAllTimeStats } from "../utilities/auth/authApi";

const initialStats = {
  accountCreated: '...',
  daysActive: 0,
  lessonsCompleted: 0,
  postsCreated: 0,
  commentsGiven: 0,
  likesGiven: 0,
  learningMinutes: 0
}


function AllTimeStats(props) {

  // Define variables
  const [stats, setStats] = useState(initialStats);
  const publicProfileId = props.publicProfileId;


  // Page load actions
  useEffect(() => {

    if( publicProfileId) {
      getAllTimeStats(publicProfileId).then((response) => {
        if( response?.data ) {
          if( response.data.success ) {
            setStats(response.data.stats);
          } else {

          }
        } else {

        }
      }).finally(() => {

      });
    }

  }, [publicProfileId]);


  return (

    <div class='stat-container'>

      <div>
        <h3>All Time Stats</h3>
        <p style={{marginTop: '-15px', marginBottom: '15px'}}>
          <small><i>Since {stats.accountCreated}</i></small>
        </p>
      </div>

      <div>

        <div class='flex-row stats-all-time-row'>
          <div class='stat-block'>
            <h2>{stats.lessonsCompleted}</h2>
            <p>Lessons</p>
            <p>Completed</p>
          </div>
          <div class='stat-block'>
            <h2>{stats.daysActive}</h2>
            <p>Days</p>
            <p>Active</p>
          </div>
        </div>

        <div class='flex-row stats-all-time-row'>
          <div class='stat-block'>
            <h3>{stats.postsCreated}</h3>
            <p>Posts</p>
            <p>Created</p>
          </div>
          <div class='stat-block'>
            <h3>{stats.commentsGiven}</h3>
            <p>Comments</p>
            <p>Given</p>
          </div>
          <div class='stat-block'>
            <h3>{stats.likesGiven}</h3>
            <p>Likes</p>
            <p>Given</p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default AllTimeStats;