import { useEffect, useState } from 'react';
import { reportError } from "../../functions/sentry";
import { getLessonsCompleted } from "../utilities/auth/authApi";
import LoadingBasic from "../utilities/loadingbasic";
import LineGraph from "../utilities/graphs/linegraph";


function LessonCompletedLineGraph(props) {

  // Define variables
  const [graph, setGraph] = useState(<LoadingBasic />);
  const publicProfileId = props.publicProfileId;


  // Page load actions
  useEffect(() => {

    if( publicProfileId) {
      getLessonsCompleted(publicProfileId).then((response) => {
        if( response?.data?.success ) {

          const datasets = [
            {
              label: 'Lessons Completed',
              data: response.data.lessonsCompleted.data,
              borderColor: 'rgb(120, 8, 168)',
              backgroundColor: 'rgba(120, 8, 168, 0.2)',
              borderWidth: 2,
              pointRadius: 3,
              pointBorderWidth: 1,
              pointHoverRadius: 10,
              tension: .35
            }
          ];

          // Find the max value
          // And force 2 as a the min
          // TO DO - maybe use suggested min instead
          // const mostLessons = (Math.max(...response.data.lessonsCompleted.data));
          // const yMax = (mostLessons > 1) ? null : 2;

          const graph = (
            <LineGraph
              datasets={datasets}
              labels={response.data.lessonsCompleted.labels}
              yConfig={{min: 0, suggestedMax: 2, gridDisplay: false}}
              xConfig={{maxTicksLimit: 10}}
            />
          );

          setGraph(graph);
        }
      }).catch((error) => {
        reportError(error);
      });
    }

  }, [publicProfileId]);


  return (

    <div class='stat-container'>
      <h3>Lessons Completed</h3>
      <p style={{marginTop: '-15px', marginBottom: '15px'}}>
        <small><i>Over the past 30 days</i></small>
      </p>
      {graph}
    </div>
  )
}

export default LessonCompletedLineGraph;