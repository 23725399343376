
const initialSate = {
  initialized: false,
  series: []
}

export function AllSeriesReducer(state = initialSate, action) {

  if( action.type === 'initializeAllSeries') {
    return {
      ...state,
      initialized: action.payload.initialized,
      series: action.payload.series
    }
  }

  return state;
}