
function fadeInMainBody(scrollPosition) {
  // Reset the scroll position and do a brief fade in
  // to make the whole thing smooth
  const position = scrollPosition || 0;

  // window.scrollTo(0,position);

  loadTries = 0;

  setTimeout(() => fadeIn(position), 100);
}

function fadeIn(position) {
  const invisibleDiv = document.getElementsByClassName('invisible')[0];

  if( invisibleDiv ) {
    invisibleDiv.classList.add('visible');
    window.scrollTo({ top: position });
  } else {
    if( loadTries < 10 ) {
      setTimeout(() => fadeIn(), 100);
    }

    loadTries +=1;
  }
}

let loadTries;

export default fadeInMainBody;