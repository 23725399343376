import axios from 'axios';

import { reportError } from './sentry';
import { apiBaseUrl } from "../components/utilities/auth/authApi";
import { getToken, removeToken } from "../components/utilities/auth/tokenHelpers";

const loadModule = async (id) => {

  axios.defaults.baseURL = apiBaseUrl;

  let apiPath = 'learning_modules/' + id;


  // console.log("------ 1. GET starting --------");

  var config = {
    method: 'get',
    url: apiPath,
    headers: { authorization: `Bearer ${getToken()}` }
  };

  // console.log("------ 2. Start axios --------");

  return axios(config)
    .then(function (response) {

      // console.log('------ 3. Successfully recieved module data');

      let responseObject;

      // Ensure the module has data
      if( response.data && response.data.moduleContent.length > 0 ){
        responseObject = {fetchData: 'success', data: response.data };
      }else{
        responseObject = {fetchData: 'error', error: 'Module has no data' };
      }

      return responseObject;
    })
    .catch(function (error) {

      // Log the error
      reportError(error);

      console.log('------ 4. Error');
      let response = {
        errorCode: 'Unknown',
        errorMessage: 'An unknown error has occured'
      }

      if( error.response && error.response.status ){
        response = {
          errorCode: error.response.status,
          errorMessage: error.response.statusText
        }

        if( error.response.status === 401 ) {
          removeToken();
          // window.location.href = '/start#signin';
          window.location.href = '/login';
        }

      }

      return response;
    });

}

export default loadModule;