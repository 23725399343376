import React from "react";

function ListeningSpinner () {
  return (
    <div class="listening-spinner">
      <div class="listen1"></div>
      <div class="listen2"></div>
      <div class="listen3"></div>
      <div class="listen4"></div>
      <div class="listen5"></div>
    </div>
  );
};

export default ListeningSpinner;