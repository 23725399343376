import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom"
import { useNavigate } from "react-router";

import '../../styles/start.css';

import { reportError } from '../../functions/sentry';
import apiCall from '../utilities/auth/apiCall';
import { setPassword, getOrganizationBySlug } from "../utilities/auth/authApi";
import { saveToken } from '../utilities/auth/tokenHelpers';

import RenderHtmlText from "../utilities/renderhtmltext";
import StartLeftColumn from './startLeftColumn';
import UnauthedStartHeader from './unauthedStartHeader';
// import Input from "../utilities/forms/input";
import ButtonSubmitRound from '../utilities/forms/buttonSubmitRound';
import loadCustomCss from '../../functions/loadCustomCss';
import PasswordWithEye from '../utilities/passwordWithEye';


function ActivateScreen() {

  // Define variables
  const { organizationSlug, accountCodeInitial } = useParams();
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [accountCode, setAccountCode] = useState(null);
  const [password, setPasswordValue] = useState('');
  const [orgLogo, setOrgLogo] = useState('');


  // Component load actions
  useEffect(() => {
    setAccountCode(accountCodeInitial);

    // Look up org
    apiCall(getOrganizationBySlug, {slug: organizationSlug}).then((response) => {
      if( response.success ) {
        if( response.data.logo_url ) {
          setOrgLogo(response.data.logo_url);
        }

        if( response.data.external_css_url ) {
          loadCustomCss(response.data.external_css_url);
        }
      } else {
        reportError(response.error);
      }
    });

  }, []);


  // Define functions
  function handleFormSubmit(event) {
    event.preventDefault();
    setButtonLoading(true);

    setErrors([]);


    const payload = {
      account_code: accountCode,
      password: password,
      agree_to_terms: true
    }

    setPassword(payload).then((response) => {
      if( response.data ) {
        if( response.data.auth ) {

          // Save token
          saveToken(response.data);

          setTimeout(() => {
            // Send to onboarding steps
            navigate('/welcome-choose-topics/group'); // We know they have a course
          }, 800);

        } else {
          setErrors(response.data.errors);
        }
      } else {
        setErrors(['Sorry, there was an error. Please contact help@flowspeak.io.']);
      }
    }).finally(() => {
      setTimeout(() => {
        // Send to onboarding steps
        setButtonLoading(false);
      }, 2000);
    });

  }


  return (
    <div class='wrapper'>
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <UnauthedStartHeader
                orgLogo={orgLogo}
              />

              <section class='section-auth-form'>

                <div class='flex-row content-left auth-links-container'>
                  <p class='auth-link-button'>Set Your Password</p>
                </div>

                <form
                  class='signup-login-form'
                  onSubmit={handleFormSubmit}
                >

                  {/* <Input
                    name={'account_code'}
                    label={'Account Code'}
                    placeholder={'enter account code'}
                    autoComplete={'off'}
                    preselectValue={accountCode}
                    onChange={(event) => setAccountCode(event.target.value)}
                  /> */}

                  <div class="field">
                    <label class="label">Account Code</label>
                    <div class="control">
                      <input
                        name="account_code"
                        type="input"
                        title="enter account code"
                        required
                        placeholder='enter account code'
                        class='input'
                        autocomplete='off'
                        defaultValue={accountCode}
                        onChange={(event) => setAccountCode(event.target.value)}
                      />
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Password</label>
                    <div class="control">
                      {/* <input
                        name="password"
                        type="password"
                        minLength="8"
                        title="Password must be at least 8 characters"
                        required
                        placeholder='password'
                        class='input'
                        autocomplete='off'
                        onChange={(event) => setPasswordValue(event.target.value)}
                      /> */}
                      <PasswordWithEye
                        password={password}
                        setPassword={setPasswordValue}
                      />
                    </div>
                  </div>

                  <div class='form-error-header form-error'>
                    {/* {errors} &nbsp; */}
                    <RenderHtmlText string={errors} />
                  </div>

                  <div class='flex-column' style={{marginTop: '15px'}}>
                    <ButtonSubmitRound
                      value={'Set Password'}
                      loading={buttonLoading}
                    />
                  </div>

                  <div>
                    <p class='terms-text'>
                      By clicking Set Password, you agree to the <br /><a href='https://www.flowspeak.io/terms-and-conditions' target='_blank' rel="noreferrer">Terms & Conditions</a> and <a href='https://www.flowspeak.io/privacy-policy' target='_blank' rel="noreferrer">Privacy Policy</a>
                    </p>
                  </div>

                </form>

                <br />

                <div class='login-secondary-action'>
                  Already a member? &nbsp;
                  {/* <Link to="/start#signin" title='Go to login'> */}
                  <Link to="/login" title='Go to login'>
                    Login
                  </Link>
                </div>

              </section>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivateScreen;