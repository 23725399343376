import ScoreDonut from "../../utilities/learning_module/scoredonut";
import ScoreBlockRetryCount from "./scoreBlockRetryCount";


function ScoresBlockSpeaking(props) {

  // Define variables
  const retryCount = props.retryCount;
  const scores = props.scores;
  const overallScore = scores.score;
  const pronunciationScore = scores.pronunciationScore;
  const speedScore = scores.cadenceScore;
  const fluencyScore = scores.fluencyScore;


  return (
    <div class='score-block-container'>

      <div class='flex-row score-block-row'>
        <p><strong>Overall</strong></p>
        <p class='flex-row'>
          {overallScore}%
          <ScoreDonut scorePercent={overallScore} />
        </p>
      </div>

      <div class='flex-row score-block-row'>
        <p>Pronunciation</p>
        <p class='flex-row'>
          {pronunciationScore}%
          <ScoreDonut scorePercent={pronunciationScore} />
        </p>
      </div>

      <div class='flex-row score-block-row'>
        <p>Speed</p>
        <p class='flex-row'>
          {speedScore}%
          <ScoreDonut scorePercent={speedScore} />
        </p>
      </div>

      <div class='flex-row score-block-row'>
        <p>Fluency</p>
        <p class='flex-row'>
          {fluencyScore}%
          <ScoreDonut scorePercent={fluencyScore} />
        </p>
      </div>

      <ScoreBlockRetryCount retryCount={retryCount} />

    </div>
  );
}

export default ScoresBlockSpeaking;