
function scrollQuestionsDiv(complete = false) {

  const bodyContentDiv = document.getElementsByClassName("body-content")[0];
  const questionsDiv = document.getElementsByClassName("questions")[0];

  if( questionsDiv ) {
    let questionsHeight = questionsDiv.clientHeight;

    // When the lesson is complete, scroll the questions div down a bit
    // So learners can see that there is more content below
    if( complete ) {

      // Mobile has a shorter window
      // Note, the 'ipad' option is removed as it is a taller screen
        if( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        questionsHeight = questionsHeight - 50;
      } else {
        questionsHeight = questionsHeight - 315;
      }

    }

    bodyContentDiv.scrollTo({
      top: questionsHeight,
      behavior: 'smooth'
    });
  }

}

export default scrollQuestionsDiv;