import PlayAudioIcon from "../playaudioicon";
import AvatarImage from "../avatarImage";

function MessageLearner(props) {

  // Define variables
  const message = props.message;
  const audioUrl = props.audioUrl;
  const avatarUrl = props.avatarUrl;


  // Screen components
  let audioElement;
  if( audioUrl ) {
    audioElement = (
      <div class='message-content-audio'>
        <PlayAudioIcon
          audioUrl={audioUrl}
          fullDark={true}
          position={'left'}
        />
      </div>
    );
  }

  let avatarElement;
  if( avatarUrl ) {
    avatarElement = <AvatarImage avatarUrl={avatarUrl} />;
  }


  return (
    <div class='flex-row message-container message-learner'>
      <div class='flex-row message-content'>
        {audioElement}
        {message}
      </div>
      {avatarElement}
    </div>
  );
}

export default MessageLearner;