import { useState } from "react";
import PlayAudioIcon from "../utilities/playaudioicon";


function Listener(props) {

  // Define variables
  const [playbackSpeedTwo, setPlaybackSpeedTwo] = useState('normal');
  const secondaryLine = props.secondaryLine || '';
  const listeningText = props.listeningText;
  const audioUrlConversationSpeed = props.audioUrlConversationSpeed;
  const audioUrlHalfSpeed = props.audioUrlHalfSpeed;
  const hideListeningTextByDefault = props.hideListeningTextByDefault;
  const showListeningText = props.showListeningText;
  const allowTextToggle = props.allowTextToggle;


  // Define functions
  function toggleText(event) {
    const target = event.target;
    const listeningTextElement = document.getElementsByClassName('listener-text')[0];
    if( listeningTextElement ) {
      listeningTextElement.classList.toggle('is-hidden');
      target.innerText = target.innerText.includes('hide') ? 'show text' : 'hide text';
    }
  }


  // Screen comonents
  let audioNormalElement;
  let audioHalfElement;

  if( audioUrlConversationSpeed ) {
    audioNormalElement = (
      <div class='flex-row'>
        <button
          type='button'
          class={`clean-button playback-speed ${playbackSpeedTwo === 'normal' ? 'selected' : null}`}
          onClick={() => setPlaybackSpeedTwo('normal')}
        >Natural Speed</button>
        <PlayAudioIcon
          audioUrl={audioUrlConversationSpeed}
          isHidden={playbackSpeedTwo === 'normal' ? null : true}
        />
      </div>
    );
  }

  if( audioUrlHalfSpeed ) {
    audioHalfElement = (
      <div class='flex-row'>
        <button
          type='button'
          class={`clean-button playback-speed ${playbackSpeedTwo === 'half' ? 'selected' : null}`}
          onClick={() => setPlaybackSpeedTwo('half')}
        >Slow Speed</button>
        <PlayAudioIcon
          audioUrl={audioUrlHalfSpeed}
          isHidden={playbackSpeedTwo === 'half' ? null : true}
        />
      </div>
    );
  }


  let listeningTextElement;
  let textToggleElement;

  if( showListeningText ) {

    if( hideListeningTextByDefault ) {
      listeningTextElement = <div class='listener-text is-hidden'>{listeningText}</div>;
    } else {
      listeningTextElement = <div class='listener-text'>{listeningText}</div>;
    }

    if( allowTextToggle && !hideListeningTextByDefault ) {
      textToggleElement = <button type='button' class='clean-button toggle-text' onClick={toggleText}>hide text</button>;
    } else if( allowTextToggle && hideListeningTextByDefault ) {
      textToggleElement = <button type='button' class='clean-button toggle-text' onClick={toggleText}>show text</button>;
    }

  }


  let footerClass = 'listener-footer';
  if( secondaryLine.length > 18 && secondaryLine.length <= 30 ) {
    footerClass = 'listener-footer small';
  } else if ( secondaryLine.length > 31 ) {
    footerClass = 'listener-footer smaller';
  }


  return (
    <div class='listener-wrapper'>

      <div class='listener-audio-wrapper'>
        <div class='listener-playback-speed'>
          {audioNormalElement}
          {audioHalfElement}
        </div>
      </div>

      {listeningTextElement}
      <div class='text-toggle-wrapper'>
        {textToggleElement}
      </div>

      <div class={footerClass}>
        {secondaryLine}
      </div>
    </div>
  );
}


export default Listener;