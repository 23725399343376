import React from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import LessonCard from "../utilities/lessoncards/lessonCard";
import HeaderLesson from "./headerlesson";
import LessonScreenSwitcher from './lessonScreenSwitcher';

import loadModule from '../../functions/loadmodule.js';


function Related() {

  const dispatch = useDispatch();

  const { id } = useParams();

  const currentLessonId = useSelector((state) => state.lesson.currentLessonId);
  const relatedConversationAndIdeaLessons = useSelector((state) => state.lesson.currentLesson.related_conversation_and_idea_modules) || [];
  const relatedVocabularyLessons = useSelector((state) => state.lesson.currentLesson.related_vocabulary_modules) || [];

  let noContentWording = <p class='no-content-wording'>There are no related lessons. <br /><br /><a href='/explore'>Check out the Library to find more Lessons.</a></p>;
  if( relatedConversationAndIdeaLessons.length > 0 || relatedVocabularyLessons.length > 0 ) {
    noContentWording = null;
  }

  useEffect(() => {

    window.scrollTo(0,0);

    if( !currentLessonId || currentLessonId !== id ) {
      // Need to fetch lesson data

      loadModule(id).then((response) => {
        if( response.data ){
          const lesson = response.data;

          // Update the redux store
          dispatch({ type: 'setLesson', payload: {
            id: id,
            isSaved: lesson.isSaved,
            lesson: lesson,
            questionList: lesson.moduleContent,
            activeQuestions: [lesson.moduleContent[0]],
            currentQuestionNumber: 0
          } });
        }
      }).catch((error) => {
        console.log("Error loading module data");
        console.log(error);
      });
    }

  }, []);


  return (
    <div class='wrapper'>
      <div class='lesson-body'>
        <div class='lesson-right-column'>

          <div class='lesson-header-content-container'>
            <HeaderLesson />
            <LessonScreenSwitcher
              id={id}
              currentScreen={'related'}
            />
          </div>

          <section
            class='body-content related-lessons'
            style={{padding: '30px'}}
          >

            {noContentWording}

            {relatedVocabularyLessons.map((relatedLesson, index) =>
              <LessonCard
                lesson={relatedLesson}
                key={index}
                hideProgressBars={true}
              />
            )}

            {relatedConversationAndIdeaLessons.map((relatedLesson, index) =>
              <LessonCard
                lesson={relatedLesson}
                key={index}
                hideProgressBars={true}
              />
            )}

          </section>
        </div>
      </div>
    </div>
  );
}

export default Related;