import React from "react";
import { useDispatch } from "react-redux";
import { useState } from 'react';
import { Link } from "react-router-dom"

import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import UnauthedStartHeader from "./unauthedStartHeader";
import MicAccessError from './micaccesserror';
import RecordTest from './recordtest';
import GrantAccess from './grantaccess';
import StartLeftColumn from "./startLeftColumn";


function WelcomeTutorial() {

  const dispatch = useDispatch();

  function setMicrophonePermission(permission) {
    dispatch({ type: 'setMicrophonePermission', payload: {microphonePermission: permission}});
  }

  const [activeComponent, setActiveComponent] = useState(<GrantAccess checkMicrophonePermission={checkMicrophonePermission} />);
  const [completeButton, setCompleteButton] = useState("");


  function checkMicrophonePermission() {

    // if( navigator && navigator.mediaDevices ) {
    if( navigator ) {

      if( navigator.mediaDevices ) {
        navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        .then((stream) => {
          setMicrophonePermission('granted');
          setActiveComponent(<RecordTest completeTutorial={completeTutorial} />);
          Mixpanel.track('Event: Mic Access - Granted');
        })
        .catch((error) => {
          setMicrophonePermission('denied');
          setActiveComponent(<MicAccessError />);
          Mixpanel.track('Event: Mic Access - Blocked');
        });
      } else {
        setMicrophonePermission('denied');
        Mixpanel.track('Event: No Mic Access - No Navigator.mediaDevices');
      }

    } else {
      // Handle no navigator
      setMicrophonePermission('denied');
      Mixpanel.track('Event: No Mic Access - No Navigator');
    }

  }


  function completeTutorial() {
    const url = '/dashboard';
    const button = (
      <div class='flex-column' style={{marginTop: '30px'}}>
        <Link
          to={url}
          class='button submit-button-round all-set-button fade-in-on-load'
        >Finish</Link>
      </div>
    );
    setTimeout(() => {
      setCompleteButton(button);
    }, 600);
  }

  return (
    <div class='wrapper'>
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <div class='welcome-mic-test-container'>
                <UnauthedStartHeader
                  hideTitle={true}
                  subTitle={"Let's test your microphone"}
                />
                {activeComponent}
                {completeButton}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeTutorial;
