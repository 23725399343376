import React from "react";

function OnboardingPackagePriceButton(props) {

  // Define variables
  const packageId = props.packageId;
  const title = props.title;
  const price = props.price;
  const discount = props.discount;
  const duration = props.duration;
  const selectedPackage = props.selectedPackage
  const setSelectedPackage= props.setSelectedPackage
  const isCommunity = packageId === 'community';
  const packageType = isCommunity ? null : 'Premium';


  // Screen components
  let discountDiv;
  if( discount ) {
    discountDiv = <div class='package-discount'>{discount}</div>;
  }

  let buttonClass = 'choose-package-button';
  if( selectedPackage === packageId ) {
    buttonClass = 'choose-package-button selected';
  }

  let priceElement = <p class='package-price'>{price}/{duration}</p>;
  if( isCommunity ) {
    priceElement = <p class='package-price'>Free</p>;
  }


  return (
    <button type='button' class={buttonClass} onClick={() => setSelectedPackage(packageId)}>
      <p>{packageType}</p>
      <p class='package-title'>{title}</p>
      {priceElement}
      {discountDiv}
    </button>
  );
}

export default OnboardingPackagePriceButton;