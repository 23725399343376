import React from "react";

import '../../../../styles/community.css';

import QuestionText from "../questiontext";
import ContentResponse from "./contentresponse";
import CommentForm from "./commentform";

import { getResponseComments } from "../../../utilities/auth/authApi";
// import { withRouter } from "react-router";


class ResponseComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLessonId: this.props.match.params.id,
      responseId: this.props.match.params.responseId,
      moduleType: props.match.url.split('/')[1],
      comments: [],
      responseContent: {},
      questionContent: {},
      introContent: {}
    };

    this.addCommentToState = this.addCommentToState.bind(this);
  }

  componentDidMount() {

    getResponseComments(this.state.responseId).then((response) => {
      if( response.data && response.data.id ) {

        const communityResponse = response.data;
        const moduleContent = communityResponse.module_content;

        const introObject = response.data.introContent;
        let introContent = {};

        if( introObject ) {
          introContent = {
            id: response.data.introContent.id,
            type: 'introduction',
            headline: response.data.introContent.headline,
            content: response.data.introContent.text,
            audioUrl: response.data.introContent.audioUrl
          }
        }


        this.setState({
          comments: communityResponse.comments,
          responseContent: communityResponse,
          questionContent: {
            id: moduleContent.id,
            questionId: 1,
            type: 'question',
            content: moduleContent.text,
            audioUrl: moduleContent.audioUrl
          },
          requestedFeedback: communityResponse.request_feedback,
          introContent: introContent
        });
      }
    });

  }

  addCommentToState(comment) {
    const newComments = this.state.comments;
    newComments.push(comment);

    this.setState({comments: newComments});
  }

  render() {

    let introComponent;
    if( this.state.introContent && this.state.introContent.id ){
      introComponent = <QuestionText content={this.state.introContent} isCommunityResponse={true} />;
    }


    // Load the question component once we have data
    let questionComponent;

    // Define the back url so we can direct the learner back to the previous screen
    const baseUrl = window.location.pathname;
    const urlArray = baseUrl.split('/');
    const backUrl = `/${urlArray[1]}/${urlArray[2]}/${urlArray[3]}/${urlArray[4]}/${urlArray[5]}`;

    if( this.state.questionContent.id ){
      questionComponent = <QuestionText content={this.state.questionContent} backUrl={backUrl} isCommunityResponse={true} />;
    }

    // Load the response component once we have data
    let responseComponent;
    if( this.state.responseContent.id ){
      responseComponent = <ContentResponse response={this.state.responseContent} index={'1'} isCommentIndex={true} />;
    }

    const commentsWord = (this.state.comments.length === 1) ? 'Comment ' : 'Comments ';


    return (
      <div class='conversation-wrapper'>
        <div class='questions'>
          {introComponent}
          {questionComponent}
          {responseComponent}

          <div class='response-header'> &#8213; {this.state.comments.length} {commentsWord} &#8213; </div>

          {
            this.state.comments.map((comment, index) => (
              <ContentResponse response={comment} index={index} isComment={true} />
            ))
          }
        </div>

        <CommentForm addCommentToState={this.addCommentToState} />
      </div>

    )
  }
};

// export default withRouter(ResponseComments);
export default ResponseComments;