import LessonCard from '../../utilities/lessoncards/lessonCard';


function ExpressionRelated(props) {

  // Define variables
  const relatedLessons = props.relatedLessons || [];


  return (
    <div class='related-lesson-wrapper expression'>
      {relatedLessons.map((lesson, index) =>
        <LessonCard
          lesson={lesson}
          key={index}
          hideProgressBars={true}
        />
      )}
    </div>
  );
}

export default ExpressionRelated;