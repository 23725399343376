import React from "react";
import { useSelector } from "react-redux";

import ExpressionRelated from "../../expression/screens/expressionRelated";


function LeftColumn () {

  // Define variables
  const relatedLessons = useSelector((state) => state.lesson.currentLesson.relatedLessons);


  return (
    <div class='lesson-left-column'>
      <p class='related-lessons-title'>Related Lessons</p>
      <ExpressionRelated relatedLessons={relatedLessons} />
    </div>
  );
};

export default LeftColumn;