import { useSelector } from "react-redux";
import ScoreDonut from './scoredonut.jsx';


function ScoreDetailsBiteLite(props) {

  // Define variables
  const responseData = props.responseObject || {};
  const ieltsCourseDispatch = useSelector((state) => state.lesson.ieltsCourse);
  const ieltsCourseProps = props.ieltsCourse;
  const ieltsCourse = ieltsCourseDispatch || ieltsCourseProps;

  // Handle overall score variables
  let overallScorePercent;
  let overallScore;

  let ieltsOverallScore;
  // let ieltsOverallPercent;

  if( ieltsCourse ) {
    ieltsOverallScore = responseData?.ieltsScores?.overall;
    // ieltsOverallPercent = ieltsOverallScore;

    overallScore = ieltsOverallScore;
    overallScorePercent = ieltsOverallScore;
  } else {
    overallScore = responseData?.overall?.word;
    overallScorePercent = responseData?.overall?.percent;
  }


  return (
    <div style={{marginTop: '4px'}}>
      <div class='flex-row clean-button response-score'>
        <span style={{cursor: 'initial'}}>{overallScore}</span>
        <ScoreDonut
          scorePercent={overallScorePercent}
          isIelts={ieltsCourse}
        />
      </div>
    </div>
  );
}

export default ScoreDetailsBiteLite;