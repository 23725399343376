import React from "react";
import { useState, useEffect } from "react";

import alertMessage from "../utilities/alertMessage";
import { signup, sendSmsAccountVerifyCode } from "../utilities/auth/authApi";
import { Mixpanel } from '../utilities/analytics/mixpanelHelpers';
import SignupForm from "./signupForm";


function Signup(props) {

  // Define variables
  let utmCode;
  let promoCode;
  let selectedPackage;
  let referrer = document.referrer;

  if( typeof window.URLSearchParams === 'function' ) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    utmCode = urlParams.get('utm_code');
    promoCode = urlParams.get('promo_code');
    selectedPackage = urlParams.get('package');
  }

  const changeCurrentScreen = props.changeCurrentScreen;
  const runLoginProcess = props.runLoginProcess;
  const updateField = props.updateField;
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorText, setErrorText] = useState([]);
  const [redirectToVerifyAccount, setRedirectToVerifyAccount] = useState(false);


  // Page load actions
  useEffect(() => {
    if( navigator.userAgent ) {
      const userAgent = navigator.userAgent.toLowerCase();
      const isAndroid = userAgent.indexOf("android") > -1;
      const isInstagram = userAgent.indexOf("instagram") > -1;
      const isFacebook = userAgent.indexOf("fb_iab") > -1;

      if( (isInstagram || isFacebook) && isAndroid ) {
        setRedirectToVerifyAccount(true);
      }
    }
  }, []);


  // Define functions
  function register(payload) {
    let mounted = true;

    signup(payload).then((response) => {

      if( response && response.data ){

        const data = response.data;

        if( data.success ) {

          localStorage.setItem("showIntroTour", true);

          // Send to the welcome page or verify
          mounted = false;
          // let path = '/welcome';
          let path = '/welcome-choose-pathway';
          if( redirectToVerifyAccount ){ path = '/verify-account' }
          runLoginProcess(data, path);

        } else {
          setErrorText(data.errors.join('. '));
          alertMessage(data.errors.join('. '), 'error');
          Mixpanel.track('Error: Register Error', {error: data.errors.join('. ')});
        }
      } else {
        alertMessage('Sorry, there was an error. Please contact support@flowspeak.io for assistance.', 'error');
        Mixpanel.track('Error: Register Error', {error: 'Unknown error'});
      }

    }).catch((error) => {
      setErrorText(error?.message);
      alertMessage(error?.message, 'error');
      Mixpanel.track('Error: Register Error', {error: error});
    }).finally(() => {
      // Enable the submit button
      if( mounted ){ setButtonLoading(false) }
    });

  };

  function smsRegister(payload) {

    let mounted = true;

    // Set the smsPayload so we can use it later
    updateField('smsPayload', payload);

    sendSmsAccountVerifyCode(payload).then((response) => {

      if( response && response.data ) {
        if( response.data.success ) {
          mounted = false;
          changeCurrentScreen('verify');
        } else {
          alertMessage(response.data.error, 'error');
        }
      } else {
        // Handle
      }

    }).catch((error) => {
      Mixpanel.track('Error: SMS Login Error', {error: error});
    }).finally(() => {
      if( mounted ) {
        setTimeout(() => setButtonLoading(null), 400);
      }
    });

  }


  return (
    <SignupForm
      errorText={errorText}
      promoCode={promoCode}
      utmCode={utmCode}
      referrer={referrer}
      selectedPackage={selectedPackage}
      buttonLoading={buttonLoading}
      setButtonLoading={setButtonLoading}
      changeCurrentScreen={changeCurrentScreen}
      register={register}
      smsRegister={smsRegister}
    />
  );
}

export default Signup;