function WordScoreContent(props) {

  // Define variables
  const wordScore = props.wordScore;
  const showScoreDetailsTooltipModal = props.showScoreDetailsTooltipModal;

  const wordScoreArray = [];
  wordScore.map((wordObject, index) => {

    const textArray = [];
    const phoneticArray = [];
    wordObject.phone_score_list.map((phoneObject, phoneIndex) => {
      const phoneScoreObject = scoreToWord(phoneObject.quality_score, phoneObject.sound_most_like);

      const data = (
        <div key={`word-score-phone-${phoneIndex}`}>
          <span class={phoneScoreObject.className}>{phoneObject.phone}</span>
          <span class={phoneScoreObject.className}>{phoneScoreObject.word}</span>
        </div>
      )

      phoneticArray.push(<span class='phone-container'>{phoneObject.phone}<span class='phone-dot'>&middot;</span></span>);
      textArray.push(data);
    });

    const text = (
      <div class='word-score-phone-container' key={`word-score-${index}`}>
        Word: <strong>{wordObject.word}</strong>
        <br />
        Word Score: <strong>{wordObject.qualityScore}</strong>
        <br />
        <br />
        Phonetic Pronunciation
        <br />
        {phoneticArray}
        <br />
        <br />
        <div>
          <span><strong>Sound</strong></span>
          <span><strong>Score</strong></span>
        </div>
        {textArray}
      </div>
    );

    const data = (
      <button
        type='button'
        class={wordObject.qualityScoreClass}
        key={index}
        title={'Tap to see details'}
        onClick={(event) => showScoreDetailsTooltipModal(event, text)}
      >{wordObject.word}</button>
    );

    wordScoreArray.push(data);

  });


  return (
    <div class='word-detail-breakdown'>
      {wordScoreArray}
      <p class='helper-text'>Tap each word to see more detail</p>
    </div>
  );
}

export default WordScoreContent;



function scoreToWord(score, soundsLike) {
  let wordObject;
  if( soundsLike ) {
    if( score >= 80 ) {
      wordObject = {word: 'Excellent', className: 'Excellent'};
    } else if ( score >= 55 ) {
      wordObject = {word: `Good - your sound: ${soundsLike}`, className: 'Good'};
    } else {
      wordObject = {word: `OK - your sound: ${soundsLike}`, className: 'Ok'};
    }

  } else {
    wordObject = {word: 'Missing', className: 'needs-improvement'};
  }

  return wordObject;
}