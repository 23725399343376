import React from "react";

import PostHeader from "./postheader";
import PostFooter from "./postfooter";
import PostMainImage from "./postmainimage";
import PostBody from "./postbody";


function PostImage(props) {

  const post = props.post;
  const postId = post.id;
  const postType = post.post_type;
  const author = post.author;
  const authorPublicProfileId = post.author_public_profile_id;
  const countryCode = post.country_code;
  const postDate = post.posted_date;
  const bodyText = post.body;
  const audioUrl = post.audio_url || false;
  const avatarUrl = post.avatar_url;

  const likeCount = post.like_count;
  const learnerLikeCount = post.learner_like_count;
  const commentCount = post.comment_count || 0;
  const headerImageUrl = post.header_image_url;
  const headerAudioUrl = post.header_audio_url;
  const headerText = post.header_text;

  const postLink = `/community/posts/${post.id}/comments`;
  const handleLikeChange = props.handleLikeChange;
  const noLinks = props.noLinks || null;
  const subHeading = props.subHeading;

  const lessonId = post.learning_module_id;


  return (

    <div>

      <PostHeader
        author={author}
        authorPublicProfileId={authorPublicProfileId}
        avatarUrl={avatarUrl}
        countryCode={countryCode}
        postDate={postDate}
        postType={postType}
      />

      <PostMainImage
        id={postId}
        headerImageUrl={headerImageUrl}
        headerAudioUrl={headerAudioUrl}
        headerText={headerText}
        subHeading={subHeading}
      />

      <PostBody
        audioUrl={audioUrl}
        lessonId={lessonId}
        noLinks={noLinks}
        bodyText={bodyText}
        postLink={postLink}
        trackScrollPosition={props.trackScrollPosition}
      />

      <PostFooter
        id={postId}
        commentCount={commentCount}
        handleLikeChange={handleLikeChange}
        likeCount={likeCount}
        learnerLikeCount={learnerLikeCount}
        noLinks={noLinks}
        postLink={postLink}
        postType={postType}
        trackScrollPosition={props.trackScrollPosition}
      />

    </div>
);
}

export default PostImage;