import PlayAudioIcon from "../playaudioicon";


function AvatarWithAudio(props) {

  // Define variables
  const type = props.type;
  const avatarUrl = props.avatarUrl;
  const audioUrl = props.audioUrl;
  const size = props.size || 'small';

  const fullActive = (type === 'assistant') ? true : false;
  const fullDark = (type === 'learner') ? true : false;
  const extraClass = (type === 'learner') ? 'learner' : false;
  const position = (type === 'learner') ? 'left' : null;


  // Screen components
  let audioElement;
  if( audioUrl ) {
    audioElement = <PlayAudioIcon audioUrl={audioUrl} fullActive={fullActive} fullDark={fullDark} position={position} />;
  }


  return (
    <div class={`avatar-with-audio-image-container ${size}`}>
      <img src={avatarUrl} width='45' alt='Manager icon' />
      <div class={`avatar-with-audio-audio-container ${extraClass}`}>
        {audioElement}
      </div>
    </div>
  );
}

export default AvatarWithAudio;