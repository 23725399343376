import { Link } from "react-router-dom"

import '../../styles/nextLesson.css';
import VectorIcon from "../utilities/vectorIcon";
import generateLessonLinkPath from "../../functions/generateLessonLinkPath";


function NextLesson(props) {

  // Define variables
  const nextLesson = props.nextLesson;
  const id = nextLesson.id;
  const title = nextLesson.title;
  const level = nextLesson.level;
  const duration = nextLesson.duration;
  const lessonType = nextLesson.lessonType.toLowerCase();
  const displayLessonType = nextLesson.displayLessonType;
  const nextLessonUrl = generateLessonLinkPath(lessonType, id);
  const functionToCallAfterNavigate = props.functionToCallAfterNavigate;


  return (
    <div class='next-lesson-container'>
      <p class='next-instruction'>Take the next Lesson</p>
      <Link
        to={nextLessonUrl}
        class='next-lesson-link-container'
        title='Take the next lesson'
        onClick={functionToCallAfterNavigate}
      >
        <div class='next-lesson-details-container'>
          <p class='next-title'>{title}</p>
          <p class='next-type'>{level} {displayLessonType}</p>
          <p class='next-duration'>{duration}</p>
        </div>
        <VectorIcon
          icon='chevronForwardFull'
          color={'active'}
          size={'2.6rem'}
        />
      </Link>
    </div>
  );
}

export default NextLesson;