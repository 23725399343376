import React from 'react';
import { useState } from 'react';

import VectorIcon from './vectorIcon';
import { Mixpanel } from "../utilities/analytics/mixpanelHelpers";


function PhrasePhoneDetail(props) {

  // Define variables
  const [modalContent, setModalContent] = useState(null);
  const responseObject = props.responseObject;
  const wordScore = responseObject.wordScore;
  const wordList = [];

  for (let i = 0; i < wordScore.length; i++) {
    const wordObject = wordScore[i];

    const word = (
      <span>
        <button
          class={`clean-button ${wordObject.qualityScoreClass}`}
          title={'Tap to see details'}
          tabIndex={0}
          key={`word-${i}`}
          onClick={(event) => openWordDetailModal(event, i)}
        >{wordObject.word}</button>
        &nbsp;
      </span>
    );

    wordList.push(word);
  }


  // Define functions
  function closeModal() {
    setModalContent(null);
  }

  function openWordDetailModal(event, iterator) {

    const wordObject = wordScore[iterator];
    const word = wordObject.word.replace(',', '').replace('.', '');
    const phoneArray = [];
    const textArray = [];

    wordObject.phone_score_list.map((phoneObject, phoneIndex) => {
      const phoneScoreObject = scoreToWord(phoneObject.quality_score, phoneObject.sound_most_like);

      const data = (
        <div
          key={`word-score-phone-${phoneIndex}`}
          class={phoneScoreObject.className}
        >
          <span>{phoneObject.phone}</span>
          <span>{phoneScoreObject.word}</span>
        </div>
      )

      phoneArray.push(<span class='phone-container'>{phoneObject.phone}<span class='phone-dot'>&middot;</span></span>);
      textArray.push(data);
    });

    let previousWordButton = <span>&nbsp;</span>;
    let nextWordButton = <span>&nbsp;</span>;

    if( iterator > 0 ) {
      previousWordButton = (
        <button
          type='button'
          class='clean-button pointer'
          onClick={() => nextWord(iterator - 1) }
        >
          <VectorIcon icon={'chevronBackOutline'} size={'1.5rem'} />
          <span style={{marginLeft: '5px'}}>Previous</span>
        </button>
      );
    }

    if( (iterator + 1) < wordScore.length ) {
      nextWordButton = (
        <button
          type='button'
          class='clean-button pointer'
          onClick={() => nextWord(iterator + 1) }
        >
          <span style={{marginRight: '5px'}}>Next</span>
          <VectorIcon icon={'chevronForwardOutline'} size={'1.5rem'} />
        </button>
      );
    }



    const content = (
      <div class='window–modal-wrapper'>
        <div class='window-modal word-score-modal'>

          <div class='word-score-modal-header'>
            <div class='flex-row' style={{width: '100%'}}>
              <span class='icon'>&nbsp;</span>
              <p>Word Score: {word}</p>
              <button class='icon icon-close' tabIndex={0} onClick={closeModal}></button>
            </div>
            <h2>{wordObject.qualityScore}%</h2>
          </div>

          <div class='word-score-modal-body'>
            <p>Phonetic Pronunciation</p>
            {phoneArray}
            <p>&nbsp;</p>
            <p>Phonetic Score</p>
            <div class='word-score-modal-phone-container'>
              {textArray}
            </div>
          </div>

          <div class='word-score-modal-footer'>
            {previousWordButton}
            {nextWordButton}
          </div>

        </div>
      </div>
    )

    setModalContent(content);

    Mixpanel.track('Event: Expression open word score modal', {'word': word});
  }

  function nextWord(iterator) {
    // closeModal();
    openWordDetailModal(null, iterator);
  }


  // Screen components
  let modal;
  if( modalContent ) {
    modal = modalContent;
  }


  return (
    <div>
      {modal}
      <div class='transcript-words-score-container'>
        {wordList}
      </div>
    </div>
  )
}

export default PhrasePhoneDetail;


// --

function scoreToWord(score, soundsLike) {
  let wordObject;
  if( soundsLike ) {
    if( score >= 80 ) {
      wordObject = {word: 'Excellent', className: 'Excellent'};
    } else if ( score >= 55 ) {
      wordObject = {word: `Good - your sound: ${soundsLike}`, className: 'Good'};
    } else {
      wordObject = {word: `OK - your sound: ${soundsLike}`, className: 'Ok'};
    }

  } else {
    wordObject = {word: 'Missing', className: 'needs-improvement'};
  }

  return wordObject;
}