import {IoMdPerson} from "react-icons/io";


function AvatarImage(props) {

  // Define variables
  const avatarUrl = props.avatarUrl;
  const size = props.size || 40;
  const backupSize = size - 13;


  // // Screen components
  // let imageElement;
  // // if( avatarUrl.includes('default-avatar') ) {
  //   imageElement = (
  //     <div
  //       class='flex-column avatar-image'
  //       style={{width: size, height: size, maxWidth: size, maxHeight: size, justifyContent: 'center', backgroundColor: '#f1f1f1'}}
  //     >
  //       <IoMdPerson color={'#848484'} size={`${backupSize}px`} />
  //     </div>
  //   );
  // } else {
  //   imageElement = (
  //     <img
  //       src={avatarUrl}
  //       style={{width: size, height: size, maxWidth: size, maxHeight: size}}
  //       class='avatar-image'
  //       alt='Profile'
  //     />
  //   );
  // }


  return (
    // <>{imageElement}</>
    <img
      src={avatarUrl}
      style={{width: size, height: size, maxWidth: size, maxHeight: size}}
      class='avatar-image'
      alt='Profile'
    />
  );
}

export default AvatarImage;