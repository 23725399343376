import ScoresBlock from "./scoresBlock";
import LessonDetailComments from "./lessonDetailComments";
import MessageLearner from "../../utilities/learning_module/messageLearner";
import MessageSpeaker from "../../utilities/learning_module/messageSpeaker";


function LessonDetailResponse(props) {

  // Define functions
  const isIelts = props.isIelts;
  const lessonType = props.lessonType;
  const response = props.response;

  const questionText = response.questionText;
  const questionAudioUrl = response.questionAudioUrl;
  const responseText = response.responseText;
  const responseAudioUrl = response.responseAudioUrl;
  const retryCount = response.retryCount;
  const scores = response.scores;
  // const likeCount = response.likeCount;
  const comments = response.comments;


  // Screen compoents
  let questionElement;
  if( questionText ) {
    questionElement = (
      <MessageSpeaker
        message={questionText}
        audioUrl={questionAudioUrl}
      />
    );
  }


  return (
    <div class='lesson-detail-content'>
      {questionElement}

      <MessageLearner
        message={responseText}
        audioUrl={responseAudioUrl}
      />

      <div class='flex-row lesson-detail-content-response-score-wrapper'>
        <div class='lesson-detail-content-response-score-container'>
          <ScoresBlock
            isIelts={isIelts}
            scores={scores}
            lessonType={lessonType}
            retryCount={retryCount}
          />
        </div>
      </div>

      <LessonDetailComments comments={comments} />

    </div>
  );
}

export default LessonDetailResponse;