import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import CompleteSuccess from "./completesuccess";
import CompleteFailed from "./completefailed";
import HeaderMain from "../utilities/headermain";
import Nav from "../utilities/nav";
import LoadingBasic from "../utilities/loadingbasic";

import fadeInMainBody from "../../functions/fadeinmainbody";
import { finalizeSubscriptionSuccess, lookupPaymentDetails } from "../utilities/auth/authApi";


function PackagesComplete() {

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const paymentIntentId = new URLSearchParams(window.location.search).get('payment_intent');
  const [loading, setLoading] = useState(<LoadingBasic />);
  const [message, setMessage] = useState('');

  useEffect(() => {

    fadeInMainBody();

    function setHasPremiumSubscription(value) {
      dispatch({ type: 'setHasPremiumSubscription', payload: {hasPremiumSubscription: value}});
    }

    if( paymentIntentId ) {

      const payload = {
        payment_intent_id: paymentIntentId
      };

      lookupPaymentDetails(payload).then((response) => {

        if( response && response.data ) {
          handlePaymentDetails(response.data);
        } else {
          // Handle no response
        }

      });

    } else {
      // Redirect
      navigate('/dashboard');
    }


    function handlePaymentDetails(paymentDetails) {

      const paymentStatus = paymentDetails.paymentStatus;
      const stripePriceId = paymentDetails.stripePriceId;

      switch (paymentStatus) {
        case 'succeeded':
          // setMessage('Success! Payment received.');
          setMessage(<CompleteSuccess paymentDetails={paymentDetails} />);
          setLoading(null);
          finalizeSubscription(stripePriceId);
          break;

        case 'processing':
          setMessage("Payment processing. We'll update you when payment is received.");
          break;

        case 'requires_payment_method':
          setMessage(<CompleteFailed paymentDetails={paymentDetails} />);
          setLoading(null);
          break;

        default:
          setMessage('Something went wrong.');
          break;
      }

    }

    // Let the server know the payment went through succesfully
    // and associate the learner to the subscription
    function finalizeSubscription(stripePriceId) {

      const payload = {
        stripe_price_id: stripePriceId
      };

      finalizeSubscriptionSuccess(payload).then((response) => {

        // console.log('finalize response', response);

        if( response && response.data && response.data === 'success' ) {
          // Update redux
          setHasPremiumSubscription(true);
        } else {
          // Handle no response
        }

      });

    }

  }, [paymentIntentId, dispatch]);


  return (
    <div class='wrapper gray-background'>
      <Nav />
      <div class='main-body invisible'>
        <HeaderMain title="Purchase Complete" />
        <div class='main-body-content purchase-complete'>
          {loading}
          {message}
        </div>
      </div>
    </div>
  );
};

export default PackagesComplete;