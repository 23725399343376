import React from "react";
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import PurchaseSteps from "./purchasesteps";
import CheckoutForm from "./checkoutform";
import PurchaseSummary from "./purchasesummary";
import CancelLink from "./cancellink";

import { makePaymentIntent } from "../utilities/auth/authApi";
import LoadingBasic from "../utilities/loadingbasic";
import HeaderMain from "../utilities/headermain";
import Nav from "../utilities/nav";

import fadeInMainBody from "../../functions/fadeinmainbody";

import { stripePublishableKey } from "../utilities/stripe/stripeHelpers.js";
const stripePromise = loadStripe(stripePublishableKey);


function PackagesPurchase() {

  const navigate = useNavigate();
  const packageId = useSelector((state) => state.package.packageId);
  const promoCode = useSelector((state) => state.package.promoCode);
  // const packageData = useSelector((state) => state.package.packageData);
  const currencyCode = useSelector((state) => state.package.currencyCode);

  const [checkout, setCheckout] = useState(<LoadingBasic />);
  const [packageData, setPackageData] = useState(null);


  useEffect(() => {
    fadeInMainBody();

    // Ensure a package is selected
    if( packageId ) {

      const payload = {
        packageId: packageId,
        promoCode: promoCode,
        currencyCode: currencyCode
      }

      // Make the payment intent
      makePaymentIntent(payload).then((response) => {

        const options = {
          clientSecret: response.data.clientSecret,
        };

        if( response.data ) {

          setPackageData(response.data);

          setCheckout(
            <div>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
              </Elements>
            </div>
          );
        }
      });

    } else {
      // Redirect
      navigate('/packages');
    }


  // });
}, [packageId, promoCode, currencyCode]); // -------------------------------


  return (
    <div class='wrapper gray-background'>
      <Nav />
      <div class='main-body invisible'>
        <HeaderMain title="Purchase" />
        <div class='main-body-content purchase'>
          <PurchaseSteps currentStep={'purchase'} />
          <PurchaseSummary packageData={packageData} />
          {checkout}
          <CancelLink />
        </div>
      </div>
    </div>
  );
};

export default PackagesPurchase;