import React from "react";

import InputTextareaBasicRichText from "../utilities/forms/inputtextareabasicrichtext";
import InputHidden from "../utilities/forms/inputhidden";
import ButtonSubmit from "../utilities/forms/buttonsubmit";
import RecordBasicAudio from "../utilities/recordbasicaudio";


function NewComment(props) {

  // Define variables
  const author = props.author;
  const commentableType = props.commentableType;


  return (
    <div class='new-comment-wrapper'>
      <form onSubmit={props.handleFormSubmit} class='new-comment-form'>

        <InputTextareaBasicRichText
          name={'text'}
          placeholder={'Write a comment ...'}
          required={true}
          commentableType={commentableType}
          author={author}
        />

        <InputHidden
          name={'commentable_type'}
          value={commentableType}
        />

        <InputHidden
          name={'post_id'}
          value={props.id}
        />

        <div class='new-comment-actions'>
          <RecordBasicAudio handleAudioRecording={props.handleAudioRecording} removeAudio={props.removeAudio} darkMode={true} />
          <ButtonSubmit value={'Comment'} />
        </div>

      </form>
    </div>
  );
}

export default NewComment;