import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import apiCall from '../utilities/auth/apiCall';
import { validateSmsAccountVerifyCode } from "../utilities/auth/authApi";
import VerifyForm from '../start/verifyForm';


function OrgVerify(props) {

  // Define variables
  const [code, setCode] = useState('');
  const [errorText, setErrorText] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(null);

  const runLoginProcess = props.runLoginProcess;
  const changeCurrentScreen = props.changeCurrentScreen;

  const smsPayload = useSelector((state) => state.profile.smsPayload);
  const countryCode = smsPayload.phone_country_code;
  const phoneNumber = smsPayload.phone_number;
  const type = 'register';


  // Define functions
  function validateAccountCode() {

    const payload = JSON.parse(JSON.stringify(smsPayload));;
    payload.code = code;

    apiCall(validateSmsAccountVerifyCode, payload).then((response) => {
      if( response.success ) {
        runLoginProcess(response.data);
      } else {
        setErrorText(response.error);
      }
    }).finally(() => {
      setTimeout( setButtonLoading(false), 400);
    });

  }


  return(
    <VerifyForm
      validateLoginCode={undefined}
      validateAccountCode={validateAccountCode}
      changeCurrentScreen={changeCurrentScreen}
      buttonLoading={buttonLoading}
      setButtonLoading={setButtonLoading}
      errorText={errorText}
      setErrorText={setErrorText}
      code={code}
      setCode={setCode}
      type={type}
      countryCode={countryCode}
      phoneNumber={phoneNumber}
    />
  );
}

export default OrgVerify;