import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthed } from "./tokenHelpers";


const PrivateRoute = ({component: Component}) => {
  const authedId = isAuthed();
  return authedId ? <Component /> : <Navigate to="/start" />;
};

export default PrivateRoute;