import React from 'react';
import { Link } from 'react-router-dom';

import '../../../styles/lessonCard.css';
import generateLessonLinkPath from '../../../functions/generateLessonLinkPath';

const defaultImageUrl = 'https://images.pexels.com/photos/7130552/pexels-photo-7130552.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';


function LessonCard(props) {

  // Define variables
  // const index = props.index;
  const firstInList = props.firstInList;
  const lastInList = props.lastInList;
  const lesson = props.lesson;
  const complete = lesson.isComplete;
  const isRepeat = lesson.isRepeat;
  const hasAccessToLesson = lesson.learnerHasAccessToLesson;
  const lessonType = lesson.lessonType;
  const level = lesson.level;
  const thumbnailUrl = lesson.thumbnailUrl || defaultImageUrl;
  const hideProgressBars = props.hideProgressBars;
  const showCompletedDate = props.showCompletedDate;
  const linkPath = generateLessonLinkPath(lessonType, lesson.id);

  let displayLessonType = lesson.lessonType;
  if( displayLessonType ) {
    displayLessonType = displayLessonType.replace('ListeningLesson', 'Listening');
    displayLessonType = displayLessonType.replace('AiTalk', 'AI Talk');
  }


  // Screen components
  let centerLineTopClass = 'status-center';
  if( !firstInList ) { centerLineTopClass = 'status-center status-center-line' }

  let centerLineBottomClass = 'status-center';
  if( !lastInList ) { centerLineBottomClass = 'status-center status-center-line' }

  let icon;
  if( complete ) {
    if( hasAccessToLesson ) {
      icon = <div class='icon icon-complete-purple'></div>;
    } else {
      icon = <div class='icon icon-lock-purple'></div>;
    }
  } else {
    if( hasAccessToLesson ) {
      icon = <div class='icon icon-circle-gray'></div>;
    } else {
      icon = <div class='icon icon-lock-dark'></div>;

    }
  }


  let leftBarElement;
  if( !hideProgressBars ) {
    leftBarElement = (
      <div class='lesson-card-left-bar'>

        <div class='status-top-container'>
          <div class='status-left'></div>
          <div class={centerLineTopClass}></div>
          <div class='status-right'></div>
        </div>

        <div class='status-indicator'>
          {icon}
        </div>

        <div class='status-bottom-container'>
          <div class='status-left'></div>
          <div class={centerLineBottomClass}></div>
          <div class='status-right'></div>
        </div>

      </div>
    );
  }

  let repeatElement;
  if( isRepeat ) {
    repeatElement = (
      <button
        class='clean-button repeat-element-container'
        type='button'
        onClick={() => alert("Repetition is key to improvement. Do this lessons again to lock it in your memory!")}
      >
        <p class='repeat-lesson'>Repeat lesson</p>
        <span class='icon icon-info' />
      </button>
    );
  }

  let completedOnElement;
  if( showCompletedDate && lesson.completedOn ) {
    completedOnElement = <p class='completed_on'>Completed on {lesson.completedOn}</p>;
  }


  return (
    <div class='lesson-card-wrapper'>

      {leftBarElement}

      <Link to={linkPath} class={'lesson-card-details-container'}>

        <div
          class='lesson-card-image'
          style={{backgroundImage: `url(${thumbnailUrl})`}}
        ></div>

        <div class='lesson-card-content'>
          <div class='lesson-card-type-container'>
            <p class='display-lesson-type'>{displayLessonType}</p>
          </div>
          <h6>{lesson.title}</h6>
          <p><i>{level}{lesson.duration ? ',' : null} {lesson.duration}</i></p>
          {repeatElement}
          {completedOnElement}
        </div>

      </Link>

    </div>
  );
}

export default LessonCard;
