import React from 'react';
import '../../../styles/scoredonut.css';

const colorExcellent = '#BC0181';
const colorGood = '#7E0278';
const colorOk = '#3F036E';


function ScoreDonutWithText(props) {

  // Define variables
  const isIelts = props.isIelts;
  const text = props.text;
  let percent = props.percent;
  let scoreText = percent;

  if( isIelts ) {
    percent = ((percent / 9) * 100).toFixed();
    scoreText = percent;
  }


  let color
  if( percent >= 80 ) {
    color = colorExcellent;
  } else if ( percent >= 55 ) {
    color = colorGood;
  } else {
    color = colorOk;
  }

  let donut;
  if( percent > 0 ) {
    donut = (
      <div
        class='pie animate'
        style={{
          '--p': scoreText,
          '--c': color
        }}>
      </div>
    );
  }


  return (
    <span class='score-donut-wrapper with-text'>
      <div class='score-donut'>
        {donut}
      </div>
      <div class='donut-hole'>
        <p>{text}</p>
      </div>
    </span>
  );
}

export default ScoreDonutWithText;