import React from "react";
import PurchaseSummary from "./purchasesummary";


function CompleteSuccess (props) {

  const paymentDetails = props.paymentDetails;

  return (
    <div class='payment-complete-success-wrapper'>

      <h3>Thank you for becoming a <span class='nowrap'>Premium Member!</span></h3>

      <p>Nice job on committing to your learning. &#x1F525;</p>

      <p>Remember to do one lesson every day <br />to keep improving.  &#x1F680;</p>

      <PurchaseSummary packageData={paymentDetails} noDisclaimer={true} />

      <div class='action-button-wrapper'>
        <a href='/dashboard' class='button action-button'>Back to the Lessons!</a>
      </div>

    </div>
  );
};

export default CompleteSuccess;