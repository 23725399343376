import ChallengeBadgeWithNumber from '../challengeBadgeWithNumber';


function StatsChallengeBar(props) {

  // Define variables
  const weeklyChallengesCompleted = props.weeklyChallengesCompleted;
  const isThisLearner = props.isThisLearner;
  let showElement = true;

  if( weeklyChallengesCompleted < 1 && !isThisLearner ) {
    showElement = false;
  }


  // Screen components
  let bodyElement;
  if( showElement ) {
    bodyElement = (
      <div class='flex-column stat-container stat-challenge-container'>
        <div class='flex-row'>
          <ChallengeBadgeWithNumber number={weeklyChallengesCompleted} />
        </div>
      </div>
    )
  }


  return (
    <>{bodyElement}</>
  );
}


export default StatsChallengeBar;


// --

// const styles = StyleSheet.create({
//   wrapper: {
//     justifyContent: 'center',
//     padding: theme.paddingPrimary,
//     borderBottomColor: theme.grayLightMid,
//     borderBottomWidth: 1,
//     backgroundColor: theme.grayLight
//   },
// });