import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";



function BadgeSocialShareBand(props) {

  // Define variables
  const scoreLevel = props.scoreLevel;
  const shareUrl = `https://www.flowspeak.io/badges/confidence-level-${scoreLevel}`;
  const title = 'I earned a FLOW Speak Badge';


  return (
    <div class='mt-4'>

      <p class='has-text-centered'>Share Your Achievement</p>

      <div class='social-share-container'>

        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>

        <FacebookShareButton
          url={shareUrl}
          quote={title}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={shareUrl}
          title={title}
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <LinkedinShareButton
          url={shareUrl}
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>

        <LineShareButton
          url={shareUrl}
          title={title}
        >
          <LineIcon size={32} round />
        </LineShareButton>

        </div>
    </div>
  );
}

export default BadgeSocialShareBand;