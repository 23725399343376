import { useState } from "react";
import VectorIcon from "./vectorIcon";


function PasswordWithEye(props) {

  // Define variables
  const [passwordHidden, setPasswordHidden] = useState(true);
  const setPassword = props.setPassword;
  const password = props.password;

  const eyeOffIcon = <VectorIcon icon='eyeOff' color='#6D6D6D' size='1.35rem' />;
  const eyeOnIcon = <VectorIcon icon='eye' color='#6D6D6D' size='1.35rem' />;
  const eyeIcon = passwordHidden ? eyeOnIcon : eyeOffIcon;


  return (
    <div class='password-wrapper'>
      <input
        name="password"
        type={passwordHidden ? 'password' : 'text'}
        value={password}
        onChange={event => setPassword(event.target.value)}
        minLength="8"
        title="Password must be at least 8 characters"
        required
        placeholder='Password'
        class='input password-input-with-eye'
      />

      <button
        type='button'
        class='flex-column clean-button password-eye'
        onClick={() => setPasswordHidden(!passwordHidden)}
      >{eyeIcon}</button>
    </div>
  );
}

export default PasswordWithEye;