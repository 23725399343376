import { Link } from "react-router-dom";


function WeekListUpgradeCta() {

  return (
    <Link
      to='/packages'
      class='flex-column week-list-upgrade-cta-container'
      title='View Plans'
    >

      <div class='flex-row week-list-upgrade-cta-header'>
        <div></div>
        <p>Upgrade to Premium</p>
        <div></div>
      </div>

      <p>to unlock access to all lessons <span class='nobr'>and earn badges</span></p>

    </Link>
  );
}

export default WeekListUpgradeCta;