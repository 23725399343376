import React from "react";

// import { Toaster } from 'react-hot-toast';
import toast from 'react-hot-toast';

import { flagComment } from "./auth/authApi";


function FlagContent (props) {

  const contentType = props.contentType;
  const id = props.id;
  const wrapperId = props.wrapperId;

  const title = 'Flag ' + contentType + ' as inappropriate';
  const toastMessage = 'The ' + contentType + ' has been flagged as inappropriate.';

  function handleClick(event) {
    if (window.confirm('Are you sure you want to flag this comment as inappropriate?')) {

      // const element = event.target;
      const payload = {comment_id: id};

      // TO DO - account for different resource type
      // const resourceType = element.getAttribute('data-resouce-type');

      flagComment(payload).then((response) => {
        const wrapperDiv = document.getElementById(wrapperId);
        if( wrapperDiv ) { wrapperDiv.classList.add('is-flagged') }

        toast.success(toastMessage);
      });

    }
  }

  return (
    <div
      class='flag-content'
      title={title}
      onClick={handleClick}>report</div>
  );
}

export default FlagContent;