import { useEffect, useState } from 'react';
import { reportError } from "../../functions/sentry";
import { getScoreScatter } from "../utilities/auth/authApi";
import LoadingBasic from "../utilities/loadingbasic";
import ScatterPlot from '../utilities/graphs/scatterplot';


function ScoreScatterPlot(props) {

  // Define variables
  const [scatter, setScatter] = useState(<LoadingBasic />);
  const publicProfileId = props.publicProfileId;


  // Page load actions
  useEffect(() => {

    if( publicProfileId) {
      getScoreScatter(publicProfileId).then((response) => {
        if( response?.data?.success ) {
          const scatter = (
            <ScatterPlot
              myData={response.data.scoreScatter.myScore}
              allData={response.data.scoreScatter.allScores}
            />
          );

          setScatter(scatter);
        }
      }).catch((error) => {
        reportError(error);
      });
    }

  }, [publicProfileId]);


  return (

    <div class='stat-container'>
      <h3>Confidence Level</h3>
      <p style={{marginTop: '-15px', marginBottom: '15px'}}>
        <small><i>Compared to other learners</i></small>
      </p>
      {scatter}
    </div>
  )
}

export default ScoreScatterPlot;