import React from "react";
import PackageFeatureList from "./packagefeaturelist";
import LoadingBasic from "../utilities/loadingbasic";
import currencyFormat from "../../functions/currencyformat";


function PackageSummary (props) {

  // const dispatch = useDispatch();

  // const packageId = useSelector((state) => state.package.packageId);

  const packageData = props.packageData;

  let packageContents = (
    <div class='package-summary'>

      <div class='summary-header'>
        <div class='summary-header-plan-name'>
          <h5>Premium Membership</h5>
        </div>
      </div>

      <LoadingBasic />

    </div>
  );

  if( packageData && packageData.packageExists ) {

    const intervalCount = packageData.intervalCount;
    const interval = (intervalCount > 1 ) ? packageData.interval + 's' : packageData.interval;

    // Stripe prices don't have decimals
    const packagePrice = (packageData.packagePrice / 100);

    packageContents = (
      <div>
        <div class='package-summary'>

          <div class='summary-header'>
            <div class='summary-header-plan-name'>
              <h5>Premium Membership</h5>
              <div>&nbsp;- {intervalCount} {interval}</div>
            </div>
            <div class='summary-price'>${currencyFormat(packagePrice)}</div>
          </div>

          <PackageFeatureList />

        </div>

        <div class='sub-line'>
          Your subscription will automatically renew every {intervalCount === 1 ? '' : intervalCount} {interval}.<br />
          You can cancel at any time.
        </div>
      </div>
    );
  }



  // useEffect(() => {

  // // });
  // }, []); // -------------------------------




  return (
    <div class='package-summary-wrapper'>
      {packageContents}
    </div>
  );
};

export default PackageSummary;