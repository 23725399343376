import ActivityGraph from "../graphs/activityGraph";


function StatsActivity(props) {

  // Define variables
  const publicProfileId = props.publicProfileId;
  const isThisLearner = props.isThisLearner;

  return (
    <div class='flex-column stat-container'>
      <h3>Activity</h3>
      <p style={{marginTop: '-15px', marginBottom: '15px'}}>
        <small><i>Over the past 30 days</i></small>
      </p>
      <ActivityGraph publicProfileId={publicProfileId} isThisLearner={isThisLearner} />
    </div>
  );
}


export default StatsActivity;