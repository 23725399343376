import React from "react";
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import StartLeftColumn from "./startLeftColumn";
import UnauthedStartHeader from "./unauthedStartHeader";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "../packages/checkoutform";
import PurchaseSummary from "../packages/purchasesummary";

import '../../styles/start.css';

import { makePaymentIntent } from "../utilities/auth/authApi";
import LoadingBasic from "../utilities/loadingbasic";
import fadeInMainBody from "../../functions/fadeinmainbody";

import { stripePublishableKey } from "../utilities/stripe/stripeHelpers.js";
// const stripePromise = loadStripe(stripePublishableKey);


function WelcomePurchase() {

  // Define variables
  const navigate = useNavigate();
  const packageId = useSelector((state) => state.package.packageId);
  const promoCode = useSelector((state) => state.package.promoCode);
  const currencyCode = useSelector((state) => state.package.currencyCode);
  const { slug } = useParams();

  const [checkout, setCheckout] = useState(<LoadingBasic />);
  const [packageData, setPackageData] = useState(null);

  let confirmationPath = '/welcome-purchase-confirmation';
  if( slug ) { confirmationPath = `/register/${slug}/purchase-confirmation` }


  // Page load actions
  useEffect(() => {
    fadeInMainBody();

    // Ensure a package is selected
    if( packageId ) {

      const payload = {
        packageId: packageId,
        promoCode: promoCode,
        currencyCode: currencyCode
      }

      // Make the payment intent
      makePaymentIntent(payload).then((response) => {

        if( response.data ) {

          setPackageData(response.data);

          const options = {
            clientSecret: response.data.clientSecret,
          };

          setTimeout(() => {
            populateCheckout(options);
          }, 300);

          // const stripePromise = loadStripe(stripePublishableKey);

          // setCheckout(
          //   <div>
          //     <Elements stripe={stripePromise} options={options}>
          //       <CheckoutForm returnPath={confirmationPath} />
          //     </Elements>
          //   </div>
          // );
        }
      });

    } else {
      goBack();
    }

  }, []);


  // Define functions
  function goBack() {
    navigate(-1);
  }

  function populateCheckout(options) {
    const stripePromise = loadStripe(stripePublishableKey);

    setCheckout(
      <div>
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm returnPath={confirmationPath} />
        </Elements>
      </div>
    );
  }


  return (
    <div class='wrapper'>
      <div class='main-body start with-start-left-column'>
        <StartLeftColumn />
        <div class='main-body-content start'>
          <div class='start-vertical-wrapper'>
            <div class='start-vertical-container'>
              <div class='welcome-mic-test-container'>

                <UnauthedStartHeader
                  hideTitle={true}
                  subTitle={" "}
                />

                {/* <h1>Purchase</h1> */}

                <PurchaseSummary packageData={packageData} />
                {checkout}

                <div class='purchase-back-button-wrapper'>
                  <button class='clean-button' onClick={goBack}>Go Back</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomePurchase;