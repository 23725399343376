import React from "react";

import PlayAudioIcon from "../utilities/playaudioicon";
import FlagContent from "../utilities/flagcontent";
import dateFormat from "../../functions/dateformat";
import RenderHtmlText from "../utilities/renderhtmltext";


function PostComments(props) {

  const comments = props.comments;

  return (

    <div class='comments-wrapper'>
      {comments.map((comment, index) =>
        <div class='comment-wrapper' key={index} id={'comment-' + index}>
          <div class='comment-header'>
            <p><strong>{comment.author}</strong> commented on {dateFormat(comment.created_at)}</p>
          </div>
          <div class='comment-body-wrapper'>
            <div class='comment-body'>
              <RenderHtmlText string={comment.text} />
            </div>
            <div class='comment-audio'>
              { ( comment.audio_url ) ? <PlayAudioIcon audioUrl={comment.audio_url} iconDark={true} /> : '' }
            </div>
          </div>
          <FlagContent
            contentType={'comment'}
            id={comment.id}
            wrapperId={'comment-' + index}
          />
        </div>
      )}
    </div>
  );
}

export default PostComments;