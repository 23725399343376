import axios from 'axios';
import { apiBaseUrl } from "../auth/authApi";
import { getToken } from "../auth/tokenHelpers";

const formSumbitPost = async (form, audioBlob) => {

  axios.defaults.baseURL = apiBaseUrl;

  let apiPath = 'posts';

  const formData = new FormData();

  const category = form.querySelector('select[name=category]').value;
  const body = form.querySelector('textarea[name=body]').value;
  const fileName = 'post-' + form.querySelector('textarea[name=body]').getAttribute('id');

  formData.append('category', category);
  formData.append('body', body);
  formData.append('post[category]', category);
  formData.append('post[body]', body);

  if( audioBlob ) {
    formData.append('audio', audioBlob, fileName);
  }

  var config = {
    method: 'post',
    url: apiPath,
    headers: { authorization: `Bearer ${getToken()}`, "Content-Type": "multipart/form-data" },
    data : formData
  };

  // Send the data to the server
  return axios(config)
  .then(function (response) {

    if( response && response.data ) {
      return response.data;
    } else {
      // handle with errors
    }


  })
  .catch(function (error) {
    return error;
  });

}

export default formSumbitPost;