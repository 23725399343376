function FeedbackElement(props) {

  // Define variables
  const author = props.author;
  const addText = props.addText;


  return (
    <div class='response-feedback-container'>
      <p class='ml-2 mb-2'>Give {author} feedback</p>
      <div class='feedback-button-wrapper'>
        <button type='button' class='button' onClick={addText}>Great Job!&nbsp;</button>
        <button type='button' class='button' onClick={addText}>You were very clear&nbsp;</button>
        <button type='button' class='button' onClick={addText}>You can improve by&nbsp;...</button>
      </div>
  </div>
  );
}

export default FeedbackElement;