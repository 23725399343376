import PlayAudioIcon from "../../utilities/playaudioicon";


function LessonDetailComments(props) {

  // Define variables
  const comments = props.comments;


  // Screen components
  let bodyElement;
  if( comments && comments.length > 0 ) {

    bodyElement = (
      <div class='flex-column lesson-detail-content-response-comments-container'>
        <h4>Comments</h4>
        {comments.map((comment, index) =>
          <div class='lesson-detail-content-response-comment'>
            <p>{comment.text}</p>
            <span>{comment.audioUrl ? <PlayAudioIcon audioUrl={comment.audioUrl} iconDark={true} /> : null }</span>
            <p>
              <small>By {comment.author} on {comment.created}</small>
            </p>
          </div>
        )}
    </div>
    );
  }

  return (
    <>{bodyElement}</>
  );
}

export default LessonDetailComments;