import { Link } from "react-router-dom";
import Flag from "../profile/flag";
import AvatarImage from "../utilities/avatarImage";
import ProgressBar from "../utilities/progressBar";

function ChallengeCard(props) {

  // Define variables
  const learner = props.learner;
  const learnerPublicProfileId = props.learnerPublicProfileId;
  const publicProfileId = learner.publicProfileId;
  const avatarUrl = learner.avatarUrl;
  const firstName = learner.firstName;
  const countryCode = learner.country;
  const goalCount = learner.goalCount;
  const completedCount = learner.completedCount;
  const complete = learner.complete;
  const score = learner.score;
  const isThisLearner = learnerPublicProfileId === publicProfileId;

  let containerClass = 'challenge-card-container';
  if( isThisLearner ) {
    containerClass += ' is-learner';
  }


  // Screen components
  let progressBarWidth;
  let completeIcon;

  if( complete ) {
    progressBarWidth = '100%';
    const iconClass =  'icon icon-challenge-full-burgendy-complete-with-shadow';

    completeIcon = (
      <div class='challenge-card-icon-container'>
        <div class={iconClass}></div>
      </div>
    );
  } else {
    let progressBarWidthInteger = ((completedCount/goalCount) * 100 );
    if( progressBarWidthInteger > 100 ) { progressBarWidthInteger = 100 }
    progressBarWidth = progressBarWidthInteger.toString() + '%';
  }

  let flag;
  if( countryCode ) {
    flag = <Flag countryCode={countryCode} />;
  }

  let completedCountElement;
  if( isThisLearner ) {
    completedCountElement = <p class='challenge-card-completed-count'>{completedCount} {completedCount === 1 ? 'lesson' : 'lessons'} completed this week</p>;
  }


  return (
    <Link
      to={{pathname: `/progress/${publicProfileId}`}}
      class={containerClass}
    >

      <AvatarImage avatarUrl={avatarUrl} />

      <div class='challenge-card-details'>

        <div class='challenge-card-name'>
          <p>{firstName}</p>
          {flag}
        </div>

        <p class='challenge-card-score'>{score} Confidence Level</p>

        <div class='challenge-card-bar-container'>
          <ProgressBar width={progressBarWidth} />
        </div>
        {completedCountElement}

      </div>

      {completeIcon}

    </Link>
  );
}

export default ChallengeCard;