import { Link } from "react-router-dom";
import VectorIcon from "../utilities/vectorIcon";


function FooterStepElement(props) {

  // Define variables
  const stepData = props.stepData;
  const step = stepData.step;
  const pathwayName = stepData.pathwayName;
  const level = stepData.level;
  const shortText = stepData.shortText;


  // Screen components
  let instruction;
  let titleStart;
  let titleEnd;
  let actionUrl;
  let text;

  if( step === 4 ) {

    instruction = 'You completed a Level';
    titleStart = `You completed ${pathwayName}`;
    titleEnd = `Level ${level}`;
    text = shortText;
    actionUrl = '/';

  } else if( step === 'course_complete_show_score' ) {

    instruction = 'You completed a Course';
    // titleStart = `You completed ${pathwayName}`;
    titleEnd = instruction;
    text = 'Return to the Dashboard';
    actionUrl = '/';

  }

  return(
    <div class='next-lesson-container'>
      <p class='next-instruction'>{instruction}</p>
      <Link
        to={actionUrl}
        class='next-lesson-link-container'
        title='Continue'
        // onClick={functionToCallAfterNavigate}
      >
        <div class='next-lesson-details-container'>
          <p class='next-title'>
            {titleStart} <span class='nobr'>{titleEnd}</span>
          </p>
          <p class='next-type'>{text}</p>
          {/* <p class='next-duration'>{duration}</p> */}
        </div>
        <VectorIcon
          icon='chevronForwardFull'
          color={'active'}
          size={'2.6rem'}
        />
      </Link>
    </div>
  );
}

export default FooterStepElement;