import React from "react";
import { useState } from "react";

import Modal from "../utilities/modal";
import ScoreDonut from "../utilities/learning_module/scoredonut";
// import LoadingBasic from "../utilities/loadingbasic";
// import RenderHtmlText from "../utilities/renderhtmltext";


function AiTalkMessageScore(props) {

  // Define variables
  // const [improveElement, setImproveElement] = useState(null);
  // const isIelts = props.isIelts;
  // const generateTips = props.generateTips;
  // const messageIndex = props.messageIndex;
  // const improveText = props.improveText;
  // const setImproveText = props.setImproveText;
  const closeModal = props.closeModal;
  const responseObject = props.responseObject;
  // const fluencyPercent = responseObject.fluency.percent;

  const overallScore = responseObject.overall.percent;
  const fluencyWord = responseObject.fluency.word;
  const fluencyScore = responseObject.fluency.percent;
  const fluencyExplanation = responseObject.fluency.explanation;
  const relevancyWord = responseObject.relevance.word;
  const relevancyScore = responseObject.relevance.percent;
  const relevancyExplanation = responseObject.relevance.explanation;
  const tips = responseObject.tips;

  let subtitle = `${overallScore}%`;
  // if( isIelts ) {
  //   subtitle = `${responseObject.ieltsScores.fluencyAndCoherence}`;
  // }

  const body = (
    <div>

      <div class='ai-talk-score-row'>
        <div class='flex-row ai-talk-score-row-header'>
          <p><strong>Fluency</strong></p>
          <div class='flex-row'>
            {fluencyWord}
            <ScoreDonut scorePercent={fluencyScore} />
          </div>
        </div>
        <p>{fluencyExplanation}</p>
      </div>

      <div class='ai-talk-score-row'>
        <div class='flex-row ai-talk-score-row-header'>
          <p><strong>Relevance</strong></p>
          <div class='flex-row'>
            {relevancyWord}
            <ScoreDonut scorePercent={relevancyScore} />
          </div>
        </div>
        <p>{relevancyExplanation}</p>
      </div>

      <div class='ai-talk-score-spacer'></div>

      <div class='ai-talk-score-row'>
        <div class='flex-row ai-talk-score-row-header'>
          <p><strong>Example response</strong></p>
        </div>
        <p>{tips}</p>
      </div>

    </div>
  );


  // Page load actions
  // useEffect(() => {
  //   // if( improveText ) {
  //   //   setImproveElement(improveText);
  //   // } else {
  //   //   getTips();
  //   // }
  // }, []);


  // Define functions
  // function getTips() {
  //   generateTips(messageIndex).then((response) => {
  //     setImproveElement(response);
  //     setImproveText(response);
  //   });
  // }


  return (
    <Modal
      windowClass={'aitalk-message-modal'}
      closeModal={closeModal}
      // title={'Fluency Score'}
      title={'Score'}
      centerTitle={true}
      subtitle={subtitle}
      body={body}
      closeIcon={true}
    />
  );
}

export default AiTalkMessageScore;