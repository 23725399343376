import LessonCardPrimary from "../utilities/lessoncards/lessonCardPrimary";


function SeriesList(props) {

  // Define variables
  const filters = props.filters;
  const series = props.series;
  const baseLessons = series.lessons;
  let lessons = [];

  if( !filters ) {
    lessons = baseLessons;
  } else {

    const keys = [];
    if( filters.level && filters.level !== 'All' ) { keys.push('level') }
    if( filters.lessonType && filters.lessonType !== 'All' ) { keys.push('lessonType') }
    if( filters.searchTerm && filters.searchTerm !== '' ) { keys.push('searchTerm') }

    const filteredLessons = baseLessons.filter(lesson => {
      return keys.every(key => {
        if( key === 'level' ) {
          return filters.level === lesson[key];
        }
        if( key === 'lessonType' ) {
          return filters.lessonType === lesson[key];
        }
        if( key === 'searchTerm' ) {
          return lesson.title.toLowerCase().includes(filters.searchTerm)
        }
      });
    });

    lessons = filteredLessons;
  }


  // Screen components
  let seriesElement;
  if( lessons.length > 0 ) {
    seriesElement = (
      <div class='series-wrapper'>
        <div class='series-info-wrapper'>
          <div class='series-description-wrapper'>
            <div class='series-info'>
              <p class='series-intro-label'>Series</p>
              <h3>{series.title}</h3>
              <p>{series.description}</p>
              <p class='series-level'><i>{series.level}</i></p>
            </div>
          </div>
        </div>

        <div class='lesson-cards-container'>
          {lessons.map((lesson, index) =>
            <LessonCardPrimary
              lesson={lesson}
              key={index}
              index={index}
              firstInList={index === 0}
              lastInList={index === (lessons.length - 1)}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <>{seriesElement}</>
  );
}

export default SeriesList;