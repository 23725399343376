import { useState } from "react";

import alertMessage from "../../utilities/alertMessage";
import apiCall from "../../utilities/auth/apiCall";
import { blockUpdateName } from "../../utilities/auth/authApi";

import ButtonSubmitRound from "../../utilities/forms/buttonSubmitRound";


function StepShowCertificateValidateName(props) {

  // Define variables
  const initialFullName = props.fullName || ' ';
  const blockId = props.blockId;
  const setNameVerified = props.setNameVerified;
  const setTempFullName = props.setTempFullName;
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState(initialFullName);
  const startWord = fullName.length > 0 ? 'Confirm' : 'Enter';


  // Define functions
  function updateName(value) {
    setFullName(value);
    setTempFullName(value)
  }

  function handleSubmit(event) {
    event.preventDefault();

    if( fullName.length <= 3 ) {
      alertMessage('Please enter your full name', 'error');
      return;
    }

    setLoading(true);

    const payload = {
      fullName: fullName,
      blockId: blockId
    }

    apiCall(blockUpdateName, payload).then((response) => {
      if( response.success ) {
        setTimeout(() => {
          setNameVerified(true);
        }, 500);
      } else {
        alertMessage(response.error, 'error');
      }
    });

  }


  return (
    <div style={{marginTop: '15px'}}>

      <p><strong>{startWord} the name you want on your certificate</strong></p>

      <form onSubmit={(event) => handleSubmit(event)}>
        <input
          type='text'
          class='input'
          defaultValue={fullName}
          onChange={(event) => updateName(event.target.value)}
          style={{textAlign: 'center'}}
        />

        <ButtonSubmitRound
          value={"Save Full Name"}
          // handleButtonClick={handleSubmit}
          // disabled={disabled}
          loading={loading}
        />

      </form>

    </div>
  );
}

export default StepShowCertificateValidateName